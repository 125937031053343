
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12H9.5V21.672C10.3005 21.8785 11.135 22 12 22C17.523 22 22 17.523 22 12Z" fill="#FF3D00"/>
<path d="M22 12C22 6.477 17.523 2 12 2C11.135 2 10.3005 2.1215 9.5 2.328V12H22Z" fill="#FFC107"/>
<path d="M9.5 2.32788C5.1895 3.43938 2 7.34188 2 11.9999C2 16.6579 5.1895 20.5604 9.5 21.6719V11.9999V2.32788Z" fill="#43A047"/>
  </svg>
</template>

<script>
export default {
  name: 'BJ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  