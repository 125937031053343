
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M11.9945 22C14.0295 22 15.9205 21.3885 17.5 20.3445V3.6555C15.9205 2.6115 14.03 2 11.9945 2C9.964 2 8.0775 2.6095 6.5 3.6495V20.351C8.0775 21.3905 9.964 22 11.9945 22Z" fill="#FFCA28"/>
<path d="M11.654 11.0794L10.1135 13.7389L8.573 11.0794L10.1135 8.41992L11.654 11.0794Z" fill="#007D2A"/>
<path d="M15.4235 11.0794L13.883 13.7389L12.3425 11.0794L13.883 8.41992L15.4235 11.0794Z" fill="#007D2A"/>
<path d="M13.5585 14.358L12.018 17.018L10.4775 14.358L12.018 11.6985L13.5585 14.358Z" fill="#007D2A"/>
<path d="M21.9945 12C21.9945 8.51252 20.2065 5.44502 17.5 3.65552V20.344C20.207 18.555 21.9945 15.4875 21.9945 12Z" fill="#2E7D32"/>
<path d="M1.99451 11.9999C1.99451 15.4919 3.78751 18.5619 6.50001 20.3504V3.64941C3.78751 5.43791 1.99451 8.50791 1.99451 11.9999Z" fill="#283593"/>
  </svg>
</template>

<script>
export default {
  name: 'VC',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  