
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C12 22 2.16702 9.9035 2.47652 8.939C3.76902 4.9125 7.54302 2 12 2C16.457 2 20.231 4.9125 21.5235 8.939C21.833 9.9035 12 22 12 22Z" fill="#212121"/>
<path d="M18 9.5L14.8785 8.8935L17.4805 7.196L14.44 7.8355L16.1945 5.2715L13.6305 7.026L14.27 3.9855L12.5725 6.5875L12 3.5L11.4275 6.5875L9.73 3.9855L10.3695 7.026L7.8055 5.2715L9.56 7.8355L6.5195 7.196L9.1215 8.8935L6 9.5L12 10L18 9.5Z" fill="#FDD835"/>
<path d="M15.0001 21L12.0001 22L9.00006 21L5.40906 13.4775H18.5911L15.0001 21Z" fill="white"/>
<path d="M21.5 9.5H2.5V14H21.5V9.5Z" fill="#0277BD"/>
<path d="M2.48 8.93994L12 21.9999C11.48 21.9999 10.975 21.9599 10.48 21.8849C5.675 21.1499 2 17.0049 2 11.9999C2 10.9349 2.165 9.90494 2.48 8.93994Z" fill="#C62828"/>
<path d="M22 11.9999C22 17.0049 18.325 21.1499 13.52 21.8849C13.025 21.9599 12.52 21.9999 12 21.9999L21.52 8.93994C21.835 9.90494 22 10.9349 22 11.9999Z" fill="#C62828"/>
  </svg>
</template>

<script>
export default {
  name: 'AG',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  