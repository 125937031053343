
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.799 10C20.8725 5.4355 16.838 2 12 2C11.831 2 11.6665 2.017 11.5 2.025V10H21.799Z" fill="#3F51B5"/>
<path d="M2.20105 14C2.81705 17.0355 4.81255 19.563 7.50005 20.9215V14H2.20105Z" fill="#3F51B5"/>
<path d="M7.50005 3.07861C4.81255 4.43711 2.81705 6.96461 2.20105 10.0001H7.50005V3.07861Z" fill="#3F51B5"/>
<path d="M11.5 21.975C11.6665 21.9835 11.831 22 12 22C16.838 22 20.8725 18.5645 21.799 14H11.5V21.975Z" fill="#3F51B5"/>
<path d="M8.50004 2.64209C8.15754 2.77009 7.82354 2.91459 7.50004 3.07859V10.0001H2.20104C2.13454 10.3281 2.08454 10.6616 2.05054 11.0001H8.50004V2.64209Z" fill="white"/>
<path d="M2.05054 13C2.08404 13.3385 2.13404 13.672 2.20104 14H7.50004V20.9215C7.82354 21.085 8.15754 21.2295 8.50004 21.358V13H2.05054Z" fill="white"/>
<path d="M11.5 9.9999V2.0249C11.162 2.0419 10.8285 2.0744 10.5 2.1244V10.9999H21.9495C21.916 10.6614 21.866 10.3279 21.799 9.9999H11.5Z" fill="white"/>
<path d="M10.5 21.8755C10.8285 21.925 11.162 21.958 11.5 21.975V14H21.799C21.8655 13.672 21.9155 13.3385 21.9495 13H10.5V21.8755Z" fill="white"/>
<path d="M10.5 2.12451C9.807 2.22901 9.1385 2.40301 8.5 2.64201V11H2.0505C2.018 11.329 2 11.6625 2 12C2 12.3375 2.018 12.671 2.0505 13H8.5V21.358C9.1385 21.597 9.807 21.771 10.5 21.8755V13H21.9495C21.982 12.671 22 12.3375 22 12C22 11.6625 21.982 11.329 21.9495 11H10.5V2.12451Z" fill="#FF3D00"/>
  </svg>
</template>

<script>
export default {
  name: 'IS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  