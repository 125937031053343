
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 10.577 21.698 9.2255 21.1625 8H2.8375C2.302 9.2255 2 10.577 2 12C2 13.423 2.302 14.7745 2.8375 16H21.162C21.698 14.7745 22 13.423 22 12Z" fill="white"/>
<path d="M21.1625 16H2.83752C4.38152 19.5305 7.90052 22 12 22C16.0995 22 19.6185 19.5305 21.1625 16Z" fill="#388E3C"/>
<path d="M12 2C7.90052 2 4.38152 4.4695 2.83752 8H21.162C19.6185 4.4695 16.0995 2 12 2Z" fill="#283593"/>
<path d="M14.074 13.1621L13.865 13.2421L13.037 12.2751C13.199 11.9956 13.5895 11.2421 13.4765 10.6326C13.334 9.86109 12.824 9.50759 11.963 9.49609C11.1565 9.50409 10.6135 9.94159 10.51 10.6701C10.424 11.2736 10.809 12.0216 10.959 12.2871L10.1485 13.2501L9.912 13.1776L9.5 13.9531C9.5 13.9531 10.5175 14.5156 11.996 14.5001C13.619 14.4826 14.5 13.9276 14.5 13.9276L14.074 13.1621ZM12.9865 10.7226C13.049 11.0626 12.8515 11.5506 12.6895 11.8691L12.252 11.3611L12.25 10.8106H12.5195L12.1525 10.5081L12.1545 10.0081C12.7285 10.0546 12.912 10.3241 12.9865 10.7226ZM11.004 10.7401C11.08 10.2146 11.4515 10.0446 11.803 10.0076L11.807 10.5176L11.475 10.8241H11.7445L11.7405 11.3571L11.307 11.8746C11.1485 11.5586 10.957 11.0761 11.004 10.7401ZM10.049 13.4961L10.127 13.7071L9.8985 13.7501C9.8985 13.7501 10.031 13.5176 10.049 13.4961ZM9.9415 13.8671L10.18 13.7966L10.229 13.9666L9.9415 13.8671ZM10.289 13.5021L10.625 13.5921L10.703 13.7386L10.408 13.8126L10.289 13.5021ZM10.5175 14.0526L10.4745 13.9256L10.752 13.8456L10.9065 14.1366L10.5175 14.0526ZM10.908 13.6601L11.244 13.7226L11.3395 13.8496L11.0445 13.9511L10.908 13.6601ZM11.1485 14.1681L11.0995 14.0566L11.412 13.9626L11.6035 14.2261L11.1485 14.1681ZM11.537 13.7441L12.084 13.7656L12.133 13.8671L11.7055 14.0036L11.537 13.7441ZM11.873 14.2366L11.783 14.1001L12.205 13.9636L12.4025 14.2216L11.873 14.2366ZM12.342 13.7561L12.7325 13.7131L12.8225 13.8301L12.4865 13.9726L12.342 13.7561ZM12.6485 14.1956L12.5585 14.0686L12.8945 13.9201L13.0975 14.1311L12.6485 14.1956ZM13.004 13.6916L13.3635 13.5961L13.459 13.6861L13.1775 13.8836L13.004 13.6916ZM13.3205 14.0841L13.248 13.9726L13.5195 13.7931L13.6935 13.9981L13.3205 14.0841ZM13.58 13.5276L13.8905 13.4161L13.957 13.5021L13.7715 13.7441L13.58 13.5276ZM13.885 13.8026L14.018 13.6286L14.1315 13.8296L13.905 13.8981L13.885 13.8026Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'LS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  