
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M15.5 2.642C14.409 2.234 13.2335 2 12 2C10.7665 2 9.591 2.234 8.5 2.642V21.3575C9.591 21.766 10.7665 22 12 22C13.2335 22 14.409 21.766 15.5 21.358V2.642Z" fill="#FFC107"/>
<path d="M22 12.0001C22 7.71059 19.294 4.06259 15.5 2.64209V21.3576C19.294 19.9376 22 16.2896 22 12.0001Z" fill="#FF3D00"/>
<path d="M2 12.0001C2 16.2896 4.706 19.9376 8.5 21.3581V2.64209C4.706 4.06259 2 7.71059 2 12.0001Z" fill="#2196F3"/>
  </svg>
</template>

<script>
export default {
  name: 'TD',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  