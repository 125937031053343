
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#388E3C"/>
<path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="#263238"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#D50000"/>
<path d="M14.5021 4.53312C14.5841 4.39062 14.6211 4.22862 14.5351 4.17962C14.4396 4.12512 14.3241 4.24612 14.2441 4.38462C14.0451 4.72862 13.9881 4.92562 13.7891 5.47062C14.1621 5.02562 14.3046 4.87312 14.5021 4.53312Z" fill="#D50000"/>
<path d="M14.5295 6.039C15.006 5.705 15.184 5.5955 15.461 5.3185C15.576 5.2015 15.6545 5.055 15.584 4.9845C15.506 4.9065 15.3635 4.9945 15.25 5.1075C14.9685 5.3885 14.8635 5.5645 14.5295 6.039Z" fill="#D50000"/>
<path d="M12.5625 3.85349C12.576 3.69149 12.541 3.52749 12.4415 3.51949C12.332 3.50949 12.2795 3.66999 12.2655 3.82799C12.2305 4.22449 12.2615 4.42549 12.3105 5.00399C12.463 4.44349 12.5275 4.24399 12.5625 3.85349Z" fill="#D50000"/>
<path d="M14.8435 4.76556C14.9375 4.63256 14.988 4.47256 14.906 4.41606C14.818 4.35356 14.691 4.46506 14.5995 4.59556C14.371 4.92156 14.2985 5.11306 14.0525 5.63656C14.463 5.22656 14.619 5.08806 14.8435 4.76556Z" fill="#D50000"/>
<path d="M12.9706 3.92207C13.0001 3.76007 12.9786 3.59407 12.8811 3.57607C12.7736 3.55857 12.7051 3.71257 12.6781 3.86907C12.6096 4.25957 12.6236 4.46457 12.6216 5.04507C12.8206 4.50007 12.9026 4.30657 12.9706 3.92207Z" fill="#D50000"/>
<path d="M13.371 4.02344C13.414 3.86544 13.408 3.69944 13.3105 3.67394C13.205 3.64444 13.125 3.79294 13.084 3.94744C12.9805 4.33044 12.9765 4.53544 12.926 5.11344C13.172 4.58794 13.2695 4.40244 13.371 4.02344Z" fill="#D50000"/>
<path d="M13.7634 4.16007C13.8179 4.00557 13.8259 3.83957 13.7339 3.80457C13.6304 3.76757 13.5364 3.90807 13.4819 4.05857C13.3469 4.43157 13.3254 4.63457 13.2219 5.20707C13.5134 4.70307 13.6289 4.52957 13.7634 4.16007Z" fill="#D50000"/>
<path d="M14.2991 5.828C14.7446 5.455 14.9126 5.33 15.1641 5.029C15.2696 4.904 15.3341 4.7495 15.2581 4.685C15.1741 4.6145 15.0391 4.7145 14.9356 4.8375C14.6796 5.1425 14.5921 5.326 14.2991 5.828Z" fill="#D50000"/>
<path d="M11.9999 5.36353C10.6054 5.36353 9.42192 6.25603 8.98242 7.50003H15.0194C14.5779 6.25603 13.3944 5.36353 11.9999 5.36353Z" fill="#D50000"/>
<path d="M15.3616 7.34408C15.9341 7.24458 16.1411 7.21908 16.5101 7.08608C16.6646 7.02958 16.7971 6.92808 16.7641 6.83408C16.7251 6.73258 16.5591 6.75008 16.4086 6.80458C16.0351 6.94158 15.8651 7.05658 15.3616 7.34408Z" fill="#D50000"/>
<path d="M15.76 7.5H16.559C16.5805 7.494 16.6 7.49 16.6235 7.4845C16.7815 7.4415 16.9225 7.3515 16.897 7.256C16.8675 7.1505 16.6995 7.1545 16.5455 7.1955C16.246 7.2755 16.0705 7.3555 15.76 7.5Z" fill="#D50000"/>
<path d="M15.0974 6.77949C15.6444 6.58199 15.8434 6.52149 16.1834 6.32449C16.3259 6.24249 16.4394 6.11949 16.3884 6.03349C16.3359 5.93949 16.1734 5.98649 16.0349 6.06649C15.6914 6.26549 15.5429 6.40799 15.0974 6.77949Z" fill="#D50000"/>
<path d="M15.2419 7.05644C15.8024 6.90594 16.0074 6.86294 16.3629 6.69694C16.5114 6.62844 16.6344 6.51544 16.5934 6.42544C16.5464 6.32794 16.3824 6.36094 16.2379 6.42744C15.8769 6.59544 15.7184 6.72444 15.2419 7.05644Z" fill="#D50000"/>
<path d="M14.9294 6.51547C15.4569 6.27147 15.6499 6.19297 15.9724 5.96847C16.1074 5.87447 16.2089 5.74197 16.1519 5.66197C16.0894 5.57197 15.9314 5.63247 15.8004 5.72447C15.4764 5.95297 15.3419 6.10747 14.9294 6.51547Z" fill="#D50000"/>
<path d="M14.74 6.26758C15.244 5.97858 15.4295 5.88458 15.73 5.63258C15.857 5.52708 15.947 5.38658 15.8825 5.31008C15.812 5.22608 15.66 5.30008 15.5385 5.40408C15.2345 5.66008 15.115 5.82408 14.74 6.26758Z" fill="#D50000"/>
<path d="M14.1405 4.33008C14.211 4.18158 14.2325 4.01758 14.1425 3.97458C14.043 3.92958 13.9375 4.06058 13.871 4.20508C13.703 4.56458 13.664 4.76558 13.5115 5.32608C13.8455 4.85158 13.9745 4.68558 14.1405 4.33008Z" fill="#D50000"/>
<path d="M7.81647 6.32404C8.15997 6.52304 8.35747 6.58004 8.90247 6.77904C8.45697 6.40604 8.30497 6.26354 7.96497 6.06604C7.82447 5.98404 7.66047 5.94704 7.61147 6.03304C7.55647 6.12904 7.67747 6.24404 7.81647 6.32404Z" fill="#D50000"/>
<path d="M8.02744 5.96888C8.35344 6.19738 8.54494 6.26988 9.07044 6.51588C8.66044 6.10588 8.51944 5.94938 8.19944 5.72488C8.06444 5.63088 7.90644 5.58038 7.84794 5.66038C7.78494 5.75188 7.89644 5.87688 8.02744 5.96888Z" fill="#D50000"/>
<path d="M8.26941 5.633C8.57391 5.887 8.75791 5.977 9.25941 6.268C8.88641 5.8245 8.76141 5.6565 8.46041 5.4045C8.33541 5.299 8.18091 5.2345 8.11641 5.3105C8.04691 5.3945 8.14641 5.5295 8.26941 5.633Z" fill="#D50000"/>
<path d="M12.1486 3.8225C12.1486 3.658 12.0996 3.5 12.0001 3.5C11.8906 3.5 11.8516 3.664 11.8516 3.8225C11.8516 4.221 11.9006 4.42 12.0001 4.9905C12.1016 4.42 12.1486 4.215 12.1486 3.8225Z" fill="#D50000"/>
<path d="M7.49002 7.08594C7.86302 7.22094 8.06802 7.24244 8.63852 7.34594C8.13652 7.05494 7.96102 6.93944 7.59152 6.80494C7.43702 6.75044 7.27102 6.74244 7.23802 6.83644C7.19902 6.93744 7.34002 7.03144 7.49002 7.08594Z" fill="#D50000"/>
<path d="M7.37894 7.48444C7.40044 7.49044 7.41994 7.49444 7.44144 7.49994H8.24244C7.92994 7.35344 7.75044 7.27544 7.45544 7.19544C7.29744 7.15444 7.13144 7.16044 7.10594 7.25594C7.07594 7.36144 7.22444 7.44344 7.37894 7.48444Z" fill="#D50000"/>
<path d="M9.70097 5.82798C9.40997 5.32398 9.31597 5.13848 9.06447 4.83798C8.95897 4.71098 8.81847 4.62098 8.74197 4.68548C8.65797 4.75598 8.73197 4.90598 8.83597 5.02948C9.09197 5.33398 9.25797 5.45298 9.70097 5.82798Z" fill="#D50000"/>
<path d="M7.63657 6.69751C7.99607 6.86551 8.19707 6.90451 8.75757 7.05701C8.28307 6.72301 8.11707 6.59401 7.76157 6.42801C7.61307 6.35951 7.44907 6.33601 7.40607 6.42601C7.36157 6.52551 7.49207 6.63101 7.63657 6.69751Z" fill="#D50000"/>
<path d="M9.47039 6.03902C9.13639 5.56252 9.02689 5.38452 8.74989 5.10752C8.63289 4.99252 8.48639 4.91402 8.41589 4.98452C8.33789 5.06252 8.42589 5.20502 8.53889 5.31852C8.82039 5.59952 8.99589 5.70502 9.47039 6.03902Z" fill="#D50000"/>
<path d="M11.3789 5.04511C11.3789 4.46511 11.3904 4.25611 11.3224 3.86911C11.2949 3.70711 11.2189 3.55861 11.1194 3.57611C11.0119 3.59561 11.0024 3.76361 11.0294 3.92161C11.0974 4.31261 11.1814 4.50011 11.3789 5.04511Z" fill="#D50000"/>
<path d="M9.9476 5.63659C9.7036 5.11109 9.6251 4.91759 9.4006 4.59559C9.3066 4.46059 9.1741 4.35909 9.0941 4.41609C9.0041 4.47859 9.0646 4.63659 9.1566 4.76559C9.3851 5.09209 9.5391 5.22659 9.9476 5.63659Z" fill="#D50000"/>
<path d="M11.6876 5.00409C11.7386 4.42609 11.7696 4.21909 11.7346 3.82809C11.7211 3.66409 11.6566 3.51159 11.5586 3.51959C11.4491 3.52959 11.4236 3.69559 11.4376 3.85559C11.4726 4.25009 11.5391 4.44359 11.6876 5.00409Z" fill="#D50000"/>
<path d="M11.0739 5.11345C11.0229 4.53545 11.0174 4.32445 10.9159 3.94545C10.8729 3.78745 10.7849 3.64645 10.6894 3.67195C10.5819 3.70145 10.5879 3.86945 10.6289 4.02345C10.7304 4.40645 10.8299 4.58595 11.0739 5.11345Z" fill="#D50000"/>
<path d="M10.7775 5.2069C10.676 4.6344 10.6525 4.4274 10.5175 4.0584C10.461 3.9039 10.3595 3.7714 10.2655 3.8044C10.164 3.8434 10.1815 4.0094 10.236 4.1599C10.373 4.5329 10.4885 4.7029 10.7775 5.2069Z" fill="#D50000"/>
<path d="M10.2109 5.47041C10.0134 4.92341 9.95289 4.72441 9.75589 4.38441C9.67389 4.24191 9.55089 4.12841 9.46489 4.17941C9.37089 4.23391 9.41789 4.39441 9.49789 4.53291C9.69739 4.87691 9.83989 5.02541 10.2109 5.47041Z" fill="#D50000"/>
<path d="M10.4886 5.32604C10.3381 4.76553 10.2951 4.56053 10.1291 4.20503C10.0606 4.05653 9.94757 3.93353 9.85757 3.97453C9.76007 4.02153 9.79307 4.18553 9.85957 4.33003C10.0276 4.69153 10.1561 4.84954 10.4886 5.32604Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'MW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  