
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C6.477 2 2 6.477 2 12C2 12.7925 2.102 13.5595 2.276 14.2985L17.543 3.6785C15.956 2.6195 14.051 2 12 2Z" fill="#1A237E"/>
<path d="M22 11.9999C22 11.2074 21.898 10.4404 21.724 9.70142L6.45703 20.3214C8.04403 21.3804 9.94903 21.9999 12 21.9999C17.523 21.9999 22 17.5229 22 11.9999Z" fill="#388E3C"/>
<path d="M17.543 3.67847L2.276 14.2985C2.384 14.7565 2.5225 15.2015 2.691 15.6325L18.641 4.53747C18.2935 4.22847 17.931 3.93747 17.543 3.67847Z" fill="white"/>
<path d="M21.309 8.36743L5.35901 19.4624C5.70601 19.7714 6.06901 20.0629 6.45701 20.3219L21.724 9.70193C21.616 9.24343 21.4775 8.79843 21.309 8.36743Z" fill="white"/>
<path d="M18.6406 4.53809L2.69055 15.6331C3.26955 17.1156 4.19055 18.4221 5.35905 19.4626L21.3091 8.36759C20.7301 6.88509 19.8091 5.57809 18.6406 4.53809Z" fill="#D50000"/>
<path d="M9.5235 6.8905L10.5 6.5L9.5275 6.1115L10.1955 5.305L9.0645 5.44L9.203 4.309L8.3905 4.977L8 4L7.6115 4.9725L6.805 4.3045L6.94 5.4355L5.809 5.297L6.477 6.1095L5.5 6.5L6.4725 6.8885L5.8045 7.695L6.9355 7.56L6.797 8.691L7.6095 8.023L8 9L8.3885 8.0275L9.195 8.6955L9.06 7.5645L10.191 7.703L9.5235 6.8905ZM8 8C7.172 8 6.5 7.328 6.5 6.5C6.5 5.672 7.172 5 8 5C8.828 5 9.5 5.672 9.5 6.5C9.5 7.328 8.828 8 8 8Z" fill="#FFD600"/>
<path d="M9 6.5C9 7.0525 8.5525 7.5 8 7.5C7.4475 7.5 7 7.0525 7 6.5C7 5.9475 7.4475 5.5 8 5.5C8.5525 5.5 9 5.9475 9 6.5Z" fill="#FFD600"/>
  </svg>
</template>

<script>
export default {
  name: 'NA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  