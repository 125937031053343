
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.541 15H2.45898C3.73348 19.057 7.52248 22 12 22C16.4775 22 20.2665 19.057 21.541 15Z" fill="#B71C1C"/>
<path d="M12 2C7.52248 2 3.73348 4.943 2.45898 9H21.5405C20.2665 4.943 16.4775 2 12 2Z" fill="#B71C1C"/>
<path d="M22 12C22 10.9545 21.8385 9.947 21.541 9H2.459C2.1615 9.947 2 10.9545 2 12C2 13.0455 2.1615 14.053 2.459 15H21.5405C21.8385 14.053 22 13.0455 22 12Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'LV',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  