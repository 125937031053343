
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.358 8.5C19.9375 4.706 16.2895 2 12 2C8.62348 2 5.64448 3.678 3.83398 6.2405L7.29748 8.5H21.358Z" fill="#00ACC1"/>
<path d="M21.358 15.5H7.30296L3.83496 17.761C5.64546 20.3225 8.62446 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#00ACC1"/>
<path d="M12.6665 12.0025L7.30248 15.5H21.3575C21.766 14.409 22 13.2335 22 12C22 10.7665 21.766 9.591 21.358 8.5H7.29749L12.6665 12.0025Z" fill="#FFEB3B"/>
<path d="M12.6665 12.0025L7.2975 8.49998L3.834 6.24048C2.6825 7.86948 2 9.85348 2 12C2 14.147 2.683 16.1315 3.835 17.761L7.3025 15.5L12.6665 12.0025Z" fill="#424242"/>
  </svg>
</template>

<script>
export default {
  name: 'BS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  