
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C6.477 2 2 6.477 2 12H12V2Z" fill="white"/>
<path d="M12 22C17.523 22 22 17.523 22 12H12V22Z" fill="white"/>
<path d="M2 12C2 17.523 6.477 22 12 22V12H2Z" fill="#01579B"/>
<path d="M12 2V12H22C22 6.477 17.523 2 12 2Z" fill="#D32F2F"/>
<path d="M9.5 7.01545H7.9725L7.5 5.56445L7.0275 7.01545H5.5L6.7365 7.91395L6.264 9.36695L7.5 8.47045L8.7365 9.36695L8.264 7.91395L9.5 7.01545Z" fill="#01579B"/>
<path d="M17.2635 16.414L18.5 15.5155H16.9725L16.5 14.0625L16.0275 15.5155H14.5L15.7365 16.414L15.264 17.867L16.5 16.969L17.7365 17.8675L17.2635 16.414Z" fill="#D32F2F"/>
  </svg>
</template>

<script>
export default {
  name: 'PA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  