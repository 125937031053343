
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M18 4.0135C16.3275 2.755 14.2545 2 12 2C9.7455 2 7.6725 2.755 6 4.0135V19.9865C7.6725 21.245 9.7455 22 12 22C14.2545 22 16.3275 21.245 18 19.9865V4.0135Z" fill="white"/>
<path d="M22 11.9999C22 8.73143 20.425 5.83843 18 4.01343V19.9864C20.425 18.1614 22 15.2684 22 11.9999Z" fill="#FF3D00"/>
<path d="M2 11.9999C2 15.2684 3.575 18.1614 6 19.9864V4.01343C3.575 5.83843 2 8.73143 2 11.9999Z" fill="#FF3D00"/>
<path d="M16.5 11.5H15L14.9805 10.508L13.824 11.086L14 8.5L13 9L12.004 7.5L11 9L10 8.5L10.176 11.0725L9.0195 10.508C9.0195 10.508 8.998 11.4945 9 11.5H7.5L8.5 13L7.5 13.5L9.5 14.5V15.5C9.5 15.5 10.768 15.431 11.5 15.394V16.5H12.5V15.394C13.232 15.431 14.5 15.5 14.5 15.5V14.5L16.5 13.5L15.5 13L16.5 11.5Z" fill="#FF3D00"/>
  </svg>
</template>

<script>
export default {
  name: 'CA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  