
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.9749 12.5H2.0249C2.1164 14.3415 2.6964 16.0525 3.6524 17.5H20.3474C21.3029 16.0525 21.8834 14.3415 21.9749 12.5Z" fill="#3F51B5"/>
<path d="M22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 12.169 2.017 12.3335 2.025 12.5H21.975C21.983 12.3335 22 12.169 22 12Z" fill="#FFC107"/>
<path d="M20.3476 17.5H3.65259C5.44159 20.2095 8.51009 22 12.0001 22C15.4901 22 18.5586 20.2095 20.3476 17.5Z" fill="#FF3D00"/>
  </svg>
</template>

<script>
export default {
  name: 'CO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  