
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.358 8.5C19.9375 4.706 16.2895 2 12 2C10.2685 2 8.63997 2.4405 7.21997 3.215L14.2675 8.5H21.358Z" fill="#E53935"/>
<path d="M14.2675 15.5L7.21997 20.785C8.63997 21.5595 10.2685 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5H14.2675Z" fill="#283593"/>
<path d="M21.7991 10.0001C21.6941 9.48309 21.5391 8.98509 21.3576 8.50009H14.2676L7.22008 3.21509C6.78608 3.45159 6.37808 3.72809 5.98608 4.02409L14.0001 10.0001H21.7991Z" fill="white"/>
<path d="M21.3581 15.5C21.5396 15.015 21.6946 14.517 21.7996 14H14.0001L5.9856 19.9755C6.3776 20.2715 6.7856 20.548 7.2196 20.7845L14.2676 15.5H21.3581Z" fill="white"/>
<path d="M21.799 13.9999C21.9305 13.3534 22 12.6849 22 11.9999C22 11.3149 21.9305 10.6464 21.799 9.99991H14L5.98551 4.02441C4.85351 4.87941 3.90301 5.96141 3.21851 7.21391L9.60001 11.9999L3.21851 16.7859C3.90251 18.0384 4.85351 19.1204 5.98551 19.9754L14 13.9999H21.799Z" fill="#2E7D32"/>
<path d="M3.21856 7.21411C2.99006 7.63211 2.80056 8.07311 2.63306 8.52461L7.26756 12.0001L2.63306 15.4756C2.80056 15.9271 2.99006 16.3681 3.21856 16.7861L9.60006 12.0001L3.21856 7.21411Z" fill="#FFC107"/>
<path d="M2.633 8.52441C2.2305 9.60841 2 10.7759 2 11.9999C2 13.2239 2.2305 14.3914 2.633 15.4754L7.2675 11.9999L2.633 8.52441Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'ZA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  