
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M4.5 5.39755V18.5996C5.789 20.0626 7.492 21.1516 9.4335 21.6656V2.33105C7.492 2.84555 5.789 3.93455 4.5 5.39755Z" fill="#E53935"/>
<path d="M4.5 5.40088C2.9485 7.16238 2 9.46788 2 11.9999C2 14.5319 2.9485 16.8374 4.5 18.5989V5.40088Z" fill="#388E3C"/>
<path d="M12 2C11.111 2 10.254 2.1275 9.43347 2.345V21.655C10.254 21.8725 11.111 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#388E3C"/>
<path d="M14.9015 5.01758C15.451 5.46408 15.8095 6.13708 15.8095 6.90058C15.8095 8.24558 14.719 9.33608 13.374 9.33608C13.059 9.33608 12.76 9.27158 12.4835 9.16258C12.9025 9.50308 13.4295 9.71558 14.011 9.71558C15.356 9.71558 16.4465 8.62508 16.4465 7.28008C16.447 6.24958 15.805 5.37358 14.9015 5.01758Z" fill="white"/>
<path d="M12.3876 7.25242L12.6736 7.44792L12.9536 7.24292L12.8561 7.57592L13.1371 7.77842L12.7906 7.78842L12.6846 8.11892L12.5681 7.79192L12.2216 7.79342L12.4961 7.58142L12.3876 7.25242Z" fill="white"/>
<path d="M12.395 6.03855L12.681 6.23405L12.961 6.02905L12.8635 6.36205L13.1445 6.56455L12.798 6.57455L12.6925 6.90455L12.5755 6.57805L12.229 6.57955L12.5035 6.36755L12.395 6.03855Z" fill="white"/>
<path d="M11.1579 6.64549L11.4444 6.84099L11.7239 6.63599L11.6264 6.96899L11.9079 7.17149L11.5614 7.18149L11.4554 7.51199L11.3389 7.18499L10.9919 7.18649L11.2664 6.97449L11.1579 6.64549Z" fill="white"/>
<path d="M13.609 5.39353L13.8955 5.58903L14.175 5.38403L14.0775 5.71703L14.359 5.91953L14.012 5.92953L13.9065 6.26003L13.7895 5.93303L13.443 5.93453L13.7175 5.72303L13.609 5.39353Z" fill="white"/>
<path d="M12.395 4.7866L12.681 4.9821L12.961 4.7771L12.8635 5.1101L13.1445 5.3126L12.798 5.3226L12.6925 5.6531L12.5755 5.3261L12.229 5.3276L12.5035 5.1161L12.395 4.7866Z" fill="white"/>
<path d="M4.84802 5.2939L5.27502 5.5909V5.7859L5.58802 6.0319H5.90852L6.37502 6.3564H7.55152L8.03702 6.0319H8.44302L8.74652 5.8254V5.5574L9.12602 5.3039V4.7724L8.74652 4.5029V4.2414L8.40502 3.9954H8.03202L7.58902 3.6804L6.49752 3.6709C5.90202 4.0639 5.34952 4.5154 4.85252 5.0234L4.84802 5.2939Z" fill="white"/>
<path d="M7.04248 6.35712H7.55148L8.03748 6.03262H8.44348L8.74698 5.82562V5.55812L9.12648 5.30412V4.94312H7.04248V6.35712Z" fill="#FFC107"/>
<path d="M7.02355 4.94306V3.67556L6.48355 3.66406C5.92005 4.03606 5.41105 4.43906 4.93555 4.94306H7.02355Z" fill="#FFC107"/>
<path d="M8.88306 7.77759H5.11206V9.25359H8.88306V7.77759Z" fill="#43A047"/>
<path d="M8.47044 7.6001H5.53894V9.4601H8.47044V7.6001Z" fill="#FFC107"/>
<path d="M7.91547 7.39355H6.07947V9.66655H7.91547V7.39355Z" fill="#43A047"/>
<path d="M7.41695 7.24609H6.54895V9.81909H7.41695V7.24609Z" fill="#FFC107"/>
<path d="M8.01047 5.16492L8.28547 4.97042L8.01047 4.77092V4.45892H7.57997L7.02397 4.05542L6.49247 4.45892H6.02747V4.81192L5.81897 4.97042L6.02747 5.12992V5.51142H6.52797L7.00497 5.87592L7.51997 5.51142H8.01047V5.16492Z" fill="#43A047"/>
<path d="M7.00449 4.98753H8.22449L7.97849 4.80953V4.49703H7.54799L7.00449 4.10303V4.97303H5.83449L5.78699 5.00903L5.99599 5.16803V5.55003H6.49599L6.97299 5.91403L7.00449 5.89203V4.98753Z" fill="#E53935"/>
<path d="M6.52502 4.99901L7.03452 4.63501L7.56102 4.98101L7.05202 5.32701L6.52502 4.99901Z" fill="white"/>
<path d="M7.05306 5.38647L6.43506 5.00147L7.03306 4.57397L7.65106 4.97997L7.05306 5.38647ZM6.61506 4.99597L7.05106 5.26747L7.47156 4.98147L7.03606 4.69547L6.61506 4.99597Z" fill="#E53935"/>
<path d="M7.49744 9.89012H6.49644V9.73312H6.01244V9.52662H5.48144V9.32962H5.05444V7.75412H5.48144V7.55712H6.01244V7.35012H6.49644V7.19312H7.49744V7.35012H7.98144V7.55712H8.51244V7.75412H8.93944V9.32962H8.51244V9.52662H7.98144V9.73312H7.49744V9.89012ZM6.59594 9.79062H7.39694V9.63362H7.88094V9.42712H8.41194V9.23011H8.83894V7.85362H8.41194V7.65662H7.88094V7.44962H7.39694V7.29262H6.59594V7.44962H6.11194V7.65662H5.58094V7.85362H5.15394V9.23011H5.58094V9.42712H6.11194V9.63362H6.59594V9.79062Z" fill="#FFC107"/>
<path d="M5.50254 8.3746L5.49304 8.6991H6.16654L6.59354 8.9846H7.39054L7.81754 8.6991L8.49104 8.6796L8.48154 8.3451L7.81754 8.3646L7.40954 8.0791H6.59354L6.18554 8.3746H5.50254Z" fill="#E53935"/>
<path d="M7.40553 9.03405H6.57853L6.15153 8.74905H5.44153L5.45403 8.32455H6.16953L6.57753 8.02905H7.42503L7.43803 8.03805L7.83253 8.31405L8.53003 8.29355L8.54253 8.72755L7.83353 8.74855L7.40553 9.03405ZM6.60853 8.93455H7.37503L7.80153 8.65005L8.43953 8.63105L8.43303 8.39605L7.80253 8.41455L7.39403 8.12905H6.61003L6.20203 8.42455H5.55153L5.54503 8.64955H6.18203L6.60853 8.93455Z" fill="#43A047"/>
<path d="M8.24705 13.1571H5.74705V12.8916H5.36755V11.1881H5.74705V10.9226H8.24705V11.1881H8.62655V12.8916H8.24705V13.1571ZM5.84655 13.0576H8.14655V12.7921H8.52605V11.2876H8.14655V11.0221H5.84655V11.2876H5.46705V12.7921H5.84655V13.0576Z" fill="#FFC107"/>
<path d="M6.98302 12.4335L6.48352 12.045L6.99252 11.646L7.46902 12.035L6.98302 12.4335ZM6.64602 12.045L6.98152 12.3055L7.31152 12.035L6.99102 11.774L6.64602 12.045Z" fill="white"/>
<path d="M6.34805 12.9019L5.90855 12.5309L5.88855 11.5599L6.32805 11.1784H6.34655L7.71355 11.1584L8.07705 11.4914L8.06705 12.5104L7.66705 12.8724H7.64855L6.34805 12.9019ZM6.00755 12.4839L6.38355 12.8014L7.62655 12.7724L7.96655 12.4654L7.97555 11.5354L7.67455 11.2589L6.36555 11.2779L5.98905 11.6044L6.00755 12.4839Z" fill="#43A047"/>
<path d="M6.99202 14.2295L6.65052 14.485H5.54052L5.50252 14.82H5.19902V15.1545H4.85752L4.84802 15.8925H5.21802V16.2565H5.55002V16.601H6.61252L6.99202 16.8665L7.37152 16.601H8.45302L8.48152 16.2565H8.78502L8.80402 15.9415H9.11702L9.10752 15.174H8.80402L8.78502 14.879H8.48152L8.50052 14.495H7.37152L6.99202 14.2295Z" fill="#43A047"/>
<path d="M6.99201 14.613L6.48901 15.056L7.00151 15.489L7.49501 15.0365L6.99201 14.613Z" fill="#E53935"/>
<path d="M6.14752 15.125L5.64502 15.5675L6.15702 16.0005L6.65052 15.548L6.14752 15.125Z" fill="#FFC107"/>
<path d="M7.87446 15.125L7.37146 15.5675L7.88346 16.0005L8.37696 15.548L7.87446 15.125Z" fill="#FFC107"/>
<path d="M6.48902 17.4805L5.21802 18.1395V19.2025L6.36602 19.9205H7.55152L8.76602 19.281V18.169L7.54202 17.49L6.48902 17.4805Z" fill="white"/>
<path d="M6.99201 15.5874L6.48901 16.0299L7.00151 16.4629L7.49501 16.0104L6.99201 15.5874Z" fill="#E53935"/>
<path d="M5.23706 18.6709L7.01106 18.6809V17.4604L6.54606 17.4804L5.27506 18.1394L5.23706 18.6709Z" fill="#FFC107"/>
<path d="M8.75651 18.74L7.08701 18.72L7.02051 19.9305L7.50451 19.9405L8.76601 19.232L8.75651 18.74Z" fill="#FFC107"/>
<path d="M7.56398 19.9704H6.35098L6.33898 19.9629L5.16748 19.2299V18.1089L6.47698 17.4299L6.48948 17.4304L7.56598 17.4459L8.81598 18.1399V19.3114L7.56398 19.9704ZM6.37998 19.8709H7.53898L8.71598 19.2509V18.1989L7.52898 17.5404L6.50098 17.5309L5.26748 18.1699V19.1749L6.37998 19.8709Z" fill="#43A047"/>
<path d="M6.99205 17.9524L6.47005 18.2869L6.26155 18.3069L6.24255 19.0744L6.48905 19.1039L6.99205 19.4484L7.51355 19.1234L7.73205 19.1139L7.72255 18.2869H7.54205L6.99205 17.9524Z" fill="#E53935"/>
<path d="M6.99054 19.5081L6.47104 19.1521L6.19104 19.1191L6.21204 18.2616L6.45254 18.2386L6.99054 17.8936L7.01704 17.9101L7.55554 18.2376H7.77104L7.78154 19.1616L7.52804 19.1731L6.99054 19.5081ZM6.29354 19.0301L6.51754 19.0626L6.99304 19.3886L7.49804 19.0741L7.68104 19.0651L7.67254 18.3366H7.52754L6.99204 18.0111L6.47404 18.3366L6.30954 18.3516L6.29354 19.0301Z" fill="white"/>
<path d="M7.00345 5.93793L6.51095 5.56143H5.97745V5.15493L5.73645 4.96993L5.97745 4.78693V4.40893H6.47545L7.02295 3.99243L7.59595 4.40893H8.05995V4.74543L8.37095 4.97043L8.05995 5.19043V5.56093H7.53545L7.00345 5.93793ZM6.07795 5.46193H6.54495L7.00595 5.81393L7.50395 5.46193H7.96045V5.13893L8.19995 4.96943L7.96045 4.79593V4.50893H7.56395L7.02495 4.11793L6.50995 4.50893H6.07845V4.83693L5.90145 4.96993L6.07795 5.10493V5.46193Z" fill="#FFC107"/>
<path d="M6.99199 16.9275L6.59649 16.6505H5.49999V16.306H5.16799V15.942H4.79749L4.80799 15.104H5.14899V14.77H5.45799L5.49599 14.4355H6.63399L6.99099 14.168L7.38699 14.4455H8.55299L8.53399 14.8295H8.83198L8.85099 15.1245H9.15698L9.16748 15.9915H8.85099L8.83198 16.3065H8.52749L8.49898 16.651H7.38699L6.99199 16.9275ZM5.59999 16.5515H6.62849L6.99199 16.806L7.35549 16.5515H8.40698L8.43549 16.207H8.73799L8.75699 15.892H9.06699L9.05849 15.224H8.75699L8.73799 14.929H8.42899L8.44799 14.545H7.35549L6.99299 14.291L6.66699 14.535H5.58499L5.54699 14.8695H5.24899V15.204H4.90649L4.89798 15.8425H5.26749V16.2065H5.59999V16.5515Z" fill="#FFC107"/>
<path d="M8.37044 18.3496H8.14294V18.5266H8.37044V18.3496Z" fill="#E53935"/>
<path d="M7.77303 17.877H7.54553V18.054H7.77303V17.877Z" fill="#E53935"/>
<path d="M8.37044 18.9546H8.14294V19.1316H8.37044V18.9546Z" fill="#E53935"/>
<path d="M5.89449 18.3496H5.66699V18.5266H5.89449V18.3496Z" fill="#E53935"/>
<path d="M5.89449 18.9546H5.66699V19.1316H5.89449V18.9546Z" fill="#E53935"/>
<path d="M7.75851 19.3831H7.53101V19.5601H7.75851V19.3831Z" fill="#E53935"/>
<path d="M6.52047 17.877H6.29297V18.054H6.52047V17.877Z" fill="#E53935"/>
<path d="M6.50655 19.3831H6.27905V19.5601H6.50655V19.3831Z" fill="#E53935"/>
<path d="M5.025 13.5864L4.9 13.4654L5.1245 13.2464L4.5 12.5439V12.7259L4.957 13.2404L4.726 13.4654L5.028 13.7574L5.269 13.5074L5.5815 13.8194L5.2975 14.1024L5.0435 13.8384L4.7145 14.1384L4.945 14.4074L4.5 14.8784V15.0544L5.108 14.4114L4.883 14.1494L5.038 14.0074L5.296 14.2744L5.7525 13.8194L5.2675 13.3339L5.025 13.5864Z" fill="#FFC107"/>
<path d="M5.025 17.0701L4.9 16.9491L5.1245 16.7301L4.5 16.0276V16.2096L4.957 16.7241L4.726 16.9491L5.028 17.2411L5.269 16.9911L5.5815 17.3031L5.2975 17.5861L5.0435 17.3221L4.7145 17.6221L4.945 17.8911L4.5 18.3621V18.5381L5.108 17.8951L4.883 17.6331L5.038 17.4911L5.296 17.7581L5.7525 17.3031L5.2675 16.8176L5.025 17.0701Z" fill="#FFC107"/>
<path d="M5.025 6.54054L4.9 6.41954L5.1245 6.20104L4.5 5.49854V5.68004L4.957 6.19504L4.726 6.41954L5.028 6.71154L5.269 6.46154L5.5815 6.77354L5.2975 7.05654L5.0435 6.79304L4.7145 7.09304L4.945 7.36204L4.5 7.83304V8.00904L5.108 7.36554L4.883 7.10354L5.038 6.96154L5.296 7.22904L5.7525 6.77354L5.2675 6.28804L5.025 6.54054Z" fill="#FFC107"/>
<path d="M5.025 10.103L4.9 9.98155L5.1245 9.76305L4.5 9.06055V9.24255L4.957 9.75705L4.726 9.98155L5.028 10.274L5.269 10.024L5.5815 10.336L5.2975 10.619L5.0435 10.355L4.7145 10.655L4.945 10.924L4.5 11.395V11.571L5.108 10.928L4.883 10.666L5.038 10.5235L5.296 10.791L5.7525 10.336L5.2675 9.85055L5.025 10.103Z" fill="#FFC107"/>
<path d="M9.43346 14.969V14.7925L9.06946 14.4075L9.29996 14.1385L8.97096 13.8385L8.71696 14.1025L8.43346 13.8195L8.74546 13.5075L8.98646 13.7575L9.28846 13.4655L9.05746 13.2405L9.43346 12.8175V12.6355L8.88996 13.2465L9.11446 13.4655L8.98946 13.5865L8.74696 13.334L8.26196 13.8195L8.71846 14.2745L8.97646 14.0075L9.13146 14.1495L8.90646 14.4115L9.43346 14.969Z" fill="#FFC107"/>
<path d="M9.43346 11.4851V11.3091L9.06946 10.9241L9.29996 10.6551L8.97096 10.3551L8.71696 10.6191L8.43346 10.3361L8.74546 10.0241L8.98646 10.2741L9.28846 9.9816L9.05746 9.7571L9.43346 9.3341V9.1521L8.88996 9.7631L9.11446 9.9816L8.98946 10.1031L8.74696 9.8506L8.26196 10.3361L8.71846 10.7911L8.97646 10.5236L9.13146 10.6661L8.90646 10.9281L9.43346 11.4851Z" fill="#FFC107"/>
<path d="M8.74696 6.28809L8.26196 6.77359L8.71846 7.22909L8.97646 6.96159L9.13146 7.10359L8.90646 7.36559L9.43346 7.92309V7.74709L9.06946 7.36209L9.29996 7.09309L8.97096 6.79309L8.71696 7.05659L8.43346 6.77359L8.74546 6.46159L8.98646 6.71159L9.28846 6.41959L9.05746 6.19509L9.43346 5.77159V5.59009L8.88996 6.20109L9.11446 6.41959L8.98946 6.54059L8.74696 6.28809Z" fill="#FFC107"/>
<path d="M8.74696 3.01691L8.26196 3.48891L8.71846 3.93241L8.97646 3.67191L9.13146 3.81041L8.90646 4.06541L9.43346 4.60791V4.43641L9.06946 4.06191L9.29996 3.79991L8.97096 3.50791L8.71696 3.76441L8.43346 3.48891L8.74546 3.18541L8.98646 3.42891L9.28846 3.14441L9.05746 2.92591L9.43346 2.51391V2.33691L8.88996 2.93191L9.11446 3.14441L8.98946 3.26241L8.74696 3.01691Z" fill="#FFC107"/>
<path d="M9.43346 18.4524V18.2759L9.06946 17.8909L9.29996 17.6219L8.97096 17.3219L8.71696 17.5859L8.43346 17.3029L8.74546 16.9909L8.98646 17.2409L9.28846 16.9489L9.05746 16.7239L9.43346 16.3009V16.1189L8.88996 16.7299L9.11446 16.9489L8.98946 17.0699L8.74696 16.8174L8.26196 17.3029L8.71846 17.7579L8.97646 17.4909L9.13146 17.6329L8.90646 17.8949L9.43346 18.4524Z" fill="#FFC107"/>
<path d="M8.90998 19.6565C8.91098 19.6645 8.98898 20.4615 8.50548 20.999C8.42148 21.0925 8.32198 21.169 8.21448 21.236C8.25298 21.2515 8.28998 21.269 8.32898 21.2845C8.42098 21.221 8.50548 21.1495 8.57998 21.0665C9.09298 20.4965 9.01348 19.681 9.00998 19.6465L8.90998 19.6565Z" fill="#FFC107"/>
<path d="M6.07198 4.0336L6.53148 3.7096L7.59498 3.7191L8.03848 4.0341H8.41098L8.71848 4.2556V4.5176L9.09798 4.7871V5.2656L8.71848 5.5196V5.7876L8.44998 5.9711H8.04398L7.55848 6.2956H6.41248L5.95848 5.9796L5.94548 5.9706H5.62648L5.34598 5.7501V5.5526L4.91948 5.2561L4.92498 4.9521C4.89148 4.9856 4.85598 5.0171 4.82298 5.0506L4.81848 5.3076L5.24548 5.6051V5.7986L5.57798 6.0596L5.59148 6.0701H5.91398L6.36798 6.3861L6.38098 6.3951H7.58848L8.07398 6.0706H8.47998L8.81848 5.8401V5.5731L9.19798 5.3191V4.7356L8.81848 4.4661V4.2046L8.44298 3.9341H8.06998L7.62698 3.6191L6.56498 3.6106C6.35598 3.7456 6.17148 3.8841 5.97348 4.0336H6.07198Z" fill="#43A047"/>
  </svg>
</template>

<script>
export default {
  name: 'TM',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  