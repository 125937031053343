
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="#03A9F4"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#FF3D00"/>
<path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#43A047"/>
<path d="M13.5154 12C13.5154 13.1055 12.6209 14 11.5154 14C10.4119 14 9.51538 13.1055 9.51538 12C9.51538 10.8945 10.4119 10 11.5154 10C12.6209 10 13.5154 10.8945 13.5154 12Z" fill="white"/>
<path d="M13.6269 12C13.6269 12.92 12.8809 13.666 11.9609 13.666C11.0409 13.666 10.2949 12.92 10.2949 12C10.2949 11.08 11.0409 10.334 11.9609 10.334C12.8809 10.334 13.6269 11.08 13.6269 12Z" fill="#FF3D00"/>
<path d="M13.8886 10.8884L14.1016 11.4859L14.6741 11.2144L14.4026 11.7869L15.0001 11.9999L14.4026 12.2129L14.6741 12.7854L14.1016 12.5139L13.8886 13.1114L13.6756 12.5139L13.1031 12.7854L13.3766 12.2129L12.7776 11.9999L13.3771 11.7869L13.1036 11.2144L13.6761 11.4859L13.8886 10.8884Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'AZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  