
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M5 18H19.9865C20.8625 16.8355 21.4985 15.4805 21.7995 14H11.6665L5 18Z" fill="white"/>
<path d="M11.6665 10H21.799C21.4985 8.5195 20.8625 7.1645 19.986 6H5L11.6665 10Z" fill="white"/>
<path d="M19.9865 6C18.1615 3.575 15.2685 2 12 2C8.91497 2 6.15847 3.3985 4.32397 5.5945L4.99997 6H19.9865Z" fill="#3F51B5"/>
<path d="M19.9865 18H4.99997L4.32397 18.4055C6.15847 20.6015 8.91497 22 12 22C15.2685 22 18.1615 20.425 19.9865 18Z" fill="#3F51B5"/>
<path d="M15 12L11.6665 14H21.799C21.9305 13.3535 22 12.685 22 12C22 11.315 21.9305 10.6465 21.799 10H11.6665L15 12Z" fill="#3F51B5"/>
<path d="M11.6665 14L15 12L11.6665 9.99998L5 5.99998L4.324 5.59448C2.8745 7.32948 2 9.56198 2 12C2 14.438 2.8745 16.6705 4.324 18.4055L5 18L11.6665 14Z" fill="#D50000"/>
<path d="M6.50002 9.5L7.05852 11.3615H8.86502L7.40402 12.2815L7.96302 14L6.50002 12.9375L5.03702 14L5.59552 12.281L4.13452 11.361H5.94102L6.50002 9.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'CU',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  