
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#039BE5"/>
<path d="M7.33843 8.15912L5.04443 7.50012L7.33843 6.84112L7.99693 4.54712L8.65593 6.84112L10.9494 7.50012L8.65593 8.15912L7.99693 10.4531L7.33843 8.15912Z" fill="#E53935"/>
<path d="M7.99754 4.99998L8.55554 6.94198L10.4975 7.49998L8.55554 8.05798L7.99754 9.99998L7.43954 8.05798L5.49754 7.49998L7.43954 6.94198L7.99754 4.99998ZM7.99754 4.09448L7.75704 4.93098L7.23754 6.73998L5.42854 7.25948L4.59204 7.49998L5.42854 7.74048L7.23754 8.25998L7.75704 10.069L7.99754 10.9055L8.23804 10.069L8.75754 8.25998L10.5665 7.74048L11.4025 7.49998L10.566 7.25948L8.75704 6.73998L8.23754 4.93098L7.99754 4.09448Z" fill="white"/>
<path d="M4.00659 18C4.27159 18.3525 4.56009 18.686 4.86809 19H19.1316C19.4401 18.686 19.7281 18.3525 19.9931 18H4.00659Z" fill="#FDD835"/>
<path d="M3.34603 17H20.654C20.8405 16.678 21.007 16.3435 21.1575 16H2.84253C2.99303 16.3435 3.15953 16.678 3.34603 17Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'AW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  