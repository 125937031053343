
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="#1976D2"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#1976D2"/>
<path d="M12.2365 11.9999L12 11.2734L11.7635 11.9999H11L11.617 12.4489L11.3825 13.1754L12 12.7264L12.617 13.1754L12.3825 12.4489L13 11.9999H12.2365Z" fill="#1976D2"/>
<path d="M15.383 11.6796L16 11.2306L16.617 11.6796L16.3825 10.9531L17 10.5041H16.2365L16 9.77759L15.7635 10.5041H15L15.617 10.9531L15.383 11.6796Z" fill="#1976D2"/>
<path d="M16.2365 13.0041L16 12.2776L15.7635 13.0041H15L15.617 13.4516L15.3825 14.1801L16 13.7306L16.617 14.1796L16.3825 13.4511L17 13.0041H16.2365Z" fill="#1976D2"/>
<path d="M8 9.77759L7.7635 10.5041H7L7.617 10.9516L7.3825 11.6801L8 11.2306L8.617 11.6796L8.3825 10.9511L9 10.5041H8.2365L8 9.77759Z" fill="#1976D2"/>
<path d="M8.2365 13.0041L8 12.2776L7.7635 13.0041H7L7.617 13.4516L7.3825 14.1801L8 13.7306L8.617 14.1796L8.3825 13.4511L9 13.0041H8.2365Z" fill="#1976D2"/>
  </svg>
</template>

<script>
export default {
  name: 'HN',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  