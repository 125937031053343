
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 6.477 17.523 2 12 2C11.6625 2 11.329 2.018 11 2.0505V12H22Z" fill="#FFD600"/>
<path d="M22 12H11V21.9495C11.329 21.982 11.6625 22 12 22C17.523 22 22 17.523 22 12Z" fill="#388E3C"/>
<path d="M11 2.05054C5.947 2.55254 2 6.81504 2 12C2 17.185 5.947 21.4475 11 21.9495V12V2.05054Z" fill="#E53935"/>
<path d="M7.90805 12.0684L9.37705 10.9999H7.56055L7.00005 9.27344L6.43955 10.9999H4.62305L6.09205 12.0684L5.53155 13.7949L7.00005 12.7264L8.46855 13.7949L7.90805 12.0684Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'GW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  