
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M2 11.9999C2 15.4364 3.7345 18.4664 6.375 20.2669V3.73291C3.7345 5.53341 2 8.56341 2 11.9999Z" fill="#E3E4E5"/>
<path d="M22 12C22 6.477 17.523 2 12 2C9.9135 2 7.978 2.6405 6.375 3.733V20.2665C7.978 21.3595 9.9135 22 12 22C17.523 22 22 17.523 22 12Z" fill="#1B5E20"/>
<path d="M19 12C19 14.4845 16.9845 16.5 14.5 16.5C12.0155 16.5 10 14.4845 10 12C10 9.5155 12.0155 7.5 14.5 7.5C16.9845 7.5 19 9.5155 19 12Z" fill="white"/>
<path d="M18.926 7.92609C17.3675 6.17209 14.682 6.01409 12.926 7.57259C11.172 9.13309 11.014 11.8186 12.5745 13.5726C14.133 15.3286 16.8205 15.4846 18.5745 13.9261C20.3285 12.3676 20.4865 9.67959 18.926 7.92609Z" fill="#1B5E20"/>
<path d="M15.9999 8.5L17.7654 10.926L14.9099 10L17.7634 9.0725L16.0019 11.5L15.9999 8.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'PK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  