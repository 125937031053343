
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C7.71047 2 4.06247 4.706 2.64197 8.5H21.3575C19.9375 4.706 16.2895 2 12 2Z" fill="#FFD600"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#1A237E"/>
<path d="M21.358 15.5H2.64197C4.06247 19.294 7.71047 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#D50000"/>
<path d="M12.953 10.4365L12.8905 9.98755L12.6775 10.388L12.23 10.31L12.5445 10.636L12.3315 11.0365L12.7395 10.8375L13.054 11.1655L12.9915 10.7145L13.3995 10.517L12.953 10.4365Z" fill="white"/>
<path d="M14.4099 11.3155L14.8609 11.2665L14.4684 11.04L14.5624 10.5945L14.2244 10.899L13.8319 10.6725L14.0154 11.0865L13.6794 11.391L14.1304 11.342L14.3159 11.758L14.4099 11.3155Z" fill="white"/>
<path d="M15.537 12.3625L15.9765 12.474L15.6855 12.1265L15.9255 11.74L15.5055 11.912L15.2125 11.5625L15.244 12.0155L14.824 12.1855L15.2635 12.297L15.2965 12.75L15.537 12.3625Z" fill="white"/>
<path d="M16.457 13.5616L16.8165 13.2801L16.3615 13.2976L16.207 12.8701L16.082 13.3076L15.629 13.3231L16.004 13.5771L15.879 14.0126L16.2365 13.7331L16.6135 13.9871L16.457 13.5616Z" fill="white"/>
<path d="M11.047 10.4365L11.1095 9.98755L11.3225 10.388L11.77 10.31L11.4555 10.636L11.6685 11.0365L11.2605 10.8375L10.946 11.1655L11.0085 10.7145L10.6005 10.517L11.047 10.4365Z" fill="white"/>
<path d="M9.59004 11.3155L9.13904 11.2665L9.53154 11.04L9.43754 10.5945L9.77554 10.899L10.168 10.6725L9.98454 11.0865L10.3205 11.391L9.86954 11.342L9.68404 11.758L9.59004 11.3155Z" fill="white"/>
<path d="M8.46306 12.3625L8.02356 12.474L8.31456 12.1265L8.07456 11.74L8.49456 11.912L8.78756 11.5625L8.75606 12.0155L9.17606 12.1855L8.73656 12.297L8.70356 12.75L8.46306 12.3625Z" fill="white"/>
<path d="M7.54297 13.5616L7.18347 13.2801L7.63847 13.2976L7.79297 12.8701L7.91797 13.3076L8.37097 13.3231L7.99597 13.5771L8.12097 14.0126L7.76347 13.7331L7.38647 13.9871L7.54297 13.5616Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'VE',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  