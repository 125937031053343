
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M9.6955 14.4795C12.7035 12.083 18.4395 8.718 20.5555 8.055C18.3585 10.269 18.829 14.089 20.806 16.7395C21.567 15.329 22 13.7155 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 14.4445 2.8795 16.6815 4.336 18.4185C5.7455 17.442 7.8785 15.927 9.6955 14.4795Z" fill="#283593"/>
<path d="M6.53552 20.3689C8.10702 21.3974 9.98202 21.9999 12 21.9999C14.018 21.9999 15.893 21.3974 17.4645 20.3689H6.53552Z" fill="#283593"/>
<path d="M5.79951 19.8365H18.2C18.425 19.658 18.6405 19.469 18.8495 19.2725H5.15051C5.35951 19.469 5.57451 19.6585 5.79951 19.8365Z" fill="#283593"/>
<path d="M8.04203 12.4417L8.27103 13.1187L8.98603 13.1277L8.41303 13.5547L8.62553 14.2377L8.04203 13.8247L7.45853 14.2377L7.67053 13.5547L7.09753 13.1277L7.81253 13.1187L8.04203 12.4417Z" fill="white"/>
<path d="M4.68254 7.27808L4.87504 7.84708L5.47554 7.85458L4.99404 8.21358L5.17254 8.78758L4.68254 8.44058L4.19204 8.78758L4.37054 8.21358L3.88904 7.85458L4.48954 7.84708L4.68254 7.27808Z" fill="white"/>
<path d="M7.99303 4.16504L8.15503 4.64254L8.65903 4.64904L8.25503 4.95054L8.40453 5.43204L7.99303 5.14054L7.58153 5.43204L7.73103 4.95054L7.32703 4.64904L7.83103 4.64254L7.99303 4.16504Z" fill="white"/>
<path d="M10.8325 6.59766L10.9605 6.97466L11.3585 6.97966L11.0395 7.21766L11.1575 7.59816L10.8325 7.36816L10.5075 7.59816L10.626 7.21766L10.3065 6.97966L10.705 6.97466L10.8325 6.59766Z" fill="white"/>
<path d="M6.48842 20.3689H17.4979C17.7499 20.2029 17.9924 20.0239 18.2279 19.8364H5.75842C5.99392 20.0244 6.23642 20.2029 6.48842 20.3689Z" fill="#FFCA28"/>
<path d="M5.10996 19.2724H18.876C19.648 18.5414 20.302 17.6904 20.811 16.7474C18.829 14.0969 18.3565 10.2719 20.555 8.05542C18.439 8.71842 12.703 12.0834 9.69496 14.4794C7.86896 15.9339 5.72296 17.4574 4.31396 18.4334C4.56246 18.7294 4.82996 19.0069 5.10996 19.2724Z" fill="#FFCA28"/>
  </svg>
</template>

<script>
export default {
  name: 'TK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  