
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2V22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="white"/>
<path d="M2 12C2 17.523 6.477 22 12 22V2C6.477 2 2 6.477 2 12Z" fill="#33691E"/>
<path d="M15.367 10C14.2615 8.088 11.816 7.4315 9.904 8.537C7.99 9.6405 7.3355 12.088 8.439 14C9.5425 15.912 11.99 16.5685 13.902 15.463C14.5095 15.1135 15.0155 14.6075 15.367 14C14.2615 15.379 12.248 15.6015 10.869 14.498C9.49 13.3925 9.2675 11.379 10.371 10C11.4765 8.621 13.49 8.3985 14.869 9.502C15.0525 9.6505 15.2185 9.8165 15.367 10ZM15.5995 12.1075L12 10.9335L14.24 14V10.215L12 13.2795L15.5995 12.1075Z" fill="#D21034"/>
  </svg>
</template>

<script>
export default {
  name: 'DZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  