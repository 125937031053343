
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M11.9945 22C17.5174 22 21.9945 17.5228 21.9945 12C21.9945 6.47715 17.5174 2 11.9945 2C6.47166 2 1.99451 6.47715 1.99451 12C1.99451 17.5228 6.47166 22 11.9945 22Z" fill="white"/>
<path d="M1.99451 12.0001C1.99451 17.5231 6.47151 22.0001 11.9945 22.0001C14.327 22.0001 16.468 21.1946 18.169 19.8561L10.277 11.7501H20.197L7.42901 3.11011C7.33951 3.15611 7.24451 3.20561 7.15051 3.25411C4.07701 4.96011 1.99451 8.23561 1.99451 12.0001Z" fill="#E53935"/>
<path d="M7.4985 9.99251C9.156 9.99251 10.5 8.64901 10.5 6.99101C10.2365 7.66151 9.759 8.21651 9.1515 8.57851L8.8955 8.38751L9.398 8.01101L8.79 7.85551L9.1115 7.31051L8.4835 7.40101L8.5765 6.77801L8.0315 7.10451L7.876 6.48901L7.4995 6.99151L7.123 6.48901L6.965 7.10201L6.4225 6.78051L6.5155 7.40851L5.8875 7.31551L6.214 7.86051L5.6 8.01601L6.1025 8.39251L5.8515 8.58601C5.2565 8.23451 4.7715 7.69201 4.5005 6.99851C4.5 8.65651 5.8435 10 7.5015 10L7.4985 9.99251ZM6.154 14.654L4.902 14.5L5.6615 15.5045L4.5 16L5.661 16.4905L4.9015 17.495L6.154 17.341L6 18.596L7.0045 17.8365L7.5 19L7.9905 17.8365L9 18.598L8.846 17.3455L10.0985 17.502L9.339 16.4975L10.5 16.007L9.339 15.5115L10.0985 14.507L8.846 14.659L9 13.4065L7.9955 14.166L7.5 13L7.0095 14.161L6 13.402L6.154 14.654Z" fill="white"/>
<path d="M17.75 11H8.49997L17.562 20.3065C17.9805 20.0255 18.37 19.706 18.7405 19.3665L12.054 12.5H17.75H21.9695C21.977 12.353 21.991 12.208 21.9915 12.059L18.5905 9.75745L8.22297 2.74146C7.69097 2.95846 7.18597 3.22396 6.70447 3.52496L17.75 11Z" fill="#0D47A1"/>
  </svg>
</template>

<script>
export default {
  name: 'NP',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  