
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#B71C1C"/>
<path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#283593"/>
<path d="M15.3605 12.026C15.3605 9.264 12.299 6.7505 12.014 6.5225V6.5C12.014 6.5 12.0035 6.5085 12 6.5115C11.9965 6.5085 11.986 6.5 11.986 6.5V6.5225C11.701 6.753 8.63953 9.2915 8.63953 12.054C8.63953 14.816 11.701 17.3295 11.986 17.5575V17.58C11.986 17.58 11.9965 17.5715 12 17.5685C12.0035 17.5715 12.014 17.58 12.014 17.58V17.5575C12.299 17.327 15.3605 14.788 15.3605 12.026Z" fill="#B3E5FC"/>
<path d="M9.03296 13.6851H15.023C15.023 13.6851 14.6715 15.2036 12.056 17.6501C11.1845 16.8631 9.03296 13.6851 9.03296 13.6851Z" fill="#1E88E5"/>
<path d="M15.248 12.9541C15.248 12.9541 14.1655 13.5026 11.592 13.3056C11.5215 13.5586 11.4795 13.6806 11.4795 13.6806L14.9665 13.7136L15.248 12.9541Z" fill="#9E9D24"/>
<path d="M10.186 14.1629C10.186 14.1629 10.65 13.3614 10.4955 12.7849C11.4515 13.5724 11.6205 14.2614 11.6205 14.2614L10.9315 14.3739L10.186 14.1629Z" fill="white"/>
<path d="M14.6715 14.2334C14.6715 14.2334 14.4605 14.2474 14.109 14.4724C13.7575 14.6974 13.3355 14.8239 12.97 14.8519C12.6045 14.8799 12.548 14.8519 12.1965 14.8239C11.845 14.7959 11.634 15.1049 12.126 15.1194C12.618 15.1334 13.1525 15.2319 13.1105 15.5129C13.0685 15.7939 12.309 15.7519 11.9995 15.7939C11.69 15.8359 11.0995 15.9344 10.8045 16.2719C10.495 16.0189 10.3545 15.7094 10.3545 15.7094C10.3545 15.7094 10.5375 15.4139 11.0155 15.4139C11.4935 15.4139 11.2825 15.5264 11.6905 15.4844C12.0985 15.4424 12.4075 15.3159 11.789 15.2174C11.1705 15.1189 11.297 15.0344 10.889 15.0064C10.481 14.9784 9.98898 14.7109 9.55298 14.7394C9.83398 15.3299 10.256 15.8079 10.256 15.8079L10.762 16.3704L11.479 17.1299L12.0135 17.5799L13.476 16.2724L14.5305 14.8804L14.882 14.4164L14.6715 14.2334Z" fill="#FDD835"/>
<path d="M10.4675 12.371C10.295 12.371 10.1525 12.2065 10.1365 11.9875C10.118 11.7375 10.2365 11.582 10.4625 11.561C10.6745 11.544 10.792 11.6865 10.829 11.7655L10.738 11.8075C10.735 11.8005 10.6585 11.643 10.472 11.6605C10.3005 11.6765 10.221 11.784 10.2355 11.9795C10.248 12.1455 10.3475 12.271 10.467 12.271C10.568 12.271 10.6795 12.226 10.728 12.204L10.7375 12.047H10.5585V11.9475H10.843L10.825 12.268L10.8 12.281C10.793 12.2845 10.6255 12.371 10.4675 12.371Z" fill="#C2185B"/>
<path d="M11.388 12.3696C11.2465 12.3696 11.1035 12.2926 11.0995 12.1181C11.095 11.9431 11.106 11.6361 11.1065 11.6231L11.206 11.6271C11.206 11.6301 11.1955 11.9441 11.199 12.1161C11.2025 12.2611 11.3525 12.2701 11.389 12.2701C11.39 12.2701 11.3905 12.2701 11.3915 12.2701C11.4895 12.2691 11.5915 12.2171 11.5915 12.1321V11.6121H11.691V12.1321C11.691 12.2846 11.537 12.3686 11.392 12.3706C11.391 12.3696 11.3895 12.3696 11.388 12.3696Z" fill="#C2185B"/>
<path d="M12.7616 12.3605L12.5256 11.7465L12.2781 12.347L12.1851 12.309L12.4806 11.592C12.4886 11.5735 12.5071 11.561 12.5271 11.561C12.5476 11.561 12.5666 11.5735 12.5741 11.5925L12.8551 12.3235L12.7616 12.3605Z" fill="#C2185B"/>
<path d="M12.7379 12.0181H12.3514V12.1176H12.7379V12.0181Z" fill="#C2185B"/>
<path d="M13.3015 12.3491H13.2015V11.6181L13.297 11.5971L13.5735 12.1931L13.8385 11.5916L13.934 11.6116V12.3281H13.8345V11.8486L13.6205 12.3341L13.5295 12.3351L13.3015 11.8441V12.3491Z" fill="#C2185B"/>
<path d="M11.999 17.8231C11.9925 17.8231 11.9865 17.8231 11.98 17.8226C11.9445 17.8216 11.9095 17.8131 11.8785 17.7971C11.8385 17.7766 11.804 17.7471 11.779 17.7116C11.251 17.2791 8.39001 14.8076 8.39001 12.0546C8.39001 9.2801 11.2965 6.7671 11.7905 6.3596C11.8135 6.3291 11.8425 6.3036 11.876 6.2866C11.9225 6.2646 11.9765 6.2551 12.021 6.2571C12.052 6.2581 12.0835 6.2651 12.112 6.2781C12.157 6.2991 12.1955 6.3301 12.2225 6.3691C12.753 6.8036 15.611 9.2741 15.611 12.0256C15.611 14.8051 12.705 17.3136 12.2105 17.7201C12.187 17.7516 12.157 17.7776 12.1215 17.7941C12.0825 17.8131 12.04 17.8231 11.999 17.8231ZM12.001 6.8356C11.3235 7.4131 8.88951 9.6561 8.88951 12.0546C8.88951 14.4511 11.319 16.6706 11.9985 17.2446C12.6315 16.6996 15.1105 14.4131 15.1105 12.0261C15.1105 9.6286 12.6785 7.4076 12.001 6.8356Z" fill="#C2185B"/>
<path d="M11.817 14.177C11.2265 14.2895 9.94702 14.191 9.94702 14.191C9.94702 14.191 10.0375 14.4025 10.4615 14.47L10.439 14.627L11.339 14.599L11.358 14.4455C11.7125 14.4 11.721 14.3375 11.817 14.177Z" fill="#6D4C41"/>
<path d="M12.0706 9.48091C12.0706 9.48091 12.0426 11.7869 12.0986 12.3214C12.1546 12.8559 12.5906 14.0089 13.3641 14.5289C14.1376 15.0494 13.4486 15.1194 13.3501 15.1474C13.2516 15.1754 13.0266 14.9224 12.9421 14.7254C12.8576 14.5284 12.3796 13.9804 12.1266 13.4039C11.8736 12.8274 11.8311 12.1384 11.8311 11.7449C11.8311 11.3514 11.8451 9.36841 11.8451 9.36841L12.0706 9.48091Z" fill="#6D4C41"/>
<path d="M11.9441 9.59361C11.9441 9.59361 11.4941 10.2406 11.2971 11.0136C11.1426 10.9151 11.1566 10.6901 11.1986 10.3386C11.2406 9.98711 11.5921 9.59361 11.5921 9.59361C11.5921 9.59361 10.9311 9.73411 10.3406 10.3951C10.4251 9.88911 10.9876 9.46711 11.3251 9.41061C10.4956 9.34011 9.68005 9.69161 9.68005 9.69161C9.68005 9.69161 10.4676 8.60911 11.6486 9.24161C11.4376 8.89011 10.8471 8.27161 10.8471 8.27161C10.8471 8.27161 11.4656 8.37011 11.9436 9.03111C11.6906 8.34211 11.4656 7.52661 11.4656 7.52661C11.4656 7.52661 12.2251 8.00461 12.2106 8.94661C12.7026 8.21561 13.3496 8.32811 13.3496 8.32811L12.3651 9.10161C12.3651 9.10161 13.3071 8.67961 13.8416 9.27011C13.2371 9.08711 12.4496 9.32611 12.4496 9.32611C12.4496 9.32611 13.6731 9.53711 13.6871 10.3526C13.1526 9.90261 12.2386 9.63561 12.2386 9.63561C12.2386 9.63561 12.8856 10.2121 12.7026 10.9151C12.2391 10.4231 11.9441 9.59361 11.9441 9.59361Z" fill="#388E3C"/>
  </svg>
</template>

<script>
export default {
  name: 'GU',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  