
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M3.50305 17.2624C5.26705 20.1024 8.40555 21.9999 11.9946 21.9999C17.5176 21.9999 21.9946 17.5229 21.9946 11.9999C21.9946 11.5339 21.9516 11.0789 21.8901 10.6299L3.50305 17.2624Z" fill="#283593"/>
<path d="M19.761 5.706C17.9275 3.4465 15.131 2 11.9945 2C6.47151 2 1.99451 6.477 1.99451 12C1.99451 13.315 2.25501 14.567 2.71601 15.717L19.761 5.706Z" fill="#283593"/>
<path d="M11.7766 7.48208L11.7491 7.48008L11.7766 7.47808H11.7216L9.42306 7.31608L10.7561 6.81758L9.30706 7.03758L10.4551 6.20308L9.15656 6.79458L10.0726 5.67008L8.97106 6.56258L9.53906 5.27558L8.69256 6.42358L8.91306 5.00908L8.40306 6.33058L8.25356 3.96608V3.96558L8.10556 6.33058L7.59556 5.00908L7.81556 6.42358L6.96956 5.27558L7.53756 6.56258L6.43606 5.67008L7.35206 6.79458L6.05356 6.20308L7.20106 7.03758L5.75206 6.81758L7.08506 7.31608L4.78656 7.47808H4.73206L4.75956 7.48008L4.73206 7.48208H4.78656L7.08506 7.64458L5.75206 8.14308L7.20106 7.92258L6.05356 8.75758L7.35206 8.16608L6.43606 9.29058L7.53756 8.39808L6.96956 9.68458L7.81556 8.53708L7.59556 9.95158L8.10556 8.63008L8.25456 10.9941V10.9946L8.40306 8.63008L8.91306 9.95158L8.69256 8.53708L9.53906 9.68458L8.97106 8.39808L10.0726 9.29058L9.15656 8.16608L10.4551 8.75758L9.30706 7.92258L10.7561 8.14308L9.42306 7.64458L11.7216 7.48208H11.7766Z" fill="white"/>
<path d="M21.8895 10.628L21.8405 10.305C21.699 9.47745 21.4595 8.68445 21.128 7.93945L3.04651 16.445C3.18601 16.7255 3.33851 16.998 3.50301 17.2625L21.8895 10.628Z" fill="white"/>
<path d="M21.1285 7.94105C20.7695 7.13455 20.3105 6.38305 19.761 5.70605L2.71802 15.7211C2.82252 15.9811 2.94002 16.2336 3.06502 16.4826L21.1285 7.94105Z" fill="#F57C00"/>
  </svg>
</template>

<script>
export default {
  name: 'MH',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  