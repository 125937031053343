
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C9.30446 2 6.86246 3.0715 5.06396 4.806L21.8815 10.539C21.173 5.7105 17.0255 2 12 2Z" fill="#388E3C"/>
<path d="M21.8815 13.4609L5.06396 19.1939C6.86246 20.9284 9.30446 21.9999 12 21.9999C17.0255 21.9999 21.173 18.2894 21.8815 13.4609Z" fill="#388E3C"/>
<path d="M21.9975 12.0476L4.13696 18.1651C4.42296 18.5291 4.73146 18.8731 5.06396 19.1941L21.8815 13.4611C21.9495 12.9981 21.9955 12.5286 21.9975 12.0476Z" fill="white"/>
<path d="M21.8814 10.5389L5.06388 4.80591C4.73238 5.12541 4.42488 5.46791 4.14038 5.82991L21.9964 11.9314C21.9934 11.4574 21.9484 10.9949 21.8814 10.5389Z" fill="white"/>
<path d="M21.9999 12.0001C21.9999 11.9771 21.9964 11.9546 21.9964 11.9316L4.14041 5.83008C3.94391 6.08008 3.75891 6.33958 3.58691 6.60808L11.4894 12.0001L3.58691 17.3921C3.75841 17.6591 3.94191 17.9166 4.13691 18.1651L21.9974 12.0476C21.9974 12.0316 21.9999 12.0161 21.9999 12.0001Z" fill="#FFD600"/>
<path d="M3.58692 6.60791C3.34542 6.98391 3.13092 7.37791 2.94092 7.78591L9.12242 11.9999L2.94092 16.2139C3.13092 16.6219 3.34542 17.0159 3.58692 17.3919L11.4894 11.9999L3.58692 6.60791Z" fill="#263238"/>
<path d="M2.941 7.78589C2.3435 9.06789 2 10.4924 2 11.9999C2 13.5074 2.3435 14.9319 2.941 16.2139L9.1225 11.9999L2.941 7.78589Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'GY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  