
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M17 3.34839V11.9999H2C2 17.5229 6.477 21.9999 12 21.9999C17.523 21.9999 22 17.5229 22 11.9999C22 8.30089 19.986 5.07839 17 3.34839Z" fill="#D50000"/>
<path d="M17 3.3485C15.528 2.496 13.8235 2 12 2C6.477 2 2 6.477 2 12H17V3.3485Z" fill="#303F9F"/>
<path d="M9.90855 5.0425L9.50005 3L9.09155 5.0425C9.22355 5.015 9.36005 5 9.50005 5C9.64005 5 9.77655 5.015 9.90855 5.0425Z" fill="white"/>
<path d="M7.5 3.53589L8.1705 5.51739C8.3745 5.33439 8.6155 5.19539 8.8815 5.10789L7.5 3.53589Z" fill="white"/>
<path d="M6.03601 5L7.60801 6.3815C7.69551 6.115 7.83401 5.8745 8.01751 5.6705L6.03601 5Z" fill="white"/>
<path d="M7.5425 6.59155L5.5 7.00005L7.5425 7.40855C7.515 7.27655 7.5 7.14005 7.5 7.00005C7.5 6.86005 7.515 6.72355 7.5425 6.59155Z" fill="white"/>
<path d="M6.03601 8.99991L8.01751 8.32941C7.83451 8.12541 7.69551 7.88441 7.60801 7.61841L6.03601 8.99991Z" fill="white"/>
<path d="M7.5 10.4639L8.8815 8.89192C8.615 8.80442 8.3745 8.66592 8.1705 8.48242L7.5 10.4639Z" fill="white"/>
<path d="M9.09155 8.95752L9.50005 11L9.90855 8.95752C9.77655 8.98502 9.64005 9.00002 9.50005 9.00002C9.36005 9.00002 9.22355 8.98502 9.09155 8.95752Z" fill="white"/>
<path d="M11.5 10.4639L10.8295 8.48242C10.6255 8.66542 10.3845 8.80442 10.1185 8.89192L11.5 10.4639Z" fill="white"/>
<path d="M12.964 8.99991L11.392 7.61841C11.3045 7.88491 11.166 8.12541 10.9825 8.32941L12.964 8.99991Z" fill="white"/>
<path d="M11.4575 7.40855L13.5 7.00005L11.4575 6.59155C11.485 6.72355 11.5 6.86005 11.5 7.00005C11.5 7.14005 11.485 7.27655 11.4575 7.40855Z" fill="white"/>
<path d="M12.964 5L10.9825 5.6705C11.1655 5.8745 11.3045 6.1155 11.392 6.3815L12.964 5Z" fill="white"/>
<path d="M11.5 3.53589L10.1185 5.10789C10.385 5.19539 10.6255 5.33389 10.8295 5.51739L11.5 3.53589Z" fill="white"/>
<path d="M9.5 8.5C10.3284 8.5 11 7.82843 11 7C11 6.17157 10.3284 5.5 9.5 5.5C8.67157 5.5 8 6.17157 8 7C8 7.82843 8.67157 8.5 9.5 8.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  