
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 15.9999C12.112 15.9999 12.2215 15.9919 12.3305 15.9844L13.317 21.9034C15.709 21.5879 17.829 20.4294 19.378 18.7329L15.117 14.4999C15.2135 14.3774 15.302 14.2524 15.385 14.1199L20.3105 17.5604C21.2255 16.1954 21.808 14.5914 21.956 12.8624L15.9865 12.2654C15.992 12.1769 16 12.0884 16 11.9999C16 11.9114 15.992 11.8229 15.987 11.7344L21.9565 11.1374C21.8085 9.40894 21.226 7.80494 20.311 6.43944L15.3855 9.87994C15.302 9.74694 15.2135 9.61944 15.1175 9.49994L19.3785 5.26694C17.8295 3.57044 15.7095 2.41244 13.3175 2.09644L12.331 8.01544C12.2215 8.00794 12.112 7.99994 12 7.99994C11.888 7.99994 11.7785 8.00794 11.6695 8.01544L10.683 2.09644C8.29096 2.41194 6.17096 3.57044 4.62196 5.26694L8.88296 9.49994C8.78646 9.62244 8.69796 9.74744 8.61496 9.87994L3.68946 6.43944C2.77446 7.80444 2.19196 9.40844 2.04396 11.1374L8.01346 11.7344C8.00796 11.8229 7.99996 11.9114 7.99996 11.9999C7.99996 12.0884 8.00796 12.1769 8.01296 12.2654L2.04346 12.8624C2.19146 14.5909 2.77396 16.1949 3.68896 17.5604L8.61446 14.1199C8.69796 14.2529 8.78646 14.3779 8.88246 14.4999L4.62146 18.7329C6.17046 20.4294 8.29046 21.5874 10.6825 21.9034L11.669 15.9844C11.7785 15.9919 11.888 15.9999 12 15.9999ZM9.33346 11.9999C9.33346 10.5259 10.526 9.33344 12 9.33344C13.474 9.33344 14.6665 10.5259 14.6665 11.9999C14.6665 13.4739 13.474 14.6664 12 14.6664C10.526 14.6664 9.33346 13.4739 9.33346 11.9999Z" fill="#C62828"/>
<path d="M15.3854 9.8801L20.3109 6.4396C20.0319 6.0231 19.7154 5.6356 19.3784 5.2666L15.1169 9.5001C15.2134 9.6201 15.3019 9.7476 15.3854 9.8801Z" fill="#FFEB3B"/>
<path d="M8 12C8 11.9115 8.008 11.823 8.013 11.7345L2.0435 11.1375C2.019 11.4225 2 11.709 2 12C2 12.291 2.019 12.5775 2.0435 12.8625L8.013 12.2655C8.008 12.177 8 12.0885 8 12Z" fill="#FFEB3B"/>
<path d="M15.1169 14.4999L19.3779 18.7329C19.7149 18.3639 20.0314 17.9764 20.3104 17.5599L15.3849 14.1194C15.3019 14.2524 15.2134 14.3774 15.1169 14.4999Z" fill="#FFEB3B"/>
<path d="M12.3306 15.9846C12.2216 15.9921 12.1121 16.0001 12.0001 16.0001C11.8881 16.0001 11.7786 15.9921 11.6696 15.9846L10.6831 21.9036C11.1151 21.9606 11.5526 22.0001 12.0001 22.0001C12.4476 22.0001 12.8851 21.9606 13.3171 21.9036L12.3306 15.9846Z" fill="#FFEB3B"/>
<path d="M16.0001 12C16.0001 12.0885 15.9921 12.177 15.9871 12.2655L21.9566 12.8625C21.9811 12.5775 22.0001 12.291 22.0001 12C22.0001 11.709 21.9811 11.4225 21.9566 11.1375L15.9871 11.7345C15.9921 11.823 16.0001 11.9115 16.0001 12Z" fill="#FFEB3B"/>
<path d="M12.0001 8C12.1121 8 12.2216 8.008 12.3306 8.0155L13.3171 2.0965C12.8851 2.0395 12.4476 2 12.0001 2C11.5526 2 11.1151 2.0395 10.6831 2.0965L11.6696 8.0155C11.7786 8.008 11.8881 8 12.0001 8Z" fill="#FFEB3B"/>
<path d="M8.88295 9.50009L4.62195 5.26709C4.28495 5.63609 3.96845 6.02359 3.68945 6.44009L8.61495 9.88059C8.69795 9.74759 8.78645 9.62259 8.88295 9.50009Z" fill="#FFEB3B"/>
<path d="M8.61446 14.1201L3.68896 17.5606C3.96796 17.9771 4.28446 18.3646 4.62146 18.7336L8.88297 14.5001C8.78647 14.3776 8.69796 14.2526 8.61446 14.1201Z" fill="#FFEB3B"/>
<path d="M12.4273 14.632C13.881 14.3962 14.8683 13.0266 14.6325 11.5729C14.3967 10.1193 13.0272 9.13198 11.5735 9.36776C10.1198 9.60354 9.13255 10.9731 9.36833 12.4268C9.60411 13.8804 10.9737 14.8677 12.4273 14.632Z" fill="#FFEB3B"/>
  </svg>
</template>

<script>
export default {
  name: 'MK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  