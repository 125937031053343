
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="white"/>
<path d="M8.34444 6.58943C7.94944 6.67543 7.55744 6.72943 7.16844 6.75193C7.18444 6.33743 7.23044 5.93793 7.30944 5.55493C6.92194 5.61393 6.56494 5.61243 6.23544 5.55493C6.32344 5.95793 6.37794 6.35793 6.39944 6.75443C5.99644 6.73393 5.59644 6.67943 5.20044 6.58993C5.25894 6.94593 5.25844 7.30393 5.20044 7.66393C5.61394 7.59193 6.01294 7.54743 6.39894 7.52793C6.37744 7.92143 6.32294 8.31193 6.23494 8.69893C6.59094 8.64043 6.94894 8.64093 7.30894 8.69893C7.23844 8.29393 7.19494 7.90293 7.17444 7.52393C7.57944 7.54093 7.96894 7.58693 8.34394 7.66443C8.28544 7.27543 8.28694 6.91843 8.34444 6.58943Z" fill="#E53935"/>
<path d="M18.8444 6.58943C18.4494 6.67543 18.0574 6.72943 17.6684 6.75193C17.6844 6.33743 17.7304 5.93793 17.8094 5.55493C17.4219 5.61393 17.0649 5.61243 16.7354 5.55493C16.8234 5.95793 16.8779 6.35793 16.8994 6.75443C16.4964 6.73393 16.0964 6.67943 15.7004 6.58993C15.7589 6.94593 15.7584 7.30393 15.7004 7.66393C16.1139 7.59193 16.5129 7.54743 16.8989 7.52793C16.8774 7.92143 16.8229 8.31193 16.7349 8.69893C17.0909 8.64043 17.4489 8.64093 17.8089 8.69893C17.7384 8.29393 17.6949 7.90293 17.6744 7.52393C18.0794 7.54093 18.4689 7.58693 18.8439 7.66443C18.7854 7.27543 18.7869 6.91843 18.8444 6.58943Z" fill="#E53935"/>
<path d="M8.34444 16.5894C7.94944 16.6754 7.55744 16.7294 7.16844 16.7519C7.18444 16.3374 7.23044 15.9379 7.30944 15.5549C6.92194 15.6139 6.56494 15.6124 6.23544 15.5549C6.32344 15.9579 6.37794 16.3579 6.39944 16.7544C5.99644 16.7339 5.59644 16.6794 5.20044 16.5899C5.25894 16.9459 5.25844 17.3039 5.20044 17.6639C5.61394 17.5919 6.01294 17.5474 6.39894 17.5279C6.37744 17.9214 6.32294 18.3119 6.23494 18.6989C6.59094 18.6404 6.94894 18.6409 7.30894 18.6989C7.23844 18.2939 7.19494 17.9029 7.17444 17.5239C7.57944 17.5409 7.96894 17.5869 8.34394 17.6644C8.28544 17.2754 8.28694 16.9184 8.34444 16.5894Z" fill="#E53935"/>
<path d="M18.8444 16.5894C18.4494 16.6754 18.0574 16.7294 17.6684 16.7519C17.6844 16.3374 17.7304 15.9379 17.8094 15.5549C17.4219 15.6139 17.0649 15.6124 16.7354 15.5549C16.8234 15.9579 16.8779 16.3579 16.8994 16.7544C16.4964 16.7339 16.0964 16.6794 15.7004 16.5899C15.7589 16.9459 15.7584 17.3039 15.7004 17.6639C16.1139 17.5919 16.5129 17.5474 16.8989 17.5279C16.8774 17.9214 16.8229 18.3119 16.7349 18.6989C17.0909 18.6404 17.4489 18.6409 17.8089 18.6989C17.7384 18.2939 17.6949 17.9029 17.6744 17.5239C18.0794 17.5409 18.4689 17.5869 18.8439 17.6644C18.7854 17.2754 18.7869 16.9184 18.8444 16.5894Z" fill="#E53935"/>
<path d="M2.201 14H10V21.799C10.6465 21.9305 11.315 22 12 22C12.685 22 13.3535 21.9305 14 21.799V14H21.799C21.9305 13.3535 22 12.685 22 12C22 11.315 21.9305 10.6465 21.799 10H14V2.201C13.3535 2.0695 12.685 2 12 2C11.315 2 10.6465 2.0695 10 2.201V10H2.201C2.0695 10.6465 2 11.315 2 12C2 12.685 2.0695 13.3535 2.201 14Z" fill="#E53935"/>
  </svg>
</template>

<script>
export default {
  name: 'GE',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  