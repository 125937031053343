
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <circle cx="12" cy="12" r="10" fill="#043CAE"/>
<path d="M11.1327 6.73459L12 6.48057L12.8673 6.73459L12.6133 5.8673L12.8673 5L12 5.25402L11.1327 5L11.3867 5.8673L11.1327 6.73459Z" fill="#FFD429"/>
<path d="M11.1327 19L12 18.746L12.8673 19L12.6133 18.1327L12.8673 17.2654L12 17.5194L11.1327 17.2654L11.3867 18.1327L11.1327 19Z" fill="#FFD429"/>
<path d="M18.1327 12.6133L17.2654 12.8673L17.5194 12L17.2654 11.1327L18.1327 11.3867L19 11.1327L18.746 12L19 12.8673L18.1327 12.6133Z" fill="#FFD429"/>
<path d="M5 12.8673L5.8673 12.6133L6.73459 12.8673L6.48057 12L6.73459 11.1327L5.8673 11.3867L5 11.1327L5.25402 12L5 12.8673Z" fill="#FFD429"/>
<path d="M17.3111 9.54692L16.4438 9.80094L16.6978 8.93365L16.4438 8.06635L17.3111 8.32038L18.1784 8.06635L17.9243 8.93365L18.1784 9.80094L17.3111 9.54692Z" fill="#FFD429"/>
<path d="M5.82163 15.9336L6.68892 15.6796L7.55622 15.9336L7.30219 15.0664L7.55622 14.1991L6.68892 14.4531L5.82163 14.1991L6.07565 15.0664L5.82163 15.9336Z" fill="#FFD429"/>
<path d="M15.0664 7.30219L14.1991 7.55622L14.4531 6.68892L14.1991 5.82163L15.0664 6.07565L15.9336 5.82163L15.6796 6.68892L15.9336 7.55622L15.0664 7.30219Z" fill="#FFD429"/>
<path d="M8.06635 18.1784L8.93365 17.9243L9.80094 18.1784L9.54692 17.3111L9.80094 16.4438L8.93365 16.6978L8.06635 16.4438L8.32038 17.3111L8.06635 18.1784Z" fill="#FFD429"/>
<path d="M17.3111 15.6796L16.4438 15.9336L16.6978 15.0664L16.4438 14.1991L17.3111 14.4531L18.1784 14.1991L17.9243 15.0664L18.1784 15.9336L17.3111 15.6796Z" fill="#FFD429"/>
<path d="M5.82163 9.80094L6.68892 9.54692L7.55622 9.80094L7.30219 8.93365L7.55622 8.06635L6.68892 8.32038L5.82163 8.06635L6.07565 8.93365L5.82163 9.80094Z" fill="#FFD429"/>
<path d="M15.0664 17.9243L14.1991 18.1784L14.4531 17.3111L14.1991 16.4438L15.0664 16.6978L15.9336 16.4438L15.6796 17.3111L15.9336 18.1784L15.0664 17.9243Z" fill="#FFD429"/>
<path d="M8.06635 7.55622L8.93365 7.30219L9.80094 7.55622L9.54692 6.68892L9.80094 5.82163L8.93365 6.07565L8.06635 5.82163L8.32038 6.68892L8.06635 7.55622Z" fill="#FFD429"/>
  </svg>
</template>

<script>
export default {
  name: 'EU',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  