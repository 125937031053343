
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M19.428 18.6786L15.511 16.0081C14.5725 16.8306 13.3465 17.3336 12.0005 17.3336C10.6545 17.3336 9.4285 16.8311 8.49 16.0081L4.573 18.6786C6.4025 20.7131 9.048 22.0001 12 22.0001C14.952 22.0001 17.5975 20.7131 19.428 18.6786Z" fill="#D50000"/>
<path d="M12 6.6665C13.346 6.6665 14.572 7.169 15.5105 7.992L19.4275 5.3215C17.5975 3.287 14.952 2 12 2C9.04802 2 6.40252 3.287 4.57202 5.3215L8.48902 7.992C9.42802 7.1695 10.654 6.6665 12 6.6665Z" fill="#D50000"/>
<path d="M17.3335 11.9999C17.3335 12.6344 17.217 13.2399 17.014 13.8044L20.9335 16.4764C21.61 15.1279 22 13.6114 22 11.9999C22 10.3884 21.6101 8.87144 20.9331 7.52344L17.0135 10.1954C17.217 10.7599 17.3335 11.3654 17.3335 11.9999Z" fill="#4CAF50"/>
<path d="M6.6665 11.9999C6.6665 11.3654 6.783 10.7599 6.986 10.1954L3.0665 7.52344C2.39 8.87144 2 10.3884 2 11.9999C2 13.6114 2.39 15.1279 3.067 16.4764L6.9865 13.8044C6.783 13.2399 6.6665 12.6344 6.6665 11.9999Z" fill="#4CAF50"/>
<path d="M6.98652 13.8044L3.06702 16.4764C3.47002 17.2789 3.97702 18.0174 4.57202 18.6789L8.48902 16.0084C7.81652 15.4179 7.29552 14.6629 6.98652 13.8044Z" fill="white"/>
<path d="M17.0135 10.1955L20.933 7.52354C20.53 6.72104 20.023 5.98254 19.428 5.32104L15.511 7.99154C16.1835 8.58204 16.7045 9.33705 17.0135 10.1955Z" fill="white"/>
<path d="M8.4895 7.99203L4.5725 5.32153C3.9775 5.98253 3.4705 6.72103 3.0675 7.52403L6.987 10.196C7.2955 9.33703 7.8165 8.58203 8.4895 7.99203Z" fill="white"/>
<path d="M15.5105 16.008L19.4275 18.6785C20.0225 18.0175 20.5295 17.279 20.9325 16.476L17.013 13.804C16.7045 14.663 16.1835 15.418 15.5105 16.008Z" fill="white"/>
<path d="M17.1705 13.8605C17.38 13.2785 17.5 12.654 17.5 12C17.5 11.346 17.38 10.7215 17.1705 10.1395C16.8515 9.254 16.3145 8.475 15.6205 7.867C14.6525 7.0185 13.388 6.5 12 6.5C10.612 6.5 9.3475 7.0185 8.3795 7.867C7.6855 8.4755 7.1485 9.254 6.8295 10.1395C6.62 10.7215 6.5 11.346 6.5 12C6.5 12.654 6.62 13.2785 6.8295 13.8605C7.1485 14.746 7.6855 15.525 8.3795 16.133C9.3475 16.9815 10.612 17.5 12 17.5C13.388 17.5 14.6525 16.9815 15.6205 16.1335C16.314 15.525 16.8515 14.746 17.1705 13.8605Z" fill="white"/>
<path d="M10.8466 10.0105H11.6146L12.0001 10.677L12.3856 10.0105H13.1536L12.7711 9.344L13.1536 8.677H12.3856L12.0001 8.0105L11.6146 8.677H10.8466L11.2291 9.344L10.8466 10.0105Z" fill="#D50000"/>
<path d="M15.8205 12.6665H15.0495L14.664 12L14.281 12.6665H13.5105L13.896 13.3335L13.5105 14H14.281L14.664 14.6665L15.0495 14H15.8205L15.435 13.3335L15.8205 12.6665Z" fill="#D50000"/>
<path d="M9.72894 14.0081H10.4999L10.1144 13.3411L10.4999 12.6746H9.72894L9.34644 12.0081L8.96094 12.6746H8.18994L8.57544 13.3411L8.18994 14.0081H8.96094L9.34644 14.6746L9.72894 14.0081Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'BI',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  