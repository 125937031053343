
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.3581 15.5H8.90011L4.29761 18.3765C6.13211 20.5895 8.90111 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#FFC604"/>
<path d="M14.5 12L10.5 14.5H21.672C21.8785 13.6995 22 12.865 22 12C22 11.135 21.8785 10.3005 21.672 9.5H10.5L14.5 12Z" fill="#263238"/>
<path d="M21.3581 8.5C19.9376 4.706 16.2896 2 12.0001 2C8.90111 2 6.13211 3.4105 4.29761 5.6235L8.90011 8.5H21.3581Z" fill="#2E7D32"/>
<path d="M10.4999 9.5H21.6719C21.5839 9.159 21.4794 8.826 21.3574 8.5H8.8999L10.4999 9.5Z" fill="white"/>
<path d="M8.8999 15.5H21.3579C21.4799 15.174 21.5844 14.841 21.6724 14.5H10.4999L8.8999 15.5Z" fill="white"/>
<path d="M10.5 14.5L14.5 12L10.5 9.50004L8.9 8.50004L4.2975 5.62354C2.863 7.35454 2 9.57604 2 12C2 14.424 2.863 16.6455 4.2975 18.3765L8.9 15.5L10.5 14.5Z" fill="#EB0E19"/>
<path d="M6 8.72461L6.865 10.6896L9 10.9046L7.4005 12.3341L7.8535 14.4316L6 13.3516L4.1465 14.4316L4.5995 12.3341L3 10.9046L5.135 10.6896L6 8.72461Z" fill="#FFC604"/>
<path d="M4.5 11H7.5V13H4.5V11Z" fill="white"/>
<path d="M5.05251 9.5564L8.79101 13.4274L8.45701 13.6989L4.71851 9.8279L5.05251 9.5564Z" fill="#263238"/>
<path d="M4.06445 12.2539L7.07445 9.8064L7.34595 10.1424L4.33595 12.5879L4.06445 12.2539Z" fill="#263238"/>
<path d="M6.5136 11.9259L6.5096 12.3909C6.0211 12.4399 5.5486 12.2894 5.0896 11.9729L5.3376 11.5254C5.7011 11.8224 6.0901 11.9724 6.5136 11.9259Z" fill="#263238"/>
<path d="M4.5625 12.2695L4.9395 12.0605L5.3575 12.8145L4.9805 13.0235L4.5625 12.2695Z" fill="#263238"/>
<path d="M3.23853 13.283L4.57453 12.197L4.84803 12.531L3.51003 13.619L3.23853 13.283Z" fill="#263238"/>
<path d="M7.30446 10.5331L7.03296 10.1991L8.37096 9.11108L7.30446 10.5331Z" fill="#263238"/>
<path d="M4.05447 11.6935L3.60547 11.057L4.40797 10.0275L4.81447 9.89648L5.04097 10.123L4.97047 10.51L4.05447 11.6935Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'MZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  