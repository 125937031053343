
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C7.71047 2 4.06247 4.706 2.64197 8.5H21.3575C19.9375 4.706 16.2895 2 12 2Z" fill="#D50000"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M21.358 15.5H2.64197C4.06247 19.294 7.71047 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#424242"/>
<path d="M7.6525 12.2951L8.7345 11.5081H7.3985L6.9845 10.2366L6.5725 11.5081H5.2345L6.3165 12.2951L5.9025 13.5666L6.9845 12.7796L8.0665 13.5666L7.6525 12.2951Z" fill="#388E3C"/>
<path d="M17.6525 12.2951L18.7345 11.5081H17.3985L16.9845 10.2366L16.5725 11.5081H15.2345L16.3165 12.2951L15.9025 13.5666L16.9845 12.7796L18.0665 13.5666L17.6525 12.2951Z" fill="#388E3C"/>
  </svg>
</template>

<script>
export default {
  name: 'SY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  