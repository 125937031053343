
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#4CAF50"/>
<path d="M21 12L12 18L3 12L12 6L21 12Z" fill="#FFEB3B"/>
<path d="M15.5 12C15.5 13.9335 13.9335 15.5 12 15.5C10.0665 15.5 8.5 13.9335 8.5 12C8.5 10.0665 10.0665 8.5 12 8.5C13.9335 8.5 15.5 10.0665 15.5 12Z" fill="#3F51B5"/>
<path d="M8.90248 10.373C8.73248 10.6955 8.61548 11.047 8.55298 11.418C9.67998 11.2795 12.7425 11.1365 15.3205 13.1055C15.4355 12.758 15.5 12.3865 15.5 12C15.5 12 15.5 11.998 15.5 11.996C13.0295 10.3225 10.328 10.256 8.90248 10.373Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'BR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  