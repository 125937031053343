
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M11.9945 22C17.5175 22 21.9945 17.523 21.9945 12H1.99451C1.99451 17.523 6.47201 22 11.9945 22Z" fill="#D50000"/>
<path d="M11.9945 2C6.47151 2 1.99451 6.477 1.99451 12H21.9945C21.9945 6.477 17.5175 2 11.9945 2Z" fill="#283593"/>
<path d="M9.39948 6.74039C9.39948 6.74039 6.91848 5.99039 6.39948 6.50939C5.88048 7.02839 6.56098 9.11739 6.56098 9.11739L9.22648 9.19839L9.39948 6.74039Z" fill="#FDD835"/>
<path d="M9.24997 9.22388L9.22547 9.22288L6.54247 9.14188L6.53697 9.12538C6.50897 9.03938 5.85797 7.01588 6.38147 6.49188C6.90497 5.96688 9.30497 6.68538 9.40697 6.71588L9.42597 6.72188L9.42447 6.74188L9.24997 9.22388ZM6.57947 9.09338L9.20297 9.17238L9.37347 6.75838C9.14147 6.69088 6.89597 6.04738 6.41697 6.52738C5.93897 7.00588 6.51697 8.89488 6.57947 9.09338Z" fill="#231F20"/>
<path d="M8.55049 6.40503H8.50049V8.95553H8.55049V6.40503Z" fill="#231F20"/>
<path d="M8.74605 6.40503H8.69604V8.95553H8.74605V6.40503Z" fill="#231F20"/>
<path d="M6.5915 6.54858H6.5415V8.68558H6.5915V6.54858Z" fill="#231F20"/>
<path d="M6.79097 6.40503H6.74097V8.95553H6.79097V6.40503Z" fill="#231F20"/>
<path d="M6.98652 6.40503H6.93652V8.95553H6.98652V6.40503Z" fill="#231F20"/>
<path d="M7.18245 6.40503H7.13245V8.95553H7.18245V6.40503Z" fill="#231F20"/>
<path d="M7.37751 6.40503H7.32751V8.95553H7.37751V6.40503Z" fill="#231F20"/>
<path d="M7.57295 6.40503H7.52295V8.95553H7.57295V6.40503Z" fill="#231F20"/>
<path d="M7.76899 6.40503H7.71899V8.95553H7.76899V6.40503Z" fill="#231F20"/>
<path d="M7.96394 6.40503H7.91394V8.95553H7.96394V6.40503Z" fill="#231F20"/>
<path d="M8.1595 6.40503H8.1095V8.95553H8.1595V6.40503Z" fill="#231F20"/>
<path d="M8.35505 6.40503H8.30505V8.95553H8.35505V6.40503Z" fill="#231F20"/>
<path d="M8.94949 6.83247C8.94949 6.83247 7.28799 6.20947 6.59549 6.60147C5.90299 6.99397 6.67649 8.62097 6.67649 8.62097L6.14549 8.43647C6.14549 8.43647 5.56149 6.92797 6.46849 6.35947C7.24149 5.87497 9.00699 6.57847 9.00699 6.57847L8.94949 6.83247Z" fill="#FDD835"/>
<path d="M6.72454 8.66439L6.12654 8.45639L6.12204 8.44539C6.11604 8.43039 5.54154 6.91139 6.45554 6.33839C7.23104 5.85189 8.94404 6.52639 9.01654 6.55539L9.03654 6.56389L8.96754 6.86639L8.94054 6.85589C8.92404 6.84889 7.28254 6.24139 6.60754 6.62389C5.94304 6.99989 6.69104 8.59389 6.69854 8.61039L6.72454 8.66439ZM6.16504 8.41689L6.62904 8.57789C6.49904 8.28689 5.94254 6.94289 6.58354 6.57989C7.23804 6.20889 8.70654 6.71739 8.93154 6.79889L8.97804 6.59389C8.78154 6.51889 7.19204 5.93539 6.48204 6.37989C5.64204 6.90689 6.11504 8.28089 6.16504 8.41689Z" fill="#231F20"/>
<path d="M5.80355 8.15105C5.92064 8.15105 6.01555 8.05614 6.01555 7.93905C6.01555 7.82197 5.92064 7.72705 5.80355 7.72705C5.68647 7.72705 5.59155 7.82197 5.59155 7.93905C5.59155 8.05614 5.68647 8.15105 5.80355 8.15105Z" fill="#FDD835"/>
<path d="M5.80353 8.1759C5.67253 8.1759 5.56653 8.0699 5.56653 7.9389C5.56653 7.8079 5.67253 7.7019 5.80353 7.7019C5.93453 7.7019 6.04053 7.8079 6.04053 7.9389C6.04053 8.0699 5.93453 8.1759 5.80353 8.1759ZM5.80353 7.7519C5.70053 7.7519 5.61653 7.8359 5.61653 7.9389C5.61653 8.0419 5.70053 8.1259 5.80353 8.1259C5.90653 8.1259 5.99053 8.0419 5.99053 7.9389C5.99053 7.8359 5.90653 7.7519 5.80353 7.7519Z" fill="#231F20"/>
<path d="M6.13253 6.57146C6.24962 6.57146 6.34453 6.47655 6.34453 6.35946C6.34453 6.24238 6.24962 6.14746 6.13253 6.14746C6.01545 6.14746 5.92053 6.24238 5.92053 6.35946C5.92053 6.47655 6.01545 6.57146 6.13253 6.57146Z" fill="#FDD835"/>
<path d="M6.13301 6.59657C6.00201 6.59657 5.89551 6.49007 5.89551 6.35907C5.89551 6.22807 6.00201 6.12207 6.13301 6.12207C6.26401 6.12207 6.37001 6.22807 6.37001 6.35907C6.37001 6.49007 6.26351 6.59657 6.13301 6.59657ZM6.13301 6.17257C6.02951 6.17257 5.94551 6.25657 5.94551 6.35957C5.94551 6.46307 6.02951 6.54707 6.13301 6.54707C6.23601 6.54707 6.32001 6.46307 6.32001 6.35957C6.32001 6.25657 6.23601 6.17257 6.13301 6.17257Z" fill="#231F20"/>
<path d="M5.88156 6.93059C5.99864 6.93059 6.09356 6.83568 6.09356 6.71859C6.09356 6.60151 5.99864 6.50659 5.88156 6.50659C5.76447 6.50659 5.66956 6.60151 5.66956 6.71859C5.66956 6.83568 5.76447 6.93059 5.88156 6.93059Z" fill="#FDD835"/>
<path d="M5.88203 6.95545C5.75103 6.95545 5.64453 6.84945 5.64453 6.71845C5.64453 6.58745 5.75103 6.48145 5.88203 6.48145C6.01303 6.48145 6.11903 6.58745 6.11903 6.71845C6.11903 6.84945 6.01253 6.95545 5.88203 6.95545ZM5.88203 6.53145C5.77853 6.53145 5.69453 6.61545 5.69453 6.71845C5.69453 6.82145 5.77853 6.90545 5.88203 6.90545C5.98503 6.90545 6.06903 6.82145 6.06903 6.71845C6.06903 6.61545 5.98503 6.53145 5.88203 6.53145Z" fill="#231F20"/>
<path d="M5.76901 7.33757C5.88609 7.33757 5.98101 7.24266 5.98101 7.12557C5.98101 7.00849 5.88609 6.91357 5.76901 6.91357C5.65192 6.91357 5.55701 7.00849 5.55701 7.12557C5.55701 7.24266 5.65192 7.33757 5.76901 7.33757Z" fill="#FDD835"/>
<path d="M5.76898 7.36243C5.63798 7.36243 5.53198 7.25643 5.53198 7.12543C5.53198 6.99443 5.63798 6.88843 5.76898 6.88843C5.89998 6.88843 6.00598 6.99443 6.00598 7.12543C6.00598 7.25643 5.89998 7.36243 5.76898 7.36243ZM5.76898 6.93843C5.66598 6.93843 5.58198 7.02243 5.58198 7.12543C5.58198 7.22843 5.66598 7.31243 5.76898 7.31243C5.87198 7.31243 5.95598 7.22843 5.95598 7.12543C5.95598 7.02243 5.87198 6.93843 5.76898 6.93843Z" fill="#231F20"/>
<path d="M7.24899 6.195C7.36607 6.195 7.46099 6.10008 7.46099 5.983C7.46099 5.86591 7.36607 5.771 7.24899 5.771C7.1319 5.771 7.03699 5.86591 7.03699 5.983C7.03699 6.10008 7.1319 6.195 7.24899 6.195Z" fill="#FDD835"/>
<path d="M7.24896 6.22011C7.11796 6.22011 7.01196 6.11361 7.01196 5.98261C7.01196 5.85161 7.11796 5.74561 7.24896 5.74561C7.37996 5.74561 7.48596 5.85161 7.48596 5.98261C7.48596 6.11361 7.37996 6.22011 7.24896 6.22011ZM7.24896 5.79611C7.14596 5.79611 7.06196 5.88011 7.06196 5.98311C7.06196 6.08661 7.14596 6.17061 7.24896 6.17061C7.35196 6.17061 7.43596 6.08661 7.43596 5.98311C7.43596 5.88011 7.35196 5.79611 7.24896 5.79611Z" fill="#231F20"/>
<path d="M6.83358 6.21233C6.95067 6.21233 7.04558 6.11741 7.04558 6.00033C7.04558 5.88325 6.95067 5.78833 6.83358 5.78833C6.7165 5.78833 6.62158 5.88325 6.62158 6.00033C6.62158 6.11741 6.7165 6.21233 6.83358 6.21233Z" fill="#FDD835"/>
<path d="M6.83356 6.23743C6.70256 6.23743 6.59656 6.13143 6.59656 6.00043C6.59656 5.86943 6.70256 5.76343 6.83356 5.76343C6.96456 5.76343 7.07106 5.86943 7.07106 6.00043C7.07106 6.13143 6.96456 6.23743 6.83356 6.23743ZM6.83356 5.81343C6.73056 5.81343 6.64656 5.89743 6.64656 6.00043C6.64656 6.10343 6.73056 6.18743 6.83356 6.18743C6.93706 6.18743 7.02106 6.10343 7.02106 6.00043C7.02106 5.89743 6.93706 5.81343 6.83356 5.81343Z" fill="#231F20"/>
<path d="M8.88497 6.48943C9.00206 6.48943 9.09697 6.39451 9.09697 6.27743C9.09697 6.16035 9.00206 6.06543 8.88497 6.06543C8.76789 6.06543 8.67297 6.16035 8.67297 6.27743C8.67297 6.39451 8.76789 6.48943 8.88497 6.48943Z" fill="#FDD835"/>
<path d="M8.88495 6.51404C8.75395 6.51404 8.64795 6.40804 8.64795 6.27704C8.64795 6.14604 8.75395 6.04004 8.88495 6.04004C9.01595 6.04004 9.12195 6.14604 9.12195 6.27704C9.12195 6.40804 9.01545 6.51404 8.88495 6.51404ZM8.88495 6.09054C8.78195 6.09054 8.69795 6.17454 8.69795 6.27754C8.69795 6.38054 8.78195 6.46454 8.88495 6.46454C8.98795 6.46454 9.07195 6.38054 9.07195 6.27754C9.07195 6.17454 8.98795 6.09054 8.88495 6.09054Z" fill="#231F20"/>
<path d="M8.46957 6.39397C8.58665 6.39397 8.68157 6.29906 8.68157 6.18197C8.68157 6.06489 8.58665 5.96997 8.46957 5.96997C8.35248 5.96997 8.25757 6.06489 8.25757 6.18197C8.25757 6.29906 8.35248 6.39397 8.46957 6.39397Z" fill="#FDD835"/>
<path d="M8.46906 6.41907C8.33806 6.41907 8.23206 6.31307 8.23206 6.18207C8.23206 6.05107 8.33806 5.94507 8.46906 5.94507C8.60006 5.94507 8.70606 6.05107 8.70606 6.18207C8.70606 6.31307 8.60006 6.41907 8.46906 6.41907ZM8.46906 5.99507C8.36606 5.99507 8.28205 6.07907 8.28205 6.18207C8.28205 6.28507 8.36606 6.36907 8.46906 6.36907C8.57206 6.36907 8.65606 6.28507 8.65606 6.18207C8.65606 6.07907 8.57256 5.99507 8.46906 5.99507Z" fill="#231F20"/>
<path d="M7.64706 6.22942C7.76414 6.22942 7.85906 6.1345 7.85906 6.01742C7.85906 5.90034 7.76414 5.80542 7.64706 5.80542C7.52997 5.80542 7.43506 5.90034 7.43506 6.01742C7.43506 6.1345 7.52997 6.22942 7.64706 6.22942Z" fill="#FDD835"/>
<path d="M7.64703 6.25502C7.51603 6.25502 7.41003 6.14852 7.41003 6.01752C7.41003 5.88652 7.51603 5.78052 7.64703 5.78052C7.77803 5.78052 7.88453 5.88652 7.88453 6.01752C7.88453 6.14852 7.77803 6.25502 7.64703 6.25502ZM7.64703 5.83052C7.54403 5.83052 7.46003 5.91452 7.46003 6.01752C7.46003 6.12102 7.54403 6.20502 7.64703 6.20502C7.75053 6.20502 7.83453 6.12102 7.83453 6.01752C7.83453 5.91452 7.75053 5.83052 7.64703 5.83052Z" fill="#231F20"/>
<path d="M5.75204 7.71843C5.86912 7.71843 5.96404 7.62352 5.96404 7.50643C5.96404 7.38935 5.86912 7.29443 5.75204 7.29443C5.63495 7.29443 5.54004 7.38935 5.54004 7.50643C5.54004 7.62352 5.63495 7.71843 5.75204 7.71843Z" fill="#FDD835"/>
<path d="M5.75203 7.74304C5.62103 7.74304 5.51453 7.63704 5.51453 7.50604C5.51453 7.37504 5.62103 7.26904 5.75203 7.26904C5.88303 7.26904 5.98903 7.37504 5.98903 7.50604C5.98903 7.63704 5.88303 7.74304 5.75203 7.74304ZM5.75203 7.31954C5.64853 7.31954 5.56453 7.40354 5.56453 7.50654C5.56453 7.60954 5.64853 7.69354 5.75203 7.69354C5.85503 7.69354 5.93903 7.60954 5.93903 7.50654C5.93903 7.40354 5.85503 7.31954 5.75203 7.31954Z" fill="#231F20"/>
<path d="M6.44406 6.32048C6.56114 6.32048 6.65606 6.22557 6.65606 6.10848C6.65606 5.9914 6.56114 5.89648 6.44406 5.89648C6.32697 5.89648 6.23206 5.9914 6.23206 6.10848C6.23206 6.22557 6.32697 6.32048 6.44406 6.32048Z" fill="#FDD835"/>
<path d="M6.44453 6.34559C6.31353 6.34559 6.20703 6.23909 6.20703 6.10809C6.20703 5.97709 6.31353 5.87109 6.44453 5.87109C6.57553 5.87109 6.68153 5.97709 6.68153 6.10809C6.68153 6.23909 6.57503 6.34559 6.44453 6.34559ZM6.44453 5.92159C6.34103 5.92159 6.25703 6.00559 6.25703 6.10859C6.25703 6.21209 6.34103 6.29609 6.44453 6.29609C6.54753 6.29609 6.63153 6.21209 6.63153 6.10859C6.63153 6.00559 6.54753 5.92159 6.44453 5.92159Z" fill="#231F20"/>
<path d="M8.07101 6.31194C8.18809 6.31194 8.28301 6.21702 8.28301 6.09994C8.28301 5.98285 8.18809 5.88794 8.07101 5.88794C7.95392 5.88794 7.85901 5.98285 7.85901 6.09994C7.85901 6.21702 7.95392 6.31194 8.07101 6.31194Z" fill="#FDD835"/>
<path d="M8.07147 6.33705C7.94047 6.33705 7.83447 6.23105 7.83447 6.10005C7.83447 5.96905 7.94047 5.86255 8.07147 5.86255C8.20247 5.86255 8.30847 5.96905 8.30847 6.10005C8.30847 6.23105 8.20197 6.33705 8.07147 6.33705ZM8.07147 5.91255C7.96847 5.91255 7.88447 5.99655 7.88447 6.10005C7.88447 6.20305 7.96847 6.28705 8.07147 6.28705C8.17447 6.28705 8.25847 6.20305 8.25847 6.10005C8.25847 5.99655 8.17447 5.91255 8.07147 5.91255Z" fill="#231F20"/>
<path d="M9.14001 6.74063C9.14001 6.74063 11.621 5.99063 12.14 6.50963C12.6595 7.02913 11.9785 9.11763 11.9785 9.11763L9.31301 9.19863L9.14001 6.74063Z" fill="#FDD835"/>
<path d="M9.28953 9.22421L9.28803 9.20021L9.11353 6.72221L9.13253 6.71621C9.23453 6.68521 11.635 5.96721 12.1575 6.49221C12.682 7.01621 12.0305 9.03971 12.0025 9.12571L11.997 9.14221L11.9795 9.14271L9.28953 9.22421ZM9.16653 6.75871L9.33653 9.17271L11.9605 9.09371C12.023 8.89571 12.601 7.00621 12.1225 6.52771C11.6435 6.04871 9.39803 6.69121 9.16653 6.75871Z" fill="#231F20"/>
<path d="M10.0395 6.40503H9.9895V8.95553H10.0395V6.40503Z" fill="#231F20"/>
<path d="M9.84407 6.40503H9.79407V8.95553H9.84407V6.40503Z" fill="#231F20"/>
<path d="M11.9985 6.54858H11.9485V8.68558H11.9985V6.54858Z" fill="#231F20"/>
<path d="M11.799 6.40503H11.749V8.95553H11.799V6.40503Z" fill="#231F20"/>
<path d="M11.603 6.40503H11.553V8.95553H11.603V6.40503Z" fill="#231F20"/>
<path d="M11.408 6.40503H11.358V8.95553H11.408V6.40503Z" fill="#231F20"/>
<path d="M11.2125 6.40503H11.1625V8.95553H11.2125V6.40503Z" fill="#231F20"/>
<path d="M11.017 6.40503H10.967V8.95553H11.017V6.40503Z" fill="#231F20"/>
<path d="M10.8215 6.40503H10.7715V8.95553H10.8215V6.40503Z" fill="#231F20"/>
<path d="M10.6256 6.40503H10.5756V8.95553H10.6256V6.40503Z" fill="#231F20"/>
<path d="M10.4305 6.40503H10.3805V8.95553H10.4305V6.40503Z" fill="#231F20"/>
<path d="M10.2351 6.40503H10.1851V8.95553H10.2351V6.40503Z" fill="#231F20"/>
<path d="M9.58997 6.83271C9.58997 6.83271 11.2515 6.20971 11.944 6.60171C12.6365 6.99421 11.863 8.62121 11.863 8.62121L12.394 8.43671C12.394 8.43671 12.978 6.92821 12.071 6.35971C11.298 5.87521 9.53247 6.57871 9.53247 6.57871L9.58997 6.83271Z" fill="#FDD835"/>
<path d="M11.8151 8.66473L11.8411 8.61073C11.8486 8.59473 12.5966 7.00023 11.9326 6.62423C11.2581 6.24273 9.61605 6.84973 9.59955 6.85623L9.57255 6.86623L9.50305 6.56423L9.52305 6.55573C9.59605 6.52673 11.3096 5.85373 12.0846 6.33873C12.9981 6.91173 12.4236 8.43073 12.4176 8.44573L12.4131 8.45673L12.4026 8.46023L11.8151 8.66473ZM11.3121 6.44673C11.5581 6.44673 11.7851 6.48323 11.9566 6.58073C12.5976 6.94423 12.0406 8.28773 11.9106 8.57873L12.3746 8.41773C12.4241 8.28223 12.8971 6.90773 12.0581 6.38123C11.3471 5.93673 9.75755 6.51973 9.56155 6.59523L9.60855 6.80023C9.77405 6.73973 10.6181 6.44673 11.3121 6.44673Z" fill="#231F20"/>
<path d="M12.736 8.15105C12.8531 8.15105 12.948 8.05614 12.948 7.93905C12.948 7.82197 12.8531 7.72705 12.736 7.72705C12.619 7.72705 12.524 7.82197 12.524 7.93905C12.524 8.05614 12.619 8.15105 12.736 8.15105Z" fill="#FDD835"/>
<path d="M12.736 8.17615C12.605 8.17615 12.499 8.07015 12.499 7.93915C12.499 7.80815 12.605 7.70215 12.736 7.70215C12.867 7.70215 12.9735 7.80815 12.9735 7.93915C12.9735 8.07015 12.8665 8.17615 12.736 8.17615ZM12.736 7.75215C12.633 7.75215 12.549 7.83615 12.549 7.93915C12.549 8.04215 12.633 8.12615 12.736 8.12615C12.8395 8.12615 12.9235 8.04215 12.9235 7.93915C12.9235 7.83615 12.8395 7.75215 12.736 7.75215Z" fill="#231F20"/>
<path d="M12.4071 6.5717C12.5242 6.5717 12.6191 6.47679 12.6191 6.3597C12.6191 6.24262 12.5242 6.14771 12.4071 6.14771C12.29 6.14771 12.1951 6.24262 12.1951 6.3597C12.1951 6.47679 12.29 6.5717 12.4071 6.5717Z" fill="#FDD835"/>
<path d="M12.407 6.59657C12.276 6.59657 12.17 6.49007 12.17 6.35907C12.17 6.22807 12.276 6.12207 12.407 6.12207C12.538 6.12207 12.644 6.22807 12.644 6.35907C12.644 6.49007 12.538 6.59657 12.407 6.59657ZM12.407 6.17257C12.304 6.17257 12.22 6.25657 12.22 6.35957C12.22 6.46307 12.304 6.54707 12.407 6.54707C12.51 6.54707 12.594 6.46307 12.594 6.35957C12.594 6.25657 12.5105 6.17257 12.407 6.17257Z" fill="#231F20"/>
<path d="M12.658 6.93059C12.7751 6.93059 12.87 6.83568 12.87 6.71859C12.87 6.60151 12.7751 6.50659 12.658 6.50659C12.541 6.50659 12.446 6.60151 12.446 6.71859C12.446 6.83568 12.541 6.93059 12.658 6.93059Z" fill="#FDD835"/>
<path d="M12.658 6.95569C12.527 6.95569 12.421 6.84969 12.421 6.71869C12.421 6.58769 12.527 6.48169 12.658 6.48169C12.789 6.48169 12.895 6.58769 12.895 6.71869C12.895 6.84969 12.789 6.95569 12.658 6.95569ZM12.658 6.53169C12.555 6.53169 12.471 6.61569 12.471 6.71869C12.471 6.82169 12.555 6.90569 12.658 6.90569C12.761 6.90569 12.845 6.82169 12.845 6.71869C12.845 6.61569 12.761 6.53169 12.658 6.53169Z" fill="#231F20"/>
<path d="M12.7705 7.33757C12.8876 7.33757 12.9825 7.24266 12.9825 7.12557C12.9825 7.00849 12.8876 6.91357 12.7705 6.91357C12.6534 6.91357 12.5585 7.00849 12.5585 7.12557C12.5585 7.24266 12.6534 7.33757 12.7705 7.33757Z" fill="#FDD835"/>
<path d="M12.7706 7.36267C12.6396 7.36267 12.5336 7.25667 12.5336 7.12567C12.5336 6.99467 12.6396 6.88867 12.7706 6.88867C12.9016 6.88867 13.0081 6.99467 13.0081 7.12567C13.0081 7.25667 12.9016 7.36267 12.7706 7.36267ZM12.7706 6.93867C12.6676 6.93867 12.5836 7.02267 12.5836 7.12567C12.5836 7.22867 12.6676 7.31267 12.7706 7.31267C12.8741 7.31267 12.9581 7.22867 12.9581 7.12567C12.9581 7.02267 12.8741 6.93867 12.7706 6.93867Z" fill="#231F20"/>
<path d="M11.2905 6.195C11.4076 6.195 11.5025 6.10008 11.5025 5.983C11.5025 5.86591 11.4076 5.771 11.2905 5.771C11.1734 5.771 11.0785 5.86591 11.0785 5.983C11.0785 6.10008 11.1734 6.195 11.2905 6.195Z" fill="#FDD835"/>
<path d="M11.2905 6.22011C11.1595 6.22011 11.0535 6.11361 11.0535 5.98261C11.0535 5.85161 11.1595 5.74561 11.2905 5.74561C11.4215 5.74561 11.528 5.85161 11.528 5.98261C11.528 6.11361 11.4215 6.22011 11.2905 6.22011ZM11.2905 5.79611C11.1875 5.79611 11.1035 5.88011 11.1035 5.98311C11.1035 6.08661 11.1875 6.17061 11.2905 6.17061C11.394 6.17061 11.478 6.08661 11.478 5.98311C11.478 5.88011 11.394 5.79611 11.2905 5.79611Z" fill="#231F20"/>
<path d="M11.706 6.21257C11.8231 6.21257 11.918 6.11766 11.918 6.00057C11.918 5.88349 11.8231 5.78857 11.706 5.78857C11.5889 5.78857 11.494 5.88349 11.494 6.00057C11.494 6.11766 11.5889 6.21257 11.706 6.21257Z" fill="#FDD835"/>
<path d="M11.706 6.23767C11.575 6.23767 11.469 6.13167 11.469 6.00067C11.469 5.86967 11.575 5.76367 11.706 5.76367C11.837 5.76367 11.943 5.86967 11.943 6.00067C11.943 6.13167 11.837 6.23767 11.706 6.23767ZM11.706 5.81367C11.603 5.81367 11.519 5.89767 11.519 6.00067C11.519 6.10367 11.603 6.18767 11.706 6.18767C11.809 6.18767 11.893 6.10367 11.893 6.00067C11.893 5.89767 11.809 5.81367 11.706 5.81367Z" fill="#231F20"/>
<path d="M9.65499 6.48943C9.77208 6.48943 9.86699 6.39451 9.86699 6.27743C9.86699 6.16035 9.77208 6.06543 9.65499 6.06543C9.53791 6.06543 9.44299 6.16035 9.44299 6.27743C9.44299 6.39451 9.53791 6.48943 9.65499 6.48943Z" fill="#FDD835"/>
<path d="M9.65497 6.51404C9.52397 6.51404 9.41797 6.40804 9.41797 6.27704C9.41797 6.14604 9.52397 6.04004 9.65497 6.04004C9.78597 6.04004 9.89247 6.14604 9.89247 6.27704C9.89247 6.40804 9.78547 6.51404 9.65497 6.51404ZM9.65497 6.09054C9.55197 6.09054 9.46797 6.17454 9.46797 6.27754C9.46797 6.38054 9.55197 6.46454 9.65497 6.46454C9.75847 6.46454 9.84247 6.38054 9.84247 6.27754C9.84247 6.17454 9.75847 6.09054 9.65497 6.09054Z" fill="#231F20"/>
<path d="M10.0705 6.39397C10.1876 6.39397 10.2825 6.29906 10.2825 6.18197C10.2825 6.06489 10.1876 5.96997 10.0705 5.96997C9.95344 5.96997 9.85852 6.06489 9.85852 6.18197C9.85852 6.29906 9.95344 6.39397 10.0705 6.39397Z" fill="#FDD835"/>
<path d="M10.0705 6.41907C9.9395 6.41907 9.8335 6.31307 9.8335 6.18207C9.8335 6.05107 9.9395 5.94507 10.0705 5.94507C10.2015 5.94507 10.3075 6.05107 10.3075 6.18207C10.3075 6.31307 10.201 6.41907 10.0705 6.41907ZM10.0705 5.99507C9.9675 5.99507 9.8835 6.07907 9.8835 6.18207C9.8835 6.28507 9.9675 6.36907 10.0705 6.36907C10.1735 6.36907 10.2575 6.28507 10.2575 6.18207C10.2575 6.07907 10.1735 5.99507 10.0705 5.99507Z" fill="#231F20"/>
<path d="M10.8925 6.22942C11.0096 6.22942 11.1045 6.1345 11.1045 6.01742C11.1045 5.90034 11.0096 5.80542 10.8925 5.80542C10.7755 5.80542 10.6805 5.90034 10.6805 6.01742C10.6805 6.1345 10.7755 6.22942 10.8925 6.22942Z" fill="#FDD835"/>
<path d="M10.8925 6.25502C10.7615 6.25502 10.6555 6.14852 10.6555 6.01752C10.6555 5.88652 10.7615 5.78052 10.8925 5.78052C11.0235 5.78052 11.1295 5.88652 11.1295 6.01752C11.1295 6.14852 11.0235 6.25502 10.8925 6.25502ZM10.8925 5.83052C10.7895 5.83052 10.7055 5.91452 10.7055 6.01752C10.7055 6.12102 10.7895 6.20502 10.8925 6.20502C10.9955 6.20502 11.0795 6.12102 11.0795 6.01752C11.0795 5.91452 10.9955 5.83052 10.8925 5.83052Z" fill="#231F20"/>
<path d="M12.788 7.71843C12.9051 7.71843 13 7.62352 13 7.50643C13 7.38935 12.9051 7.29443 12.788 7.29443C12.671 7.29443 12.576 7.38935 12.576 7.50643C12.576 7.62352 12.671 7.71843 12.788 7.71843Z" fill="#FDD835"/>
<path d="M12.788 7.74304C12.657 7.74304 12.5505 7.63704 12.5505 7.50604C12.5505 7.37504 12.657 7.26904 12.788 7.26904C12.919 7.26904 13.025 7.37504 13.025 7.50604C13.025 7.63704 12.919 7.74304 12.788 7.74304ZM12.788 7.31954C12.6845 7.31954 12.6005 7.40354 12.6005 7.50654C12.6005 7.60954 12.6845 7.69354 12.788 7.69354C12.891 7.69354 12.975 7.60954 12.975 7.50654C12.975 7.40354 12.891 7.31954 12.788 7.31954Z" fill="#231F20"/>
<path d="M12.0955 6.32048C12.2126 6.32048 12.3075 6.22557 12.3075 6.10848C12.3075 5.9914 12.2126 5.89648 12.0955 5.89648C11.9785 5.89648 11.8835 5.9914 11.8835 6.10848C11.8835 6.22557 11.9785 6.32048 12.0955 6.32048Z" fill="#FDD835"/>
<path d="M12.0955 6.34559C11.9645 6.34559 11.8585 6.23909 11.8585 6.10809C11.8585 5.97709 11.9645 5.87109 12.0955 5.87109C12.2265 5.87109 12.3325 5.97709 12.3325 6.10809C12.3325 6.23909 12.2265 6.34559 12.0955 6.34559ZM12.0955 5.92159C11.9925 5.92159 11.9085 6.00559 11.9085 6.10859C11.9085 6.21209 11.9925 6.29609 12.0955 6.29609C12.1985 6.29609 12.2825 6.21209 12.2825 6.10859C12.2825 6.00559 12.1985 5.92159 12.0955 5.92159Z" fill="#231F20"/>
<path d="M10.4686 6.31194C10.5857 6.31194 10.6806 6.21702 10.6806 6.09994C10.6806 5.98285 10.5857 5.88794 10.4686 5.88794C10.3515 5.88794 10.2566 5.98285 10.2566 6.09994C10.2566 6.21702 10.3515 6.31194 10.4686 6.31194Z" fill="#FDD835"/>
<path d="M10.4691 6.33705C10.3381 6.33705 10.2316 6.23105 10.2316 6.10005C10.2316 5.96905 10.3381 5.86255 10.4691 5.86255C10.6001 5.86255 10.7061 5.96905 10.7061 6.10005C10.7061 6.23105 10.5996 6.33705 10.4691 6.33705ZM10.4691 5.91255C10.3656 5.91255 10.2816 5.99655 10.2816 6.10005C10.2816 6.20305 10.3656 6.28705 10.4691 6.28705C10.5721 6.28705 10.6561 6.20305 10.6561 6.10005C10.6561 5.99655 10.5721 5.91255 10.4691 5.91255Z" fill="#231F20"/>
<path d="M9.28042 4L9.06592 4.3915L9.25992 4.755L9.47792 4.3915L9.28042 4Z" fill="#FDD835"/>
<path d="M9.25846 4.80553L9.03796 4.39103L9.04446 4.37903L9.28246 3.94653L9.50696 4.39253L9.49996 4.40403L9.25846 4.80553ZM9.09396 4.39103L9.26096 4.70403L9.44946 4.39003L9.27946 4.05353L9.09396 4.39103Z" fill="#231F20"/>
<path d="M9.28042 4.94556L9.06592 5.33706L9.25992 5.70056L9.47792 5.33706L9.28042 4.94556Z" fill="#FDD835"/>
<path d="M9.25846 5.75109L9.03796 5.33709L9.04446 5.32509L9.28246 4.89209L9.50696 5.33859L9.49996 5.35009L9.25846 5.75109ZM9.09396 5.33709L9.26096 5.64959L9.44946 5.33609L9.27946 4.99909L9.09396 5.33709Z" fill="#231F20"/>
<path d="M10.0116 4.85904L9.67757 4.64404L9.36707 4.83804L9.67757 5.05604L10.0116 4.85904Z" fill="#FDD835"/>
<path d="M9.67652 5.086L9.32202 4.8365L9.35402 4.817L9.67702 4.6145L10.059 4.86L9.67652 5.086ZM9.41252 4.8395L9.67802 5.0265L9.96302 4.858L9.67702 4.674L9.41252 4.8395Z" fill="#231F20"/>
<path d="M9.17643 4.85904L8.86243 4.64404L8.57043 4.83804L8.86243 5.05604L9.17643 4.85904Z" fill="#FDD835"/>
<path d="M8.86097 5.08651L8.52747 4.83701L8.86247 4.61401L9.22247 4.86001L8.86097 5.08651ZM8.61447 4.83901L8.86397 5.02601L9.13147 4.85801L8.86247 4.67451L8.61447 4.83901Z" fill="#231F20"/>
<path d="M9.28007 5.04744C9.38169 5.04744 9.46407 4.96506 9.46407 4.86344C9.46407 4.76182 9.38169 4.67944 9.28007 4.67944C9.17845 4.67944 9.09607 4.76182 9.09607 4.86344C9.09607 4.96506 9.17845 5.04744 9.28007 5.04744Z" fill="#FDD835"/>
<path d="M9.28004 5.07303C9.16504 5.07303 9.07104 4.97903 9.07104 4.86403C9.07104 4.74903 9.16454 4.65503 9.28004 4.65503C9.39554 4.65503 9.48954 4.74903 9.48954 4.86403C9.48954 4.97903 9.39554 5.07303 9.28004 5.07303ZM9.28004 4.70453C9.19204 4.70453 9.12105 4.77603 9.12105 4.86353C9.12105 4.95103 9.19254 5.02253 9.28004 5.02253C9.36754 5.02253 9.43954 4.95103 9.43954 4.86353C9.43954 4.77603 9.36754 4.70453 9.28004 4.70453Z" fill="#231F20"/>
<path d="M8.89204 6.52101C8.89204 6.52101 8.83454 7.95201 8.59204 8.34401C9.50354 8.43651 9.93054 8.35551 9.93054 8.35551C9.93054 8.35551 9.64204 7.02851 9.61904 6.52051C9.41104 6.55551 8.89204 6.52101 8.89204 6.52101Z" fill="#FDD835"/>
<path d="M9.39506 8.41296C9.18756 8.41296 8.92006 8.40246 8.58956 8.36896L8.54956 8.36495L8.57056 8.33096C8.80756 7.94896 8.86706 6.53396 8.86756 6.51996L8.86856 6.49396L8.89406 6.49595C8.89956 6.49595 9.41156 6.53145 9.61556 6.49595L9.64356 6.49146L9.64456 6.51996C9.66706 7.01996 9.95256 8.33746 9.95556 8.35046L9.96106 8.37596L9.93556 8.38046C9.93256 8.38096 9.75556 8.41296 9.39506 8.41296ZM8.63256 8.32296C9.38406 8.39596 9.79806 8.34946 9.90106 8.33496C9.86156 8.15046 9.62456 7.03096 9.59606 6.54946C9.40056 6.57446 9.02006 6.55446 8.91656 6.54696C8.90706 6.74196 8.84506 7.91746 8.63256 8.32296Z" fill="#231F20"/>
<path d="M9.08299 6.52101C9.08299 6.52101 9.05499 7.95201 8.93799 8.34401C9.37799 8.43651 9.58399 8.35551 9.58399 8.35551C9.58399 8.35551 9.44449 7.02851 9.43349 6.52051C9.33349 6.55551 9.08299 6.52101 9.08299 6.52101Z" fill="#231F20"/>
<path d="M9.32801 8.41358C9.22651 8.41358 9.09551 8.40308 8.93251 8.36858L8.90601 8.36308L8.91401 8.33658C9.02851 7.95208 9.05751 6.53458 9.05801 6.52008L9.05851 6.49158L9.08651 6.49558C9.08901 6.49608 9.33251 6.52758 9.42601 6.49708L9.45851 6.48608L9.45901 6.52008C9.46951 7.02108 9.60801 8.33958 9.60951 8.35308L9.61151 8.37158L9.59401 8.37908C9.58801 8.38158 9.50251 8.41358 9.32801 8.41358ZM8.96901 8.32508C9.30901 8.39208 9.50001 8.35358 9.55751 8.33808C9.54001 8.17158 9.42401 7.04008 9.40951 6.55258C9.31701 6.57008 9.16901 6.55558 9.10751 6.54908C9.10301 6.75158 9.07301 7.93058 8.96901 8.32508Z" fill="#231F20"/>
<path d="M9.26551 7.43752C9.36354 7.43752 9.44301 7.35805 9.44301 7.26002C9.44301 7.16199 9.36354 7.08252 9.26551 7.08252C9.16748 7.08252 9.08801 7.16199 9.08801 7.26002C9.08801 7.35805 9.16748 7.43752 9.26551 7.43752Z" fill="#FDD835"/>
<path d="M9.26548 7.46211C9.15398 7.46211 9.06348 7.37111 9.06348 7.26011C9.06348 7.14911 9.15448 7.05811 9.26548 7.05811C9.37648 7.05811 9.46748 7.14911 9.46748 7.26011C9.46748 7.37111 9.37698 7.46211 9.26548 7.46211ZM9.26548 7.10761C9.18148 7.10761 9.11298 7.17611 9.11298 7.26011C9.11298 7.34411 9.18148 7.41261 9.26548 7.41261C9.34948 7.41261 9.41798 7.34411 9.41798 7.26011C9.41798 7.17611 9.34948 7.10761 9.26548 7.10761Z" fill="#231F20"/>
<path d="M9.26551 7.09742C9.34614 7.09742 9.41151 7.03205 9.41151 6.95142C9.41151 6.87079 9.34614 6.80542 9.26551 6.80542C9.18487 6.80542 9.11951 6.87079 9.11951 6.95142C9.11951 7.03205 9.18487 7.09742 9.26551 7.09742Z" fill="#FDD835"/>
<path d="M9.26548 7.12253C9.17148 7.12253 9.09448 7.04603 9.09448 6.95103C9.09448 6.85703 9.17098 6.78003 9.26548 6.78003C9.35998 6.78003 9.43648 6.85653 9.43648 6.95103C9.43648 7.04603 9.35998 7.12253 9.26548 7.12253ZM9.26548 6.83003C9.19848 6.83003 9.14448 6.88403 9.14448 6.95103C9.14448 7.01803 9.19848 7.07253 9.26548 7.07253C9.33248 7.07253 9.38648 7.01803 9.38648 6.95103C9.38648 6.88403 9.33248 6.83003 9.26548 6.83003Z" fill="#231F20"/>
<path d="M9.26551 6.80201C9.33178 6.80201 9.38551 6.74829 9.38551 6.68201C9.38551 6.61574 9.33178 6.56201 9.26551 6.56201C9.19923 6.56201 9.14551 6.61574 9.14551 6.68201C9.14551 6.74829 9.19923 6.80201 9.26551 6.80201Z" fill="#FDD835"/>
<path d="M9.26548 6.82711C9.18548 6.82711 9.12048 6.76211 9.12048 6.68211C9.12048 6.60211 9.18548 6.53711 9.26548 6.53711C9.34548 6.53711 9.41048 6.60211 9.41048 6.68211C9.41048 6.76211 9.34548 6.82711 9.26548 6.82711ZM9.26548 6.58711C9.21348 6.58711 9.17048 6.63011 9.17048 6.68211C9.17048 6.73411 9.21348 6.77711 9.26548 6.77711C9.31748 6.77711 9.36048 6.73411 9.36048 6.68211C9.36048 6.63011 9.31798 6.58711 9.26548 6.58711Z" fill="#231F20"/>
<path d="M9.26547 7.85247C9.38255 7.85247 9.47747 7.75755 9.47747 7.64047C9.47747 7.52338 9.38255 7.42847 9.26547 7.42847C9.14838 7.42847 9.05347 7.52338 9.05347 7.64047C9.05347 7.75755 9.14838 7.85247 9.26547 7.85247Z" fill="#FDD835"/>
<path d="M9.26544 7.87758C9.13444 7.87758 9.02844 7.77158 9.02844 7.64058C9.02844 7.50958 9.13444 7.40308 9.26544 7.40308C9.39644 7.40308 9.50244 7.50958 9.50244 7.64058C9.50244 7.77158 9.39644 7.87758 9.26544 7.87758ZM9.26544 7.45308C9.16244 7.45308 9.07844 7.53708 9.07844 7.64058C9.07844 7.74358 9.16244 7.82758 9.26544 7.82758C9.36844 7.82758 9.45244 7.74358 9.45244 7.64058C9.45244 7.53708 9.36844 7.45308 9.26544 7.45308Z" fill="#231F20"/>
<path d="M9.26547 6.5888C9.38255 6.5888 9.47747 6.49388 9.47747 6.37679C9.47747 6.25971 9.38255 6.16479 9.26547 6.16479C9.14838 6.16479 9.05347 6.25971 9.05347 6.37679C9.05347 6.49388 9.14838 6.5888 9.26547 6.5888Z" fill="#FDD835"/>
<path d="M9.26544 6.61389C9.13444 6.61389 9.02844 6.50789 9.02844 6.37689C9.02844 6.24589 9.13444 6.13989 9.26544 6.13989C9.39644 6.13989 9.50244 6.24589 9.50244 6.37689C9.50244 6.50789 9.39644 6.61389 9.26544 6.61389ZM9.26544 6.18989C9.16244 6.18989 9.07844 6.27389 9.07844 6.37689C9.07844 6.47989 9.16244 6.56389 9.26544 6.56389C9.36844 6.56389 9.45244 6.47989 9.45244 6.37689C9.45244 6.27389 9.36844 6.18989 9.26544 6.18989Z" fill="#231F20"/>
<path d="M9.26552 6.19535C9.47594 6.19535 9.64652 6.02477 9.64652 5.81435C9.64652 5.60393 9.47594 5.43335 9.26552 5.43335C9.0551 5.43335 8.88452 5.60393 8.88452 5.81435C8.88452 6.02477 9.0551 6.19535 9.26552 6.19535Z" fill="#FDD835"/>
<path d="M9.2655 6.21996C9.042 6.21996 8.8595 6.03796 8.8595 5.81396C8.8595 5.58996 9.0415 5.40796 9.2655 5.40796C9.4895 5.40796 9.6715 5.58996 9.6715 5.81396C9.6715 6.03796 9.4895 6.21996 9.2655 6.21996ZM9.2655 5.45846C9.069 5.45846 8.9095 5.61796 8.9095 5.81446C8.9095 6.01096 9.069 6.17046 9.2655 6.17046C9.462 6.17046 9.6215 6.01096 9.6215 5.81446C9.6215 5.61796 9.462 5.45846 9.2655 5.45846Z" fill="#231F20"/>
<path d="M6.4685 10.2946C6.4685 10.2946 5.8225 8.64456 5.453 8.36756C6.157 7.87156 6.6645 8.31006 6.8375 8.72506C6.8605 7.94056 7.426 7.28256 8.384 8.59806C8.4995 7.76706 9.861 7.49006 10.092 8.63256C10.092 8.63256 11.373 6.94756 11.6385 8.71356C11.973 8.04406 12.608 8.03256 13 8.36756C12.7 8.66756 12.3425 9.44056 12.042 10.2716C11.269 10.2716 6.4685 10.2946 6.4685 10.2946Z" fill="#FDD835"/>
<path d="M6.45153 10.3195L6.44503 10.304C6.43853 10.2875 5.79703 8.65747 5.43753 8.38747L5.41003 8.36697L5.43853 8.34697C5.72903 8.14247 6.00853 8.07747 6.26953 8.15147C6.49503 8.21697 6.69503 8.39097 6.81853 8.62547C6.85303 8.22147 7.02703 7.91947 7.27453 7.84297C7.48853 7.77697 7.85203 7.84547 8.36903 8.53647C8.46103 8.10397 8.87553 7.85947 9.23603 7.84847C9.24503 7.84847 9.25353 7.84797 9.26253 7.84797C9.59353 7.84797 9.97553 8.03947 10.105 8.57647C10.2425 8.40497 10.776 7.78497 11.1995 7.89547C11.422 7.95247 11.5735 8.20147 11.65 8.63647C11.8425 8.29597 12.093 8.17997 12.274 8.14347C12.5315 8.08997 12.8015 8.16597 13.015 8.34847L13.0355 8.36597L13.0165 8.38497C12.7505 8.65197 12.403 9.34247 12.0645 10.28L12.0585 10.2965H12.041C11.277 10.2965 6.51603 10.3195 6.46803 10.3195H6.45153ZM5.49453 8.36897C5.85203 8.68547 6.41603 10.0925 6.48553 10.2695C6.79903 10.268 11.2325 10.2465 12.024 10.2465C12.3555 9.33247 12.696 8.65247 12.963 8.36897C12.764 8.20947 12.516 8.14347 12.285 8.19197C12.1035 8.22847 11.8475 8.35097 11.6605 8.72447L11.625 8.79547L11.6135 8.71747C11.5445 8.25847 11.4015 7.99847 11.188 7.94347C11.1585 7.93597 11.1285 7.93297 11.0975 7.93297C10.6615 7.93297 10.118 8.64047 10.112 8.64847L10.0785 8.69247L10.068 8.63847C9.97403 8.17297 9.67553 7.89897 9.26703 7.89897C9.25703 7.89897 9.24803 7.89897 9.23803 7.89897C8.88203 7.90997 8.47003 8.15997 8.40903 8.60247L8.40003 8.66397L8.36403 8.61347C7.95903 8.05797 7.57653 7.80297 7.28953 7.89147C7.04303 7.96747 6.87553 8.29597 6.86253 8.72647L6.85903 8.84297L6.81503 8.73497C6.70403 8.46897 6.49503 8.26847 6.25603 8.19947C6.02153 8.13147 5.76303 8.18697 5.49453 8.36897Z" fill="#231F20"/>
<path d="M9.26698 10.6289C10.8062 10.6289 12.054 10.4661 12.054 10.2654C12.054 10.0646 10.8062 9.90186 9.26698 9.90186C7.72776 9.90186 6.47998 10.0646 6.47998 10.2654C6.47998 10.4661 7.72776 10.6289 9.26698 10.6289Z" fill="#231F20"/>
<path d="M9.26707 10.6544C7.90407 10.6544 6.45557 10.5184 6.45557 10.2659C6.45557 10.0134 7.90407 9.87793 9.26707 9.87793C10.6301 9.87793 12.0786 10.0134 12.0786 10.2659C12.0786 10.5184 10.6301 10.6544 9.26707 10.6544ZM9.26707 9.92693C7.63957 9.92693 6.50557 10.1049 6.50557 10.2654C6.50557 10.4259 7.64007 10.6044 9.26707 10.6044C10.8941 10.6044 12.0286 10.4259 12.0286 10.2654C12.0286 10.1049 10.8946 9.92693 9.26707 9.92693Z" fill="#231F20"/>
<path d="M6.23603 8.76807C6.23603 8.76807 6.34903 8.95057 6.38353 9.09807C6.28353 9.10657 6.09253 9.03307 6.09253 9.03307C6.09253 9.03307 6.61803 9.39807 6.75303 9.66757C6.64853 9.31557 6.71403 8.85507 6.71403 8.85507L6.59253 9.05507C6.59253 9.05507 6.32303 8.77707 6.23603 8.76807Z" fill="#231F20"/>
<path d="M7.49604 8.41187L7.58704 8.82887L7.32654 8.75937C7.32654 8.75937 7.72604 9.12887 7.83054 9.47637C7.80004 9.02887 7.88254 8.62037 7.88254 8.62037L7.72204 8.75487L7.49604 8.41187Z" fill="#231F20"/>
<path d="M9.251 8.32495C9.251 8.32495 9.216 8.60745 9.112 8.64195C9.0075 8.67695 8.8385 8.52045 8.8385 8.52045C8.8385 8.52045 9.1945 9.07645 9.247 9.50645C9.299 9.07645 9.664 8.52045 9.664 8.52045C9.664 8.52045 9.4945 8.68545 9.3775 8.63795C9.2595 8.58995 9.251 8.32495 9.251 8.32495Z" fill="#231F20"/>
<path d="M10.9975 8.37695C10.9975 8.37695 10.85 8.72895 10.776 8.74195C10.702 8.75495 10.6195 8.62445 10.6195 8.62445C10.6195 8.62445 10.7195 9.19795 10.6585 9.47595C10.8325 9.04595 11.193 8.75045 11.193 8.75045C11.193 8.75045 11.015 8.84145 10.963 8.81545C10.9105 8.78995 10.9975 8.37695 10.9975 8.37695Z" fill="#231F20"/>
<path d="M12.2701 8.75098C12.2701 8.75098 11.9921 9.06798 11.9311 9.06398C11.8701 9.05998 11.7966 8.86398 11.7966 8.86398C11.7966 8.86398 11.8316 9.42898 11.7576 9.65448C11.9791 9.29848 12.4006 9.03348 12.4006 9.03348C12.4006 9.03348 12.1836 9.15948 12.1271 9.11598C12.0706 9.07248 12.2701 8.75098 12.2701 8.75098Z" fill="#231F20"/>
  </svg>
</template>

<script>
export default {
  name: 'LI',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  