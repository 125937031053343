
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="white"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#1976D2"/>
<path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#FF3D00"/>
<path d="M7.5425 15.5541C6.3305 14.9886 4.528 14.3716 4.5005 12.0906C4.473 9.80955 4.4585 8.59105 4.4585 8.59105L10.458 8.51855C10.458 8.51855 10.473 9.76055 10.5 12.0181C10.5275 14.2991 8.7405 14.9596 7.5425 15.5541Z" fill="#FF3D00"/>
<path d="M3.95239 8.09695C3.95239 8.09695 3.97089 9.61045 4.00089 12.0965C4.03089 14.5845 6.12539 15.4385 7.54889 16.054C8.95689 15.404 11.0299 14.5 10.9999 12.012C10.9699 9.52595 10.9514 8.01245 10.9514 8.01245L3.95239 8.09695ZM10.4999 12.018C10.5274 14.299 8.74039 14.9595 7.54239 15.554C6.33039 14.9885 4.52789 14.3715 4.50039 12.0905C4.47289 9.80945 4.45839 8.59095 4.45839 8.59095L10.4579 8.51845C10.4579 8.51845 10.4729 9.76045 10.4999 12.018Z" fill="white"/>
<path d="M7.78661 11.7951C8.15211 11.7966 8.86311 11.8016 9.49511 11.5831C9.49511 11.5831 9.48211 11.8096 9.48561 12.0736C9.48911 12.3376 9.50711 12.5636 9.50711 12.5636C8.92261 12.3771 8.20411 12.3821 7.79411 12.3911L7.81111 13.8011L7.23511 13.8081L7.21811 12.4001C6.80611 12.3991 6.08711 12.4116 5.50761 12.6141C5.50761 12.6141 5.52061 12.3876 5.51711 12.1216C5.51411 11.8581 5.49561 11.6316 5.49561 11.6316C6.13311 11.8366 6.84561 11.8146 7.21061 11.8021L7.20011 10.9176C6.86611 10.9196 6.52961 10.8771 5.98911 11.0651C5.98911 11.0651 5.98661 10.8386 5.98311 10.5751C5.98011 10.3096 5.97711 10.0831 5.97711 10.0831C6.52011 10.2581 6.85911 10.3321 7.19111 10.3261C7.16661 9.76407 6.99411 9.06057 6.99411 9.06057C6.99411 9.06057 7.33211 9.08207 7.46511 9.08007C7.59811 9.07857 7.93561 9.04907 7.93561 9.04907C7.93561 9.04907 7.78011 9.75607 7.76911 10.3166C8.10311 10.3146 8.43811 10.2401 8.97711 10.0541C8.97711 10.0541 8.96411 10.2806 8.96761 10.5446C8.97061 10.8101 8.98911 11.0366 8.98911 11.0366C8.44611 10.8616 8.10861 10.9046 7.77661 10.9106L7.78661 11.7951Z" fill="white"/>
<path d="M8.43642 13.8675C8.43642 13.8675 8.10792 13.047 7.51242 13.0545C6.91892 13.0615 6.60842 13.8895 6.60842 13.8895C6.60842 13.8895 6.42792 13.501 5.94142 13.507C5.64242 13.5105 5.33192 13.823 5.18042 14.071C5.82242 14.8445 6.82742 15.221 7.54242 15.5545C8.24942 15.204 9.21542 14.839 9.83892 14.05C9.68142 13.806 9.39242 13.4655 9.09342 13.469C8.60942 13.4745 8.43642 13.8675 8.43642 13.8675Z" fill="#1976D2"/>
  </svg>
</template>

<script>
export default {
  name: 'SK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  