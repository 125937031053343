
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="white"/>
<path d="M2.328 14.5H9.5V21.672C10.3005 21.8785 11.135 22 12 22C12.865 22 13.6995 21.8785 14.5 21.672V14.5H21.672C21.8785 13.6995 22 12.865 22 12C22 11.135 21.8785 10.3005 21.672 9.5H14.5V2.328C13.6995 2.1215 12.865 2 12 2C11.135 2 10.3005 2.1215 9.5 2.328V9.5H2.328C2.1215 10.3005 2 11.135 2 12C2 12.865 2.1215 13.6995 2.328 14.5Z" fill="#E53935"/>
<path d="M11.1955 5.74988V18.2499L10.525 18.8179H13.481L12.8055 18.2499V5.74988L13.481 5.18188H10.525L11.1955 5.74988Z" fill="#FDD835"/>
<path d="M5.75306 12.8075H18.2531L18.8211 13.478V10.522L18.2531 11.1975H5.75306L5.18506 10.522V13.478L5.75306 12.8075Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'GG',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  