
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M8.00001 2.8374C4.90951 4.1889 2.64401 7.0564 2.12451 10.4999H8.00001V2.8374Z" fill="#FF3D00"/>
<path d="M11 21.9495C11.329 21.982 11.6625 22 12 22C17.0115 22 21.15 18.3095 21.8755 13.5H11V21.9495Z" fill="#FF3D00"/>
<path d="M2.12451 13.5C2.64401 16.9435 4.90951 19.811 8.00001 21.1625V13.5H2.12451Z" fill="#FF3D00"/>
<path d="M12 2C11.6625 2 11.329 2.018 11 2.0505V10.5H21.8755C21.15 5.6905 17.0115 2 12 2Z" fill="#FF3D00"/>
<path d="M11 2.05054C9.942 2.15554 8.935 2.42904 8 2.83754V10.5H2.1245C2.0505 10.9905 2 11.489 2 12C2 12.511 2.0505 13.0095 2.1245 13.5H8V21.1625C8.935 21.5715 9.942 21.8445 11 21.9495V13.5H21.8755C21.9495 13.0095 22 12.511 22 12C22 11.489 21.9495 10.9905 21.8755 10.5H11V2.05054Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'DK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  