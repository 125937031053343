
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12H12H2C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12Z" fill="#FF3D00"/>
<path d="M22 12C22 6.477 17.523 2 12 2V12H22Z" fill="white"/>
<path d="M12 2C6.477 2 2 6.477 2 12H12V2Z" fill="#3F51B5"/>
<path d="M7.49499 5.10742L8.08599 6.99992H9.99999L8.45199 8.07442L9.04299 9.90792L7.49499 8.77442L5.94699 9.90792L6.53799 8.07442L4.98999 6.99992H6.90399L7.49499 5.10742Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'CL',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  