
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.9439 13C21.9764 12.671 21.9944 12.3375 21.9944 12C21.9944 6.477 17.5174 2 11.9944 2C6.47139 2 1.99438 6.477 1.99438 12C1.99438 12.3375 2.01238 12.671 2.04488 13H21.9439Z" fill="#1E88E5"/>
<path d="M14.75 4L14.937 6.8305L15.85 4.145L15.298 6.9275L16.875 4.5695L15.6215 7.114L17.755 5.245L15.886 7.3785L18.4305 6.125L16.0725 7.702L18.855 7.15L16.1695 8.063L19 8.25L16.1695 8.437L18.855 9.35L16.0725 8.798L18.4305 10.375L15.886 9.1215L17.755 11.255L15.6215 9.386L16.875 11.9305L15.298 9.5725L15.85 12.355L14.937 9.6695L14.75 12.5L14.563 9.6695L13.65 12.355L14.202 9.5725L12.625 11.9305L13.8785 9.386L11.745 11.255L13.614 9.1215L11.0695 10.375L13.4275 8.798L10.645 9.35L13.3305 8.437L10.5 8.25L13.3305 8.063L10.645 7.15L13.4275 7.702L11.0695 6.125L13.614 7.3785L11.745 5.245L13.8785 7.114L12.625 4.5695L14.202 6.9275L13.65 4.145L14.563 6.8305L14.75 4Z" fill="#FFD600"/>
<path d="M14.7499 9.55738C15.472 9.55738 16.0574 8.97199 16.0574 8.24988C16.0574 7.52777 15.472 6.94238 14.7499 6.94238C14.0278 6.94238 13.4424 7.52777 13.4424 8.24988C13.4424 8.97199 14.0278 9.55738 14.7499 9.55738Z" fill="#FFD600"/>
<path d="M14.7501 9.60758C14.0016 9.60758 13.3926 8.99858 13.3926 8.25008C13.3926 7.50158 14.0016 6.89258 14.7501 6.89258C15.4986 6.89258 16.1076 7.50158 16.1076 8.25008C16.1076 8.99858 15.4986 9.60758 14.7501 9.60758ZM14.7501 6.99208C14.0566 6.99208 13.4921 7.55658 13.4921 8.25008C13.4921 8.94358 14.0566 9.50808 14.7501 9.50808C15.4436 9.50808 16.0081 8.94358 16.0081 8.25008C16.0081 7.55658 15.4436 6.99208 14.7501 6.99208Z" fill="#1E88E5"/>
<path d="M3.64692 17.5H20.3419C21.2109 16.1835 21.7799 14.652 21.9439 13H2.04492C2.20942 14.652 2.77792 16.1835 3.64692 17.5Z" fill="#FFD600"/>
<path d="M11.9945 22C15.4845 22 18.553 20.2095 20.342 17.5H3.64697C5.43597 20.2095 8.50497 22 11.9945 22Z" fill="#2E7D32"/>
  </svg>
</template>

<script>
export default {
  name: 'RW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  