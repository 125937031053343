
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#64B5F6"/>
<path d="M12.6094 7.12439L13.5849 6.39239H12.3654L11.9994 5.23389L11.6334 6.39239H10.4139L11.3894 7.12439L11.0239 8.28289L11.9994 7.58139L12.9749 8.28289L12.6094 7.12439Z" fill="white"/>
<path d="M7.58597 11.9675L8.28047 10.965L7.12347 11.35L6.41047 10.3665L6.42897 11.581L5.27197 11.966L6.42847 12.3525L6.44697 13.5675L7.15147 12.594L8.29847 12.9515L7.58597 11.9675Z" fill="white"/>
<path d="M16.414 11.9675L15.7195 10.965L16.8765 11.35L17.5895 10.3665L17.571 11.581L18.728 11.966L17.5715 12.3525L17.553 13.5675L16.8485 12.594L15.7015 12.9515L16.414 11.9675Z" fill="white"/>
<path d="M12.6094 16.8755L13.5849 17.6075H12.3654L11.9994 18.766L11.6334 17.6075H10.4139L11.3894 16.8755L11.0239 15.717L11.9994 16.4185L12.9749 15.717L12.6094 16.8755Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'FM',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  