
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M13 6.5H20.3475C19.71 5.5345 18.906 4.6925 17.982 4H13V6.5Z" fill="#F5F5F5"/>
<path d="M13 11H21.9495C21.863 10.127 21.6545 9.292 21.358 8.5H13V11Z" fill="#F5F5F5"/>
<path d="M21.9495 13H13H12.5H8.50004H6.50004H2.05054C2.13704 13.873 2.34554 14.708 2.64204 15.5H21.3575C21.6545 14.708 21.863 13.873 21.9495 13Z" fill="#F5F5F5"/>
<path d="M20.3475 17.5H3.65247C4.28997 18.4655 5.09397 19.3075 6.01797 20H17.9815C18.906 19.3075 19.71 18.4655 20.3475 17.5Z" fill="#F5F5F5"/>
<path d="M17.982 4.00004C16.5635 2.93754 14.86 2.23504 13 2.05054V4.00004H17.982Z" fill="#3F51B5"/>
<path d="M13 8.5H21.358C21.0935 7.7925 20.7575 7.121 20.3475 6.5H13V8.5Z" fill="#3F51B5"/>
<path d="M13 13H21.9495C21.982 12.671 22 12.3375 22 12C22 11.6625 21.982 11.329 21.9495 11H13V13Z" fill="#3F51B5"/>
<path d="M21.358 15.5H2.64197C2.90647 16.2075 3.24247 16.879 3.65247 17.5H20.3475C20.7575 16.879 21.093 16.2075 21.358 15.5Z" fill="#3F51B5"/>
<path d="M6.5 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 12.3375 2.018 12.671 2.0505 13H6.5V8.5Z" fill="#3F51B5"/>
<path d="M6.49997 3.65259C5.36897 4.39959 4.39947 5.36909 3.65247 6.50009H6.49997V3.65259Z" fill="#3F51B5"/>
<path d="M13 8.5H12.5H8.5V13H12.5H13V11V8.5Z" fill="#3F51B5"/>
<path d="M12.5 6.5H13V4V2.0505C12.671 2.018 12.3375 2 12 2C10.7665 2 9.591 2.234 8.5 2.642V6.5H12.5Z" fill="#3F51B5"/>
<path d="M17.9819 20H6.01794C7.68744 21.2505 9.75344 22 11.9999 22C14.2464 22 16.3124 21.2505 17.9819 20Z" fill="#3F51B5"/>
<path d="M6.49997 13.0001H8.49997V8.50009H12.5H13V6.50009H12.5H8.49997V2.64209C7.79247 2.90659 7.12097 3.24259 6.49997 3.65259V6.50009H3.65247C3.24247 7.12109 2.90697 7.79259 2.64197 8.50009H6.49997V13.0001Z" fill="#F5F5F5"/>
  </svg>
</template>

<script>
export default {
  name: 'GR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  