
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="white"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#1976D2"/>
<path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#FF3D00"/>
<path d="M5 7V8C5 11.5 7 12 7 12C7 12 9 11.5 9 8V7H5Z" fill="#1976D2"/>
<path d="M9 7V8C9 11.5 7 12 7 12C7 12 5 11.5 5 8V7H4.5V8C4.5 11.8455 6.781 12.461 6.879 12.4865L7 12.5155L7.121 12.484C7.2185 12.461 9.5 11.8455 9.5 8V7H9Z" fill="#FF3D00"/>
<path d="M8.00001 9L7.50001 9.5L7.00001 8.5L6.50001 9.5L6.00001 9L5.21851 9.7815C5.73451 11.6775 7.00001 12 7.00001 12C7.00001 12 8.26551 11.6775 8.78101 9.781L8.00001 9Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'SI',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  