
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M3 12.0025C3.0005 15.029 3.346 17.741 5.4705 19.5745C7.2225 21.0855 9.504 22 11.9995 22H12.0005C17.523 21.9995 22 17.5225 22 12L3 12.0025Z" fill="#388E3C"/>
<path d="M22 12C22 6.477 17.523 2 12 2C9.498 2 7.211 2.9185 5.4575 4.437C3.3405 6.2705 3.001 8.9775 3 11.9975L2 12H22Z" fill="#D50000"/>
<path d="M5.4975 19.5881L13.6 14.0001H21.799C21.9305 13.3536 22 12.6851 22 12.0001C22 11.3151 21.9305 10.6466 21.799 10.0001H13.6L5.4975 4.41211C3.36 6.24561 2.001 8.96061 2 11.9971V12.0031C2.001 15.0396 3.36 17.7546 5.4975 19.5881Z" fill="#263238"/>
<path d="M6.68451 12.0155C6.68451 12.0155 6.43001 12.9505 5.42501 12.9505C4.42001 12.9505 4.15201 12.056 4.15201 11.6765C4.15201 11.1635 4.59401 10.321 5.49201 10.321C6.39001 10.321 7.01951 11.2155 7.01951 11.934C7.01951 12.6525 6.49701 13.669 5.43851 13.669C5.43851 14.211 5.43851 14.604 5.43851 14.604C5.43851 14.604 7.79651 14.4415 7.79651 11.9745C7.79651 9.72454 5.76001 9.60254 5.51851 9.60254C5.21651 9.60254 3.56201 9.79204 3.56201 11.6085C3.56201 13.289 5.15651 13.3975 5.41101 13.3975C5.59951 13.3975 6.68451 13.262 6.68451 12.0155Z" fill="#FFC107"/>
<path d="M5.11247 10.9715C5.11247 10.9715 6.00597 11.577 6.26497 11.9835C6.25597 12.1825 6.24697 12.318 6.24697 12.318L5.95197 12.2455C5.95197 12.2455 5.44297 11.396 5.00497 11.152C4.56747 10.9085 4.92497 10.8725 5.11247 10.9715Z" fill="#FFC107"/>
<path d="M5.93998 10.9716C5.93998 10.9716 5.04648 11.5771 4.78748 11.9836C4.79648 12.1826 4.80548 12.3181 4.80548 12.3181L5.10048 12.2456C5.10048 12.2456 5.60948 11.3961 6.04748 11.1521C6.48548 10.9081 6.12748 10.8726 5.93998 10.9716Z" fill="#FFC107"/>
<path d="M4.67506 18.7915L13.5561 12.6665H21.9661C21.9811 12.4455 22.0001 12.225 22.0001 12C22.0001 11.775 21.9811 11.5545 21.9666 11.3335H13.5566L4.67506 5.2085C4.37806 5.529 4.10056 5.8665 3.84706 6.2235L12.2231 12L3.84656 17.777C4.10006 18.134 4.37806 18.4715 4.67506 18.7915Z" fill="#FFC107"/>
  </svg>
</template>

<script>
export default {
  name: 'VU',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  