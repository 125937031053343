
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C17.523 2 22 6.477 22 12C22 13.8395 21.5035 15.5625 20.637 17.043C18.902 20.008 15.577 21.553 11.8935 21.553C8.2235 21.553 5.1215 20.0225 3.382 17.0755C2.504 15.588 2 13.853 2 12C2 6.477 6.477 2 12 2Z" fill="white"/>
<path d="M12 22.0001C16.253 22.0001 19.8805 19.3431 21.326 15.6006H2.67395C4.11945 19.3431 7.74695 22.0001 12 22.0001Z" fill="#D50000"/>
<path d="M13.785 14.978H17.375V14.533H13.785V14.978Z" fill="#D50000"/>
<path d="M17.375 15.0269H13.785C13.757 15.0269 13.735 15.0049 13.735 14.9769V14.5319C13.735 14.5039 13.757 14.4819 13.785 14.4819H17.375C17.403 14.4819 17.425 14.5039 17.425 14.5319V14.9769C17.425 15.0049 17.403 15.0269 17.375 15.0269ZM13.835 14.9269H17.325V14.5819H13.835V14.9269Z" fill="#263238"/>
<path d="M14.18 9.53809H16.0295V6.36359H14.18V9.53809Z" fill="#D50000"/>
<path d="M16.0305 9.58799H14.181C14.153 9.58799 14.131 9.56599 14.131 9.53799V6.36299C14.131 6.33499 14.153 6.31299 14.181 6.31299H16.0305C16.0585 6.31299 16.0805 6.33499 16.0805 6.36299V9.53799C16.08 9.56599 16.058 9.58799 16.0305 9.58799ZM14.2305 9.48849H15.9805V6.41349H14.2305V9.48849Z" fill="#263238"/>
<path d="M14.5765 9.53802H15.6345V8.14502C15.6345 7.90952 15.4436 7.71802 15.2076 7.71802H15.003C14.7675 7.71802 14.576 7.90902 14.576 8.14502L14.5765 9.53802Z" fill="#263238"/>
<path d="M14.0415 6.36304H16.168V6.00704H14.0415V6.36304Z" fill="#D50000"/>
<path d="M16.1684 6.41303H14.0419C14.0139 6.41303 13.9919 6.39103 13.9919 6.36303V6.00703C13.9919 5.97903 14.0139 5.95703 14.0419 5.95703H16.1684C16.1964 5.95703 16.2184 5.97903 16.2184 6.00703V6.36303C16.2184 6.39103 16.1964 6.41303 16.1684 6.41303ZM14.0919 6.31353H16.1189V6.05703H14.0919V6.31353Z" fill="#263238"/>
<path d="M14.2111 10.052H17.0396V9.696H14.2111V10.052Z" fill="#D50000"/>
<path d="M17.039 10.102H14.2105C14.1825 10.102 14.1605 10.08 14.1605 10.052V9.696C14.1605 9.668 14.1825 9.646 14.2105 9.646H17.039C17.067 9.646 17.089 9.668 17.089 9.696V10.052C17.089 10.08 17.067 10.102 17.039 10.102ZM14.2605 10.0025H16.9895V9.746H14.2605V10.0025Z" fill="#263238"/>
<path d="M15.6345 9.36956V8.92456H16.4355V9.36956H17.019V8.92456H17.375V9.36956V9.50806V9.72556H13.884V9.36956H15.6345Z" fill="#D50000"/>
<path d="M17.375 9.77551H13.884C13.856 9.77551 13.834 9.75351 13.834 9.72551V9.36951C13.834 9.34151 13.856 9.31951 13.884 9.31951H15.5845V8.92451C15.5845 8.89651 15.6065 8.87451 15.6345 8.87451H16.436C16.464 8.87451 16.486 8.89651 16.486 8.92451V9.31951H16.97V8.92451C16.97 8.89651 16.992 8.87451 17.02 8.87451H17.376C17.404 8.87451 17.426 8.89651 17.426 8.92451V9.72551C17.425 9.75351 17.403 9.77551 17.375 9.77551ZM13.9335 9.67601H17.325V8.97501H17.0685V9.37001C17.0685 9.39801 17.0465 9.42001 17.0185 9.42001H16.435C16.407 9.42001 16.385 9.39801 16.385 9.37001V8.97501H15.6835V9.37001C15.6835 9.39801 15.6615 9.42001 15.6335 9.42001H13.933V9.67601H13.9335Z" fill="#263238"/>
<path d="M13.7849 14.1169H16.7419V10.0424H13.7849V14.1169Z" fill="#D50000"/>
<path d="M16.742 14.1664H13.785C13.757 14.1664 13.735 14.1444 13.735 14.1164V10.0419C13.735 10.0139 13.757 9.99194 13.785 9.99194H16.742C16.77 9.99194 16.792 10.0139 16.792 10.0419V14.1164C16.792 14.1444 16.77 14.1664 16.742 14.1664ZM13.835 14.0669H16.6925V10.0919H13.835V14.0669Z" fill="#263238"/>
<path d="M13.785 14.5319H17.375L16.742 14.1069H13.785V14.5319Z" fill="#D50000"/>
<path d="M17.375 14.5819H13.785C13.757 14.5819 13.735 14.5599 13.735 14.5319V14.1064C13.735 14.0784 13.757 14.0564 13.785 14.0564H16.742C16.752 14.0564 16.7615 14.0594 16.77 14.0649L17.403 14.4904C17.4215 14.5024 17.4295 14.5254 17.423 14.5464C17.4165 14.5674 17.397 14.5819 17.375 14.5819ZM13.835 14.4824H17.211L16.727 14.1569H13.835V14.4824Z" fill="#263238"/>
<path d="M14.2995 5.34448V5.68098H14.774V5.34448H15.506V5.68098H15.9705V5.36448H16.4455V5.68098V5.77948V6.00698H15.506H14.774H14.2995H13.9135V5.68098V5.34448H14.2995Z" fill="#D50000"/>
<path d="M16.4455 6.05693H13.914C13.886 6.05693 13.864 6.03493 13.864 6.00693V5.34443C13.864 5.31643 13.886 5.29443 13.914 5.29443H14.2995C14.3275 5.29443 14.3495 5.31643 14.3495 5.34443V5.63043H14.7245V5.34443C14.7245 5.31643 14.7465 5.29443 14.7745 5.29443H15.5065C15.5345 5.29443 15.5565 5.31643 15.5565 5.34443V5.63043H15.9215V5.36393C15.9215 5.33593 15.9435 5.31393 15.9715 5.31393H16.446C16.474 5.31393 16.496 5.33593 16.496 5.36393V6.00693C16.495 6.03493 16.473 6.05693 16.4455 6.05693ZM13.9635 5.95743H16.3955V5.41393H16.0205V5.68043C16.0205 5.70843 15.9985 5.73043 15.9705 5.73043H15.5055C15.4775 5.73043 15.4555 5.70843 15.4555 5.68043V5.39443H14.823V5.68043C14.823 5.70843 14.801 5.73043 14.773 5.73043H14.2985C14.2705 5.73043 14.2485 5.70843 14.2485 5.68043V5.39443H13.9625V5.95743H13.9635Z" fill="#263238"/>
<path d="M14.9535 12.2476H15.2575C15.608 12.2476 15.892 12.5316 15.892 12.8821V14.1071H14.3195V12.8821C14.319 12.5316 14.603 12.2476 14.9535 12.2476Z" fill="#263238"/>
<path d="M10.215 14.532H6.625V14.977H10.215V14.532Z" fill="#D50000"/>
<path d="M10.215 15.0269H6.62495C6.59695 15.0269 6.57495 15.0049 6.57495 14.9769V14.5319C6.57495 14.5039 6.59695 14.4819 6.62495 14.4819H10.215C10.243 14.4819 10.265 14.5039 10.265 14.5319V14.9769C10.2645 15.0049 10.2425 15.0269 10.215 15.0269ZM6.67495 14.9269H10.165V14.5819H6.67495V14.9269Z" fill="#263238"/>
<path d="M12.168 16.9551H11.7825V21.2721H12.168V16.9551Z" fill="#FBC02D"/>
<path d="M12.218 21.322H11.7325V16.9055H12.218V21.322ZM11.832 21.222H12.118V17.005H11.832V21.222Z" fill="#263238"/>
<path d="M10.5291 20.1074V19.5139H11.7826V20.1074H11.4041V19.8109H10.9001V20.1074C10.9001 20.1074 10.5291 20.0924 10.5291 20.1074Z" fill="#FBC02D"/>
<path d="M10.9495 20.1596L10.8975 20.1571C10.744 20.1506 10.5705 20.1481 10.5445 20.1536L10.535 20.1071H10.4785V19.4641H11.8315V20.1571H11.354V19.8606H10.949L10.9495 20.1596ZM11.454 20.0576H11.7325V19.5641H10.5785V20.0516C10.6295 20.0501 10.71 20.0506 10.85 20.0561V19.7616H11.454V20.0576Z" fill="#263238"/>
<path d="M11.7826 20.3301V20.9236H10.5291V20.3301H10.9076V20.6266H11.4121V20.3301C11.4121 20.3301 11.7826 20.3451 11.7826 20.3301Z" fill="#FBC02D"/>
<path d="M11.832 20.9731H10.479V20.2801H10.957V20.5771H11.362V20.2776L11.414 20.2801C11.5665 20.2866 11.7405 20.2891 11.7665 20.2836L11.776 20.3301H11.8325V20.9731H11.832ZM10.5785 20.8736H11.7325V20.3861C11.6815 20.3876 11.601 20.3876 11.4615 20.3816V20.6766H10.8575V20.3801H10.5785V20.8736Z" fill="#263238"/>
<path d="M13.0065 16.2799L11.9755 15.7014L10.974 16.2799H10.744V16.6064H10.9295L11.953 17.2219L12.999 16.6139H13.2065V16.2799H13.0065ZM11.968 16.8884L11.1965 16.4584L11.9755 15.9839L12.7545 16.4514L11.968 16.8884Z" fill="#FBC02D"/>
<path d="M11.9525 17.2805L10.9155 16.6565H10.6945V16.2305H10.961L11.9745 15.644L11.9995 15.658L13.0195 16.2305H13.2565V16.6635H13.013L11.9525 17.2805ZM10.794 16.5565H10.943L10.955 16.5635L11.9535 17.164L12.985 16.564H13.1565V16.33H12.993L11.9755 15.759L10.987 16.33H10.7935L10.794 16.5565ZM11.968 16.9455L11.0975 16.46L11.9755 15.925L12.8545 16.4525L11.968 16.9455ZM11.2955 16.456L11.968 16.831L12.654 16.449L11.9755 16.042L11.2955 16.456Z" fill="#263238"/>
<path d="M14.735 14.7004H9.28552V15.2149H14.735V14.7004Z" fill="#D50000"/>
<path d="M14.7345 15.2639H9.28499C9.25699 15.2639 9.23499 15.2419 9.23499 15.2139V14.6999C9.23499 14.6719 9.25699 14.6499 9.28499 14.6499H14.734C14.762 14.6499 14.784 14.6719 14.784 14.6999V15.2139C14.784 15.2419 14.762 15.2639 14.7345 15.2639ZM9.33499 15.1644H14.6845V14.7499H9.33499V15.1644Z" fill="#263238"/>
<path d="M14.438 10.042H9.58203V10.3785H14.438V10.042Z" fill="#D50000"/>
<path d="M14.438 10.428H9.58198C9.55398 10.428 9.53198 10.406 9.53198 10.378V10.0415C9.53198 10.0135 9.55398 9.99146 9.58198 9.99146H14.438C14.466 9.99146 14.488 10.0135 14.488 10.0415V10.378C14.488 10.406 14.466 10.428 14.438 10.428ZM9.63198 10.3285H14.3885V10.0915H9.63198V10.3285Z" fill="#263238"/>
<path d="M13.2905 5.72046H10.729V9.38946H13.2905V5.72046Z" fill="#D50000"/>
<path d="M13.2905 9.43942H10.729C10.701 9.43942 10.679 9.41742 10.679 9.38942V5.71992C10.679 5.69192 10.701 5.66992 10.729 5.66992H13.2905C13.3185 5.66992 13.3405 5.69192 13.3405 5.71992V9.38942C13.3405 9.41742 13.3185 9.43942 13.2905 9.43942ZM10.779 9.33992H13.241V5.76992H10.779V9.33992Z" fill="#263238"/>
<path d="M9.81947 6.36304H7.96997V9.53754H9.81947V6.36304Z" fill="#D50000"/>
<path d="M9.81954 9.58799H7.97004C7.94204 9.58799 7.92004 9.56599 7.92004 9.53799V6.36299C7.92004 6.33499 7.94204 6.31299 7.97004 6.31299H9.81954C9.84754 6.31299 9.86954 6.33499 9.86954 6.36299V9.53799C9.86904 9.56599 9.84704 9.58799 9.81954 9.58799ZM8.01954 9.48849H9.76954V6.41349H8.01954V9.48849Z" fill="#263238"/>
<path d="M9.42348 9.53802H8.36548V8.14502C8.36548 7.90952 8.55648 7.71802 8.79248 7.71802H8.99698C9.23248 7.71802 9.42398 7.90902 9.42398 8.14502L9.42348 9.53802Z" fill="#263238"/>
<path d="M9.95804 6.00708H7.83154V6.36308H9.95804V6.00708Z" fill="#D50000"/>
<path d="M9.95799 6.41303H7.83149C7.80349 6.41303 7.78149 6.39103 7.78149 6.36303V6.00703C7.78149 5.97903 7.80349 5.95703 7.83149 5.95703H9.95799C9.98599 5.95703 10.008 5.97903 10.008 6.00703V6.36303C10.008 6.39103 9.98599 6.41303 9.95799 6.41303ZM7.88149 6.31353H9.90849V6.05703H7.88149V6.31353Z" fill="#263238"/>
<path d="M9.78956 9.69604H6.96106V10.052H9.78956V9.69604Z" fill="#D50000"/>
<path d="M9.78951 10.102H6.96101C6.93301 10.102 6.91101 10.08 6.91101 10.052V9.696C6.91101 9.668 6.93301 9.646 6.96101 9.646H9.78951C9.81751 9.646 9.83951 9.668 9.83951 9.696V10.052C9.83951 10.08 9.81751 10.102 9.78951 10.102ZM7.01051 10.0025H9.73951V9.746H7.01051V10.0025Z" fill="#263238"/>
<path d="M8.3655 9.36956V8.92456H7.5645V9.36956H6.981V8.92456H6.625V9.36956V9.50806V9.72556H10.116V9.36956H8.3655Z" fill="#D50000"/>
<path d="M10.116 9.7755H6.62495C6.59695 9.7755 6.57495 9.7535 6.57495 9.7255V8.925C6.57495 8.897 6.59695 8.875 6.62495 8.875H6.98095C7.00895 8.875 7.03095 8.897 7.03095 8.925V9.32H7.51495V8.925C7.51495 8.897 7.53695 8.875 7.56495 8.875H8.36645C8.39445 8.875 8.41645 8.897 8.41645 8.925V9.32H10.117C10.145 9.32 10.167 9.342 10.167 9.37V9.726C10.166 9.7535 10.144 9.7755 10.116 9.7755ZM6.67495 9.676H10.0665V9.4195H8.36595C8.33795 9.4195 8.31595 9.3975 8.31595 9.3695V8.9745H7.61445V9.3695C7.61445 9.3975 7.59245 9.4195 7.56445 9.4195H6.98095C6.95295 9.4195 6.93095 9.3975 6.93095 9.3695V8.9745H6.67495V9.676Z" fill="#263238"/>
<path d="M10.2151 10.042H7.25806V14.1165H10.2151V10.042Z" fill="#D50000"/>
<path d="M10.215 14.1664H7.25801C7.23001 14.1664 7.20801 14.1444 7.20801 14.1164V10.0419C7.20801 10.0139 7.23001 9.99194 7.25801 9.99194H10.215C10.243 9.99194 10.265 10.0139 10.265 10.0419V14.1164C10.2645 14.1444 10.2425 14.1664 10.215 14.1664ZM7.30751 14.0669H10.165V10.0919H7.30751V14.0669Z" fill="#263238"/>
<path d="M10.215 14.5319H6.625L7.258 14.1069H10.215V14.5319Z" fill="#D50000"/>
<path d="M10.2149 14.5819H6.62494C6.60294 14.5819 6.58344 14.5679 6.57694 14.5469C6.57044 14.5259 6.57844 14.5029 6.59694 14.4909L7.22994 14.0654C7.23844 14.0599 7.24794 14.0569 7.25794 14.0569H10.2149C10.2429 14.0569 10.2649 14.0789 10.2649 14.1069V14.5324C10.2644 14.5599 10.2424 14.5819 10.2149 14.5819ZM6.78894 14.4824H10.1649V14.1569H7.27294L6.78894 14.4824Z" fill="#263238"/>
<path d="M9.70056 5.34448V5.68098H9.22607V5.34448H8.49407V5.68098H8.02957V5.36448H7.55457V5.68098V5.77948V6.00698H8.49407H9.22607H9.70056H10.0866V5.68098V5.34448H9.70056Z" fill="#D50000"/>
<path d="M10.0865 6.05693H7.555C7.527 6.05693 7.505 6.03493 7.505 6.00693V5.36393C7.505 5.33593 7.527 5.31393 7.555 5.31393H8.0295C8.0575 5.31393 8.0795 5.33593 8.0795 5.36393V5.63043H8.4445V5.34443C8.4445 5.31643 8.4665 5.29443 8.4945 5.29443H9.2265C9.2545 5.29443 9.2765 5.31643 9.2765 5.34443V5.63043H9.6515V5.34443C9.6515 5.31643 9.6735 5.29443 9.7015 5.29443H10.087C10.115 5.29443 10.137 5.31643 10.137 5.34443V6.00693C10.136 6.03493 10.1145 6.05693 10.0865 6.05693ZM7.6045 5.95743H10.0365V5.39443H9.7505V5.68043C9.7505 5.70843 9.7285 5.73043 9.7005 5.73043H9.226C9.198 5.73043 9.176 5.70843 9.176 5.68043V5.39443H8.5435V5.68043C8.5435 5.70843 8.5215 5.73043 8.4935 5.73043H8.0285C8.0005 5.73043 7.9785 5.70843 7.9785 5.68043V5.41393H7.6035V5.95743H7.6045Z" fill="#263238"/>
<path d="M13.479 5.21606H10.5415V5.71056H13.479V5.21606Z" fill="#D50000"/>
<path d="M13.4785 5.76052H10.5415C10.5135 5.76052 10.4915 5.73852 10.4915 5.71052V5.21602C10.4915 5.18802 10.5135 5.16602 10.5415 5.16602H13.4785C13.5065 5.16602 13.5285 5.18802 13.5285 5.21602V5.71052C13.5285 5.73852 13.5065 5.76052 13.4785 5.76052ZM10.5915 5.66052H13.429V5.26552H10.5915V5.66052Z" fill="#263238"/>
<path d="M11.56 6.23462H11.2435V7.14462H11.56V6.23462Z" fill="#263238"/>
<path d="M12.7665 6.23462H12.45V7.14462H12.7665V6.23462Z" fill="#263238"/>
<path d="M12.1729 5.99756H11.8564V7.19406H12.1729V5.99756Z" fill="#263238"/>
<path d="M13.3205 4.67212V4.95912H12.8355V4.67212H12.262V4.95912H11.7575V4.67212H11.184V4.95912H10.6995V4.67212H10.2445V4.95912V5.10712V5.21612H13.775V5.10712V4.95912V4.67212H13.3205Z" fill="#D50000"/>
<path d="M13.7755 5.26557H10.2445C10.2165 5.26557 10.1945 5.24357 10.1945 5.21557V4.67207C10.1945 4.64407 10.2165 4.62207 10.2445 4.62207H10.6995C10.7275 4.62207 10.7495 4.64407 10.7495 4.67207V4.90957H11.1345V4.67207C11.1345 4.64407 11.1565 4.62207 11.1845 4.62207H11.758C11.786 4.62207 11.808 4.64407 11.808 4.67207V4.90957H12.213V4.67207C12.213 4.64407 12.235 4.62207 12.263 4.62207H12.8365C12.8645 4.62207 12.8865 4.64407 12.8865 4.67207V4.90957H13.2715V4.67207C13.2715 4.64407 13.2935 4.62207 13.3215 4.62207H13.7765C13.8045 4.62207 13.8265 4.64407 13.8265 4.67207V5.21607C13.825 5.24357 13.803 5.26557 13.7755 5.26557ZM10.2945 5.16607H13.7255V4.72157H13.37V4.95907C13.37 4.98707 13.348 5.00907 13.32 5.00907H12.8355C12.8075 5.00907 12.7855 4.98707 12.7855 4.95907V4.72157H12.3115V4.95907C12.3115 4.98707 12.2895 5.00907 12.2615 5.00907H11.757C11.729 5.00907 11.707 4.98707 11.707 4.95907V4.72157H11.233V4.95907C11.233 4.98707 11.211 5.00907 11.183 5.00907H10.6985C10.6705 5.00907 10.6485 4.98707 10.6485 4.95907V4.72157H10.293V5.16607H10.2945Z" fill="#263238"/>
<path d="M12.618 9.38941H11.4015V7.93191C11.4015 7.66091 11.621 7.44141 11.892 7.44141H12.127C12.398 7.44141 12.6175 7.66091 12.6175 7.93191L12.618 9.38941Z" fill="#263238"/>
<path d="M14.7345 14.7005H9.28552L10.027 14.3345H13.983L14.7345 14.7005Z" fill="#D50000"/>
<path d="M14.7345 14.7499H9.28499C9.26149 14.7499 9.24149 14.7344 9.23599 14.7114C9.23049 14.6889 9.24199 14.6654 9.26299 14.6554L10.0045 14.2899C10.0115 14.2864 10.0185 14.2849 10.0265 14.2849H13.9825C13.9905 14.2849 13.9975 14.2864 14.0045 14.2899L14.756 14.6554C14.777 14.6659 14.7875 14.6889 14.783 14.7114C14.778 14.7339 14.758 14.7499 14.7345 14.7499ZM9.49949 14.6504H14.5175L13.971 14.3844H10.0385L9.49949 14.6504Z" fill="#263238"/>
<path d="M14.0026 10.3787H10.0171V14.3347H14.0026V10.3787Z" fill="#D50000"/>
<path d="M14.0025 14.3846H10.017C9.98904 14.3846 9.96704 14.3626 9.96704 14.3346V10.3786C9.96704 10.3506 9.98904 10.3286 10.017 10.3286H14.0025C14.0305 10.3286 14.0525 10.3506 14.0525 10.3786V14.3346C14.052 14.3626 14.0305 14.3846 14.0025 14.3846ZM10.067 14.2846H13.9525V10.4281H10.067V14.2846Z" fill="#263238"/>
<path d="M9.04653 12.2476H8.74253C8.39203 12.2476 8.10803 12.5316 8.10803 12.8821V14.1071H9.68053V12.8821C9.68103 12.5316 9.39703 12.2476 9.04653 12.2476Z" fill="#263238"/>
<path d="M13.073 14.3345H10.9465V12.6405C10.9465 12.167 11.3305 11.783 11.804 11.783H12.215C12.6885 11.783 13.0725 12.167 13.0725 12.6405L13.073 14.3345Z" fill="#263238"/>
<path d="M14.131 8.93457V9.38957H13.4785V8.93457H12.608V9.38957H11.392V8.93457H10.5215V9.38957H9.89853V8.93457H9.38403V9.38957V9.57757V10.0326H14.6355V9.57757V9.38957V8.93457H14.131Z" fill="#D50000"/>
<path d="M14.6355 10.082H9.38447C9.35647 10.082 9.33447 10.06 9.33447 10.032V8.93452C9.33447 8.90652 9.35647 8.88452 9.38447 8.88452H9.89847C9.92647 8.88452 9.94847 8.90652 9.94847 8.93452V9.34002H10.472V8.93452C10.472 8.90652 10.494 8.88452 10.522 8.88452H11.392C11.42 8.88452 11.442 8.90652 11.442 8.93452V9.34002H12.559V8.93452C12.559 8.90652 12.581 8.88452 12.609 8.88452H13.479C13.507 8.88452 13.529 8.90652 13.529 8.93452V9.34002H14.082V8.93452C14.082 8.90652 14.104 8.88452 14.132 8.88452H14.6365C14.6645 8.88452 14.6865 8.90652 14.6865 8.93452V10.032C14.6855 10.06 14.6635 10.082 14.6355 10.082ZM9.43397 9.98252H14.586V8.98452H14.181V9.39002C14.181 9.41802 14.159 9.44002 14.131 9.44002H13.478C13.45 9.44002 13.428 9.41802 13.428 9.39002V8.98452H12.6575V9.39002C12.6575 9.41802 12.6355 9.44002 12.6075 9.44002H11.3905C11.3625 9.44002 11.3405 9.41802 11.3405 9.39002V8.98452H10.57V9.39002C10.57 9.41802 10.548 9.44002 10.52 9.44002H9.89697C9.86897 9.44002 9.84697 9.41802 9.84697 9.39002V8.98452H9.43247V9.98252H9.43397Z" fill="#263238"/>
<path d="M11.02 14.1645L11.897 14.7175C11.897 14.7175 11.746 14.829 11.752 14.9965C11.758 15.164 11.9475 15.3315 12.076 15.3315C12.2045 15.3315 12.428 15.287 12.4445 15.013C12.45 14.812 12.305 14.745 12.305 14.745L13.0145 14.22V13.935L12.121 14.5435L11.021 13.8735V14.1645H11.02Z" fill="#FBC02D"/>
<path d="M12.0755 15.3814C11.921 15.3814 11.7085 15.1919 11.7015 14.9984C11.6975 14.8744 11.765 14.7784 11.8155 14.7249L10.993 14.2069C10.979 14.1974 10.97 14.1814 10.97 14.1644V13.8739C10.97 13.8559 10.98 13.8394 10.9955 13.8304C11.011 13.8214 11.0305 13.8219 11.046 13.8314L12.119 14.4849L12.9855 13.8939C13.0015 13.8844 13.021 13.8834 13.0375 13.8909C13.0535 13.8999 13.064 13.9169 13.064 13.9354V14.2199C13.064 14.2354 13.0565 14.2509 13.044 14.2599L12.389 14.7444C12.439 14.7904 12.4985 14.8754 12.494 15.0144C12.48 15.2449 12.3235 15.3814 12.0755 15.3814ZM11.07 14.1369L11.924 14.6744C11.9375 14.6834 11.9465 14.6984 11.947 14.7149C11.948 14.7314 11.94 14.7469 11.927 14.7569C11.926 14.7579 11.797 14.8559 11.8015 14.9949C11.807 15.1394 11.9785 15.2814 12.076 15.2814C12.189 15.2814 12.3795 15.2459 12.3945 15.0099C12.399 14.8484 12.288 14.7919 12.283 14.7904C12.2675 14.7824 12.2565 14.7669 12.2545 14.7499C12.253 14.7324 12.2605 14.7154 12.2745 14.7049L12.964 14.1944V14.0299L12.1485 14.5854C12.132 14.5959 12.111 14.5964 12.0945 14.5864L11.07 13.9624V14.1369Z" fill="#263238"/>
<path d="M12.0335 16.4091C11.8375 16.4091 11.679 16.2506 11.679 16.0551V15.2786C11.679 15.0826 11.8375 14.9241 12.0335 14.9241C12.0615 14.9241 12.0835 14.9461 12.0835 14.9741C12.0835 15.0021 12.0615 15.0241 12.0335 15.0241C11.893 15.0241 11.7785 15.1386 11.7785 15.2791V16.0556C11.7785 16.1956 11.893 16.3101 12.0335 16.3101C12.1735 16.3101 12.288 16.1956 12.288 16.0556V15.2791C12.288 15.2511 12.31 15.2291 12.338 15.2291C12.366 15.2291 12.388 15.2511 12.388 15.2791V16.0556C12.3875 16.2506 12.229 16.4091 12.0335 16.4091Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'GI',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  