
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FF3D00"/>
<path d="M11.959 11.885C8.70496 10.516 10.1485 6.29502 13.26 6.50802C12.631 6.83052 12.5395 7.43202 12.8985 7.98852C13.301 8.61152 12.8615 9.61352 12.3165 9.85752C11.424 10.26 11.244 11.328 11.959 11.885Z" fill="white"/>
<path d="M11.7829 8.83793L11.5994 9.12293L11.5329 8.79093L11.2029 8.70493L11.5019 8.53893L11.4804 8.20093L11.7304 8.43143L12.0489 8.30643L11.9064 8.61493L12.1214 8.87643L11.7829 8.83793Z" fill="#FF3D00"/>
<path d="M11.9764 11.8495C11.5839 11.6485 11.2654 11.2285 11.1289 10.7265C10.9709 10.1525 11.0604 9.53499 11.3789 9.03299L11.3104 8.98999C10.9824 9.51149 10.8864 10.152 11.0504 10.748C11.1969 11.2795 11.5209 11.705 11.9409 11.92L11.9764 11.8495Z" fill="#FF3D00"/>
<path d="M11.9629 11.8851C12.2654 8.38111 16.7399 8.44361 17.4999 11.4551C16.9979 10.9591 16.3944 11.0586 15.9744 11.5701C15.5039 12.1441 14.4099 12.0391 14.0074 11.5976C13.3494 10.8751 12.2734 11.0351 11.9629 11.8851Z" fill="white"/>
<path d="M14.8185 10.7775L14.4885 10.6915L14.7855 10.5275L14.766 10.1875L15.016 10.418L15.3325 10.293L15.19 10.6015L15.407 10.863L15.069 10.824L14.8855 11.109L14.8185 10.7775Z" fill="#FF3D00"/>
<path d="M12.0019 11.8925C12.0724 11.457 12.3749 11.0275 12.8104 10.742C13.3104 10.414 13.9294 10.3085 14.5059 10.455L14.5254 10.377C13.9259 10.2265 13.2854 10.334 12.7654 10.674C12.3044 10.9765 11.9979 11.416 11.9214 11.879L12.0019 11.8925Z" fill="#FF3D00"/>
<path d="M11.9629 11.8884C15.4044 11.0914 16.7264 15.3494 14.0859 16.9999C14.4044 16.3709 14.1229 15.8299 13.5039 15.5919C12.8104 15.3224 12.5744 14.2539 12.8689 13.7384C13.3574 12.8904 12.8709 11.9199 11.9629 11.8884Z" fill="white"/>
<path d="M13.9045 14.2521L13.883 13.9121L14.133 14.1426L14.4495 14.0176L14.307 14.3261L14.524 14.5896L14.186 14.5486L14.0025 14.8356L13.936 14.5016L13.606 14.4176L13.9045 14.2521Z" fill="#FF3D00"/>
<path d="M11.9685 11.9275C12.406 11.861 12.91 12.0135 13.318 12.3375C13.785 12.7105 14.076 13.2635 14.115 13.855L14.197 13.849C14.154 13.2355 13.853 12.6615 13.369 12.2765C12.9375 11.9325 12.4235 11.7765 11.957 11.849L11.9685 11.9275Z" fill="#FF3D00"/>
<path d="M11.9611 11.8904C13.7836 14.9019 10.1271 17.4724 7.73462 15.4784C8.43362 15.5879 8.86162 15.1544 8.90062 14.4919C8.94362 13.7534 9.89062 13.1989 10.4746 13.3199C11.4336 13.5194 12.2111 12.7599 11.9611 11.8904Z" fill="white"/>
<path d="M10.3026 14.4571L10.6211 14.3341L10.4766 14.6406L10.6956 14.9041L10.3556 14.8631L10.1741 15.1501L10.1076 14.8161L9.77759 14.7321L10.0746 14.5661L10.0531 14.2261L10.3026 14.4571Z" fill="#FF3D00"/>
<path d="M11.9241 11.9081C12.1231 12.3006 12.1331 12.8261 11.9496 13.3126C11.7386 13.8691 11.2991 14.3166 10.7466 14.5371L10.7781 14.6111C11.3506 14.3826 11.8056 13.9196 12.0261 13.3396C12.2196 12.8261 12.2096 12.2906 11.9966 11.8706L11.9241 11.9081Z" fill="#FF3D00"/>
<path d="M11.9571 11.8864C9.64456 14.5464 6.05856 11.8764 7.22456 8.9939C7.33806 9.6894 7.88256 9.9629 8.52556 9.7949C9.24606 9.6054 10.0686 10.3319 10.1331 10.9219C10.2401 11.8924 11.2051 12.3924 11.9571 11.8864Z" fill="white"/>
<path d="M8.99201 11.1135L9.20901 11.3735L8.87102 11.3345L8.68752 11.6215L8.62102 11.2875L8.29102 11.2035L8.59002 11.0375L8.56851 10.6995L8.81851 10.928L9.13502 10.805L8.99201 11.1135Z" fill="#FF3D00"/>
<path d="M11.9294 11.8594C11.6149 12.1699 11.1169 12.3399 10.5954 12.3164C9.99794 12.2889 9.43544 12.0119 9.05444 11.5564L8.99194 11.6074C9.38844 12.0799 9.97244 12.3674 10.5914 12.3964C11.1424 12.4219 11.6519 12.2479 11.9859 11.9159L11.9294 11.8594Z" fill="#FF3D00"/>
  </svg>
</template>

<script>
export default {
  name: 'HK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  