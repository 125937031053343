
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FF3D00"/>
<path d="M10.8945 11.594L10.8905 10.229L11.695 11.3285L12.992 10.9045L12.193 12.01L13 13.1115L11.701 12.6935L10.904 13.801L10.898 12.436L9.599 12.02L10.8945 11.594Z" fill="white"/>
<path d="M9.23647 15.543C7.28747 15.543 5.70497 13.963 5.70497 12.0135C5.70497 10.064 7.28497 8.48397 9.23647 8.48397C9.55297 8.48397 9.85747 8.52897 10.1505 8.60697C9.54097 8.23197 8.82647 8.01147 8.05847 8.01147C5.84947 8.01147 4.05847 9.80247 4.05847 12.0115C4.05847 14.2205 5.84947 16.0115 8.05847 16.0115C8.82597 16.0115 9.54097 15.7925 10.1505 15.4175C9.85747 15.498 9.55247 15.543 9.23647 15.543Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  