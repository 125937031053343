
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 9.204 20.8495 6.679 19 4.8645V19.136C20.8495 17.321 22 14.796 22 12Z" fill="#3F51B5"/>
<path d="M17.4185 20.397L3.679 6.45703C2.6195 8.04353 2 9.94903 2 12C2 17.517 6.468 21.99 11.983 21.999H12.017C14.009 21.996 15.8605 21.4045 17.4185 20.397Z" fill="#3F51B5"/>
<path d="M19 19.1355V4.8645C17.196 3.0945 14.727 2 12 2C8.52796 2 5.47146 3.7705 3.67896 6.457L17.4185 20.3975C17.9875 20.029 18.5185 19.608 19 19.1355Z" fill="#FFEB3B"/>
<path d="M3.857 8.5L4.1215 9.064L4.714 9.1545L4.2855 9.595L4.386 10.2145L3.857 9.9215L3.328 10.2145L3.4285 9.595L3 9.1545L3.5925 9.064L3.857 8.5Z" fill="white"/>
<path d="M5.5716 10.2146L5.8361 10.7786L6.4286 10.8691L6.0001 11.3091L6.1006 11.9286L5.5716 11.6356L5.0426 11.9286L5.1431 11.3091L4.7146 10.8686L5.3071 10.7781L5.5716 10.2146Z" fill="white"/>
<path d="M7.28547 11.9285L7.54997 12.4925L8.14247 12.583L7.71397 13.0235L7.81447 13.643L7.28547 13.35L6.75647 13.643L6.85697 13.0235L6.42847 12.583L7.02097 12.4925L7.28547 11.9285Z" fill="white"/>
<path d="M9.00007 13.6431L9.26457 14.2071L9.85707 14.2976L9.42857 14.7381L9.52907 15.3576L9.00007 15.0641L8.47107 15.3571L8.57157 14.7376L8.14307 14.2971L8.73557 14.2066L9.00007 13.6431Z" fill="white"/>
<path d="M10.7144 15.3569L10.9789 15.9209L11.5714 16.0114L11.1429 16.4519L11.2434 17.0714L10.7144 16.7784L10.1854 17.0714L10.2859 16.4519L9.85742 16.0114L10.4499 15.9209L10.7144 15.3569Z" fill="white"/>
<path d="M12.4285 17.0715L12.693 17.6355L13.2855 17.726L12.857 18.1665L12.9575 18.786L12.4285 18.493L11.8995 18.786L12 18.1665L11.5715 17.726L12.164 17.6355L12.4285 17.0715Z" fill="white"/>
<path d="M14.1429 18.7854L14.4074 19.3494L14.9999 19.4404L14.5714 19.8809L14.6719 20.4999L14.1429 20.2069L13.6139 20.4999L13.7144 19.8804L13.2859 19.4399L13.8784 19.3494L14.1429 18.7854Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'BA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  