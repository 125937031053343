
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M10.6775 2.992L6.70997 4.3145L10.6775 5.637L6.70997 6.9595L10.6775 8.282L6.70997 9.6045L10.6775 10.927L6.70947 12.25L10.677 13.5725L6.70947 14.895L10.677 16.2175L6.70947 17.54L10.677 18.8625L6.70947 20.185L10.677 21.5075L9.75647 21.7375C10.479 21.904 11.2275 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C10.8855 2 9.81697 2.19 8.81597 2.5265L10.6775 2.992Z" fill="#880E4F"/>
<path d="M6.7095 20.1856L10.677 18.8631L6.7095 17.5406L10.677 16.2181L6.7095 14.8956L10.677 13.5731L6.7095 12.2501L10.677 10.9276L6.7095 9.6051L10.677 8.2826L6.7095 6.9601L10.677 5.6376L6.7095 4.3151L10.677 2.9926L8.8155 2.5271C4.856 3.8576 2 7.5916 2 12.0001C2 16.7506 5.3155 20.7186 9.7565 21.7381L10.677 21.5081L6.7095 20.1856Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'QA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  