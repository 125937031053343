
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M7.663 21H16.337C17.1605 20.6025 17.92 20.098 18.599 19.5H5.401C6.08 20.098 6.8395 20.6025 7.663 21Z" fill="#D50000"/>
<path d="M13.5 12H22C22 11.489 21.9495 10.9905 21.8755 10.5H13.5V12Z" fill="#D50000"/>
<path d="M13.5 9H21.541C21.3775 8.479 21.1645 7.9805 20.9215 7.5H13.5V9Z" fill="#D50000"/>
<path d="M16.337 3.00001C15.455 2.57401 14.503 2.27601 13.5 2.12451V3.00001H16.337Z" fill="#D50000"/>
<path d="M2.12451 13.5C2.20201 14.0125 2.30701 14.516 2.45901 15H21.5405C21.6925 14.516 21.798 14.0125 21.875 13.5H13.5H2.12451Z" fill="#D50000"/>
<path d="M4.01349 18H19.9865C20.3405 17.53 20.654 17.0295 20.9215 16.5H3.07849C3.34599 17.0295 3.65999 17.53 4.01349 18Z" fill="#D50000"/>
<path d="M13.5 6H19.9865C19.5755 5.4535 19.1115 4.951 18.599 4.5H13.5V6Z" fill="#D50000"/>
<path d="M12 22C13.556 22 15.0235 21.634 16.337 21H7.66296C8.97646 21.634 10.444 22 12 22Z" fill="white"/>
<path d="M5.40105 19.5H18.5991C19.1116 19.049 19.575 18.5465 19.9865 18H4.01355C4.42455 18.5465 4.88855 19.049 5.40105 19.5Z" fill="white"/>
<path d="M3.07848 16.5H20.9215C21.1645 16.0195 21.377 15.521 21.541 15H2.45898C2.62298 15.521 2.83548 16.0195 3.07848 16.5Z" fill="white"/>
<path d="M13.5 13.5H21.8755C21.9495 13.0095 22 12.511 22 12H13.5V13.5Z" fill="white"/>
<path d="M13.5 10.5H21.8755C21.798 9.9875 21.693 9.484 21.541 9H13.5V10.5Z" fill="white"/>
<path d="M13.5 7.5H20.9215C20.654 6.9705 20.34 6.47 19.9865 6H13.5V7.5Z" fill="white"/>
<path d="M13.5 3V4.5H18.599C17.92 3.902 17.1605 3.3975 16.337 3H13.5Z" fill="white"/>
<path d="M13.5 12V10.5V9V7.5V6V4.5V3V2.1245C13.0095 2.0505 12.511 2 12 2C6.477 2 2 6.477 2 12C2 12.511 2.0505 13.0095 2.1245 13.5H13.5V12Z" fill="#311B92"/>
<path d="M7.01 5.53491C5.348 5.53491 4 6.87891 4 8.53491C4 10.1909 5.3475 11.5349 7.01 11.5349C7.6115 11.5349 8.17 11.3589 8.639 11.0564C8.3655 11.1519 8.0725 11.2029 7.768 11.2029C6.293 11.2029 5 9.96891 5 8.49991C5 7.03091 6.293 5.88291 7.7655 5.88291C8.0915 5.88291 8.404 5.94141 8.693 6.04891C8.213 5.72441 7.633 5.53491 7.01 5.53491Z" fill="#FFD600"/>
<path d="M12.502 10.0605L11.2345 9.4005L11.6095 10.754L10.762 9.621L10.496 11L10.236 9.621L9.3845 10.752L9.7635 9.3985L8.494 10.0565L9.4375 8.998L8 9.0525L9.3205 8.498L8.002 7.9395L9.4395 7.998L8.498 6.9375L9.7655 7.5995L9.3905 6.246L10.24 7.379L10.504 6L10.764 7.379L11.6155 6.248L11.2365 7.6015L12.506 6.9435L11.5625 8.002L13 7.9475L11.6795 8.502L12.998 9.0605L11.5605 9.002L12.502 10.0605Z" fill="#FFD600"/>
  </svg>
</template>

<script>
export default {
  name: 'MY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  