
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M3.61694 6.559L10.0229 11.3335L20.0959 17.854C21.2884 16.2075 21.9999 14.189 21.9999 12C21.9999 6.477 17.5229 2 11.9999 2C8.48544 2 5.39994 3.817 3.61694 6.559Z" fill="#43A047"/>
<path d="M12 22C15.414 22 18.425 20.2865 20.229 17.6755L3.771 6.32446C2.6565 7.93746 2 9.89096 2 12C2 17.523 6.477 22 12 22Z" fill="#3F51B5"/>
<path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" fill="#FFCA28"/>
<path d="M13.1639 10.4361C13.1639 10.4361 13.0844 10.6746 12.8324 10.7146C12.8189 11.2446 11.7454 11.6291 11.3479 11.6156C11.2814 11.5226 11.2154 11.3901 11.2154 11.3901C11.2154 11.3901 11.0959 11.5756 10.8574 11.4301C10.6189 11.2841 10.3539 10.8601 10.3539 10.8601C10.3539 10.8601 10.2214 10.9926 10.2609 11.1386C10.3009 11.2846 10.4999 11.3906 10.4999 11.3906V11.6821C10.4999 11.6821 10.3009 11.6821 10.2879 11.7616C10.2744 11.8411 10.3409 12.0266 10.2479 12.0796C10.1549 12.1326 9.86342 12.3316 9.87692 12.3976C9.89042 12.4641 10.0624 12.4906 10.0624 12.4906C10.0624 12.4906 10.1024 12.4641 10.0889 12.4241C10.0754 12.3841 10.0754 12.3841 10.0754 12.3841L10.1814 12.2781C10.1814 12.2781 10.7909 12.3446 10.9104 12.3046C11.0299 12.2646 11.0959 12.4771 11.3344 12.3976C11.5864 12.6231 11.8909 12.6361 12.0764 12.5566C12.1824 12.7421 12.1559 12.6496 12.1824 12.8351C12.2089 13.0206 12.1294 13.8026 12.1294 13.8026L12.6859 13.9221L12.7259 13.4581C12.7259 13.4581 12.9909 12.8086 12.9114 12.5571C12.8319 12.3051 12.9379 12.3451 12.9379 12.3451C12.9379 12.3451 13.1499 12.3451 13.2164 12.1461C13.2829 11.9471 13.5744 11.6556 13.7334 11.6426C13.8924 11.6291 13.8129 11.4966 13.8129 11.4966L13.5609 11.3376C13.5609 11.3376 13.7199 10.9531 13.6539 10.7811C13.5874 10.6086 13.6274 10.4231 13.6274 10.4231C13.6274 10.4231 13.4284 10.5956 13.3359 10.5556C13.2434 10.5156 13.1639 10.4361 13.1639 10.4361Z" fill="#43A047"/>
<path d="M17.5325 6.61052C17.61 6.71402 17.727 6.17052 17.676 6.06802C17.625 5.96552 17.4305 5.87352 17.3175 5.67902C17.205 5.48452 17.215 5.31052 17.1335 5.28002C17.052 5.24952 16.9595 4.81952 16.898 4.71702C16.8365 4.61452 16.7955 4.44052 17.062 4.65552C17.3285 4.87052 18.0445 5.47452 18.0755 5.67902C18.1065 5.88352 18.188 6.08852 18.2595 6.56952C18.331 7.05052 18.425 7.32302 18.5565 7.26552C18.884 7.12202 19.181 7.28602 19.273 7.41902C19.365 7.55202 18.9965 7.70552 18.9045 7.73652C18.8125 7.76752 17.533 8.16652 17.0315 8.50402C16.53 8.84202 16.2765 9.10102 15.2835 9.08102C14.2905 9.06052 14.0425 8.82152 14.0015 8.75002C13.9605 8.67852 15.199 9.00602 15.7005 8.85252C16.202 8.69902 17.2565 8.01302 17.2565 8.01302C17.2565 8.01302 17.543 7.62402 17.502 7.45002C17.461 7.27602 16.6215 6.73352 16.417 6.63102C16.2125 6.52852 15.8335 6.10902 15.803 6.02702C15.7725 5.94502 15.936 6.00652 16.3045 6.10902C16.6725 6.21152 17.287 6.28302 17.5325 6.61052Z" fill="#FFCA28"/>
<path d="M7.7919 10.894L8.0104 11.3975L8.5409 11.2545L8.2829 11.7395L8.7254 12.0645L8.1859 12.1655L8.2074 12.7145L7.7919 12.355L7.3764 12.7145L7.3979 12.1655L6.8584 12.0645L7.3009 11.7395L7.0434 11.2545L7.5734 11.3975L7.7919 10.894Z" fill="white"/>
<path d="M6.3981 16.1021L6.6166 16.6061L7.1471 16.4626L6.8896 16.9476L7.3321 17.2726L6.7921 17.3736L6.8136 17.9226L6.3981 17.5636L5.9826 17.9226L6.0046 17.3736L5.4646 17.2726L5.9071 16.9476L5.6496 16.4626L6.1796 16.6061L6.3981 16.1021Z" fill="white"/>
<path d="M4.03092 11.8411L4.24942 12.3446L4.77942 12.2016L4.52192 12.6866L4.96442 13.0116L4.42442 13.1126L4.44642 13.6611L4.03092 13.3021L3.61542 13.6611L3.63692 13.1126L3.09692 13.0116L3.53942 12.6866L3.28192 12.2016L3.81242 12.3446L4.03092 11.8411Z" fill="white"/>
<path d="M5.42447 9L5.64347 9.504L6.17347 9.3605L5.91597 9.8455L6.35847 10.1705L5.81847 10.2715L5.83997 10.8205L5.42447 10.4615L5.00897 10.8205L5.03097 10.2715L4.49097 10.1705L4.93347 9.8455L4.67597 9.3605L5.20597 9.504L5.42447 9Z" fill="white"/>
<path d="M6.73603 13.606L6.88953 14.016L7.32703 14.036L6.98453 14.3085L7.10153 14.731L6.73603 14.4895L6.37053 14.731L6.48703 14.3085L6.14453 14.036L6.58203 14.016L6.73603 13.606Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'CX',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  