
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 9.7455 21.245 7.6725 19.9865 6H4.0135C2.755 7.6725 2 9.7455 2 12C2 14.2545 2.755 16.3275 4.0135 18H19.9865C21.245 16.3275 22 14.2545 22 12Z" fill="#D50000"/>
<path d="M19.1354 5H4.86442C4.55792 5.3125 4.27742 5.6495 4.01392 6H19.9869C19.7224 5.6495 19.4424 5.3125 19.1354 5Z" fill="white"/>
<path d="M12 2C9.204 2 6.679 3.1505 4.8645 5H19.136C17.321 3.1505 14.796 2 12 2Z" fill="#0D47A1"/>
<path d="M19.9864 18H4.01343C4.27743 18.3505 4.55743 18.6875 4.86393 19H19.1354C19.4424 18.6875 19.7224 18.3505 19.9864 18Z" fill="white"/>
<path d="M19.1355 19H4.8645C6.679 20.8495 9.204 22 12 22C14.796 22 17.321 20.8495 19.1355 19Z" fill="#0D47A1"/>
<path d="M16.6665 12C16.6665 14.578 14.578 16.6665 12 16.6665C9.422 16.6665 7.3335 14.578 7.3335 12C7.3335 9.422 9.422 7.3335 12 7.3335C14.578 7.3335 16.6665 9.422 16.6665 12Z" fill="white"/>
<path d="M13.6429 12.534L16.2989 10.6015H13.0149L11.9999 7.479L10.9844 10.6015H7.70044L10.3564 12.534L9.34344 15.6565L11.9999 13.7265L14.6559 15.656L13.6429 12.534Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'KP',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  