
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M11.9999 2C9.75394 2 7.68744 2.7495 6.01794 4H17.9814C16.3124 2.7495 14.2459 2 11.9999 2Z" fill="white"/>
<path d="M22 12C22 9.7455 21.245 7.6725 19.9865 6H4.0135C2.755 7.6725 2 9.7455 2 12C2 14.2545 2.755 16.3275 4.0135 18H19.9865C21.245 16.3275 22 14.2545 22 12Z" fill="white"/>
<path d="M6.01794 20C7.68744 21.2505 9.75344 22 11.9999 22C14.2464 22 16.3124 21.2505 17.9819 20H6.01794Z" fill="white"/>
<path d="M19.9865 18H4.01355C4.58405 18.7585 5.25855 19.431 6.01805 20H17.9816C18.7416 19.431 19.4155 18.7585 19.9865 18Z" fill="#3F51B5"/>
<path d="M17.982 4H6.01805C5.25855 4.569 4.58405 5.2415 4.01355 6H19.9865C19.4155 5.2415 18.7415 4.569 17.982 4Z" fill="#3F51B5"/>
<path d="M8.50403 14.0001H10.838L11.5685 15.2521L12 15.9921L12.4315 15.2521L13.162 14.0001H15.496L15.0565 13.2481L14.328 12.0001L15.0565 10.7521L15.496 10.0001H13.162L12.4315 8.74806L12 8.00806L11.5685 8.74806L10.838 10.0001H8.50403L8.94353 10.7521L9.67203 12.0001L8.94353 13.2481L8.50403 14.0001ZM12 15.0001L11.416 14.0001H12.582L12 15.0001ZM11.125 13.5001L10.25 12.0001L11.125 10.5001H12.875L13.75 12.0001L12.875 13.5001H11.125ZM14.625 13.5001H13.453L14.039 12.4961L14.625 13.5001ZM14.625 10.5001L14.039 11.5041L13.453 10.5001H14.625ZM12 9.00006L12.584 10.0001H11.418L12 9.00006ZM9.37503 10.5001H10.547L9.96103 11.5041L9.37503 10.5001ZM9.96103 12.4961L10.547 13.5001H9.37503L9.96103 12.4961Z" fill="#3F51B5"/>
  </svg>
</template>

<script>
export default {
  name: 'IL',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  