
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.523 22 22 17.523 22 12H2C2 17.523 6.477 22 12 22Z" fill="#B71C1C"/>
<path d="M12 2C6.477 2 2 6.477 2 12H22C22 6.477 17.523 2 12 2Z" fill="white"/>
<path d="M10 7C7.2385 7 5 9.2385 5 12H15C15 9.2385 12.7615 7 10 7Z" fill="#B71C1C"/>
<path d="M10 17C12.7615 17 15 14.7615 15 12H5C5 14.7615 7.2385 17 10 17Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'GL',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  