
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M17.357 3.56543L3.68347 17.5509C4.11997 18.2039 4.63747 18.7959 5.21197 19.3279L21.985 11.7039C21.8845 8.27843 20.0665 5.29043 17.357 3.56543Z" fill="#D50000"/>
<path d="M12.0001 2C10.9031 2 9.85056 2.183 8.86306 2.5095L2.58606 15.347C2.86456 16.13 3.22906 16.8715 3.68306 17.551L17.3566 3.566C15.8076 2.5795 13.9731 2 12.0001 2Z" fill="#FFC107"/>
<path d="M2 12C2 13.176 2.2135 14.299 2.586 15.347L8.863 2.50952C4.8785 3.82602 2 7.57402 2 12Z" fill="#3F51B5"/>
<path d="M22 12.0001C22 11.9001 21.988 11.8031 21.985 11.7041L5.21155 19.3281C5.62155 19.7081 6.05905 20.0571 6.52805 20.3646L20.5415 17.1796C21.461 15.6676 22 13.8991 22 12.0001Z" fill="white"/>
<path d="M20.5419 17.1799L6.52844 20.3649C8.10094 21.3954 9.97894 21.9999 11.9999 21.9999C15.6239 21.9999 18.7879 20.0659 20.5419 17.1799Z" fill="#43A047"/>
  </svg>
</template>

<script>
export default {
  name: 'SC',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  