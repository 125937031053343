
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.358 15.5H10.479L4.95703 19.097C6.76453 20.8905 9.25253 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#FF3D00"/>
<path d="M10.479 15.4585H21.373C21.7715 14.379 22 13.2175 22 12C22 10.7665 21.766 9.591 21.358 8.5H10.479V15.4585Z" fill="white"/>
<path d="M21.358 8.5C19.9375 4.706 16.2895 2 12 2C9.25253 2 6.76453 3.1095 4.95703 4.903L10.479 8.5H21.358Z" fill="#689F38"/>
<path d="M10.479 15.4586V8.50008L4.957 4.90308C3.1315 6.71508 2 9.22508 2 12.0001C2 14.7751 3.1315 17.2851 4.957 19.0971L10.479 15.5001V15.4586Z" fill="#37474F"/>
  </svg>
</template>

<script>
export default {
  name: 'KW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  