
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <g clip-path="url(#clip0_7992_8981)">
<rect x="2" y="2" width="20" height="20" rx="10" fill="white"/>
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M7.35803 6.33911C7.35803 5.97776 7.06509 5.68481 6.70374 5.68481C6.34238 5.68481 6.04944 5.97776 6.04944 6.33911V23.787C6.04944 24.1484 6.34238 24.4413 6.70374 24.4413C7.06509 24.4413 7.35803 24.1484 7.35803 23.787V16.677V7.95304V6.33911Z" fill="#ADB3BE"/>
<path d="M13.5156 7.5665L13.155 7.42228C11.7916 6.87692 10.2991 6.73988 8.85914 7.02787L7.35803 7.32808V16.052L8.85914 15.7518C10.2991 15.4638 11.7916 15.6009 13.155 16.1462C14.6325 16.7372 16.2586 16.8475 17.8024 16.4616L17.8897 16.4398C18.401 16.312 18.6434 15.7265 18.3723 15.2746L17.0108 13.0054C16.7129 12.5089 16.5639 12.2607 16.5286 11.9906C16.5139 11.878 16.5139 11.7639 16.5286 11.6512C16.5639 11.3812 16.7129 11.1329 17.0108 10.6364L18.1262 8.77741C18.4181 8.29086 17.976 7.69425 17.4256 7.83187C16.1267 8.15657 14.7587 8.06372 13.5156 7.5665Z" fill="#ADB3BE"/>
</g>
<defs>
<clipPath id="clip0_7992_8981">
<rect x="2" y="2" width="20" height="20" rx="10" fill="white"/>
</clipPath>
</defs>
  </svg>
</template>

<script>
export default {
  name: 'Fallback',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  