
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M8 15.5H21.358C21.766 14.409 22 13.2335 22 12C22 10.7665 21.766 9.591 21.358 8.5H8V15.5Z" fill="white"/>
<path d="M12 2C10.577 2 9.2255 2.302 8 2.8375V8.5H21.358C19.9375 4.706 16.2895 2 12 2Z" fill="#388E3C"/>
<path d="M8 21.1625C9.2255 21.698 10.577 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5H8V21.1625Z" fill="#424242"/>
<path d="M8 8.4999V2.8374C4.4695 4.3814 2 7.9004 2 11.9999C2 16.0994 4.4695 19.6184 8 21.1624V15.4999V8.4999Z" fill="#F44336"/>
  </svg>
</template>

<script>
export default {
  name: 'AE',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  