
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C9.204 2 6.679 3.1505 4.8645 5H19.136C17.321 3.1505 14.796 2 12 2Z" fill="#3F51B5"/>
<path d="M19.1355 19H4.8645C6.679 20.8495 9.204 22 12 22C14.796 22 17.321 20.8495 19.1355 19Z" fill="#3F51B5"/>
<path d="M21.358 15.5H2.64197C3.13647 16.821 3.89447 18.012 4.86397 19H19.1355C20.105 18.012 20.8635 16.821 21.358 15.5Z" fill="white"/>
<path d="M19.1355 5H4.86446C3.89496 5.988 3.13696 7.179 2.64246 8.5H21.358C20.8635 7.179 20.105 5.988 19.1355 5Z" fill="white"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'CR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  