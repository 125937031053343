
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C6.477 2 2 6.477 2 12C2 13.5555 2.3655 15.0215 2.999 16.3345L16.3345 2.999C15.0215 2.3655 13.5555 2 12 2Z" fill="#43A047"/>
<path d="M22 12C22 10.4445 21.6345 8.97853 21.001 7.66553L7.66553 21.001C8.97853 21.6345 10.4445 22 12 22C17.523 22 22 17.523 22 12Z" fill="#FF3D00"/>
<path d="M16.3345 2.99902L2.99902 16.3345C3.98152 18.3705 5.62952 20.0185 7.66552 21.001L21.001 7.66552C20.0185 5.62952 18.3705 3.98152 16.3345 2.99902Z" fill="#FFC107"/>
  </svg>
</template>

<script>
export default {
  name: 'CG',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  