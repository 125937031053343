
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.64805 2 3.95555 4.7855 2.58105 8.6665H21.4186C20.0446 4.7855 16.3521 2 12.0001 2Z" fill="#263238"/>
<path d="M21.419 15.3335C21.7885 14.2895 22 13.1705 22 12C22 10.8295 21.7885 9.7105 21.419 8.6665H2.581C2.2115 9.7105 2 10.8295 2 12C2 13.1705 2.2115 14.2895 2.581 15.3335H21.419Z" fill="white"/>
<path d="M11.9996 22H12.0006C16.3526 22 20.0446 19.2145 21.4186 15.3335H2.58105C3.95555 19.2145 7.64755 21.9995 11.9996 22Z" fill="#388E3C"/>
<path d="M2 11.9999C2 14.7934 3.148 17.3169 4.995 19.1309L13.2 11.9999L4.995 4.8689C3.148 6.6829 2 9.2064 2 11.9999Z" fill="#D50000"/>
<path d="M17.916 10.1389L18.3575 11.4409L19.732 11.4584L18.63 12.2799L19.0385 13.5924L17.916 12.7984L16.794 13.5924L17.2025 12.2799L16.1005 11.4584L17.475 11.4409L17.916 10.1389Z" fill="#D50000"/>
<path d="M15.723 11.9786C15.723 10.6951 16.6475 9.62957 17.867 9.40657C17.7135 9.37857 17.5555 9.36157 17.394 9.36157C15.9485 9.36157 14.777 10.5331 14.777 11.9786C14.777 13.4241 15.9485 14.5956 17.394 14.5956C17.556 14.5956 17.7135 14.5786 17.867 14.5506C16.6475 14.3281 15.723 13.2621 15.723 11.9786Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'EH',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  