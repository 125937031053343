
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M3.34851 7H20.651C18.9215 4.014 15.699 2 12 2C8.30101 2 5.07851 4.014 3.34851 7Z" fill="#283593"/>
<path d="M22 12C22 10.1765 21.504 8.472 20.6515 7H3.3485C2.496 8.472 2 10.1765 2 12H22Z" fill="white"/>
<path d="M20.651 17C21.504 15.528 22 13.8235 22 12H2C2 13.8235 2.496 15.528 3.349 17H20.651Z" fill="#43A047"/>
<path d="M12 22C15.699 22 18.9215 19.986 20.651 17H3.349C5.0785 19.986 8.301 22 12 22Z" fill="#FDD835"/>
<path d="M16 21.1625V2.8375C14.7745 2.302 13.423 2 12 2V22C13.423 22 14.7745 21.698 16 21.1625Z" fill="#D50000"/>
<path d="M8.76745 5.67552L9.80895 6.50002L9.43994 5.15452L10.4819 4.33102H9.21244L8.76745 3.06152L8.38895 4.33102H7.04395L8.17094 5.15452L7.71595 6.50002L8.76745 5.67552Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'CF',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  