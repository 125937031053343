
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 11.9999C22 7.9004 19.5305 4.3814 16 2.8374V21.1619C19.5305 19.6184 22 16.0994 22 11.9999Z" fill="#3F51B5"/>
<path d="M2 11.9999C2 16.0994 4.4695 19.6184 8 21.1624V2.8374C4.4695 4.3814 2 7.9004 2 11.9999Z" fill="#3F51B5"/>
<path d="M16 2.8375C14.7745 2.302 13.423 2 12 2C10.577 2 9.2255 2.302 8 2.8375V21.162C9.2255 21.698 10.577 22 12 22C13.423 22 14.7745 21.698 16 21.1625V2.8375Z" fill="#FFC107"/>
<path d="M14.9436 10.004C14.3891 10.094 13.8166 10.3945 13.4241 10.8145C13.3791 10.871 13.6661 10.752 13.6916 10.746C13.9066 10.6795 13.6291 11.0605 13.6136 11.158C13.3931 11.8865 13.2016 12.5875 13.1311 13.4255C13.1311 13.482 13.1211 13.523 13.0961 13.527L12.3596 13.4135V10.72C12.5316 10.716 12.8011 10.763 12.9551 10.8155C12.4591 10.3065 12.2266 9.6405 12.0001 9C11.7736 9.6405 11.5411 10.3065 11.0451 10.8165C11.1996 10.764 11.4691 10.717 11.6406 10.7225V13.414L10.9041 13.5275C10.8786 13.5255 10.8691 13.4825 10.8691 13.426C10.7986 12.59 10.6076 11.887 10.3866 11.1605C10.3711 11.061 10.0916 10.682 10.3086 10.7485C10.3341 10.7525 10.6211 10.8735 10.5761 10.815C10.1836 10.397 9.61106 10.0945 9.05656 10.0065C9.00556 9.993 8.97655 10.018 9.02155 10.077C9.76005 11.251 10.3771 12.6355 10.3711 14.278C10.6581 14.278 11.3536 14.102 11.6406 14.102V16H12.3596V14.0995C12.6466 14.0995 13.3421 14.2755 13.6291 14.2755C13.6231 12.633 14.2406 11.248 14.9786 10.0765C15.0236 10.0155 14.9921 9.99 14.9436 10.004Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'BB',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  