
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#33691E"/>
<path d="M22 12C22 11.315 21.9305 10.6465 21.799 10H2.201C2.0695 10.6465 2 11.315 2 12C2 12.685 2.0695 13.3535 2.201 14H21.7995C21.9305 13.3535 22 12.685 22 12Z" fill="#1A237E"/>
<path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="#D50000"/>
<path d="M21.3579 8.5H2.64194C2.46044 8.985 2.30544 9.483 2.20044 10H21.7989C21.6939 9.483 21.5394 8.985 21.3579 8.5Z" fill="white"/>
<path d="M21.7989 14H2.20093C2.30593 14.517 2.46093 15.015 2.64243 15.5H21.3579C21.5394 15.015 21.6939 14.517 21.7989 14Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'GM',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  