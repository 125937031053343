




import { computed, defineComponent } from '@vue/composition-api'
import CircularFlags from '@galileo/assets/icons/circularFlagsComponents/index'

export default defineComponent({
  name: 'CircularFlag',

  props: {
    countryCode: {
      type: String,
      required: false,
    },
    currencyCode: {
      type: String,
      required: false,
    },
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },

  setup(props) {
    const flagComponent = computed(() => {
      // countryCode (ISO 2) should be the first two letters of the currency code
      const countryCode = props.currencyCode?.slice(0, 2) ?? 'Fallback'
      return CircularFlags[countryCode]
    })

    return {
      flagComponent,
    }
  },
});
