
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.541 15H2.45898C2.68048 15.705 2.98448 16.372 3.34848 17H20.651C21.015 16.372 21.3195 15.705 21.541 15Z" fill="white"/>
<path d="M22 12C22 11.6625 21.982 11.329 21.9495 11H11.5H2.0505C2.018 11.329 2 11.6625 2 12C2 12.3375 2.018 12.671 2.0505 13H21.9495C21.982 12.671 22 12.3375 22 12Z" fill="white"/>
<path d="M19.1355 19H4.8645C5.669 19.82 6.617 20.495 7.663 21H16.337C17.3835 20.495 18.331 19.82 19.1355 19Z" fill="white"/>
<path d="M11.5 9H21.541C21.3195 8.295 21.0155 7.628 20.6515 7H11.5V9Z" fill="white"/>
<path d="M11.5 5H19.1355C18.331 4.18 17.383 3.505 16.337 3H11.5V5Z" fill="white"/>
<path d="M16.337 3C15.0235 2.366 13.556 2 12 2C11.831 2 11.6665 2.017 11.5 2.025V3H16.337Z" fill="#F44336"/>
<path d="M11.5 7H20.6515C20.229 6.271 19.722 5.5975 19.136 5H11.5V7Z" fill="#F44336"/>
<path d="M11.5 11H21.9495C21.881 10.31 21.7425 9.6415 21.541 9H11.5V11Z" fill="#F44336"/>
<path d="M21.9495 13H2.05054C2.11904 13.69 2.25754 14.3585 2.45904 15H21.5405C21.742 14.3585 21.881 13.69 21.9495 13Z" fill="#F44336"/>
<path d="M20.651 17H3.349C3.7715 17.729 4.2785 18.4025 4.8645 19H19.136C19.722 18.4025 20.229 17.729 20.651 17Z" fill="#F44336"/>
<path d="M7.66296 21C8.97646 21.634 10.444 22 12 22C13.556 22 15.0235 21.634 16.337 21H7.66296Z" fill="#F44336"/>
<path d="M11.5 8.9999V6.9999V4.9999V2.9999V2.0249C6.54454 2.2709 2.53604 6.1114 2.05054 10.9999H11.5V8.9999Z" fill="#3F51B5"/>
<path d="M7.5 4.7439L8.09 6.6189H10L8.455 7.6834L9.045 9.4999L7.5 8.3769L5.955 9.4999L6.545 7.6834L5 6.6189H6.91L7.5 4.7439Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'LR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  