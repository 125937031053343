
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M1.99438 12C1.99438 13.9445 2.55838 15.7535 3.51838 17.289L20.6509 7.0095C18.9229 4.018 15.6979 2 11.9944 2C6.47188 2 1.99438 6.477 1.99438 12Z" fill="#0D47A1"/>
<path d="M21.379 8.57253C21.18 8.02753 20.938 7.50503 20.6515 7.00903L3.51904 17.289C3.82554 17.779 4.17204 18.2385 4.55604 18.6665L21.379 8.57253Z" fill="#FDD835"/>
<path d="M11.9944 22C17.5174 22 21.9944 17.523 21.9944 12C21.9944 10.794 21.7699 9.64301 21.3789 8.57251L4.55591 18.6665C6.38691 20.708 9.03641 22 11.9944 22Z" fill="#2E7D32"/>
<path d="M7.07954 4.28442L7.35754 5.10442L8.22354 5.11542L7.52904 5.63292L7.78654 6.45992L7.07954 5.95942L6.37304 6.45992L6.63054 5.63292L5.93604 5.11542L6.80204 5.10442L7.07954 4.28442Z" fill="white"/>
<path d="M9.52656 6.37842L9.80456 7.19842L10.6701 7.20892L9.97606 7.72692L10.2336 8.55342L9.52656 8.05342L8.82006 8.55342L9.07706 7.72692L8.38306 7.20892L9.24906 7.19842L9.52656 6.37842Z" fill="white"/>
<path d="M11.9736 4.28442L12.2516 5.10442L13.1171 5.11542L12.4231 5.63292L12.6801 6.45992L11.9736 5.95942L11.2666 6.45992L11.5241 5.63292L10.8301 5.11542L11.6956 5.10442L11.9736 4.28442Z" fill="white"/>
<path d="M7.07954 8.52246L7.35754 9.34246L8.22354 9.35346L7.52904 9.87096L7.78654 10.6975L7.07954 10.1975L6.37304 10.6975L6.63054 9.87096L5.93604 9.35346L6.80204 9.34246L7.07954 8.52246Z" fill="white"/>
<path d="M11.9736 8.52246L12.2516 9.34246L13.1171 9.35346L12.4231 9.87096L12.6801 10.6975L11.9736 10.1975L11.2666 10.6975L11.5241 9.87096L10.8301 9.35346L11.6956 9.34246L11.9736 8.52246Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'SB',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  