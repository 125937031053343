
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C7.71047 2 4.06247 4.706 2.64197 8.5H21.3575C19.9375 4.706 16.2895 2 12 2Z" fill="#388E3C"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="#FFD600"/>
<path d="M21.358 15.5H2.64197C4.06247 19.294 7.71047 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#D50000"/>
<path d="M17.5 11.949C17.5 14.988 15.037 17.449 12 17.449C8.963 17.449 6.5 14.988 6.5 11.949C6.5 8.91198 8.963 6.44897 12 6.44897C15.037 6.44897 17.5 8.91198 17.5 11.949Z" fill="#0F47AF"/>
<path d="M13.3615 9.87294L14.4785 8.33594L14.883 8.63094L13.766 10.1659L13.3615 9.87294Z" fill="#FCDD09"/>
<path d="M14.416 13.1329L14.5705 12.6584L16.3305 13.2309L16.176 13.7054L14.416 13.1329Z" fill="#FCDD09"/>
<path d="M11.75 14.5H12.25V16.5H11.75V14.5Z" fill="#FCDD09"/>
<path d="M7.66003 13.1955L9.42003 12.625L9.57453 13.0995L7.81453 13.672L7.66003 13.1955Z" fill="#FCDD09"/>
<path d="M9.12695 8.59407L9.53145 8.29907L10.6485 9.83607L10.244 10.1311L9.12695 8.59407Z" fill="#FCDD09"/>
<path d="M12.5995 10.5H11.949L11.8005 11H14.3495L15.0505 10.5H13.15L12 7.05103L11.75 7.85003L12.5995 10.5Z" fill="#FCDD09"/>
<path d="M10.699 10.9999L10.5 11.6504L10.949 11.9494L11.75 9.44939L11.5 8.65039L10.8495 10.4999H7.25L7.9005 10.9999H10.699Z" fill="#FCDD09"/>
<path d="M11.1505 13.3495L11.5995 13.0505L10.75 12.449L9.44899 11.5H8.59949L10.1505 12.6505L9.05099 16.0995L9.74999 15.5995L10.5995 12.949L11.1505 13.3495Z" fill="#FCDD09"/>
<path d="M12.449 13.6505L12.949 13.25L12.8005 12.75L10.699 14.301L10.449 15.1L12 13.949L14.949 16.0995L14.699 15.3005L12.449 13.6505Z" fill="#FCDD09"/>
<path d="M13.75 14L14.4005 14.5L13.801 12.6505L16.75 10.5H15.9005L13.6505 12.1505L13.449 11.5H12.949L13.75 14Z" fill="#FCDD09"/>
  </svg>
</template>

<script>
export default {
  name: 'ET',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  