
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C6.477 2 2 6.477 2 12H22C22 6.477 17.523 2 12 2Z" fill="#FF3D00"/>
<path d="M22 12H2C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12Z" fill="white"/>
<path d="M9.3105 10.344C9.0115 10.4475 8.6935 10.5 8.3575 10.5C6.781 10.5 5.5 9.2715 5.5 7.75C5.5 6.2285 6.781 5 8.3575 5C8.6935 5 9.012 5.0525 9.3105 5.1565C7.867 5.2055 6.7165 6.35 6.7165 7.7505C6.7165 9.151 7.867 10.295 9.3105 10.344Z" fill="white"/>
<path d="M8.713 7.81642L8.3245 7.52942L7.938 7.81642L8.0865 7.35542L7.698 7.07042H8.1785L8.325 6.60742L8.4735 7.07042H8.954L8.5655 7.35542L8.713 7.81642Z" fill="white"/>
<path d="M11.76 7.81642L11.3715 7.52942L10.985 7.81642L11.1335 7.35542L10.745 7.07042H11.2255L11.372 6.60742L11.5205 7.07042L12 7.06842L11.6115 7.35342L11.76 7.81642Z" fill="white"/>
<path d="M10.2385 6.72853L9.85001 6.44153L9.46351 6.72853L9.61201 6.26553L9.22351 5.98253H9.70401L9.85051 5.51953L9.99901 5.98253H10.4795L10.089 6.26553L10.2385 6.72853Z" fill="white"/>
<path d="M9.28294 9.55641L8.89444 9.26941L8.50795 9.55641L8.65644 9.09541L8.26794 8.81041H8.74844L8.89495 8.34741L9.04344 8.81041H9.52394L9.13344 9.09541L9.28294 9.55641Z" fill="white"/>
<path d="M11.1896 9.55641L10.8011 9.26941L10.4146 9.55641L10.5631 9.09541L10.1746 8.81041H10.6551L10.8016 8.34741L10.9501 8.81041H11.4306L11.0401 9.09541L11.1896 9.55641Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'SG',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  