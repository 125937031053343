
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#007FFF"/>
<path d="M21.624 9.3115C21.2845 8.0935 20.7205 6.9725 19.979 5.9895L2.79504 15.911C3.28654 17.0665 3.99154 18.108 4.85754 18.992L21.624 9.3115Z" fill="#D50000"/>
<path d="M6.50449 4.94556L7.18049 7.01556L9.35749 7.01856L7.59799 8.30106L8.26799 10.3726L6.50449 9.09556L4.74099 10.3726L5.41049 8.30106L3.65149 7.01856L5.82849 7.01556L6.50449 4.94556Z" fill="#FDD835"/>
<path d="M4.51294 18.6131C4.74144 18.8716 4.98294 19.1166 5.23644 19.3501L21.7534 9.81457C21.6779 9.47607 21.5844 9.14507 21.4754 8.82007L4.51294 18.6131Z" fill="#FDD835"/>
<path d="M20.2805 6.39359C20.0915 6.11509 19.8875 5.84759 19.672 5.59009L2.61853 15.4356C2.73453 15.7526 2.86603 16.0621 3.01253 16.3631L20.2805 6.39359Z" fill="#FDD835"/>
<path d="M23.4045 8.57642V8.86092L23.528 8.78992L23.4045 8.57642Z" fill="#FDD835"/>
<path d="M0.983509 16.3794L0.859009 16.4509L0.983509 16.6669V16.3794Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'CD',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  