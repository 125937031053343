
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.892 2.18 9.826 2.513 8.8295C3.1555 6.906 7.2445 5.5 7.2445 5.5C7.2445 5.5 7.2645 3.1535 7.998 2.833C9.2235 2.297 10.577 2 12 2C17.523 2 22 6.477 22 12Z" fill="#283593"/>
<path d="M6.5 14L6.797 14.9455L7.703 14.594L7.166 15.422L8 15.9295L7.035 16.0155L7.168 17L6.5 16.2795L5.832 17L5.965 16.0155L5 15.9295L5.834 15.422L5.297 14.594L6.203 14.9475L6.5 14Z" fill="white"/>
<path d="M17.25 15.5L17.3985 15.9725L17.8515 15.797L17.584 16.211L18 16.465L17.5175 16.508L17.584 17L17.25 16.6405L16.916 17L16.9825 16.508L16.5 16.465L16.916 16.211L16.6485 15.797L17.1015 15.9725L17.25 15.5Z" fill="white"/>
<path d="M14.7324 10.9919L14.8809 11.4649L15.3339 11.2889L15.0664 11.7029L15.4824 11.9569L14.9999 11.9999L15.0664 12.4919L14.7324 12.1329L14.3984 12.4919L14.4649 11.9999L13.9824 11.9569L14.4004 11.7029L14.1309 11.2889L14.5839 11.4649L14.7324 10.9919Z" fill="white"/>
<path d="M17.25 8L17.3985 8.4725L17.8515 8.297L17.584 8.711L18 8.965L17.5175 9.008L17.584 9.5L17.25 9.1405L16.916 9.5L16.9825 9.008L16.5 8.965L16.916 8.711L16.6485 8.297L17.1015 8.4725L17.25 8Z" fill="white"/>
<path d="M20.25 10.5L20.3985 10.9725L20.8515 10.797L20.584 11.211L21 11.465L20.5175 11.508L20.584 12L20.25 11.6405L19.916 12L19.9825 11.508L19.5 11.465L19.916 11.211L19.6485 10.797L20.1015 10.9725L20.25 10.5Z" fill="white"/>
<path d="M18.5 12.5L18.6365 12.8535L19 12.883L18.7225 13.129L18.8085 13.5L18.5 13.299L18.1915 13.5L18.2775 13.129L18 12.883L18.3635 12.8535L18.5 12.5Z" fill="white"/>
<path d="M8.00001 4.297V2.8375C7.28401 3.1505 6.61651 3.5495 6.00001 4.0135V4.155L5.89701 4.091C4.34451 5.2905 3.15551 6.9325 2.52051 8.8325L6.00001 6.66V9H8.00001V6.5175L11.9745 9H12.5V7.6955L10.5855 6.5H12.5V4.5H10.2895L12.5 3.119V2.025C12.3335 2.017 12.169 2 12 2C11.8825 2 11.768 2.0135 11.6515 2.0175L8.00001 4.297Z" fill="white"/>
<path d="M6.49992 6.00011V9.00011H7.49992V6.00011H12.4999V5.00011H7.49992V3.07861C7.15542 3.25261 6.81992 3.44111 6.49992 3.65261V5.00011H4.86442C4.55792 5.31261 4.27742 5.64961 4.01392 6.00011H6.49992Z" fill="#E53935"/>
<path d="M5.82041 6.5H4.94891L2.96291 7.7395C2.84841 7.9815 2.74441 8.228 2.64941 8.48L5.82041 6.5Z" fill="#E53935"/>
<path d="M12.1066 2.00562L8.11157 4.50012H8.98457L12.5001 2.30462V2.02512C12.3691 2.01862 12.2391 2.00712 12.1066 2.00562Z" fill="#E53935"/>
<path d="M12.5 7.967L10.1505 6.5H9.27954L12.5 8.51V7.967Z" fill="#E53935"/>
  </svg>
</template>

<script>
export default {
  name: 'AU',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  