
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12H15.435L15.4385 12.0025L4.80554 18.935C6.62454 20.8215 9.17254 22 12 22C17.523 22 22 17.523 22 12Z" fill="#FF3D00"/>
<path d="M22.0001 12C22.0001 6.477 17.5231 2 12.0001 2C9.17205 2 6.62355 3.1785 4.80505 5.0655L15.4351 12H22.0001Z" fill="#3F51B5"/>
<path d="M15.435 11.9999L4.805 5.06543C3.0715 6.86343 2 9.30493 2 11.9999C2 14.6954 3.0715 17.1369 4.8055 18.9349L15.4385 12.0019L15.435 11.9999Z" fill="white"/>
<path d="M4.59045 6.5L4.82295 6.994L5.34045 7.072L4.96545 7.457L5.05545 8L4.59045 7.744L4.12545 8L4.21545 7.457L3.84045 7.072L4.35795 6.994L4.59045 6.5Z" fill="#FFA000"/>
<path d="M4.73303 16.0349L4.96553 16.5309L5.48303 16.6089L5.10803 16.9939L5.19803 17.5349L4.73303 17.2809L4.27003 17.5349L4.35803 16.9939L3.98303 16.6089L4.50253 16.5309L4.73303 16.0349Z" fill="#FFA000"/>
<path d="M12.073 11.1621L12.3035 11.6561L12.823 11.7341L12.448 12.1191L12.536 12.6621L12.073 12.4061L11.608 12.6621L11.698 12.1191L11.323 11.7341L11.8405 11.6561L12.073 11.1621Z" fill="#FFA000"/>
<path d="M8.84045 12C8.84045 12.828 8.16845 13.5 7.34045 13.5C6.51245 13.5 5.84045 12.828 5.84045 12C5.84045 11.172 6.51245 10.5 7.34045 10.5C8.16845 10.5 8.84045 11.172 8.84045 12Z" fill="#FFA000"/>
<path d="M7.84045 14.5C7.84045 14.7775 7.61795 15 7.34045 15C7.06295 15 6.84045 14.7775 6.84045 14.5L7.34045 13.502V10.5L6.84045 9.5C6.84045 9.2225 7.06295 9 7.34045 9C7.61795 9 7.84045 9.2225 7.84045 9.5L7.34045 10.5V13.502L7.84045 14.5Z" fill="#FFA000"/>
<path d="M9.84045 11.5C10.118 11.5 10.3405 11.7225 10.3405 12C10.3405 12.2775 10.118 12.5 9.84045 12.5L8.84245 12H5.84045L4.84045 12.5C4.56295 12.5 4.34045 12.2775 4.34045 12C4.34045 11.7225 4.56295 11.5 4.84045 11.5L5.84045 12H8.84245L9.84045 11.5Z" fill="#FFA000"/>
<path d="M5.21954 10.586C5.02204 10.3905 5.02204 10.0745 5.21954 9.87905C5.41504 9.68355 5.73104 9.68355 5.92654 9.87905L6.27804 10.9395L8.40104 13.0605L9.46154 13.414C9.65704 13.6095 9.65704 13.9255 9.46154 14.121C9.26604 14.3165 8.95004 14.3165 8.75454 14.121L8.40104 13.0605L6.27804 10.9395L5.21954 10.586Z" fill="#FFA000"/>
<path d="M8.7545 9.87905C8.95 9.68355 9.266 9.68355 9.4615 9.87905C9.657 10.0745 9.657 10.3905 9.4615 10.586L8.401 10.9395L6.28 13.0605L5.9265 14.121C5.731 14.3165 5.415 14.3165 5.2195 14.121C5.024 13.9255 5.024 13.6095 5.2195 13.414L6.28 13.0605L8.401 10.9395L8.7545 9.87905Z" fill="#FFA000"/>
  </svg>
</template>

<script>
export default {
  name: 'PH',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  