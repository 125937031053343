
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2.186 10.8405 3.018 9.66651 3.351 8.67001C3.9935 6.74651 7.2445 5.50001 7.2445 5.50001C7.2445 5.50001 7.7905 3.49051 8.524 3.17001C9.7495 2.63401 10.5985 2.25501 12.0215 2.25501C12.483 2.25501 12.9155 2.07151 13.3605 2.13201C13.4005 2.13751 13.441 2.10301 13.4805 2.10851C18.3015 2.82401 22 6.98001 22 12Z" fill="#D50000"/>
<path d="M2 12H13.4975V2.108C12.994 2.044 12.4835 2.013 11.966 2C6.4435 2.114 2 6.543 2 12Z" fill="white"/>
<path d="M11.75 6.7065H9.5435V4.5H7.4565V6.7065H5.25V8.7935H7.4565V11H9.5435V8.7935H11.75V6.7065Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'TO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  