
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C6.477 2 2 6.477 2 12C2 12.7615 2.0925 13.5 2.254 14.2125L17.465 3.631C15.8935 2.603 14.018 2 12 2Z" fill="#00C853"/>
<path d="M11.9999 22.0001C17.5229 22.0001 21.9999 17.5231 21.9999 12.0001C21.9999 11.2386 21.9074 10.5001 21.7459 9.7876L6.53491 20.3691C8.10641 21.3971 9.98191 22.0001 11.9999 22.0001Z" fill="#03A9F4"/>
<path d="M17.4649 3.6311L2.25391 14.2126C2.36041 14.6826 2.50191 15.1386 2.67191 15.5811L18.6004 4.5011C18.2424 4.1861 17.8654 3.8936 17.4649 3.6311Z" fill="#FFEA00"/>
<path d="M5.39941 19.4989C5.75741 19.8144 6.13441 20.1069 6.53491 20.3689L21.7459 9.78745C21.6394 9.31745 21.4979 8.86145 21.3279 8.41895L5.39941 19.4989Z" fill="#FFEA00"/>
<path d="M18.6006 4.50098L2.67212 15.581C3.25662 17.1015 4.19862 18.4405 5.39962 19.4985L21.3281 8.41848C20.7436 6.89798 19.8016 5.55898 18.6006 4.50098Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'TZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  