
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M7.5 3.07861C4.24 4.72661 2 8.09761 2 12.0001C2 15.9021 4.24 19.2736 7.5 20.9216V12.0001V3.07861Z" fill="white"/>
<path d="M7.5 20.9215C8.854 21.606 10.3795 22 12 22C17.523 22 22 17.523 22 12H7.5V20.9215Z" fill="#2E7D32"/>
<path d="M7.5 12H22C22 6.477 17.523 2 12 2C10.3795 2 8.854 2.394 7.5 3.0785V12Z" fill="#F44336"/>
  </svg>
</template>

<script>
export default {
  name: 'MG',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  