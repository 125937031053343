
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M19.2956 5.1755C17.4706 3.2255 14.8816 2 12.0001 2C9.11859 2 6.52959 3.2255 4.70459 5.1755L12.0001 10.1L19.2956 5.1755Z" fill="#7CB342"/>
<path d="M19.2956 18.8244L12.0001 13.8999L4.70459 18.8244C6.52959 20.7744 9.11859 21.9999 12.0001 21.9999C14.8816 21.9999 17.4706 20.7744 19.2956 18.8244Z" fill="#7CB342"/>
<path d="M2.941 7.78491C2.3435 9.06741 2 10.4919 2 11.9999C2 13.5079 2.3435 14.9324 2.941 16.2149L9.185 11.9999L2.941 7.78491Z" fill="#263238"/>
<path d="M21.0589 16.2149C21.6564 14.9324 21.9999 13.5079 21.9999 11.9999C21.9999 10.4919 21.6564 9.06741 21.0589 7.78491L14.8149 11.9999L21.0589 16.2149Z" fill="#263238"/>
<path d="M11.9999 10.1L4.70442 5.17554C3.98642 5.94254 3.38992 6.82154 2.94092 7.78504L9.18492 12L11.9999 10.1Z" fill="#FFEB3B"/>
<path d="M12 13.9L19.2955 18.8245C20.0135 18.0575 20.61 17.1785 21.059 16.215L14.815 12L12 13.9Z" fill="#FFEB3B"/>
<path d="M14.8149 12L21.0589 7.78504C20.6094 6.82104 20.0134 5.94254 19.2954 5.17554L11.9999 10.1L9.18492 12L2.94092 16.215C3.39042 17.179 3.98642 18.0575 4.70442 18.8245L11.9999 13.9L14.8149 12Z" fill="#FFEB3B"/>
  </svg>
</template>

<script>
export default {
  name: 'JM',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  