
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FF3D00"/>
<path d="M6 9.28711L6.59 11.1621H8.5L6.955 12.2266L7.545 14.0431L6 12.9201L4.455 14.0431L5.045 12.2266L3.5 11.1621H5.41L6 9.28711Z" fill="#FFEB3B"/>
<path d="M9.7366 8.03149L9.91409 8.59399H10.4866L10.0236 8.91249L10.2011 9.45749L9.7361 9.11949L9.2731 9.45749L9.45059 8.91249L8.9856 8.59399H9.5596L9.7366 8.03149Z" fill="#FFEB3B"/>
<path d="M9.75 14.543L9.9275 15.1055H10.5L10.037 15.424L10.213 15.969L9.75 15.633L9.287 15.969L9.463 15.424L9 15.1055H9.5725L9.75 14.543Z" fill="#FFEB3B"/>
<path d="M11.75 10.043L11.9275 10.6055H12.5L12.037 10.924L12.213 11.469L11.75 11.133L11.287 11.469L11.463 10.924L11 10.6055H11.5725L11.75 10.043Z" fill="#FFEB3B"/>
<path d="M11.75 12.543L11.9275 13.1055H12.5L12.037 13.424L12.213 13.969L11.75 13.633L11.287 13.969L11.463 13.424L11 13.1055H11.5725L11.75 12.543Z" fill="#FFEB3B"/>
  </svg>
</template>

<script>
export default {
  name: 'CN',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  