
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.419 15.3335C21.7885 14.2895 22 13.1705 22 12C22 10.8295 21.7885 9.7105 21.419 8.6665H2.581C2.2115 9.7105 2 10.8295 2 12C2 13.1705 2.2115 14.2895 2.581 15.3335H21.419Z" fill="#B71C1C"/>
<path d="M12.0001 9L12.7586 11.239L15.1221 11.2685L13.2271 12.6815L13.9296 14.9385L12.0001 13.573L10.0706 14.9385L10.7731 12.6815L8.87805 11.2685L11.2416 11.239L12.0001 9Z" fill="#FFCA28"/>
<path d="M20.704 7.1065C18.992 4.062 15.742 2 12 2C8.25802 2 5.00802 4.062 3.29602 7.1065H20.704Z" fill="#388E3C"/>
<path d="M2.60505 8.60645C2.59755 8.62695 2.58805 8.64645 2.58105 8.66695H21.4186C21.4116 8.64645 21.4021 8.62695 21.3946 8.60645H2.60505Z" fill="#388E3C"/>
<path d="M21.4161 15.3405C21.4171 15.338 21.4181 15.336 21.4191 15.3335H2.58105C2.58205 15.336 2.58305 15.338 2.58405 15.3405H21.4161Z" fill="#388E3C"/>
<path d="M3.26453 16.8406C4.96853 19.9146 8.23553 22.0001 11.9995 22.0001H12.0005C15.7645 22.0001 19.0315 19.9146 20.7355 16.8406H3.26453Z" fill="#388E3C"/>
<path d="M21.3965 8.60644C21.2085 8.08544 20.984 7.58295 20.7155 7.10645H3.28403C3.01603 7.58295 2.79153 8.08544 2.60303 8.60644H21.3965Z" fill="white"/>
<path d="M3.25198 16.8403H20.7485C21.013 16.3638 21.232 15.8603 21.4165 15.3403H2.58398C2.76848 15.8603 2.98748 16.3638 3.25198 16.8403Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'SR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  