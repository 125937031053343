
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.892 2.18 9.826 2.513 8.8295C3.1555 6.906 7.2445 5.5 7.2445 5.5C7.2445 5.5 7.7905 3.4905 8.524 3.17C9.7495 2.634 10.577 2 12 2C17.523 2 22 6.477 22 12Z" fill="#283593"/>
<path d="M11.9999 2C7.42644 2 3.57844 5.074 2.38794 9.266H13.2874V2.093C12.8649 2.038 12.4374 2 11.9999 2Z" fill="white"/>
<path d="M15.3735 14.9736V13.2666H16.1865L16.784 12.2731H15.3735V11.7596H17.083L17.6805 10.7661H12.0045L12.635 11.7596H14.311V14.9981L13.0165 13.0841L11.2905 15.8161H12.3525L12.459 15.5676H13.5755L14.8255 17.5241V17.5546L14.834 17.5391L14.8425 17.5546V17.5241L16.076 15.5676H17.209L17.3155 15.8161H18.3775L16.6515 13.0841L15.3735 14.9736ZM12.75 14.8891L13.0335 14.2266L13.3935 14.8891H12.75ZM16.275 14.8891L16.635 14.2266L16.9185 14.8891H16.275Z" fill="white"/>
<path d="M11.3954 12.9741L12.0224 12.5316L11.2549 12.5426L11.0279 11.8091L10.8014 12.5426L10.0339 12.5316L10.6609 12.9741L10.4134 13.7006L11.0279 13.2411L11.6429 13.7006L11.3954 12.9741Z" fill="white"/>
<path d="M19.7056 12.5316L18.9381 12.5426L18.7111 11.8091L18.4841 12.5426L17.7166 12.5316L18.3441 12.9741L18.0966 13.7006L18.7111 13.2411L19.3256 13.7006L19.0786 12.9741L19.7056 12.5316Z" fill="white"/>
<path d="M17.1666 17.2445L16.9396 16.5115L16.7126 17.2445L15.9451 17.234L16.5721 17.6765L16.3251 18.403L16.9396 17.943L17.5541 18.403L17.3066 17.6765L17.9341 17.234L17.1666 17.2445Z" fill="white"/>
<path d="M12.994 17.2445L12.767 16.5115L12.54 17.2445L11.7725 17.234L12.3995 17.6765L12.152 18.403L12.767 17.943L13.3815 18.403L13.134 17.6765L13.7615 17.234L12.994 17.2445Z" fill="white"/>
<path d="M15.0635 18.8509L14.8365 18.1174L14.6095 18.8509L13.842 18.8399L14.4695 19.2824L14.222 20.0089L14.8365 19.5494L15.451 20.0089L15.2035 19.2824L15.831 18.8399L15.0635 18.8509Z" fill="white"/>
<path d="M2.4895 8.91748H5.933V4.06348C4.3395 5.28398 3.1215 6.96598 2.4895 8.91748Z" fill="#283593"/>
<path d="M11.9999 2C11.1099 2 10.2514 2.1275 9.42993 2.346V8.9175H12.9269V2.0445C12.6214 2.0165 12.3124 2 11.9999 2Z" fill="#FF3D00"/>
  </svg>
</template>

<script>
export default {
  name: 'TF',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  