
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="white"/>
<path d="M6.5 6L11.5 8L6.5 10L11.5 12L6.5 14L11.5 16L6.5 18L11.5 20L8.291 21.2835C9.4375 21.744 10.688 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C10.688 2 9.4375 2.256 8.291 2.7165L11.5005 4L6.5 6Z" fill="#C62828"/>
  </svg>
</template>

<script>
export default {
  name: 'BH',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  