
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M2.201 14H8V21.1625C9.2255 21.698 10.577 22 12 22V14H21.799C21.9305 13.3535 22 12.685 22 12C22 11.315 21.9305 10.6465 21.799 10H12V2C10.577 2 9.2255 2.302 8 2.8375V10H2.201C2.0695 10.6465 2 11.315 2 12C2 12.685 2.0695 13.3535 2.201 14Z" fill="#FFD600"/>
<path d="M2.0505 13H9V21.541C9.6415 21.7425 10.31 21.881 11 21.9495V13H21.9495C21.982 12.671 22 12.3375 22 12C22 11.6625 21.982 11.329 21.9495 11H11V2.05054C10.31 2.11904 9.6415 2.25754 9 2.45904V11H2.0505C2.018 11.329 2 11.6625 2 12C2 12.3375 2.018 12.671 2.0505 13Z" fill="#E53935"/>
<path d="M12 10H21.799C20.8725 5.4355 16.838 2 12 2V10Z" fill="#1976D2"/>
<path d="M12 14V22C16.838 22 20.8725 18.5645 21.799 14H12Z" fill="#1976D2"/>
<path d="M8.00005 9.9999V2.8374C5.05955 4.1234 2.85505 6.7784 2.20105 9.9999H8.00005Z" fill="#1976D2"/>
<path d="M8.00005 14H2.20105C2.85505 17.2215 5.05955 19.8765 8.00005 21.1625V14Z" fill="#1976D2"/>
  </svg>
</template>

<script>
export default {
  name: 'AX',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  