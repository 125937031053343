
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.419 15.3335H6V15.5V16.5V17.5V18.5V19.5V19.9865C7.6725 21.245 9.7455 22 12 22C16.352 22 20.0445 19.2145 21.419 15.3335Z" fill="#7CB342"/>
<path d="M6 8.5H5V9.5H6V8.5Z" fill="white"/>
<path d="M6 6.5H5V7.5H6V6.5Z" fill="white"/>
<path d="M5 7.5H4V8.5H5V7.5Z" fill="white"/>
<path d="M5 11.5H4V12.5H5V11.5Z" fill="white"/>
<path d="M6 10.5H5V11.5H6V10.5Z" fill="white"/>
<path d="M6 12.5H5V13.5H6V12.5Z" fill="white"/>
<path d="M5 13.5H4V14.5H5V13.5Z" fill="white"/>
<path d="M5 9.5H4V10.5H5V9.5Z" fill="white"/>
<path d="M4 8.5H3V9.5H4V8.5Z" fill="white"/>
<path d="M6 16.5H5V17.5H6V16.5Z" fill="white"/>
<path d="M2.99997 7.66309C2.86847 7.93559 2.74897 8.21459 2.64197 8.50009H2.99997V7.66309Z" fill="white"/>
<path d="M5 14.5V15.5H6V15.3335V14.5H5Z" fill="white"/>
<path d="M6 5.5V4.5H5.401C5.2655 4.6195 5.129 4.738 5 4.8645V5.5H6Z" fill="white"/>
<path d="M2.64197 15.5C2.74897 15.7855 2.86847 16.0645 2.99997 16.337V15.5H2.64197Z" fill="white"/>
<path d="M5 15.5H4V16.5H5V15.5Z" fill="white"/>
<path d="M3.00001 13.5H2.12451C2.17551 13.8395 2.24351 14.1725 2.32801 14.5H3.00001V13.5Z" fill="white"/>
<path d="M3 11.5H2.025C2.017 11.6665 2 11.831 2 12C2 12.169 2.017 12.3335 2.025 12.5H3V11.5Z" fill="white"/>
<path d="M5 6.5V5.5H4.41C4.2665 5.6675 4.132 5.8415 4 6.018V6.5H5Z" fill="white"/>
<path d="M4 10.5H3V11.5H4V10.5Z" fill="white"/>
<path d="M3.00001 9.5H2.32801C2.24351 9.8275 2.17601 10.1605 2.12451 10.5H3.00001V9.5Z" fill="white"/>
<path d="M3.99999 16.5H3.07849C3.25249 16.8445 3.44099 17.18 3.65249 17.5H3.99999V16.5Z" fill="white"/>
<path d="M5 17.5H4V17.982C4.132 18.1585 4.2665 18.3325 4.41 18.5H5V17.5Z" fill="white"/>
<path d="M3.99999 6.5H3.65249C3.44099 6.82 3.25249 7.1555 3.07849 7.5H3.99999V6.5Z" fill="white"/>
<path d="M4 14.5H3V15.5H4V14.5Z" fill="white"/>
<path d="M4 12.5H3V13.5H4V12.5Z" fill="white"/>
<path d="M6 18.5H5V19.1355C5.129 19.262 5.2655 19.3805 5.401 19.5H6V18.5Z" fill="white"/>
<path d="M3.99997 6.01807C3.88147 6.17707 3.76197 6.33457 3.65247 6.50007H3.99997V6.01807Z" fill="#FF3D00"/>
<path d="M5.00003 4.8645C4.79353 5.067 4.59853 5.28 4.41003 5.5H5.00003V4.8645Z" fill="#FF3D00"/>
<path d="M6 4.01343C5.794 4.16843 5.594 4.32993 5.401 4.49993H6V4.01343Z" fill="#FF3D00"/>
<path d="M6 5.5H5V6.5H6V5.5Z" fill="#FF3D00"/>
<path d="M4 8.5V7.5H3.0785C3.0515 7.554 3.026 7.6085 3 7.663V8.5H4Z" fill="#FF3D00"/>
<path d="M5 6.5H4V7.5H5V6.5Z" fill="#FF3D00"/>
<path d="M6 7.5H5V8.5H6V7.5Z" fill="#FF3D00"/>
<path d="M3.00001 8.5H2.64201C2.52001 8.826 2.41551 9.159 2.32751 9.5H3.00001V8.5Z" fill="#FF3D00"/>
<path d="M4 9.5H3V10.5H4V9.5Z" fill="#FF3D00"/>
<path d="M5 8.5H4V9.5H5V8.5Z" fill="#FF3D00"/>
<path d="M6 9.5H5V10.5H6V9.5Z" fill="#FF3D00"/>
<path d="M3.00002 10.5H2.12452C2.07502 10.8285 2.04202 11.162 2.02502 11.5H3.00002V10.5Z" fill="#FF3D00"/>
<path d="M4 11.5H3V12.5H4V11.5Z" fill="#FF3D00"/>
<path d="M5 10.5H4V11.5H5V10.5Z" fill="#FF3D00"/>
<path d="M6 11.5H5V12.5H6V11.5Z" fill="#FF3D00"/>
<path d="M3.00002 12.5H2.02502C2.04202 12.838 2.07452 13.1715 2.12452 13.5H3.00002V12.5Z" fill="#FF3D00"/>
<path d="M4 13.5H3V14.5H4V13.5Z" fill="#FF3D00"/>
<path d="M5 12.5H4V13.5H5V12.5Z" fill="#FF3D00"/>
<path d="M6 13.5H5V14.5H6V13.5Z" fill="#FF3D00"/>
<path d="M3 14.5H2.328C2.416 14.841 2.5205 15.174 2.6425 15.5H3V14.5Z" fill="#FF3D00"/>
<path d="M4 15.5H3V16.337C3.026 16.3915 3.0515 16.446 3.0785 16.5H4V15.5Z" fill="#FF3D00"/>
<path d="M5 14.5H4V15.5H5V14.5Z" fill="#FF3D00"/>
<path d="M6 15.5H5V16.5H6V15.5Z" fill="#FF3D00"/>
<path d="M3.65247 17.5C3.76197 17.6655 3.88147 17.823 3.99997 17.982V17.5H3.65247Z" fill="#FF3D00"/>
<path d="M5 16.5H4V17.5H5V16.5Z" fill="#FF3D00"/>
<path d="M6 17.5H5V18.5H6V17.5Z" fill="#FF3D00"/>
<path d="M4.41003 18.5C4.59853 18.7195 4.79353 18.933 5.00003 19.1355V18.5H4.41003Z" fill="#FF3D00"/>
<path d="M5.401 19.5C5.594 19.67 5.794 19.8315 6 19.9865V19.5H5.401Z" fill="#FF3D00"/>
<path d="M6 5.5V6.5V7.5V8.5V9.5V10.5V11.5V12.5V13.5V14.5V15.3335H21.419C21.7885 14.2895 22 13.1705 22 12C22 6.477 17.523 2 12 2C9.7455 2 7.6725 2.755 6 4.0135V4.5V5.5Z" fill="#FF3D00"/>
  </svg>
</template>

<script>
export default {
  name: 'BY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  