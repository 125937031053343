
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M2.003 11.936C2.003 11.9575 2 11.9785 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 11.9785 21.997 11.9575 21.997 11.936H2.003Z" fill="white"/>
<path d="M12 2C6.477 2 2 6.477 2 12H22C22 6.477 17.523 2 12 2Z" fill="#1A237E"/>
<path d="M12 5.5L8.2085 12L12 13.625L15.7915 12L12 5.5Z" fill="white"/>
<path d="M12 18.5L15.7915 12L12 13.625L8.2085 12L12 18.5Z" fill="#1A237E"/>
  </svg>
</template>

<script>
export default {
  name: 'AQ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  