
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 11.9999C22 9.57144 21.1315 7.34844 19.6915 5.61694H4.3085C2.8685 7.34844 2 9.57144 2 11.9999C2 14.4269 2.8655 16.6509 4.3035 18.3829H19.6965C21.1345 16.6509 22 14.4269 22 11.9999Z" fill="#FDD835"/>
<path d="M19.6965 5.617C17.862 3.4075 15.096 2 12 2C8.90397 2 6.13797 3.4075 4.30347 5.617H19.6965Z" fill="#D50000"/>
<path d="M4.30347 18.3831C6.13797 20.5921 8.90397 22.0001 12 22.0001C15.096 22.0001 17.862 20.5921 19.6965 18.3831H4.30347Z" fill="#D50000"/>
<path d="M22 12.0001C22 10.3321 21.5875 8.7626 20.865 7.3811L12 12.3741L20.5395 17.1841C21.46 15.6711 22 13.9006 22 12.0001Z" fill="#388E3C"/>
<path d="M2 12.0001C2 13.9006 2.54 15.6711 3.4605 17.1841L12 12.3741L3.135 7.3811C2.4125 8.7626 2 10.3321 2 12.0001Z" fill="#388E3C"/>
<path d="M12.25 2.99561L12.5075 3.75661L13.311 3.76661L12.667 4.24711L12.906 5.01411L12.25 4.55011L11.594 5.01411L11.833 4.24711L11.189 3.76661L11.9925 3.75661L12.25 2.99561Z" fill="#FDD835"/>
<path d="M15.8864 2.99561L16.1439 3.75661L16.9474 3.76661L16.3034 4.24711L16.5419 5.01411L15.8864 4.55011L15.2304 5.01411L15.4694 4.24711L14.8254 3.76661L15.6284 3.75661L15.8864 2.99561Z" fill="#FDD835"/>
<path d="M8.61349 2.99561L8.87149 3.75661L9.67449 3.76661L9.03049 4.24711L9.26949 5.01411L8.61349 4.55011L7.95799 5.01411L8.19649 4.24711L7.55249 3.76661L8.35599 3.75661L8.61349 2.99561Z" fill="#FDD835"/>
<path d="M12.25 18.782L12.5075 19.543L13.311 19.553L12.667 20.0335L12.906 20.8005L12.25 20.3365L11.594 20.8005L11.833 20.0335L11.189 19.553L11.9925 19.543L12.25 18.782Z" fill="#FDD835"/>
<path d="M15.8864 18.782L16.1439 19.543L16.9474 19.553L16.3034 20.0335L16.5419 20.8005L15.8864 20.3365L15.2304 20.8005L15.4694 20.0335L14.8254 19.553L15.6284 19.543L15.8864 18.782Z" fill="#FDD835"/>
<path d="M8.61349 18.782L8.87149 19.543L9.67449 19.553L9.03049 20.0335L9.26949 20.8005L8.61349 20.3365L7.95799 20.8005L8.19649 20.0335L7.55249 19.553L8.35599 19.543L8.61349 18.782Z" fill="#FDD835"/>
<path d="M12 14.3179C13.2802 14.3179 14.318 13.2801 14.318 11.9999C14.318 10.7197 13.2802 9.68188 12 9.68188C10.7198 9.68188 9.68201 10.7197 9.68201 11.9999C9.68201 13.2801 10.7198 14.3179 12 14.3179Z" fill="#D50000"/>
<path d="M12.014 9.86011L12.5005 11.2971L14.0175 11.3161L12.8015 12.2231L13.2525 13.6716L12.014 12.7951L10.7755 13.6716L11.2265 12.2231L10.0105 11.3161L11.5275 11.2971L12.014 9.86011Z" fill="#FDD835"/>
<path d="M4.99197 13.134C4.99197 13.134 4.63697 12.6015 4.67797 11.7825C5.11497 11.919 5.68847 12.479 5.81097 12.738C5.96097 11.632 5.78347 11.209 5.15547 10.977C4.52747 10.745 3.88597 10.3215 3.88597 10.3215C3.88597 10.3215 3.92947 11.197 3.87247 11.4545C3.71547 12.162 3.99047 13.0985 4.99197 13.134Z" fill="#FDD835"/>
<path d="M5.38749 12.9701C5.12799 12.8881 4.55449 12.7381 4.65049 11.7141C5.59249 12.0831 5.38749 12.7381 5.38749 12.9701Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'GD',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  