
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.6719 14.5H2.32788C3.43938 18.8105 7.34188 22 11.9999 22C16.6579 22 20.5604 18.8105 21.6719 14.5Z" fill="#2196F3"/>
<path d="M11.9999 2C7.34188 2 3.43938 5.1895 2.32788 9.5H21.6724C20.5604 5.1895 16.6579 2 11.9999 2Z" fill="#2196F3"/>
<path d="M21.672 9.5H2.32801C2.24351 9.8275 2.17601 10.1605 2.12451 10.5H21.8755C21.8245 10.1605 21.7565 9.8275 21.672 9.5Z" fill="white"/>
<path d="M21.8755 13.5H2.12451C2.17551 13.8395 2.24351 14.1725 2.32801 14.5H21.6725C21.7565 14.1725 21.8245 13.8395 21.8755 13.5Z" fill="white"/>
<path d="M22 12C22 11.489 21.9495 10.9905 21.8755 10.5H2.1245C2.0505 10.9905 2 11.489 2 12C2 12.511 2.0505 13.0095 2.1245 13.5H21.8755C21.9495 13.0095 22 12.511 22 12Z" fill="#424242"/>
  </svg>
</template>

<script>
export default {
  name: 'BW',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  