
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M20.3475 17.5H3.65247C5.44147 20.2095 8.50997 22 12 22C15.49 22 18.5585 20.2095 20.3475 17.5Z" fill="#D50000"/>
<path d="M12 2C8.50997 2 5.44147 3.7905 3.65247 6.5H20.3475C18.5585 3.7905 15.49 2 12 2Z" fill="#D50000"/>
<path d="M22 12C22 9.967 21.3895 8.0785 20.3475 6.5H3.6525C2.6105 8.0785 2 9.967 2 12C2 14.033 2.6105 15.9215 3.6525 17.5H20.3475C21.3895 15.9215 22 14.033 22 12ZM12 16C9.7915 16 8 14.2085 8 12C8 9.7915 9.7915 8 12 8C14.2085 8 16 9.7915 16 12C16 14.2085 14.2085 16 12 16Z" fill="#3F51B5"/>
<path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'LA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  