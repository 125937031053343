
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.9999 12H14.1779L14.1819 12.0025L4.33789 18.421C6.17239 20.6075 8.92239 22 11.9999 22C17.5229 22 21.9999 17.523 21.9999 12Z" fill="#43A047"/>
<path d="M21.9999 12C21.9999 6.477 17.5229 2 11.9999 2C8.92191 2 6.17141 3.393 4.33691 5.5805L14.1779 12H21.9999Z" fill="#29B6F6"/>
<path d="M14.178 12.0001L4.337 5.58057C2.88 7.31757 2 9.55507 2 12.0001C2 14.4456 2.8805 16.6836 4.338 18.4211L14.182 12.0031L14.178 12.0001Z" fill="white"/>
<path d="M6.90805 12.0684L8.37705 10.9999H6.56055L6.00005 9.27344L5.43955 10.9999H3.62305L5.09155 12.0684L4.53155 13.7949L6.00005 12.7264L7.46905 13.7949L6.90805 12.0684Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'DJ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  