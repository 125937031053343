
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="#64B5F6"/>
<path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#64B5F6"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M11.8689 12.0671L12.9199 14.3026C12.9199 14.3026 12.9199 14.3696 12.9869 14.3026V14.2356L12.1314 11.9336M12.0669 12.7891C12.0669 13.1181 12.2624 13.3156 12.2624 13.6446C12.2624 13.9736 12.3959 14.1046 12.4604 14.2356C12.5274 14.3691 12.3964 14.4336 12.4604 14.4336C12.5274 14.4336 12.5914 14.3696 12.5274 14.1716C12.5274 13.9736 12.3964 13.9736 12.3964 13.5781C12.3964 13.1851 12.2629 13.1181 12.2629 12.7891" fill="#FFC107"/>
<path d="M12.4599 14.5C12.3959 14.5 12.3959 14.433 12.4599 14.433C12.4599 14.369 12.4599 14.369 12.4599 14.302C12.4599 14.302 12.4599 14.235 12.3959 14.171C12.3289 14.04 12.2624 13.9065 12.2624 13.6445C12.2624 13.5135 12.2624 13.3825 12.1984 13.249C12.1314 13.118 12.1314 12.987 12.1314 12.789H12.1984C12.1984 12.987 12.2624 13.118 12.2624 13.1845C12.3294 13.3155 12.3294 13.4465 12.3294 13.5775C12.3294 13.7755 12.3964 13.973 12.4604 14.04C12.4604 14.104 12.5274 14.104 12.5274 14.171C12.4599 14.302 12.4599 14.369 12.4599 14.5C12.5269 14.433 12.5269 14.369 12.5269 14.2355C12.5269 14.1715 12.5269 14.1715 12.4599 14.1045C12.3959 14.0405 12.3959 13.9065 12.3959 13.6445C12.3959 13.4465 12.3959 13.3825 12.3289 13.249C12.3289 13.185 12.2619 13.0535 12.2619 12.92L11.8689 12.067H11.9329L12.2619 12.856H12.3289V12.92L12.9869 14.3025L12.1309 12H12.1979L13.0534 14.302V14.369C13.0534 14.369 12.9864 14.369 12.9864 14.302L12.3954 12.9865C12.3954 13.0535 12.3954 13.1175 12.4594 13.1845C12.5264 13.3155 12.5264 13.3825 12.5264 13.5775C12.5264 13.842 12.5264 13.9065 12.5904 14.04C12.5904 14.104 12.6574 14.104 12.6574 14.171C12.5264 14.302 12.5264 14.433 12.4599 14.5Z" fill="#BF360C"/>
<path d="M11.8689 12L11.9329 14.433L11.9999 12M11.7379 12.789C11.6044 13.118 11.7379 13.3825 11.6044 13.6445C11.4734 13.9065 11.5404 14.1045 11.5404 14.2355C11.5404 14.369 11.4094 14.369 11.4734 14.4335C11.5404 14.5005 11.6044 14.4335 11.6714 14.2355C11.7384 14.04 11.6044 13.9735 11.8024 13.6445C12.0004 13.3155 11.8694 13.1845 12.0004 12.8555" fill="#FFC107"/>
<path d="M11.9999 14.5C11.9329 14.5 11.9329 14.433 11.9329 14.433L11.8689 13.053C11.8689 13.117 11.8689 13.184 11.8689 13.2485C11.8689 13.382 11.8689 13.513 11.7379 13.644C11.6044 13.842 11.6044 13.973 11.6709 14.0395C11.6709 14.1035 11.6709 14.1705 11.6709 14.1705C11.6709 14.3015 11.5399 14.3685 11.5399 14.3685H11.4729V14.3015C11.4729 14.3015 11.5399 14.2345 11.5399 14.1705C11.5399 14.1705 11.5399 14.1035 11.5399 14.0395C11.5399 13.906 11.4729 13.7105 11.6039 13.513C11.6709 13.382 11.6709 13.2485 11.6709 13.1175C11.6709 12.9865 11.6709 12.8555 11.7379 12.6575H11.8019C11.7379 12.7885 11.7379 12.9195 11.7379 13.053C11.7379 13.184 11.7379 13.315 11.6709 13.446C11.5399 13.644 11.6039 13.8415 11.6039 13.906C11.6039 13.973 11.6039 13.973 11.6039 14.0395C11.6039 14.1035 11.5399 14.1705 11.5399 14.1705C11.5399 14.1705 11.6039 14.1035 11.6709 13.9725C11.6709 13.9055 11.6709 13.9055 11.6709 13.8415C11.6709 13.7105 11.6709 13.6435 11.8019 13.3815C11.8689 13.248 11.8689 13.117 11.9329 12.986C11.9329 12.919 11.9329 12.788 11.9999 12.657L11.8689 12H11.9329V12.8555H11.9999C11.9999 12.8555 11.9999 12.9195 11.9329 12.9195L11.9999 14.433V14.5C11.9999 14.433 11.9999 14.433 11.9999 14.433L12.0669 12H12.1309L11.9999 14.5C12.0669 14.5 12.0669 14.5 11.9999 14.5Z" fill="#BF360C"/>
<path d="M11.8689 11.9331L11.0134 14.2351V14.3021H11.0804L12.1314 12.0666M11.4734 12.6581C11.2114 12.8561 11.2114 13.1846 11.0134 13.3826C10.8154 13.5781 10.7514 13.8426 10.7514 13.9066C10.7514 13.9736 10.6179 14.0401 10.6179 14.0401C10.6179 14.1041 10.7514 14.0401 10.8824 13.9066C11.0134 13.7756 10.9464 13.6446 11.2114 13.3826C11.4734 13.1181 11.4094 12.9201 11.6714 12.7246" fill="#FFC107"/>
<path d="M11.0801 14.3021C11.0131 14.3021 11.0131 14.2351 11.0131 14.2351L11.4731 12.9196C11.4091 12.9866 11.4091 13.0531 11.3421 13.1176C11.2751 13.2486 11.2111 13.3156 11.0801 13.4466C10.8821 13.5776 10.8821 13.7111 10.8156 13.7756C10.8156 13.8426 10.7516 13.8426 10.7516 13.9066C10.6846 13.9736 10.6181 14.0401 10.5536 14.0401H10.4866C10.4866 14.0401 10.4866 13.9731 10.5536 13.9731C10.5536 13.9731 10.6176 13.9061 10.6846 13.9061C10.6846 13.9061 10.6846 13.8421 10.7516 13.7751C10.8156 13.6441 10.8156 13.5131 11.0136 13.3151C11.1446 13.2481 11.2116 13.1171 11.2116 12.9861C11.2756 12.8551 11.3426 12.7241 11.4736 12.5906C11.3426 12.7241 11.2756 12.8551 11.2116 12.9196C11.1446 13.0531 11.0806 13.1841 10.9471 13.2486C10.7516 13.3821 10.7516 13.5776 10.6851 13.6441C10.6851 13.7111 10.6851 13.7111 10.6181 13.7751C10.6851 13.9731 10.6181 13.9731 10.6181 14.0396C10.6851 14.0396 10.7516 14.0396 10.8161 13.9061C10.8831 13.8421 10.8831 13.8421 10.8831 13.7751C10.9471 13.7111 10.9471 13.5771 11.1451 13.3821C11.2761 13.2486 11.3431 13.1841 11.4096 13.0531C11.4736 12.9861 11.4736 12.8551 11.6051 12.7886L11.9341 11.9331H12.0001L11.6711 12.7246C11.6711 12.7246 11.6041 12.7246 11.6041 12.7886L11.0801 14.2351C11.0801 14.2356 11.0136 14.3021 11.0801 14.3021L12.1311 12.0001H12.1981L11.0801 14.3021Z" fill="#BF360C"/>
<path d="M11.933 11.9331L10.2885 13.7111V13.7751H10.3555L12.131 12.1306M11.2755 12.3956C10.9465 12.5266 10.8825 12.7886 10.5535 12.8556C10.289 12.9196 10.158 13.1846 10.0935 13.2486C10.0265 13.3156 9.89551 13.3156 9.89551 13.3156C9.89551 13.3156 10.0265 13.3826 10.1575 13.3156C10.2885 13.2486 10.2885 13.1176 10.684 12.9866C11.013 12.8556 11.0795 12.6576 11.4085 12.5911" fill="#FFC107"/>
<path d="M10.2245 13.7755C10.1575 13.7115 10.1575 13.7115 10.2245 13.7115L11.211 12.658C11.144 12.725 11.08 12.725 11.08 12.789C11.013 12.856 10.882 12.92 10.751 12.987C10.553 13.054 10.422 13.118 10.3555 13.185C10.2885 13.249 10.2885 13.249 10.2245 13.316C10.0935 13.383 10.0265 13.383 9.95996 13.383C9.95996 13.383 9.95996 13.383 9.95996 13.316C9.95996 13.249 10.027 13.249 10.027 13.249C10.094 13.249 10.094 13.249 10.158 13.185L10.225 13.118C10.289 12.987 10.423 12.856 10.618 12.789C10.7515 12.725 10.8825 12.658 10.947 12.591C11.0805 12.527 11.145 12.3955 11.3425 12.329V12.396C11.2115 12.46 11.0805 12.527 11.0135 12.658C10.8825 12.725 10.8155 12.856 10.6845 12.92C10.4865 12.987 10.3555 13.118 10.289 13.249C10.289 13.316 10.225 13.316 10.225 13.316C10.027 13.316 9.95996 13.316 9.95996 13.316C9.95996 13.316 10.094 13.316 10.1585 13.249C10.2255 13.249 10.2255 13.185 10.2895 13.118C10.3565 13.054 10.423 12.987 10.685 12.856C10.883 12.789 10.9465 12.725 11.0135 12.658C11.0805 12.591 11.145 12.527 11.276 12.46L11.934 11.802L11.343 12.46V12.527H11.276L10.225 13.6445C10.2245 13.711 10.2245 13.7755 10.2245 13.7755Z" fill="#BF360C"/>
<path d="M12.1311 12.8555C12.1311 13.1845 12.3291 13.3155 12.3291 13.6445C12.3956 13.3825 12.1981 13.2485 12.1311 12.8555ZM11.9331 12L12.6576 13.5135L12.0671 12" fill="#BF360C"/>
<path d="M11.8019 12.8555C11.6709 13.1845 11.8019 13.382 11.6709 13.6445C11.8019 13.4465 11.7379 13.1845 11.8019 12.8555ZM11.9329 12L11.9999 13.711L12.0669 12" fill="#BF360C"/>
<path d="M11.4736 12.7245C11.2756 12.9865 11.2756 13.1845 11.0806 13.3825C11.2756 13.2485 11.2756 12.9865 11.4736 12.7245ZM11.9331 12L11.3421 13.5775L12.0666 12.0665" fill="#BF360C"/>
<path d="M11.2754 12.4601C11.0134 12.5911 10.9464 12.8556 10.6174 12.9201C10.8824 12.9201 10.9464 12.6581 11.2754 12.4601ZM11.9329 11.9331L10.8154 13.1846L12.0669 12.0671" fill="#BF360C"/>
<path d="M11.9329 11.8689L9.69741 12.9199C9.69741 12.9199 9.63041 12.9199 9.69741 12.9869H9.76441L12.0664 12.1314M11.2109 12.0669C10.8819 12.0669 10.6844 12.2624 10.3554 12.2624C10.0264 12.2624 9.89541 12.3959 9.76441 12.4604C9.63091 12.5274 9.56641 12.3964 9.56641 12.4604C9.56641 12.5274 9.63041 12.5914 9.82841 12.5274C10.0264 12.5274 10.0264 12.3964 10.4219 12.3964C10.8149 12.3964 10.8819 12.2629 11.2109 12.2629" fill="#FFC107"/>
<path d="M9.69789 12.9864C9.63089 12.9194 9.63089 12.9194 9.69789 12.8554L11.0134 12.2619C10.9464 12.2619 10.8824 12.2619 10.8154 12.3289C10.6844 12.3959 10.6174 12.3959 10.4224 12.3959C10.1579 12.3959 10.0934 12.3959 9.95989 12.4599C9.89589 12.4599 9.89589 12.5269 9.82889 12.5269C9.69789 12.5269 9.63089 12.5269 9.56689 12.4599V12.3959H9.63089C9.69789 12.3959 9.69789 12.3959 9.76439 12.3959C9.76439 12.3959 9.82839 12.3959 9.89539 12.3289C10.0264 12.2619 10.1574 12.1979 10.2884 12.1979H10.3554C10.4864 12.1979 10.6174 12.1979 10.7509 12.1309C10.8819 12.0669 11.0129 12.0669 11.2109 12.0669V12.1309C11.0799 12.1309 10.9464 12.1979 10.8154 12.1979C10.6844 12.2619 10.5534 12.2619 10.4224 12.2619C10.2244 12.2619 10.0269 12.3289 9.95989 12.3954C9.89589 12.3954 9.89589 12.4594 9.82889 12.4594C9.76489 12.4594 9.69789 12.4594 9.63089 12.4594C9.63089 12.4594 9.76439 12.5264 9.82889 12.5264C9.89589 12.5264 9.89589 12.5269 9.95989 12.4599C10.0269 12.3959 10.1579 12.3959 10.4224 12.3959C10.6179 12.3959 10.6844 12.3954 10.8154 12.3284C10.8824 12.3284 11.0134 12.2614 11.1444 12.2614L11.9999 11.8689V11.9329L11.2109 12.2619V12.3289H11.1439L9.69789 12.9864C9.69789 12.9199 9.69789 12.9199 9.69789 12.9864L11.9999 12.0669V12.1309L9.69789 12.9864Z" fill="#BF360C"/>
<path d="M12 11.8689L9.567 11.9999L12 12.0669M11.211 11.7379C10.882 11.6044 10.6175 11.7379 10.3555 11.6044C10.0935 11.4734 9.8955 11.5404 9.7645 11.5404C9.631 11.5404 9.631 11.4094 9.5665 11.4734C9.4995 11.5404 9.5665 11.6044 9.7645 11.6714C9.96 11.7384 10.0265 11.6044 10.3555 11.8024C10.6845 12.0004 10.8155 11.8694 11.1445 12.0004" fill="#FFC107"/>
<path d="M12 12.1309L9.567 12.0669C9.567 12.0669 9.5 12.0669 9.5 11.9999C9.5 11.9329 9.567 11.9329 9.567 11.9329L10.947 11.8689C10.883 11.8689 10.816 11.8689 10.7515 11.8689C10.618 11.8689 10.487 11.8689 10.356 11.7379C10.158 11.6044 10.027 11.6044 9.9605 11.6709C9.8965 11.6709 9.8295 11.6709 9.8295 11.6709C9.6985 11.6709 9.6315 11.5399 9.6315 11.5399V11.4729H9.6985C9.6985 11.4729 9.7655 11.5399 9.8295 11.5399C9.8295 11.5399 9.8965 11.5399 9.9605 11.5399C10.094 11.5399 10.2895 11.4729 10.487 11.6039C10.618 11.6709 10.7515 11.6709 10.8825 11.6709C11.0135 11.6709 11.1445 11.6709 11.3425 11.7379V11.8019C11.2115 11.7379 11.0805 11.7379 10.947 11.7379C10.816 11.7379 10.685 11.7379 10.554 11.6709C10.356 11.5399 10.225 11.6039 10.094 11.6039C10.027 11.6039 10.027 11.6039 9.9605 11.6039C9.8965 11.6039 9.8295 11.5399 9.8295 11.5399C9.8295 11.5399 9.8965 11.6039 10.0275 11.6709C10.0945 11.6709 10.1585 11.6709 10.1585 11.6709C10.2895 11.6709 10.3565 11.6709 10.6185 11.8019C10.752 11.8689 10.883 11.8689 11.014 11.9329C11.081 11.9329 11.212 11.9329 11.343 11.9999L12.263 11.9329V11.9999H11.41V12.0669C11.41 12.0669 11.343 12.0669 11.343 11.9999H9.5675L12 12.1309Z" fill="#BF360C"/>
<path d="M12.067 11.8689L9.765 11.0134H9.698V11.0804L11.9335 12.1314M11.342 11.4734C11.144 11.2114 10.8155 11.2114 10.6175 11.0134C10.422 10.8154 10.1575 10.7514 10.0935 10.7514C10.0265 10.7514 9.96 10.6179 9.96 10.6179C9.896 10.6179 9.96 10.7514 10.0935 10.8824C10.2245 11.0134 10.3555 10.9464 10.6175 11.2114C10.882 11.4734 11.08 11.4094 11.2755 11.6714" fill="#FFC107"/>
<path d="M11.9331 12.1311L9.69762 11.0801L9.63062 11.0131C9.63062 11.0131 9.69762 10.9461 9.69762 11.0131L11.0131 11.4731C10.9461 11.4091 10.8821 11.4091 10.8151 11.3421C10.6841 11.2751 10.6171 11.2111 10.4861 11.0801C10.3551 10.8821 10.2241 10.8821 10.1571 10.8156C10.0931 10.8156 10.0931 10.7516 10.0261 10.7516C9.89512 10.6846 9.89512 10.5536 9.89512 10.5536V10.4866C9.89512 10.4866 9.95912 10.4866 9.95912 10.5536C9.95912 10.5536 10.0261 10.6176 10.0261 10.6846C10.0261 10.6846 10.0931 10.6846 10.1571 10.7516C10.2881 10.8156 10.4216 10.8156 10.6171 11.0136C10.6841 11.1446 10.8151 11.2116 10.9461 11.2116C11.0796 11.2756 11.2106 11.3426 11.3416 11.4736C11.2106 11.3426 11.0796 11.2756 11.0126 11.2116C10.8816 11.1446 10.7506 11.0806 10.6836 10.9471C10.5526 10.7516 10.3546 10.7516 10.2881 10.6851C10.2241 10.6851 10.2241 10.6851 10.1571 10.6181C10.0261 10.6851 10.0261 10.6181 9.95912 10.6181C9.95912 10.6851 9.95912 10.7516 10.0926 10.8161C10.1566 10.8831 10.1566 10.8831 10.2236 10.8831C10.2876 10.9471 10.4216 10.9471 10.6166 11.1451C10.7501 11.2761 10.8146 11.3431 10.9456 11.4096C11.0126 11.4736 11.1436 11.4736 11.2101 11.6051L12.0656 11.9341V12.0001L11.2741 11.6711C11.2741 11.6711 11.2741 11.6041 11.2101 11.6041L9.76362 11.0801L11.9331 12.1311Z" fill="#BF360C"/>
<path d="M12.0671 11.933L10.2891 10.2885H10.2251V10.3555L11.8696 12.131M11.6046 11.2755C11.4736 10.9465 11.2116 10.8825 11.1446 10.5535C11.0806 10.289 10.8156 10.158 10.7516 10.0935C10.6846 10.0265 10.6846 9.89551 10.6846 9.89551C10.6846 9.89551 10.6176 10.0265 10.6846 10.1575C10.7516 10.2885 10.8826 10.2885 11.0136 10.684C11.1446 11.013 11.3426 11.0795 11.4091 11.4085" fill="#FFC107"/>
<path d="M11.8691 12.131L10.2246 10.3555C10.2246 10.3555 10.2246 10.3555 10.2246 10.2885C10.2246 10.2885 10.2246 10.2885 10.2246 10.2245H10.2886L11.3421 11.211C11.2751 11.144 11.2751 11.08 11.2111 11.08C11.1441 11.013 11.0801 10.882 11.0131 10.751C10.9461 10.553 10.8821 10.422 10.8151 10.3555C10.7511 10.2885 10.7511 10.2885 10.6841 10.2245C10.6171 10.0935 10.6171 10.0265 10.6171 9.95996C10.6171 9.95996 10.6171 9.95996 10.6841 9.95996C10.7511 9.95996 10.7511 10.027 10.7511 10.027C10.7511 10.094 10.7511 10.094 10.8151 10.158L10.8821 10.225C11.0131 10.289 11.1441 10.423 11.2111 10.618C11.2751 10.7515 11.3421 10.8825 11.4091 10.947C11.4731 11.0805 11.6046 11.145 11.6711 11.3425H11.6041C11.5401 11.2115 11.4731 11.0805 11.3421 11.0135C11.2751 10.8825 11.1441 10.8155 11.0801 10.6845C11.0131 10.4865 10.8821 10.3555 10.7511 10.289C10.6841 10.289 10.6841 10.225 10.6841 10.225C10.6841 10.027 10.6841 9.95996 10.6841 9.95996C10.6841 9.95996 10.6841 10.094 10.7511 10.1585C10.7511 10.2255 10.8151 10.2255 10.8821 10.2895C10.9461 10.3565 11.0131 10.423 11.1441 10.685C11.2111 10.883 11.2751 10.9465 11.3421 11.0135C11.4091 11.0805 11.4731 11.145 11.5401 11.276L12.1981 11.934L11.5401 11.343H11.4731V11.276L10.3556 10.225L11.8691 12.131Z" fill="#BF360C"/>
<path d="M11.1445 12.1311C10.8155 12.1311 10.6845 12.3291 10.3555 12.3291C10.6175 12.3956 10.7515 12.1981 11.1445 12.1311ZM12 11.9331L10.4225 12.6576L12 12.0671" fill="#BF360C"/>
<path d="M11.1446 11.8019C10.8156 11.6709 10.6181 11.8019 10.3556 11.6709C10.5536 11.8019 10.8156 11.7379 11.1446 11.8019ZM12.0001 11.9329L10.2891 11.9999L12.0001 12.0669" fill="#BF360C"/>
<path d="M11.2756 11.4736C11.0136 11.2756 10.8156 11.2756 10.6176 11.0806C10.7516 11.2756 11.0136 11.2756 11.2756 11.4736ZM12.0001 11.9331L10.4226 11.3421L11.9336 12.0666" fill="#BF360C"/>
<path d="M11.5399 11.2754C11.4089 11.0134 11.1444 10.9464 11.0799 10.6174C11.0799 10.8824 11.3419 10.9464 11.5399 11.2754ZM12.0669 11.9329L10.8154 10.8154L11.9329 12.0669" fill="#BF360C"/>
<path d="M12.1309 11.9329L11.0799 9.69741C11.0799 9.69741 11.0799 9.63041 11.0129 9.69741V9.76441L11.8684 12.0664M11.9329 11.2109C11.9329 10.8819 11.7374 10.6844 11.7374 10.3554C11.7374 10.0264 11.6039 9.89541 11.5394 9.76441C11.4724 9.63091 11.6034 9.56641 11.5394 9.56641C11.4724 9.56641 11.4084 9.63041 11.4724 9.82841C11.4724 10.0264 11.6034 10.0264 11.6034 10.4219C11.6034 10.8149 11.7369 10.8819 11.7369 11.2109" fill="#FFC107"/>
<path d="M11.869 12.0671L11.0135 9.76509V9.69809C11.0135 9.69809 11.0805 9.69809 11.0805 9.76509L11.6715 11.0806C11.6715 11.0136 11.6715 10.9471 11.6045 10.8826C11.5405 10.7516 11.5405 10.6846 11.5405 10.4871C11.5405 10.2251 11.5405 10.1581 11.4735 10.0271C11.4735 9.96009 11.4095 9.96009 11.4095 9.89609C11.4095 9.76509 11.4095 9.69809 11.4735 9.63159H11.5405V9.69859C11.5405 9.76559 11.5405 9.76559 11.5405 9.82959C11.5405 9.82959 11.5405 9.89659 11.6045 9.96059C11.6715 10.0941 11.738 10.2251 11.738 10.4871C11.738 10.6181 11.738 10.7516 11.802 10.8826C11.869 11.0136 11.869 11.1446 11.869 11.3426H11.802C11.802 11.1446 11.738 11.0136 11.738 10.9471C11.671 10.8161 11.671 10.6851 11.671 10.5541C11.671 10.3561 11.604 10.1586 11.54 10.0941C11.54 10.0271 11.473 10.0271 11.473 9.96059C11.473 9.89659 11.473 9.82959 11.473 9.76509C11.473 9.76509 11.409 9.89609 11.409 9.96059C11.409 10.0276 11.409 10.0276 11.473 10.0941C11.54 10.1581 11.54 10.2896 11.54 10.5541C11.54 10.7521 11.54 10.8161 11.604 10.9471C11.604 11.0141 11.671 11.1451 11.671 11.2761L12.0665 12.1316H12L11.671 11.3426H11.604V11.2756L10.946 9.89559L11.869 12.0671Z" fill="#BF360C"/>
<path d="M12.1309 12L11.9999 9.567L11.8689 12M12.2619 11.211C12.3954 10.882 12.2619 10.6175 12.3954 10.3555C12.5264 10.0935 12.4594 9.8955 12.4594 9.7645C12.4594 9.631 12.5904 9.631 12.5264 9.5665C12.4594 9.4995 12.3954 9.5665 12.3284 9.7645C12.2614 9.96 12.3954 10.0265 12.1974 10.3555C11.9994 10.6845 12.1304 10.8155 11.9994 11.1445" fill="#FFC107"/>
<path d="M12.131 12V11.1445H12.067C12.067 11.1445 12.067 11.0805 12.131 11.0805L12 9.567L11.869 12H11.802L11.869 9.567C11.869 9.567 11.869 9.5 11.933 9.5C12 9.5 12 9.567 12 9.567L12.067 10.947C12.067 10.883 12.067 10.816 12.067 10.7515C12.067 10.618 12.067 10.487 12.198 10.356C12.329 10.158 12.329 10.027 12.262 9.9605C12.262 9.8965 12.262 9.8295 12.262 9.8295C12.262 9.6985 12.3955 9.6315 12.3955 9.6315H12.4595V9.6985C12.4595 9.6985 12.3955 9.7655 12.3955 9.8295C12.3955 9.8295 12.3955 9.8965 12.3955 9.9605C12.3955 10.094 12.4595 10.2895 12.3285 10.487C12.2615 10.618 12.2615 10.7515 12.2615 10.8825C12.2615 11.0135 12.2615 11.1445 12.1975 11.3425H12.1305C12.1975 11.2115 12.1975 11.0805 12.1975 10.947C12.1975 10.816 12.1975 10.685 12.2615 10.554C12.395 10.356 12.3285 10.1585 12.3285 10.094C12.3285 10.027 12.3285 10.027 12.3285 9.9605C12.3285 9.8965 12.3955 9.8295 12.3955 9.8295C12.3955 9.8295 12.3285 9.8965 12.262 10.0275C12.262 10.0945 12.262 10.0945 12.262 10.1585C12.262 10.2895 12.262 10.3565 12.131 10.6185C12.067 10.752 12.067 10.883 12 11.014C12 11.081 12 11.212 11.933 11.343L12.131 12Z" fill="#BF360C"/>
<path d="M12.1309 12.067L12.9864 9.765V9.698H12.9199L11.8689 11.9335M12.5264 11.342C12.7884 11.144 12.7884 10.8155 12.9864 10.6175C13.1844 10.422 13.2484 10.1575 13.2484 10.0935C13.2484 10.0265 13.3819 9.96 13.3819 9.96C13.3819 9.896 13.2484 9.96 13.1174 10.0935C12.9864 10.2245 13.0534 10.3555 12.7884 10.6175C12.5264 10.882 12.5904 11.08 12.3284 11.2755" fill="#FFC107"/>
<path d="M12.1309 12.0671L12.3954 11.2116C12.3954 11.2116 12.4594 11.2116 12.4594 11.1446L12.9859 9.6981L11.9324 11.9336H11.8684L12.9194 9.6981L12.9864 9.6311C12.9864 9.6311 13.0529 9.6981 12.9859 9.6981L12.5264 11.0136C12.5904 10.9466 12.5904 10.8826 12.6574 10.8156C12.7244 10.6846 12.7884 10.6176 12.9194 10.4866C13.1174 10.3556 13.1174 10.2246 13.1839 10.1576C13.1839 10.0936 13.2479 10.0936 13.2479 10.0266C13.3149 9.8956 13.4459 9.8956 13.4459 9.8956H13.5129C13.5129 9.8956 13.5129 9.9596 13.4459 9.9596C13.4459 9.9596 13.3819 10.0266 13.3149 10.0266C13.3149 10.0266 13.3149 10.0936 13.2479 10.1576C13.1839 10.2886 13.1839 10.4221 12.9859 10.6176C12.8549 10.6846 12.7879 10.8156 12.7879 10.9466C12.7239 11.0801 12.6569 11.2111 12.5259 11.3421C12.6569 11.2111 12.7239 11.0801 12.7879 11.0131C12.8549 10.8821 12.9189 10.7511 13.0524 10.6841C13.2479 10.5531 13.2479 10.3551 13.3144 10.2886C13.3144 10.2246 13.3144 10.2246 13.3814 10.1576C13.4454 10.0936 13.4454 10.0936 13.5124 10.0266C13.4454 10.0266 13.3814 10.0266 13.3144 10.1576C13.2474 10.2246 13.2474 10.2246 13.2474 10.2886C13.1834 10.3556 13.1834 10.4866 12.9854 10.6841C12.7234 10.6841 12.6564 10.8151 12.6564 10.9461C12.5894 11.0131 12.5894 11.1441 12.4584 11.2106L12.1309 12.0671Z" fill="#BF360C"/>
<path d="M12.0669 12.0671L13.7114 10.2891V10.2251H13.6444L11.8689 11.8696M12.7244 11.6046C13.0534 11.4736 13.1174 11.2116 13.4464 11.1446C13.7109 11.0806 13.8419 10.8156 13.9064 10.7516C13.9734 10.6846 14.1044 10.6846 14.1044 10.6846C14.1044 10.6846 13.9734 10.6176 13.8424 10.6846C13.7114 10.7516 13.7114 10.8826 13.3159 11.0136C12.9869 11.1446 12.9204 11.3426 12.5914 11.4091" fill="#FFC107"/>
<path d="M12.131 12.1311L12.6575 11.4731V11.4091H12.7245L13.7755 10.2891C13.7755 10.2891 13.7755 10.2886 13.8425 10.2886V10.3556L12.789 11.3421C12.856 11.2751 12.92 11.2751 12.92 11.2111C12.987 11.1441 13.118 11.0801 13.249 11.0131C13.447 10.9461 13.578 10.8821 13.6445 10.8151C13.7115 10.7511 13.7115 10.7511 13.7755 10.6841C13.9065 10.6171 13.9735 10.6171 14.04 10.6171C14.04 10.6171 14.04 10.6171 14.04 10.6841C14.04 10.7511 13.973 10.7511 13.973 10.7511C13.906 10.7511 13.906 10.7511 13.842 10.8151L13.775 10.8821C13.711 11.0131 13.577 11.1441 13.382 11.2111C13.2485 11.2751 13.1175 11.3421 13.053 11.4091C12.9195 11.4731 12.855 11.6046 12.6575 11.6711V11.6041C12.7885 11.5401 12.9195 11.4731 12.9865 11.3421C13.1175 11.2751 13.1845 11.1441 13.3155 11.0801C13.5135 11.0131 13.6445 10.8821 13.711 10.8156C13.711 10.7516 13.775 10.7516 13.775 10.7516C13.973 10.6846 14.0395 10.6846 14.0395 10.6846C14.0395 10.6846 13.906 10.6846 13.8415 10.7516C13.7745 10.7516 13.7745 10.8156 13.7105 10.8826C13.6435 10.9466 13.577 11.0136 13.315 11.1446C13.117 11.2116 13.053 11.2756 12.986 11.3426C12.919 11.4096 12.855 11.4736 12.724 11.5406L12.131 12.1311Z" fill="#BF360C"/>
<path d="M11.869 11.1445C11.869 10.8155 11.671 10.6845 11.671 10.3555C11.6045 10.6175 11.802 10.7515 11.869 11.1445ZM12.067 12L11.3425 10.4225L11.933 12" fill="#BF360C"/>
<path d="M12.1981 11.1446C12.3291 10.8156 12.1981 10.6181 12.3291 10.3556C12.1981 10.5536 12.2621 10.8156 12.1981 11.1446ZM12.0671 12.0001L12.0001 10.2891L11.9331 12.0001" fill="#BF360C"/>
<path d="M12.5266 11.2756C12.7246 11.0136 12.7246 10.8156 12.9196 10.6176C12.7246 10.7516 12.7246 11.0136 12.5266 11.2756ZM12.0671 12.0001L12.6581 10.4226L11.9331 12.0001" fill="#BF360C"/>
<path d="M12.7246 11.5399C12.9866 11.4089 13.0536 11.1444 13.3826 11.0799C13.1846 11.0799 13.0536 11.3419 12.7246 11.5399ZM12.0671 12.0669L13.1846 10.8154L11.9331 11.9329" fill="#BF360C"/>
<path d="M12.0671 12.1309L14.3026 11.0799C14.3026 11.0799 14.3696 11.0799 14.3026 11.0129H14.2356L11.9336 11.8684M12.7891 11.9329C13.1181 11.9329 13.3156 11.7374 13.6446 11.7374C13.9736 11.7374 14.1046 11.6039 14.2356 11.5394C14.3691 11.4724 14.4336 11.6034 14.4336 11.5394C14.4336 11.4724 14.3696 11.4084 14.1716 11.4724C13.9736 11.4724 13.9736 11.6034 13.5781 11.6034C13.1851 11.6034 13.1181 11.7369 12.7891 11.7369" fill="#FFC107"/>
<path d="M12.067 12.131L12.856 11.738V11.671H12.92L14.3025 11.013L12 11.869V11.802L14.302 10.9465H14.369C14.369 11.0135 14.369 11.0135 14.302 11.0135L12.9865 11.6045C13.0535 11.6045 13.1175 11.6045 13.1845 11.5405C13.3155 11.4735 13.3825 11.4735 13.5775 11.4735C13.6445 11.4735 13.6445 11.4735 13.711 11.4735C13.9065 11.4735 13.973 11.4095 14.04 11.4095C14.104 11.4095 14.104 11.3425 14.171 11.3425C14.302 11.3425 14.369 11.3425 14.433 11.4095V11.4735H14.369C14.302 11.4735 14.302 11.4735 14.2355 11.4735C14.2355 11.4735 14.1715 11.4735 14.1045 11.5405C13.9735 11.6045 13.8425 11.6715 13.7115 11.6715H13.6445H13.5775C13.4465 11.6715 13.382 11.7385 13.2485 11.7385C13.1175 11.8025 12.9865 11.8025 12.8555 11.8025V11.7385C12.9865 11.7385 13.1175 11.6715 13.2485 11.6715C13.382 11.6045 13.513 11.6045 13.644 11.6045C13.842 11.6045 14.0395 11.5405 14.104 11.4735C14.171 11.4735 14.171 11.4095 14.235 11.4095C14.302 11.4095 14.3685 11.4095 14.433 11.4095C14.433 11.4095 14.302 11.3425 14.235 11.3425C14.171 11.3425 14.171 11.3425 14.104 11.4095C14.04 11.4735 13.906 11.4735 13.644 11.4735C13.446 11.4735 13.382 11.4735 13.2485 11.5405C13.1845 11.5405 13.053 11.6045 12.9865 11.6045L12.067 12.131Z" fill="#BF360C"/>
<path d="M12 12.1309L14.433 12.0669L12 11.8689M12.789 12.2619C13.118 12.3954 13.3825 12.2619 13.6445 12.3954C13.9065 12.5264 14.1045 12.4594 14.2355 12.4594C14.369 12.4594 14.369 12.5904 14.4335 12.5264C14.5005 12.4594 14.4335 12.3954 14.2355 12.3284C14.04 12.2614 13.9735 12.3954 13.6445 12.1974C13.3155 11.9994 13.1845 12.1304 12.8555 11.9994" fill="#FFC107"/>
<path d="M14.433 12.591C14.369 12.591 14.369 12.5265 14.433 12.591C14.369 12.527 14.302 12.527 14.235 12.527C14.235 12.527 14.171 12.527 14.104 12.527C13.973 12.527 13.775 12.591 13.5775 12.46C13.4465 12.396 13.3155 12.396 13.1845 12.396C13.0535 12.396 12.92 12.396 12.7245 12.329V12.262C12.8555 12.329 12.9865 12.329 13.1175 12.329C13.2485 12.329 13.382 12.329 13.513 12.396C13.711 12.527 13.842 12.46 13.973 12.46C14.04 12.46 14.04 12.46 14.104 12.46C14.302 12.46 14.369 12.46 14.433 12.591C14.433 12.5265 14.369 12.5265 14.433 12.591C14.433 12.5265 14.433 12.5265 14.433 12.591C14.433 12.527 14.369 12.46 14.235 12.3955C14.171 12.3955 14.171 12.3955 14.104 12.3955C13.973 12.3955 13.906 12.3955 13.644 12.262C13.513 12.198 13.382 12.198 13.2485 12.131C13.1845 12.131 13.053 12.131 12.9195 12.067L12 12.131V12.067H12.8555V12C12.8555 12 12.9195 12 12.9195 12.067L14.433 12L12 11.869V11.802L14.433 11.869C14.433 11.869 14.5 11.869 14.5 11.933C14.5 12 14.433 12 14.433 12L13.053 12.067C13.117 12.067 13.184 12.067 13.2485 12.067C13.382 12.067 13.513 12.067 13.644 12.198C13.842 12.329 13.973 12.329 14.0395 12.262C14.1035 12.262 14.1705 12.262 14.1705 12.262C14.369 12.3955 14.433 12.46 14.433 12.591C14.433 12.5265 14.433 12.5265 14.433 12.591Z" fill="#BF360C"/>
<path d="M11.9331 12.1309L14.2351 12.9864H14.3021V12.9199L12.0666 11.8689M12.6581 12.5264C12.8561 12.7884 13.1846 12.7884 13.3826 12.9864C13.5781 13.1844 13.8426 13.2484 13.9066 13.2484C13.9736 13.2484 14.0401 13.3819 14.0401 13.3819C14.1041 13.3819 14.0401 13.2484 13.9066 13.1174C13.7756 12.9864 13.6446 13.0534 13.3826 12.7884C13.1181 12.5264 12.9201 12.5904 12.7246 12.3284" fill="#FFC107"/>
<path d="M14.0401 13.4464C13.9736 13.4464 13.9736 13.4464 14.0401 13.4464C13.9731 13.3824 13.9731 13.3154 13.9066 13.3154C13.9066 13.3154 13.8426 13.3154 13.7756 13.2484C13.6446 13.1844 13.5136 13.1844 13.3156 12.9864C13.2486 12.8554 13.1176 12.7884 12.9866 12.7884C12.8556 12.7244 12.7246 12.6574 12.5911 12.5264C12.7246 12.6574 12.8556 12.7244 12.9201 12.7884C13.0536 12.8554 13.1846 12.9194 13.2491 13.0529C13.3826 13.2484 13.5781 13.2484 13.6446 13.3149C13.7116 13.3149 13.7116 13.3149 13.7756 13.3819C13.9736 13.3154 13.9736 13.3824 14.0401 13.4464C14.0401 13.3824 14.0401 13.2484 13.9066 13.1844C13.8426 13.1174 13.8426 13.1174 13.7756 13.1174C13.7116 13.0534 13.5776 13.0534 13.3826 12.8554C13.3156 12.7244 13.1846 12.6574 13.0536 12.6574C12.9866 12.5904 12.8556 12.5904 12.7891 12.4594L11.9336 12.1304V12.0664L12.7251 12.3954C12.7251 12.3954 12.7251 12.4594 12.7891 12.4594L14.2356 12.9859L12.0001 11.9329V11.8689L14.2356 12.9199L14.3026 12.9869C14.3026 12.9869 14.2356 13.0539 14.2356 12.9869L12.9201 12.5269C12.9871 12.5909 13.0536 12.5909 13.1181 12.6579C13.2491 12.7249 13.3161 12.7889 13.4471 12.9199C13.5781 13.1179 13.7116 13.1179 13.7761 13.1844C13.8431 13.1844 13.8431 13.2484 13.9071 13.2484C14.0401 13.2484 14.1046 13.3824 14.0401 13.4464Z" fill="#BF360C"/>
<path d="M11.9331 12.0669L13.7111 13.7114H13.7751V13.6444L12.1306 11.8689M12.3956 12.7244C12.5266 13.0534 12.7886 13.1174 12.8556 13.4464C12.9196 13.7109 13.1846 13.8419 13.2486 13.9064C13.3156 13.9734 13.3156 14.1044 13.3156 14.1044C13.3156 14.1044 13.3826 13.9734 13.3156 13.8424C13.2486 13.7114 13.1176 13.7114 12.9866 13.3159C12.8556 12.9869 12.6576 12.9204 12.5911 12.5914" fill="#FFC107"/>
<path d="M13.3155 14.1045C13.3155 14.0405 13.3155 13.9735 13.2485 13.9065L13.1845 13.8425C13.0535 13.7755 12.92 13.6445 12.8555 13.447C12.7885 13.316 12.7245 13.185 12.6575 13.118C12.5905 12.987 12.4595 12.92 12.3955 12.725H12.4595C12.5265 12.856 12.5905 12.987 12.724 13.054C12.788 13.185 12.9195 13.2495 12.986 13.383C13.053 13.5785 13.184 13.712 13.315 13.776C13.382 13.776 13.382 13.843 13.382 13.843C13.315 13.974 13.315 14.041 13.315 14.041V14.1045C13.3155 14.1045 13.3155 14.04 13.3155 14.1045C13.3155 14.0405 13.3155 13.9065 13.2485 13.8425C13.2485 13.7755 13.1845 13.7755 13.1175 13.7115C13.0535 13.6445 12.9865 13.578 12.8555 13.316C12.7885 13.118 12.7245 13.054 12.6575 12.987C12.5905 12.92 12.5265 12.856 12.4595 12.725L11.8015 12.067L12.4595 12.658H12.5265V12.725L13.644 13.776L12 12L13.6445 13.7755C13.6445 13.7755 13.6445 13.7755 13.6445 13.8425C13.6445 13.8425 13.6445 13.8425 13.6445 13.9065H13.5775L12.6575 12.789C12.7245 12.856 12.7245 12.92 12.7885 12.92C12.8555 12.987 12.9195 13.118 12.9865 13.249C13.0535 13.447 13.1175 13.578 13.1845 13.6445C13.2485 13.7115 13.2485 13.7115 13.3155 13.7755C13.3825 13.9735 13.3825 14.04 13.3155 14.1045C13.3825 14.1045 13.3825 14.1045 13.3155 14.1045Z" fill="#BF360C"/>
<path d="M12.8555 11.869C13.1845 11.869 13.3155 11.671 13.6445 11.671C13.3825 11.6045 13.2485 11.802 12.8555 11.869ZM12 12.067L13.5135 11.3425L12 11.933" fill="#BF360C"/>
<path d="M12.8555 12.1981C13.1845 12.3291 13.382 12.1981 13.6445 12.3291C13.4465 12.1981 13.1845 12.2621 12.8555 12.1981ZM12 12.0671L13.711 12.0001L12 11.9331" fill="#BF360C"/>
<path d="M12.7245 12.5266C12.9865 12.7246 13.1845 12.7246 13.3825 12.9196C13.2485 12.7246 12.9865 12.7246 12.7245 12.5266ZM12 12.0671L13.5775 12.6581L12 11.9331" fill="#BF360C"/>
<path d="M12.4601 12.7246C12.5911 12.9866 12.8556 13.0536 12.9201 13.3826C12.9201 13.1846 12.6581 13.0536 12.4601 12.7246ZM11.9331 12.0671L13.1846 13.1846L12.0671 11.9331" fill="#BF360C"/>
<path d="M12.9864 11.9999C12.9864 12.5264 12.5264 12.9864 11.9999 12.9864C11.4734 12.9864 11.0134 12.5264 11.0134 11.9999C11.0134 11.4734 11.4734 11.0134 11.9999 11.0134C12.5264 11.0134 12.9864 11.4734 12.9864 11.9999Z" fill="#FFC107"/>
<path d="M12 13.0535C11.409 13.0535 10.9465 12.591 10.9465 12C10.9465 11.409 11.409 10.9465 12 10.9465C12.591 10.9465 13.0535 11.409 13.0535 12C13.0535 12.591 12.591 13.0535 12 13.0535ZM12 11.0135C11.4735 11.0135 11.0135 11.4735 11.0135 12C11.0135 12.5265 11.4735 12.9865 12 12.9865C12.5265 12.9865 12.9865 12.5265 12.9865 12C12.9865 11.4735 12.5265 11.0135 12 11.0135Z" fill="#BF360C"/>
<path d="M12.3291 11.802C12.2621 11.802 12.1981 11.802 12.1311 11.869C12.1981 11.933 12.3956 11.933 12.5266 11.869C12.4601 11.802 12.3956 11.802 12.3291 11.802ZM12.3291 11.802C12.3961 11.802 12.4601 11.802 12.4601 11.869C12.3961 11.933 12.2621 11.933 12.1981 11.869C12.2621 11.802 12.2621 11.802 12.3291 11.802Z" fill="#843511"/>
<path d="M12.3289 11.738C12.1979 11.738 12.1979 11.738 12.1309 11.802C12.0669 11.869 12.0669 11.802 12.0669 11.869C12.0669 11.869 12.1309 11.869 12.1309 11.802C12.1309 11.738 12.3289 11.738 12.3289 11.738C12.4599 11.738 12.5269 11.869 12.5269 11.869C12.5909 11.869 12.5264 11.738 12.3289 11.738Z" fill="#BF360C"/>
<path d="M12.6579 11.802C12.4599 11.671 12.2624 11.604 12.1314 11.738C12.1314 11.802 12.0674 11.802 12.0674 11.869C12.0674 11.933 12.0674 12.067 12.1314 12.131C12.0674 12 12.0674 11.869 12.0674 11.802V11.738C12.2619 11.54 12.4599 11.54 12.6579 11.802Z" fill="#BF360C"/>
<path d="M12.3955 11.869C12.3955 11.933 12.3955 11.933 12.3285 11.933C12.2615 11.933 12.2615 11.933 12.2615 11.869C12.2615 11.802 12.2615 11.802 12.3285 11.802C12.3955 11.802 12.3955 11.802 12.3955 11.869Z" fill="#BF360C"/>
<path d="M12.5266 11.9331C12.3956 12.0671 12.2621 12.0001 12.1981 12.0001C12.1311 12.0001 12.1311 11.9331 12.1311 11.9331C12.1311 11.9331 12.1311 11.9331 12.1981 12.0001C12.2621 12.0671 12.3956 12.0001 12.5266 11.9331Z" fill="#BF360C"/>
<path d="M11.869 12.1311L11.802 12.1981C11.802 12.2621 11.802 12.2621 11.869 12.2621H11.933H12H12.067H12.131C12.198 12.2621 12.198 12.2621 12.198 12.1981C12.198 12.1311 12.198 12.1311 12.131 12.1311C12.131 12.1311 12.131 12.1311 12.131 12.1981C12.131 12.1981 12.131 12.2621 12.067 12.2621C12.067 12.2621 12 12.2621 12 12.1981L11.933 12.2621C11.933 12.2621 11.933 12.1981 11.869 12.1311C11.933 12.1981 11.933 12.1981 11.869 12.1981C11.869 12.1981 11.802 12.1981 11.869 12.1311C11.802 12.1311 11.802 12.1311 11.869 12.1311Z" fill="#BF360C"/>
<path d="M11.933 12.3291C11.869 12.3291 11.802 12.3961 11.7375 12.4601C11.802 12.4601 11.802 12.3956 11.933 12.3291L12 12.3956C12 12.3956 12.067 12.3286 12.131 12.3956C12.198 12.4596 12.198 12.4596 12.262 12.4596C12.198 12.3956 12.131 12.3286 12.0665 12.3286H12C11.933 12.3291 11.933 12.3291 11.933 12.3291Z" fill="#BF360C"/>
<path d="M11.869 12.4601C11.869 12.4601 11.802 12.4601 11.738 12.4601C11.869 12.4601 11.9335 12.4601 11.9335 12.4601C12.0005 12.4601 12.0005 12.3961 12.198 12.4601C12.067 12.3961 12 12.4601 11.9335 12.4601C12 12.4601 11.933 12.4601 11.869 12.4601Z" fill="#BF360C"/>
<path d="M11.8019 12.46C11.7379 12.46 11.7379 12.46 11.8019 12.46C11.9329 12.46 11.8689 12.591 11.9999 12.591C12.1309 12.591 12.0669 12.527 12.2619 12.46C12.0664 12.46 12.1309 12.527 11.9999 12.527C11.8689 12.527 11.9329 12.46 11.8019 12.46Z" fill="#BF360C"/>
<path d="M12.1309 12.7246C12.1309 12.6576 12.0669 12.5911 11.9999 12.5911C11.9329 12.5911 11.8689 12.6581 11.8689 12.7246C11.8689 12.6576 11.9329 12.5911 11.9999 12.5911C12.0669 12.5911 12.1309 12.6581 12.1309 12.7246Z" fill="#BF360C"/>
<path d="M11.4089 11.802C11.6044 11.671 11.8019 11.604 11.9329 11.738C11.9329 11.802 11.9999 11.802 11.9999 11.869C11.9999 11.933 11.9999 12.067 11.9329 12.131C11.9999 12 11.9999 11.933 11.9999 11.802V11.738C11.8019 11.54 11.5399 11.54 11.4089 11.802Z" fill="#BF360C"/>
<path d="M11.6709 11.738C11.8019 11.738 11.8019 11.738 11.8689 11.802C11.9329 11.869 11.9329 11.802 11.9329 11.869C11.9329 11.869 11.8689 11.869 11.8689 11.802C11.8689 11.738 11.6709 11.738 11.6709 11.738C11.5399 11.738 11.4729 11.869 11.4089 11.869C11.4089 11.869 11.4734 11.738 11.6709 11.738Z" fill="#BF360C"/>
<path d="M11.6709 11.802C11.6039 11.802 11.5399 11.802 11.4729 11.869C11.5399 11.933 11.7374 11.933 11.8684 11.869C11.8019 11.802 11.7379 11.802 11.6709 11.802ZM11.6709 11.802C11.7379 11.802 11.8019 11.802 11.8019 11.869C11.7379 11.933 11.6039 11.933 11.5399 11.869C11.5399 11.802 11.6044 11.802 11.6709 11.802Z" fill="#843511"/>
<path d="M11.738 11.869C11.738 11.933 11.738 11.933 11.671 11.933C11.604 11.933 11.604 11.933 11.604 11.869C11.604 11.802 11.604 11.802 11.671 11.802C11.671 11.802 11.738 11.802 11.738 11.869Z" fill="#BF360C"/>
<path d="M11.4734 11.9331C11.6044 12.0671 11.7379 12.0001 11.8019 12.0001C11.7379 12.0671 11.6044 12.0001 11.4734 11.9331Z" fill="#BF360C"/>
  </svg>
</template>

<script>
export default {
  name: 'AR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  