
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 11.9999C22 17.5229 17.523 21.9999 12 21.9999C6.477 21.9999 2 17.5229 2 11.9999C2 10.8919 2.449 9.71442 2.782 8.71792C3.4245 6.79442 7.2445 5.49992 7.2445 5.49992C7.2445 5.49992 7.7905 3.49042 8.524 3.16992C9.7495 2.63392 10.5985 2.25492 12.0215 2.25492C12.483 2.25492 12.9165 2.03092 13.3615 2.09142C18.24 2.75592 22 6.93842 22 11.9999Z" fill="#D50000"/>
<path d="M2 12H13.4975V2.1175C12.994 2.0535 12.4835 2.013 11.966 2C6.4435 2.114 2 6.543 2 12Z" fill="#283593"/>
<path d="M8.54897 3.50293L8.77147 4.16093L9.46597 4.16943L8.90947 4.58443L9.11547 5.24743L8.54897 4.84643L7.98197 5.24743L8.18847 4.58443L7.63147 4.16943L8.32597 4.16093L8.54897 3.50293Z" fill="white"/>
<path d="M6.04151 5.54761L6.26451 6.20561L6.95901 6.21411L6.40201 6.62911L6.60851 7.29211L6.04151 6.89111L5.47501 7.29211L5.68151 6.62911L5.12451 6.21411L5.81901 6.20561L6.04151 5.54761Z" fill="white"/>
<path d="M11.0925 5.16211L11.308 5.79861L11.98 5.80711L11.441 6.20861L11.641 6.85011L11.0925 6.46211L10.544 6.85011L10.7435 6.20861L10.205 5.80711L10.877 5.79861L11.0925 5.16211Z" fill="white"/>
<path d="M8.53056 8.8374L8.80656 9.6529L9.66755 9.6634L8.97755 10.1779L9.23305 10.9999L8.53056 10.5029L7.82805 10.9999L8.08355 10.1779L7.39355 9.6634L8.25456 9.6529L8.53056 8.8374Z" fill="white"/>
<path d="M9.76754 7.26709L9.91004 7.68809L10.3545 7.69359L9.99804 7.95909L10.13 8.38359L9.76754 8.12659L9.40504 8.38359L9.53704 7.95909L9.18054 7.69359L9.62504 7.68809L9.76754 7.26709Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'WS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  