<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path
      d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.892 2.18 9.826 2.513 8.8295C3.1555 6.906 7.2445 5.5 7.2445 5.5C7.2445 5.5 7.2645 3.1535 7.998 2.833C9.2235 2.297 10.577 2 12 2C17.523 2 22 6.477 22 12Z"
      fill="#283593"
    />
    <path
      d="M8.00001 4.297V2.8375C7.28401 3.1505 6.61651 3.5495 6.00001 4.0135V4.155L5.89701 4.091C4.34451 5.2905 3.15551 6.9325 2.52051 8.8325L6.00001 6.66V9H8.00001V6.5175L11.9745 9H12.5V7.6955L10.5855 6.5H12.5V4.5H10.2895L12.5 3.119V2.025C12.3335 2.017 12.169 2 12 2C11.8825 2 11.768 2.0135 11.6515 2.0175L8.00001 4.297Z"
      fill="white"
    />
    <path
      d="M6.50004 6.00011V9.00011H7.50004V6.00011H12.5V5.00011H7.50004V3.07861C7.15554 3.25261 6.82004 3.44111 6.50004 3.65261V5.00011H4.86454C4.55804 5.31261 4.27754 5.64961 4.01404 6.00011H6.50004Z"
      fill="#E53935"
    />
    <path
      d="M5.82054 6.5H4.94904L2.96304 7.7395C2.84854 7.9815 2.74454 8.228 2.64954 8.48L5.82054 6.5Z"
      fill="#E53935"
    />
    <path
      d="M12.1065 2.00562L8.11145 4.50012H8.98445L12.5 2.30462V2.02512C12.369 2.01862 12.239 2.00712 12.1065 2.00562Z"
      fill="#E53935"
    />
    <path d="M12.5 7.967L10.1505 6.5H9.27954L12.5 8.51V7.967Z" fill="#E53935" />
    <path
      d="M15 11.4055C14.757 11.811 13.135 12.2975 12 11.4055C12 16.919 13.054 17.811 15 19.027C16.946 17.811 18 16.919 18 11.4055C16.865 12.2975 15.243 11.811 15 11.4055Z"
      fill="white"
    />
    <path
      d="M15.403 13.4629C15.439 13.6399 15.646 13.7269 15.669 13.8804C15.57 13.9014 15.502 13.9454 15.517 13.9564C15.517 13.9564 16.2765 14.1464 16.3525 15.1334C16.3905 15.5509 16.011 15.8929 15.783 15.9304C15.5555 15.9684 15.441 15.9304 15.3275 15.8544C15.2135 15.7784 15.024 15.8164 15.024 15.8164C15.024 15.8164 15.4415 16.0064 14.7965 16.3479C15.176 16.3859 15.366 16.2719 15.556 16.1959C15.746 16.1199 16.657 15.9684 16.7705 14.9814C16.8465 15.0194 16.9225 15.1334 16.9225 15.1334C16.9225 15.1334 17.149 13.5389 15.403 13.4629ZM16.003 13.7739C15.963 13.7739 15.9315 13.7419 15.9315 13.7024C15.9315 13.6624 15.9635 13.6309 16.003 13.6309C16.043 13.6309 16.0745 13.6629 16.0745 13.7024C16.0745 13.7419 16.042 13.7739 16.003 13.7739Z"
      fill="#FF8F00"
    />
    <path
      d="M15.699 14.3155C15.476 14.616 15.287 14.645 15.287 14.645C15.287 14.6205 15.277 14.4995 15.253 14.4075C15.1075 14.8725 14.618 15.367 13.8965 15.183C13.1745 14.9985 13.407 14.238 13.4555 14.146C13.5035 14.054 13.6545 14.0155 13.7655 13.899C13.3245 13.8745 13.6395 13.1475 13.6395 13.1475C13.358 13.443 13.339 13.8695 13.2855 14.0735C12.9705 15.2945 13.7315 15.6095 13.7315 15.6095C13.7315 15.6095 13.6735 15.6485 13.61 15.7115C15.704 16.07 15.704 14.65 15.699 14.3155ZM15.494 14.874C15.454 14.874 15.4225 14.842 15.4225 14.8025C15.4225 14.763 15.4545 14.731 15.494 14.731C15.5335 14.731 15.5655 14.763 15.5655 14.8025C15.5655 14.842 15.5335 14.874 15.494 14.874Z"
      fill="#FF8F00"
    />
    <path
      d="M15.898 12.857C15.3555 12.416 14.89 12.5665 14.89 12.5665C14.89 12.5665 14.929 12.4695 15.0115 12.4165C14.7935 12.441 14.236 12.741 14.047 13.1725C13.4555 14.6115 14.7445 14.859 14.7445 14.859C14.7445 14.859 14.72 14.641 14.672 14.573C14.624 14.505 14.5945 14.326 14.5945 14.326L14.7885 14.389C14.7885 14.2775 14.5895 14.079 14.5755 13.9385C14.5075 13.163 15.157 12.9065 15.438 12.9205C15.7195 12.935 15.952 13.1 16.01 13.2255C16.068 13.3515 16.117 13.371 16.223 13.4875C16.228 13.1 16.732 13.255 16.95 13.352C16.7415 12.9925 16.237 13.138 15.898 12.857ZM14.2925 14.3945C14.2525 14.3945 14.221 14.3625 14.221 14.323C14.221 14.2835 14.253 14.2515 14.2925 14.2515C14.332 14.2515 14.364 14.2835 14.364 14.323C14.364 14.3625 14.3315 14.3945 14.2925 14.3945Z"
      fill="#FF8F00"
    />
    <path
      d="M12.863 17C13.367 17.947 14.083 18.454 15 19.027C15.917 18.454 16.6335 17.947 17.137 17H12.863Z"
      fill="#BBDEFB"
    />
  </svg>
</template>

<script>
export default {
  name: 'AI',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
