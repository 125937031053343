
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M2 12.0001C2 17.5231 6.477 22.0001 12 22.0001C13.41 22.0001 14.75 21.7051 15.966 21.1786L2.9195 7.82959C2.335 9.10059 2 10.5096 2 12.0001Z" fill="#FF1744"/>
<path d="M12.0001 2C10.5901 2 9.25006 2.295 8.03406 2.8215L21.0806 16.1705C21.6651 14.8995 22.0001 13.4905 22.0001 12C22.0001 6.477 17.5231 2 12.0001 2Z" fill="#FF1744"/>
<path d="M2.91956 7.82946L15.9661 21.1785C16.4131 20.985 16.8391 20.7535 17.2496 20.4995L3.62006 6.55396C3.35556 6.95946 3.12356 7.38646 2.91956 7.82946Z" fill="white"/>
<path d="M6.75049 3.50053L20.3805 17.446C20.6445 17.0405 20.8765 16.6135 21.0805 16.17L8.03399 2.82153C7.58649 3.01503 7.16099 3.24603 6.75049 3.50053Z" fill="white"/>
<path d="M3.62 6.55399L17.25 20.5C18.505 19.723 19.576 18.682 20.3805 17.4465L6.7505 3.50049C5.4955 4.27699 4.4245 5.31849 3.62 6.55399Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'TT',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  