
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.944 11C21.4425 5.947 17.18 2 11.9945 2C6.80954 2 2.54704 5.947 2.04504 11H21.944Z" fill="#283593"/>
<path d="M2.04504 13C2.54704 18.053 6.80954 22 11.9945 22C17.1795 22 21.442 18.053 21.944 13H2.04504Z" fill="#283593"/>
<path d="M8.0845 13.3311L8.419 14.6676L9.3765 13.6776L8.998 15.0021L10.3225 14.6236L9.3325 15.5811L10.669 15.9156L9.3325 16.2501L10.3225 17.2081L8.998 16.8296L9.3765 18.1536L8.419 17.1636L8.0845 18.5001L7.75 17.1636L6.792 18.1536L7.1705 16.8296L5.8465 17.2081L6.8365 16.2501L5.5 15.9156L6.8365 15.5811L5.8465 14.6236L7.1705 15.0021L6.792 13.6776L7.75 14.6676L8.0845 13.3311Z" fill="white"/>
<path d="M21.9945 12C21.9945 11.662 21.972 11.3295 21.939 11H2.05001C2.01701 11.3295 1.99451 11.662 1.99451 12C1.99451 12.338 2.01701 12.6705 2.05001 13H21.939C21.9725 12.6705 21.9945 12.338 21.9945 12Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'NR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  