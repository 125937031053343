
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#3F51B5"/>
<path d="M2.459 15H21.5405C21.8385 14.053 22 13.0455 22 12C22 11.489 21.9495 10.9905 21.8755 10.5H2.1245C2.0505 10.9905 2 11.489 2 12C2 13.0455 2.1615 14.053 2.459 15Z" fill="white"/>
<path d="M21.8755 13.5001C21.946 13.0326 21.993 12.5571 21.9965 12.0706H2.00354C2.00704 12.5571 2.05404 13.0326 2.12454 13.5001H21.8755Z" fill="#D50000"/>
<path d="M8.5 7.5L8.677 8.045H9.25L8.7865 8.3815L8.9635 8.9265L8.5 8.59L8.0365 8.9265L8.2135 8.3815L7.75 8.045H8.323L8.5 7.5Z" fill="#FFC107"/>
<path d="M11.1575 9.45792L10.694 9.79492L10.871 9.24992L10.4075 8.91292H10.9805L11.1575 8.36792L11.3345 8.91292H11.9075L11.444 9.24992L11.621 9.79492L11.1575 9.45792Z" fill="#FFC107"/>
<path d="M12.7705 11.6674L12.307 12.0039L12.484 11.4594L12.0205 11.1224H12.5935L12.7705 10.5774L12.9475 11.1224H13.5205L13.057 11.4594L13.234 12.0039L12.7705 11.6674Z" fill="#FFC107"/>
<path d="M12.7705 14.5121L12.307 14.8491L12.484 14.3041L12.0205 13.9676H12.5935L12.7705 13.4226L12.9475 13.9676H13.5205L13.057 14.3041L13.234 14.8491L12.7705 14.5121Z" fill="#FFC107"/>
<path d="M11.1575 16.7216L10.694 17.0586L10.871 16.5136L10.4075 16.1766H10.9805L11.1575 15.6321L11.3345 16.1766H11.9075L11.444 16.5136L11.621 17.0586L11.1575 16.7216Z" fill="#FFC107"/>
<path d="M8.5 17.59L8.0365 17.9265L8.2135 17.3815L7.75 17.045H8.323L8.5 16.5L8.677 17.045H9.25L8.7865 17.3815L8.9635 17.9265L8.5 17.59Z" fill="#FFC107"/>
<path d="M5.84253 16.7216L5.37903 17.0586L5.55603 16.5136L5.09253 16.1766H5.66553L5.84253 15.6321L6.01953 16.1766H6.59253L6.12903 16.5136L6.30603 17.0586L5.84253 16.7216Z" fill="#FFC107"/>
<path d="M4.22949 14.5121L3.76599 14.8491L3.94299 14.3041L3.47949 13.9676H4.05249L4.22949 13.4226L4.40649 13.9676H4.97949L4.51599 14.3041L4.69299 14.8491L4.22949 14.5121Z" fill="#FFC107"/>
<path d="M4.22949 11.6674L3.76599 12.0039L3.94299 11.4594L3.47949 11.1224H4.05249L4.22949 10.5774L4.40649 11.1224H4.97949L4.51599 11.4594L4.69299 12.0039L4.22949 11.6674Z" fill="#FFC107"/>
<path d="M5.84253 9.45792L5.37903 9.79492L5.55603 9.24992L5.09253 8.91292H5.66553L5.84253 8.36792L6.01953 8.91292H6.59253L6.12903 9.24992L6.30603 9.79492L5.84253 9.45792Z" fill="#FFC107"/>
<path d="M8.5 7.5L8.677 8.045H9.25L8.7865 8.3815L8.9635 8.9265L8.5 8.59L8.0365 8.9265L8.2135 8.3815L7.75 8.045H8.323L8.5 7.5Z" fill="#FFC107"/>
  </svg>
</template>

<script>
export default {
  name: 'CV',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  