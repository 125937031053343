
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FF3D00"/>
<path d="M8.50005 2.6499C8.15005 2.7999 7.80005 2.8999 7.50005 3.0999V9.9999H2.20005C2.15005 10.3499 2.10005 10.6499 2.05005 10.9999H8.50005V2.6499Z" fill="white"/>
<path d="M2.05005 13C2.10005 13.35 2.15005 13.65 2.20005 14H7.50005V20.9C7.80005 21.05 8.15005 21.2 8.50005 21.35V13H2.05005Z" fill="white"/>
<path d="M21.95 11C21.9 10.65 21.85 10.35 21.8 10H11.5V2.05005C11.15 2.05005 10.85 2.10005 10.5 2.15005V11H21.95Z" fill="white"/>
<path d="M21.8 14C21.85 13.65 21.9 13.35 21.95 13H10.5V21.9C10.85 21.95 11.15 22 11.5 22V14H21.8Z" fill="white"/>
<path d="M21.95 13.0001C22 12.6501 22 12.3501 22 12.0001C22 11.6501 22 11.3501 21.95 11.0001H10.5V2.1001C9.8 2.2001 9.15 2.4001 8.5 2.6001V11.0001H2.05C2 11.3501 2 11.6501 2 12.0001C2 12.3501 2 12.6501 2.05 13.0001H8.5V21.3501C9.15 21.6001 9.8 21.7501 10.5 21.8501V13.0001H21.95Z" fill="#3F51B5"/>
  </svg>
</template>

<script>
export default {
  name: 'NO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  