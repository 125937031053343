
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.1625 16H2.83752C4.38152 19.5305 7.90052 22 12 22C16.0995 22 19.6185 19.5305 21.1625 16Z" fill="#F44336"/>
<path d="M22 12C22 10.577 21.698 9.2255 21.1625 8H2.8375C2.302 9.2255 2 10.577 2 12C2 13.423 2.302 14.7745 2.8375 16H21.162C21.698 14.7745 22 13.423 22 12ZM11.156 8.7735C11.5675 9.508 12 8.914 12 8.914C12 8.914 12.4325 9.508 12.8435 8.7735C13.078 9.2605 12.3955 9.703 12 9.258C11.604 9.703 10.922 9.2605 11.156 8.7735ZM10.599 9.255C10.599 9.255 8.1095 11.2005 9.94 14C8.216 12.9455 8.065 10.0495 10.599 9.255ZM12.2865 14.948L12 15.3205L11.7135 14.9505C11.7135 14.9505 11.044 15.172 10.1615 14.8645C10.8855 14.8645 11.289 14.737 11.4375 14.6615C11.4375 14.6615 9.7995 13.776 9.8305 11.979C9.927 10.1615 10.8955 9.6065 10.8955 9.6065C10.8955 9.6065 9.1715 12.117 11.703 14.216L11.5935 11.6195V9.713C11.737 9.6795 12 9.3775 12 9.3775C12 9.3775 12.263 9.6795 12.406 9.7135V11.62L12.294 14.2165C14.828 12.12 13.104 9.607 13.104 9.607C13.104 9.607 14.073 10.159 14.169 11.982C14.2005 13.779 12.562 14.6615 12.562 14.6615C12.7105 14.737 13.114 14.867 13.838 14.867C12.9555 15.1745 12.2865 14.948 12.2865 14.948ZM14.0625 14C15.8905 11.2005 13.4035 9.255 13.4035 9.255C15.935 10.0495 15.784 12.9455 14.0625 14Z" fill="white"/>
<path d="M12 2C7.90052 2 4.38152 4.4695 2.83752 8H21.162C19.6185 4.4695 16.0995 2 12 2Z" fill="#4CAF50"/>
<path d="M13.2845 9.44092C13.2845 9.44092 15.561 11.2214 13.8875 13.7839C15.4635 12.8189 15.6015 10.1679 13.2845 9.44092Z" fill="#D32F2F"/>
<path d="M11.9995 9.4435C12.362 9.851 12.9865 9.446 12.772 9C12.3955 9.672 11.9995 9.1285 11.9995 9.1285C11.9995 9.1285 11.604 9.672 11.2275 9C11.013 9.446 11.6375 9.851 11.9995 9.4435Z" fill="#D32F2F"/>
<path d="M13.9855 11.937C13.8975 10.2685 13.0105 9.76297 13.0105 9.76297C13.0105 9.76297 14.5885 12.0635 12.269 13.9825L12.3715 11.606V9.86097C12.2405 9.82997 11.9995 9.55347 11.9995 9.55347C11.9995 9.55347 11.7585 9.82997 11.6275 9.86097V11.606L11.7275 13.9825C9.41046 12.061 10.9885 9.76297 10.9885 9.76297C10.9885 9.76297 10.1015 10.2705 10.0135 11.9345C9.98496 13.5795 11.4845 14.39 11.4845 14.39C11.3485 14.459 10.979 14.576 10.3165 14.576C11.1245 14.8575 11.737 14.6545 11.737 14.6545L11.999 14.993L12.261 14.652C12.261 14.652 12.8735 14.8595 13.6815 14.578C13.019 14.578 12.6495 14.459 12.5135 14.3895C12.5145 14.3895 14.014 13.5815 13.9855 11.937Z" fill="#D32F2F"/>
<path d="M10.717 9.44092C8.39751 10.1679 8.53601 12.8189 10.114 13.7839C8.43851 11.2214 10.717 9.44092 10.717 9.44092Z" fill="#D32F2F"/>
  </svg>
</template>

<script>
export default {
  name: 'IR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  