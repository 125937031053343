
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M3.34851 7H20.651C18.9215 4.014 15.699 2 12 2C8.30101 2 5.07851 4.014 3.34851 7Z" fill="#FDD835"/>
<path d="M22 12C22 10.1765 21.504 8.472 20.6515 7H3.3485C2.496 8.472 2 10.1765 2 12H22Z" fill="white"/>
<path d="M20.651 17C21.504 15.528 22 13.8235 22 12H2C2 13.8235 2.496 15.528 3.349 17H20.651Z" fill="#D50000"/>
<path d="M12 22C15.699 22 18.9215 19.986 20.651 17H3.349C5.0785 19.986 8.301 22 12 22Z" fill="#283593"/>
<path d="M5.7825 4.17651C3.48 6.00851 2 8.82901 2 12C2 15.171 3.48 17.9915 5.7825 19.8235L18.3 12L5.7825 4.17651Z" fill="#43A047"/>
<path d="M5.65399 12.0935C5.65399 10.102 7.14199 8.4545 9.08899 8.146C8.74799 8.0535 8.39099 8 8.02099 8C5.76049 8 3.92749 9.8325 3.92749 12.0935C3.92749 14.3545 5.75999 16.187 8.02099 16.187C8.39149 16.187 8.74849 16.1335 9.08949 16.0415C7.14249 15.732 5.65399 14.085 5.65399 12.0935Z" fill="white"/>
<path d="M9.15695 8.58057L9.35845 9.11807L9.93195 9.14357L9.48295 9.50107L9.63595 10.0541L9.15695 9.73807L8.67845 10.0541L8.83095 9.50107L8.38245 9.14357L8.95595 9.11807L9.15695 8.58057Z" fill="white"/>
<path d="M9.15695 10.3965L9.35845 10.9335L9.93195 10.9595L9.48295 11.317L9.63595 11.87L9.15695 11.554L8.67845 11.87L8.83095 11.317L8.38245 10.9595L8.95595 10.9335L9.15695 10.3965Z" fill="white"/>
<path d="M9.15695 12.2271L9.35845 12.7646L9.93195 12.7901L9.48295 13.1476L9.63595 13.7011L9.15695 13.3846L8.67845 13.7011L8.83095 13.1476L8.38245 12.7901L8.95595 12.7646L9.15695 12.2271Z" fill="white"/>
<path d="M9.15695 14.043L9.35845 14.5805L9.93195 14.606L9.48295 14.9635L9.63595 15.517L9.15695 15.2005L8.67845 15.517L8.83095 14.9635L8.38245 14.606L8.95595 14.5805L9.15695 14.043Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'KM',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  