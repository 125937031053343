
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C7.71047 2 4.06247 4.706 2.64197 8.5H21.3575C19.9375 4.706 16.2895 2 12 2Z" fill="#D50000"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M21.358 15.5H2.64197C4.06247 19.294 7.71047 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#424242"/>
<path d="M14.1365 10.9866C14.105 10.9691 14.07 10.9436 14.0585 10.9281C14.041 10.9026 14.0505 10.9026 14.123 10.9221C14.252 10.9571 14.336 10.9436 14.418 10.8731L14.4905 10.8126L14.5765 10.8556C14.6235 10.8791 14.672 10.8966 14.688 10.8926C14.725 10.8826 14.8015 10.7831 14.7995 10.7501C14.7955 10.7071 14.831 10.7186 14.8485 10.7636C14.8835 10.8556 14.833 10.9591 14.745 10.9826C14.702 10.9926 14.663 10.9886 14.5985 10.9671C14.5185 10.9376 14.5045 10.9396 14.4655 10.9691C14.373 11.0371 14.248 11.0451 14.1365 10.9866Z" fill="#007A3D"/>
<path d="M14.4551 10.6954C14.4236 10.6544 14.3866 10.5059 14.3986 10.4689C14.4086 10.4359 14.4201 10.4299 14.4496 10.4394C14.5006 10.4549 14.5121 10.4904 14.5041 10.6094C14.4981 10.7049 14.4846 10.7304 14.4551 10.6954Z" fill="#007A3D"/>
<path d="M10.457 10.5859C10.4455 10.6599 10.586 10.8399 10.6485 10.8729C10.6055 10.8924 10.553 10.8829 10.5135 10.9044C10.2945 11.1234 9.494 11.9044 9.3475 12.0919C9.781 12.0999 10.2615 12.0859 10.668 12.0664C10.666 11.7734 10.9455 11.7579 11.133 11.6504C11.2285 11.8029 11.471 11.7889 11.5 12.0174C11.5 12.2909 11.5 12.7009 11.5 12.9979C10.2755 12.9979 9.0255 12.9979 7.801 12.9979C7.7365 13.2849 7.5 13.4999 7 13.4999C7 13.4999 7.5 13.3184 7.5 12.9999C7.5 12.5524 7.369 12.5234 7.258 12.3239C7.4415 12.2574 7.469 12.2399 7.6545 12.1149C7.5235 12.5114 7.883 12.5094 8.2305 12.4999C8.242 12.3649 8.2365 12.1659 8.133 12.1464C8.266 12.0974 8.2875 12.0819 8.5 11.9024C8.5 12.0644 8.5 12.3359 8.5 12.4999C9.326 12.4999 10.328 12.4999 11.172 12.4999C11.172 12.3339 11.215 11.9939 11.084 11.9939C10.957 11.9939 11.078 12.3379 10.9805 12.3379C10.881 12.3379 9.662 12.3379 9.002 12.3379C9.002 12.2639 8.998 12.1094 8.998 11.9979C9.082 11.9139 9.072 11.9219 9.6465 11.3474C9.703 11.2909 10.1055 10.9274 10.457 10.5859Z" fill="#007A3D"/>
<path d="M15.6525 10.5725C15.789 10.6465 15.8985 10.75 16.0685 10.793C16.049 10.8635 15.9865 10.8905 15.969 10.965C15.969 11.463 15.969 12.002 15.969 12.5C16.1585 12.541 16.1975 12.3925 16.2875 12.336C16.313 12.576 16.465 12.8105 16.4595 12.998C16.19 12.998 15.9205 12.998 15.651 12.998C15.6525 12.1895 15.6525 11.381 15.6525 10.5725Z" fill="#007A3D"/>
<path d="M14.574 11.373C14.574 11.373 14.867 11.125 14.867 11.115C14.867 11.539 14.867 12.076 14.867 12.5H15.08C15.08 12.004 15.072 11.418 15.072 10.955C15.158 10.869 15.328 10.746 15.369 10.658C15.369 11.5465 15.369 12.2965 15.369 12.994C14.7735 12.994 13.6465 13 13.5 13C13.5 12.5 13.4805 12.008 14.045 12.115C14.045 12.033 14.045 11.984 14.045 11.9175C14.0275 11.8845 13.996 11.9255 13.9905 11.8825C14.0785 11.7925 14.1095 11.769 14.352 11.5565C14.352 11.8125 14.358 12.5 14.358 12.5C14.4165 12.5 14.532 12.5 14.571 12.5C14.5725 12.5 14.574 11.4065 14.574 11.373ZM13.877 12.4065C13.916 12.465 14.051 12.465 14.045 12.3635C14.0235 12.2815 13.8435 12.3125 13.877 12.4065Z" fill="#007A3D"/>
<path d="M8.36497 13.2734C8.36497 13.3359 8.31397 13.3869 8.25147 13.3869C8.18697 13.3869 8.13647 13.3359 8.13647 13.2734C8.13647 13.2109 8.18747 13.1599 8.25147 13.1599C8.31447 13.1599 8.36497 13.2109 8.36497 13.2734Z" fill="#007A3D"/>
<path d="M12 10.574C12.1385 10.65 12.248 10.7515 12.416 10.7945C12.3965 10.865 12.334 10.892 12.3185 10.9665C12.3185 11.4645 12.3185 12.0015 12.3185 12.4995C12.508 12.5405 12.547 12.394 12.637 12.3375C12.6625 12.5775 12.8145 12.812 12.809 12.9995C12.5395 12.9995 12.27 12.9995 12.0005 12.9995C12 12.1915 12 11.383 12 10.574Z" fill="#007A3D"/>
  </svg>
</template>

<script>
export default {
  name: 'IQ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  