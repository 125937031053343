
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#00796B"/>
<path d="M13.5295 14.0431C14.6955 14.1821 16.1935 13.8126 17.0105 11.9601C15.917 11.4386 14.406 11.5706 13.142 12.3601C13.4395 12.0636 14.254 11.4246 15.8285 11.3306C15.9665 11.1771 16.12 10.9621 16.12 10.9621C16.12 10.9621 14.447 10.8701 13.096 12.0981C14.0325 10.6396 13.664 9.22758 13.311 8.58258C13.1575 8.82808 13.004 9.01258 13.004 9.01258C13.004 9.01258 13.75 10.7441 12.851 12.0971C13.468 10.6931 13.1715 9.29358 11.9985 8.19208V8.18408C11.997 8.18558 11.996 8.18658 11.9945 8.18808C11.993 8.18658 11.992 8.18558 11.9905 8.18408V8.19208C10.818 9.29358 10.5215 10.6931 11.138 12.0971C10.239 10.7441 10.985 9.01258 10.985 9.01258C10.985 9.01258 10.8315 8.82858 10.678 8.58258C10.325 9.22708 9.95652 10.6396 10.893 12.0981C9.54202 10.8701 7.86902 10.9621 7.86902 10.9621C7.86902 10.9621 8.02252 11.1771 8.16052 11.3306C9.73502 11.4246 10.5495 12.0641 10.847 12.3601C9.58252 11.5706 8.07202 11.4386 6.97852 11.9601C7.79552 13.8126 9.29352 14.1821 10.4595 14.0431C8.79502 14.5561 7.47002 13.2346 7.47002 13.2346C7.47002 13.2346 7.23952 13.2651 7.04002 13.3116C9.09452 15.4666 11.474 13.9946 11.9945 13.4381C12.515 13.9941 14.894 15.4666 16.949 13.3116C16.7495 13.2656 16.519 13.2346 16.519 13.2346C16.519 13.2346 15.194 14.5556 13.5295 14.0431Z" fill="white"/>
<path d="M7.59302 15.322L7.77702 15.552H11.8245H12.1215H16.1685L16.353 15.322L11.973 15.352L7.59302 15.322Z" fill="white"/>
<path d="M14.204 14.6771C13.805 14.6771 12.7015 14.6056 11.978 13.7866C11.976 13.7986 11.975 13.7756 11.973 13.7871C11.971 13.7756 11.97 13.7981 11.968 13.7866C11.2445 14.6056 10.1415 14.6771 9.74204 14.6771H7.07104L7.31655 15.0456C7.31655 15.0456 8.69804 15.0301 9.91054 15.0301C10.971 15.0301 11.785 14.4546 11.9725 14.3106C12.16 14.4546 12.974 15.0301 14.0345 15.0301C15.247 15.0301 16.6285 15.0456 16.6285 15.0456L16.874 14.6771H14.204Z" fill="white"/>
<path d="M11.8399 15.8589H8.12988L8.46788 16.1354H11.8399H12.1059H15.4779L15.8154 15.8589H12.1059H11.8399Z" fill="white"/>
<path d="M11.8401 16.3811H8.85156L9.43506 16.7036H11.7786H12.1676H14.5111L15.0941 16.3811H12.1061H11.8401Z" fill="white"/>
<path d="M9.87988 16.8875H14.1629C14.1629 16.8875 13.1039 17.3174 12.0294 17.3174C10.9549 17.3174 10.1719 17.041 9.87988 16.8875Z" fill="white"/>
<path d="M11.983 5.55298L12.2365 6.30148L13.027 6.31148L12.3935 6.78398L12.6285 7.53848L11.983 7.08198L11.338 7.53848L11.573 6.78398L10.9395 6.31148L11.7295 6.30148L11.983 5.55298Z" fill="#FDD835"/>
<path d="M7.83161 8.59912L7.94611 9.15112L8.50461 9.22662L8.01511 9.50612L8.11611 10.0606L7.69911 9.68162L7.20261 9.94862L7.43411 9.43512L7.02661 9.04562L7.58711 9.10712L7.83161 8.59912Z" fill="#FDD835"/>
<path d="M9.7429 6.89855L9.5604 7.43205L10.0029 7.78155L9.4394 7.77305L9.24391 8.30155L9.0779 7.76305L8.5144 7.74005L8.9754 7.41605L8.8229 6.87305L9.2739 7.21155L9.7429 6.89855Z" fill="#FDD835"/>
<path d="M16.1061 8.59912L15.9916 9.15112L15.4326 9.22662L15.9221 9.50612L15.8216 10.0606L16.2386 9.68162L16.7351 9.94862L16.5031 9.43512L16.9106 9.04562L16.3506 9.10712L16.1061 8.59912Z" fill="#FDD835"/>
<path d="M14.1946 6.89855L14.3766 7.43205L13.9346 7.78155L14.4981 7.77305L14.6936 8.30155L14.8596 7.76305L15.4231 7.74005L14.9621 7.41605L15.1146 6.87305L14.6636 7.21155L14.1946 6.89855Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'MO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  