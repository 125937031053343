
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="white"/>
<path d="M16.5 12C16.5 14.4845 14.4845 16.5 12 16.5C9.5155 16.5 7.5 14.4845 7.5 12C7.5 9.5155 9.5155 7.5 12 7.5C14.4845 7.5 16.5 9.5155 16.5 12Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'JP',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  