
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M14.0335 12.002L4.28247 18.3595C6.11647 20.583 8.89297 22 12 22C17.523 22 22 17.523 22 12H14.03L14.0335 12.002Z" fill="#FF3D00"/>
<path d="M12 2C8.89248 2 6.11598 3.4175 4.28198 5.641L14.03 12H22C22 6.477 17.523 2 12 2Z" fill="white"/>
<path d="M14.03 12.0001L4.282 5.64111C2.8565 7.36961 2 9.58461 2 12.0001C2 14.4156 2.8565 16.6311 4.2825 18.3596L14.0335 12.0021L14.03 12.0001Z" fill="#3F51B5"/>
  </svg>
</template>

<script>
export default {
  name: 'CZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  