
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.64805 2 3.95555 4.7855 2.58105 8.6665H21.4186C20.0446 4.7855 16.3521 2 12.0001 2Z" fill="#D50000"/>
<path d="M21.419 15.3335C21.7885 14.2895 22 13.1705 22 12C22 10.8295 21.7885 9.7105 21.419 8.6665H2.581C2.2115 9.7105 2 10.8295 2 12C2 13.1705 2.2115 14.2895 2.581 15.3335H21.419Z" fill="white"/>
<path d="M11.9999 14.6215C10.5739 14.6215 9.41394 13.462 9.41394 12.036C9.41394 10.61 10.5739 9.44995 11.9999 9.44995C13.4259 9.44995 14.5859 10.61 14.5859 12.036C14.5859 13.462 13.4259 14.6215 11.9999 14.6215ZM11.9999 9.54995C10.6294 9.54995 9.51394 10.665 9.51394 12.0365C9.51394 13.407 10.6289 14.5225 11.9999 14.5225C13.3709 14.5225 14.4859 13.4075 14.4859 12.0365C14.4859 10.665 13.3704 9.54995 11.9999 9.54995Z" fill="#263238"/>
<path d="M13.5855 13.1045L13.5025 13.049C13.705 12.7495 13.812 12.3995 13.812 12.0365C13.812 11.0375 12.9995 10.2245 12 10.2245C11.0005 10.2245 10.188 11.0375 10.188 12.0365C10.188 12.385 10.2875 12.724 10.4755 13.0165L10.3915 13.07C10.193 12.762 10.088 12.4045 10.088 12.0365C10.088 10.9825 10.9455 10.125 12 10.125C13.0545 10.125 13.912 10.9825 13.912 12.0365C13.912 12.4195 13.799 12.7885 13.5855 13.1045Z" fill="#90A4AE"/>
<path d="M12 14.1955C10.809 14.1955 9.84045 13.2265 9.84045 12.0365C9.84045 10.8455 10.8095 9.87695 12 9.87695C13.1905 9.87695 14.1595 10.846 14.1595 12.0365C14.1595 13.2265 13.191 14.1955 12 14.1955ZM12 9.92645C10.8365 9.92645 9.88995 10.8735 9.88995 12.0365C9.88995 13.1995 10.8365 14.146 12 14.146C13.1635 14.146 14.11 13.1995 14.11 12.0365C14.11 10.8735 13.1635 9.92645 12 9.92645Z" fill="#263238"/>
<path d="M12 13.634C11.119 13.634 10.402 12.917 10.402 12.0365C10.402 11.155 11.119 10.4385 12 10.4385C12.881 10.4385 13.598 11.1555 13.598 12.0365C13.598 12.917 12.881 13.634 12 13.634ZM12 10.488C11.1465 10.488 10.452 11.183 10.452 12.0365C10.452 12.89 11.1465 13.5845 12 13.5845C12.8535 13.5845 13.548 12.89 13.548 12.0365C13.548 11.183 12.8535 10.488 12 10.488Z" fill="#263238"/>
<path d="M12 11.4675L12.1315 11.8555L12.5415 11.861L12.213 12.106L12.3345 12.4975L12 12.2605L11.6655 12.4975L11.787 12.106L11.4585 11.861L11.8685 11.8555L12 11.4675Z" fill="#FDD835"/>
<path d="M12.3805 12.5604L12 12.2914L11.6195 12.5604L11.758 12.1149L11.384 11.8364L11.85 11.8304L12 11.3889L12.1495 11.8309L12.6155 11.8369L12.2415 12.1154L12.3805 12.5604ZM12 12.2299L12.289 12.4344L12.184 12.0964L12.4675 11.8844L12.1135 11.8804L12.108 11.8634L12 11.5454L11.8865 11.8809H11.8685L11.5325 11.8849L11.816 12.0969L11.711 12.4349L12 12.2299Z" fill="#263238"/>
<path d="M11.69 10.8566C11.69 10.8566 11.3515 10.9591 11.255 11.1526C11.261 11.0076 11.412 10.7661 11.412 10.7661C11.412 10.7661 11.1705 10.9536 11.128 11.2616C11.098 11.3221 11.0375 11.3946 11.0375 11.3946L11.0315 11.1771L11.213 10.8206C11.213 10.8206 10.9895 11.0501 10.9715 11.1591C10.9535 11.2681 10.917 11.3706 10.917 11.3706L10.8265 11.1531C10.8265 11.1531 10.8385 11.3466 10.8025 11.4671C10.766 11.5881 10.7905 11.7691 10.7905 11.7691L10.597 11.5756L10.748 11.8656L10.8505 12.1131L10.6815 12.0586C10.6815 12.0586 10.766 12.1856 10.8325 12.2761C10.899 12.3666 11.0985 12.6446 11.0985 12.6446C11.0985 12.6446 10.9415 12.5541 10.7845 12.3666C10.8085 12.5781 10.9355 12.7171 11.008 12.7411C11.0805 12.7651 11.3765 12.9161 11.3765 12.9161C11.3765 12.9161 11.0805 12.9041 10.9655 12.8436C11.1345 13.0311 11.31 13.0431 11.4065 13.0491C11.503 13.0551 11.775 13.1276 11.8535 13.1516C11.932 13.1756 12.1195 13.2061 12.1195 13.2061L12.3735 13.3571L12.5005 13.2906L12.15 13.1156C12.15 13.1156 11.9265 13.1396 11.83 13.0431C11.7335 12.9466 11.679 12.9341 11.679 12.9341C11.679 12.9341 11.4795 12.6741 11.395 12.6076C11.395 12.6076 11.4675 12.8251 11.564 12.9401C11.3585 12.8191 11.304 12.6501 11.304 12.6501L11.286 12.3236C11.286 12.3236 11.2015 12.1301 11.111 12.0636C11.1775 11.9486 11.1655 11.8036 11.1595 11.7251C11.075 11.8881 11.0085 11.9851 11.0085 11.9851L10.9965 12.3051C10.9965 12.3051 10.936 11.9306 10.9845 11.8461C11.033 11.7616 11.3955 11.4171 11.3955 11.4171C11.3955 11.4171 11.196 11.4231 11.1175 11.5321C11.166 11.3631 11.3895 11.3386 11.3895 11.3386L11.5645 11.1511L11.3895 11.2721C11.3895 11.2721 11.196 11.3146 11.1115 11.4111C11.323 11.1936 11.359 11.2116 11.359 11.2116L11.69 10.8566Z" fill="#43A047"/>
<path d="M11.545 13.2735L12.0705 13.1045L12.1915 13.141L12.3125 13.0865C12.3125 13.0865 12.4455 12.724 12.7115 12.6935C12.784 12.5545 12.8565 12.5725 12.8565 12.5725C12.8565 12.5725 12.808 12.2885 12.899 12.1435C12.9535 12.222 12.9715 12.24 12.9715 12.24L13.062 12.1735C13.062 12.1735 12.8625 11.9135 12.8805 11.7385C12.929 11.7205 13.0075 11.793 13.0075 11.793L13.0015 11.5935C13.0015 11.5935 12.7175 11.521 12.663 11.394C12.7355 11.3575 12.796 11.388 12.796 11.388L12.772 11.2005C12.772 11.2005 12.3855 11.1825 12.2645 11.013C12.3735 10.989 12.446 10.995 12.446 10.995L12.3435 10.7955L12.6275 10.892L12.543 10.717C12.543 10.717 12.797 10.8195 12.7785 10.989C12.845 10.9105 12.821 10.9225 12.821 10.9225C12.821 10.9225 12.96 11.025 13.0085 11.2185C13.063 11.0855 13.063 10.989 13.063 10.989L13.1535 11.255L13.208 11.1765C13.208 11.1765 13.2865 11.4305 13.184 11.6175C13.2805 11.545 13.2805 11.545 13.2805 11.545C13.2805 11.545 13.2805 11.696 13.2685 11.7505C13.347 11.678 13.3955 11.6175 13.3955 11.6175C13.3955 11.6175 13.4135 12.0465 13.184 12.1975C13.202 12.294 13.19 12.3545 13.19 12.3545L13.341 12.27L13.196 12.56L13.3775 12.4815C13.3775 12.4815 13.196 12.8015 12.9785 12.7835C12.942 12.838 12.8275 12.9285 12.8275 12.9285L13.0995 12.9465C13.0995 12.9465 12.9365 13.0975 12.7855 13.0615C12.695 13.164 12.6225 13.2005 12.399 13.164C12.1755 13.1275 11.7225 13.327 11.7225 13.327L11.545 13.2735Z" fill="#43A047"/>
<path d="M11.9996 22H12.0006C16.3526 22 20.0446 19.2145 21.4186 15.3335H2.58105C3.95555 19.2145 7.64755 21.9995 11.9996 22Z" fill="#0D47A1"/>
  </svg>
</template>

<script>
export default {
  name: 'PY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  