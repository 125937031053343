
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M15 2.5V2.459C14.053 2.1615 13.0455 2 12 2C6.477 2 2 6.477 2 12C2 12.3375 2.018 12.671 2.0505 13H15V2.5Z" fill="#3F51B5"/>
<path d="M15 5.5H19.59C19.1115 4.942 18.571 4.4415 17.9815 4H15V5.5Z" fill="white"/>
<path d="M21.9495 13H15H2.05054C2.10154 13.514 2.20204 14.012 2.32804 14.5H21.6725C21.798 14.012 21.8985 13.514 21.9495 13Z" fill="white"/>
<path d="M19.1355 19H4.8645C5.428 19.5745 6.0635 20.0745 6.751 20.5H17.249C17.9365 20.0745 18.572 19.5745 19.1355 19Z" fill="white"/>
<path d="M21.1624 16H2.8374C3.0669 16.525 3.3399 17.0265 3.6524 17.5H20.3474C20.6599 17.0265 20.9324 16.525 21.1624 16Z" fill="white"/>
<path d="M15 8.5H21.358C21.1625 7.978 20.9275 7.4765 20.6515 7H15V8.5Z" fill="white"/>
<path d="M15 11.5H21.975C21.9495 10.99 21.8985 10.487 21.7995 10H15V11.5Z" fill="white"/>
<path d="M15 4H17.982C17.1205 3.355 16.1565 2.842 15.1115 2.5H15V4Z" fill="#F44336"/>
<path d="M15 7H20.6515C20.343 6.467 19.9885 5.965 19.5905 5.5H15V7Z" fill="#F44336"/>
<path d="M15 10H21.799C21.694 9.483 21.539 8.985 21.3575 8.5H15V10Z" fill="#F44336"/>
<path d="M15 13H21.9495C21.982 12.671 22 12.3375 22 12C22 11.831 21.983 11.6665 21.975 11.5H15V13Z" fill="#F44336"/>
<path d="M21.6719 14.5H2.32788C2.46088 15.0165 2.62738 15.5195 2.83788 16H21.1624C21.3724 15.5195 21.5389 15.0165 21.6719 14.5Z" fill="#F44336"/>
<path d="M20.3476 17.5H3.65259C4.00809 18.039 4.41409 18.541 4.86459 19H19.1361C19.5861 18.541 19.9921 18.039 20.3476 17.5Z" fill="#F44336"/>
<path d="M6.75098 20.5C8.27798 21.445 10.072 22 12 22C13.928 22 15.722 21.445 17.249 20.5H6.75098Z" fill="#F44336"/>
<path d="M5.125 6.5L5.3575 6.994L5.875 7.072L5.5 7.457L5.59 8L5.125 7.744L4.66 8L4.75 7.457L4.375 7.072L4.8925 6.994L5.125 6.5Z" fill="white"/>
<path d="M7.125 6.5L7.3575 6.994L7.875 7.072L7.5 7.457L7.59 8L7.125 7.744L6.66 8L6.75 7.457L6.375 7.072L6.8925 6.994L7.125 6.5Z" fill="white"/>
<path d="M3.1251 7.74401L3.5901 8.00001L3.5001 7.45701L3.8751 7.07201L3.3576 6.99401L3.3556 6.98901C3.1906 7.27301 3.0391 7.56551 2.9021 7.86651L3.1251 7.74401Z" fill="white"/>
<path d="M9.125 6.5L9.3575 6.994L9.875 7.072L9.5 7.457L9.59 8L9.125 7.744L8.66 8L8.75 7.457L8.375 7.072L8.8925 6.994L9.125 6.5Z" fill="white"/>
<path d="M11.125 6.5L11.3575 6.994L11.875 7.072L11.5 7.457L11.59 8L11.125 7.744L10.66 8L10.75 7.457L10.375 7.072L10.8925 6.994L11.125 6.5Z" fill="white"/>
<path d="M13 6.5L13.2325 6.994L13.75 7.072L13.375 7.457L13.465 8L13 7.744L12.535 8L12.625 7.457L12.25 7.072L12.7675 6.994L13 6.5Z" fill="white"/>
<path d="M5.125 10.5L5.3575 10.994L5.875 11.072L5.5 11.457L5.59 12L5.125 11.744L4.66 12L4.75 11.457L4.375 11.072L4.8925 10.994L5.125 10.5Z" fill="white"/>
<path d="M7.125 10.5L7.3575 10.994L7.875 11.072L7.5 11.457L7.59 12L7.125 11.744L6.66 12L6.75 11.457L6.375 11.072L6.8925 10.994L7.125 10.5Z" fill="white"/>
<path d="M9.125 10.5L9.3575 10.994L9.875 11.072L9.5 11.457L9.59 12L9.125 11.744L8.66 12L8.75 11.457L8.375 11.072L8.8925 10.994L9.125 10.5Z" fill="white"/>
<path d="M3.125 10.5L3.3575 10.994L3.875 11.072L3.5 11.457L3.59 12L3.125 11.744L2.66 12L2.75 11.457L2.375 11.072L2.8925 10.994L3.125 10.5Z" fill="white"/>
<path d="M11.125 10.5L11.3575 10.994L11.875 11.072L11.5 11.457L11.59 12L11.125 11.744L10.66 12L10.75 11.457L10.375 11.072L10.8925 10.994L11.125 10.5Z" fill="white"/>
<path d="M13 10.5L13.2325 10.994L13.75 11.072L13.375 11.457L13.465 12L13 11.744L12.535 12L12.625 11.457L12.25 11.072L12.7675 10.994L13 10.5Z" fill="white"/>
<path d="M5.625 8.5L5.8575 8.994L6.375 9.072L6 9.457L6.09 10L5.625 9.744L5.16 10L5.25 9.457L4.875 9.072L5.3925 8.994L5.625 8.5Z" fill="white"/>
<path d="M7.625 8.5L7.8575 8.994L8.375 9.072L8 9.457L8.09 10L7.625 9.744L7.16 10L7.25 9.457L6.875 9.072L7.3925 8.994L7.625 8.5Z" fill="white"/>
<path d="M3.625 8.5L3.8575 8.994L4.375 9.072L4 9.457L4.09 10L3.625 9.744L3.16 10L3.25 9.457L2.875 9.072L3.3925 8.994L3.625 8.5Z" fill="white"/>
<path d="M9.625 8.5L9.8575 8.994L10.375 9.072L10 9.457L10.09 10L9.625 9.744L9.16 10L9.25 9.457L8.875 9.072L9.3925 8.994L9.625 8.5Z" fill="white"/>
<path d="M11.625 8.5L11.8575 8.994L12.375 9.072L12 9.457L12.09 10L11.625 9.744L11.16 10L11.25 9.457L10.875 9.072L11.3925 8.994L11.625 8.5Z" fill="white"/>
<path d="M13.5 8.5L13.7325 8.994L14.25 9.072L13.875 9.457L13.965 10L13.5 9.744L13.035 10L13.125 9.457L12.75 9.072L13.2675 8.994L13.5 8.5Z" fill="white"/>
<path d="M5.625 4.5L5.8575 4.994L6.375 5.072L6 5.457L6.09 6L5.625 5.744L5.16 6L5.25 5.457L4.875 5.072L5.3925 4.994L5.625 4.5Z" fill="white"/>
<path d="M7.625 4.5L7.8575 4.994L8.375 5.072L8 5.457L8.09 6L7.625 5.744L7.16 6L7.25 5.457L6.875 5.072L7.3925 4.994L7.625 4.5Z" fill="white"/>
<path d="M9.625 4.5L9.8575 4.994L10.375 5.072L10 5.457L10.09 6L9.625 5.744L9.16 6L9.25 5.457L8.875 5.072L9.3925 4.994L9.625 4.5Z" fill="white"/>
<path d="M11.625 4.5L11.8575 4.994L12.375 5.072L12 5.457L12.09 6L11.625 5.744L11.16 6L11.25 5.457L10.875 5.072L11.3925 4.994L11.625 4.5Z" fill="white"/>
<path d="M13.5 4.5L13.7325 4.994L14.25 5.072L13.875 5.457L13.965 6L13.5 5.744L13.035 6L13.125 5.457L12.75 5.072L13.2675 4.994L13.5 4.5Z" fill="white"/>
<path d="M7.58991 4.00002L7.49991 3.45702L7.87491 3.07202L7.59941 3.03052C7.30491 3.17502 7.01941 3.33352 6.74191 3.50552L6.65991 4.00002L7.12491 3.74402L7.58991 4.00002Z" fill="white"/>
<path d="M9.125 2.5L9.3575 2.994L9.875 3.072L9.5 3.457L9.59 4L9.125 3.744L8.66 4L8.75 3.457L8.375 3.072L8.8925 2.994L9.125 2.5Z" fill="white"/>
<path d="M11.125 2.5L11.3575 2.994L11.875 3.072L11.5 3.457L11.59 4L11.125 3.744L10.66 4L10.75 3.457L10.375 3.072L10.8925 2.994L11.125 2.5Z" fill="white"/>
<path d="M13 2.5L13.2325 2.994L13.75 3.0725L13.375 3.4575L13.465 4L13 3.744L12.535 4L12.625 3.457L12.25 3.072L12.7675 2.994L13 2.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'US',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  