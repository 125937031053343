
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M20.9215 16.5H3.07849C4.72649 19.76 8.09799 22 12 22C15.902 22 19.2735 19.76 20.9215 16.5Z" fill="#4CAF50"/>
<path d="M22 12C22 10.3795 21.606 8.854 20.9215 7.5H3.0785C2.394 8.854 2 10.3795 2 12C2 13.6205 2.394 15.146 3.0785 16.5H20.9215C21.606 15.146 22 13.6205 22 12Z" fill="#424242"/>
<path d="M12 2C8.09749 2 4.72649 4.24 3.07849 7.5H20.9215C19.2735 4.24 15.9025 2 12 2Z" fill="#D50000"/>
<path d="M11.75 14.26C10.508 14.26 9.5 13.254 9.5 12.01C9.5 10.768 10.508 9.76 11.75 9.76C11.8925 9.76 12.033 9.7775 12.168 9.803C11.8185 9.615 11.424 9.5 11 9.5C9.619 9.5 8.5 10.619 8.5 12C8.5 13.381 9.619 14.5 11 14.5C11.4065 14.5 11.783 14.3945 12.121 14.2225C12 14.244 11.877 14.26 11.75 14.26Z" fill="white"/>
<path d="M12.047 12L15.8515 10.7635L13.5 14V10L15.8515 13.2365L12.047 12Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'LY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  