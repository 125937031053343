
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.1624 16H2.8374C4.3814 19.5305 7.9004 22 11.9999 22C16.0994 22 19.6184 19.5305 21.1624 16Z" fill="#33691E"/>
<path d="M22 12C22 10.577 21.698 9.2255 21.1625 8H2.8375C2.302 9.2255 2 10.577 2 12C2 13.423 2.302 14.7745 2.8375 16H21.162C21.698 14.7745 22 13.423 22 12Z" fill="white"/>
<path d="M11.9999 2C7.9004 2 4.3814 4.4695 2.8374 8H21.1619C19.6184 4.4695 16.0994 2 11.9999 2Z" fill="#D50000"/>
<path d="M13.2559 12.9199C13.1424 13.0254 12.9959 13.0919 12.8419 13.1054C12.4589 13.1404 12.1214 12.8574 12.0859 12.4724V11.9199C12.0899 11.7929 12.1954 11.6934 12.3224 11.6974C12.4004 11.6994 12.4729 11.7424 12.5139 11.8109C12.6079 11.5279 12.4534 11.2209 12.1699 11.1274C11.8869 11.0319 11.5799 11.1859 11.4864 11.4714C11.4514 11.5809 11.4514 11.7019 11.4864 11.8114C11.5509 11.7019 11.6914 11.6649 11.8009 11.7294C11.8694 11.7684 11.9124 11.8429 11.9144 11.9209V12.4734C11.8794 12.8584 11.5414 13.1414 11.1584 13.1064C11.0039 13.0929 10.8574 13.0264 10.7444 12.9209C10.5959 13.2919 10.1759 13.4734 9.80489 13.3254C9.75039 13.3039 9.69739 13.2764 9.65039 13.2414C9.71689 13.9114 10.7169 13.7744 10.7929 13.1244C11.1054 13.6769 11.7969 13.5189 11.9979 12.8414H12.0019C12.2029 13.5189 12.8944 13.6774 13.2069 13.1244C13.2829 13.7749 14.2829 13.9114 14.3494 13.2414C14.3004 13.2764 14.2499 13.3039 14.1949 13.3254C13.8239 13.4724 13.4044 13.2909 13.2559 12.9199ZM11.6094 11.5704C11.6349 11.3944 11.7714 11.2579 11.9474 11.2324C12.1624 11.2029 12.3614 11.3554 12.3909 11.5704C12.2364 11.5389 12.0804 11.6094 12.0004 11.7464C11.9219 11.6094 11.7634 11.5389 11.6094 11.5704Z" fill="#FFC107"/>
<path d="M11.246 13.6599C11.246 13.6599 11.1755 13.6189 11.029 13.6364C11.0075 13.5424 10.9235 13.4604 10.865 13.4194C10.8065 13.4819 10.7675 13.5969 10.787 13.6809C10.6815 13.7004 10.584 13.7709 10.584 13.7709C10.828 14.0154 11.1075 13.9629 11.246 13.6599Z" fill="#FFC107"/>
<path d="M12.338 13.6269C12.338 13.6269 12.2755 13.5724 12.127 13.5684C12.121 13.4709 12.053 13.3749 12 13.3264C11.9335 13.3789 11.875 13.4864 11.881 13.5724C11.7735 13.5744 11.666 13.6269 11.666 13.6269C11.867 13.9079 12.1505 13.9024 12.338 13.6269Z" fill="#FFC107"/>
<path d="M13.4201 13.7714C13.4201 13.7714 13.3676 13.7089 13.2226 13.6794C13.2326 13.5819 13.1796 13.4764 13.1366 13.4194C13.0626 13.4604 12.9861 13.5579 12.9786 13.6439C12.8731 13.6284 12.7581 13.6614 12.7581 13.6614C12.9101 13.9724 13.1916 14.0114 13.4201 13.7714Z" fill="#FFC107"/>
<path d="M10.1741 14.1719L10.2716 14.4999C11.4026 14.1619 12.5976 14.1619 13.7286 14.4999L13.8261 14.1719C12.6311 13.8144 11.3691 13.8144 10.1741 14.1719Z" fill="#FFC107"/>
<path d="M11.834 10.0135L11.7305 10.332L12 10.135L12.2695 10.3325L12.166 10.014L12.4355 9.8185H12.1035L12 9.5L11.8965 9.8185H11.5645L11.834 10.0135Z" fill="#FFC107"/>
<path d="M10.551 10.3595L10.4495 10.676L10.719 10.4805L10.9865 10.676L10.885 10.3595L11.1545 10.162H10.8205L10.719 9.84546L10.6155 10.162H10.2815L10.551 10.3595Z" fill="#FFC107"/>
<path d="M9.61349 11.3011L9.50999 11.6196L9.77949 11.4221L10.049 11.6196L9.94549 11.3011L10.215 11.1056H9.88299L9.77949 10.7871L9.67599 11.1056H9.34399L9.61349 11.3011Z" fill="#FFC107"/>
<path d="M9.4355 12.709L9.705 12.9065L9.6015 12.588L9.871 12.3905H9.539L9.4355 12.074L9.332 12.3905H9L9.2695 12.588L9.166 12.9065L9.4355 12.709Z" fill="#FFC107"/>
<path d="M13.1149 10.3595L13.0134 10.676L13.2829 10.4805L13.5504 10.676L13.4489 10.3595L13.7184 10.162H13.3849L13.2814 9.84546L13.1799 10.162H12.8459L13.1149 10.3595Z" fill="#FFC107"/>
<path d="M14.0544 11.3011L13.9509 11.6196L14.2204 11.4221L14.4899 11.6196L14.3864 11.3011L14.6559 11.1056H14.3239L14.2204 10.7871L14.1169 11.1056H13.7849L14.0544 11.3011Z" fill="#FFC107"/>
<path d="M14.7304 12.588L14.9999 12.3905H14.6679L14.5644 12.074L14.4609 12.3905H14.1289L14.3984 12.588L14.2949 12.9065L14.5644 12.709L14.8339 12.9065L14.7304 12.588Z" fill="#FFC107"/>
  </svg>
</template>

<script>
export default {
  name: 'TJ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  