
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C6.48696 2 2.01696 6.462 2.00146 11.9715H21.9985C21.983 6.462 17.513 2 12 2Z" fill="#D8241B"/>
<path d="M22 11.9999C22 11.9904 21.9985 11.9809 21.9985 11.9714H2.0015C2.0015 11.9809 2 11.9904 2 11.9999C2 17.5229 6.477 21.9999 12 21.9999C17.523 21.9999 22 17.5229 22 11.9999Z" fill="#1F1916"/>
<path d="M11.461 9.49994L11.76 8.58594L12.049 9.49994H13L12.2385 10.0919L12.557 11.0429L11.75 10.4414L10.963 11.0429L11.2795 10.0994L10.5 9.49994H11.461Z" fill="#F7D418"/>
<path d="M10.2205 11.1211C9.36097 12.9296 11.035 13.0431 14.3005 15.4316L14.57 15.1171L11.3355 12.6056C11.314 12.5901 11.324 12.5546 11.349 12.5546C11.357 12.5546 11.3625 12.5566 11.3685 12.5606L14.607 15.0761L14.658 15.0176C10.8595 12.1096 10.418 11.8206 10.2205 11.1211Z" fill="#F7D418"/>
<path d="M14.746 15.0901L14.447 15.4651L15.4 16.1956C15.523 16.5471 15.7885 16.5591 15.941 16.4086C16.0095 16.3401 16.033 16.1271 15.9095 16.0276L14.746 15.0901Z" fill="#F7D418"/>
<path d="M15.0995 15.6956C15.123 15.6956 15.1425 15.7151 15.1425 15.7406C15.1425 15.7641 15.123 15.7836 15.0995 15.7836C15.076 15.7836 15.0565 15.7641 15.0565 15.7406C15.0565 15.7151 15.076 15.6956 15.0995 15.6956Z" fill="#F7D418"/>
<path d="M15.334 15.873C15.3595 15.873 15.379 15.8925 15.379 15.918C15.379 15.9435 15.3595 15.963 15.334 15.963C15.3105 15.963 15.291 15.9435 15.291 15.918C15.291 15.8925 15.3105 15.873 15.334 15.873Z" fill="#F7D418"/>
<path d="M15.496 15.844C15.5195 15.844 15.537 15.8635 15.537 15.887C15.537 15.9125 15.5195 15.932 15.496 15.932C15.4725 15.932 15.453 15.9125 15.453 15.887C15.453 15.8635 15.4725 15.844 15.496 15.844Z" fill="#F7D418"/>
<path d="M12.5881 14.8651C12.3731 14.7266 12.1721 14.5996 11.9806 14.4801C10.9476 14.7826 9.81455 14.5756 8.94755 13.8726L8.60205 14.3161C8.75255 14.4371 8.90655 14.5446 9.06905 14.6401L8.96505 14.9901L9.57455 15.2616L9.72705 14.9531C9.94755 15.0331 10.1746 15.0916 10.4031 15.1306L10.4401 15.5116L11.1081 15.5251L11.1316 15.1831C11.3796 15.1791 11.6276 15.1501 11.8701 15.0971L12.0281 15.4446L12.6611 15.2336L12.5671 14.8741C12.5741 14.8711 12.5801 14.8671 12.5881 14.8651Z" fill="#F7D418"/>
<path d="M14.365 10.6679C14.531 11.7169 14.205 12.7619 13.5195 13.5174C13.658 13.6229 13.8045 13.7364 13.957 13.8514C14.08 13.7129 14.201 13.5719 14.3025 13.4179L14.6775 13.5389L14.953 12.9219L14.652 12.7599C14.7515 12.5254 14.826 12.2774 14.8785 12.0254L15.269 12.0079L15.318 11.3304L14.9625 11.2834C14.9665 11.0509 14.949 10.8144 14.9115 10.5784C14.9055 10.5434 14.9 10.5059 14.892 10.4709L15.234 10.3164L15.0365 9.66791L14.689 9.75591C14.568 9.44541 14.4095 9.15241 14.22 8.88491L14.474 8.61341L14.05 8.08791L13.7355 8.32641C13.558 8.15441 13.3645 8.00241 13.1575 7.86741L13.294 7.50591L12.702 7.19141L12.5085 7.52541C12.2975 7.43941 12.079 7.37091 11.852 7.32441L11.7385 7.87691C13.1015 8.16791 14.1425 9.27741 14.365 10.6679Z" fill="#F7D418"/>
  </svg>
</template>

<script>
export default {
  name: 'AO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  