
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M11.5 21.9749V2.0249C11.162 2.0419 10.8285 2.0744 10.5 2.1244V21.8754C10.8285 21.9249 11.162 21.9579 11.5 21.9749Z" fill="#FFCA28"/>
<path d="M12 22C12.169 22 12.3335 21.983 12.5 21.975V2.025C12.3335 2.017 12.169 2 12 2C11.831 2 11.6665 2.017 11.5 2.025V21.975C11.6665 21.983 11.831 22 12 22Z" fill="#424242"/>
<path d="M13.5 21.8754V2.1244C13.1715 2.0749 12.838 2.0419 12.5 2.0249V21.9749C12.838 21.9579 13.1715 21.9249 13.5 21.8754Z" fill="white"/>
<path d="M21.9749 11.5C21.9579 11.1615 21.9254 10.8285 21.8754 10.5H2.1244C2.0749 10.8285 2.0419 11.1615 2.0249 11.5H21.9749Z" fill="#FFCA28"/>
<path d="M2.025 12.5H21.975C21.9835 12.3335 22 12.169 22 12C22 11.831 21.983 11.6665 21.975 11.5H2.025C2.017 11.6665 2 11.831 2 12C2 12.169 2.017 12.333 2.025 12.5Z" fill="#424242"/>
<path d="M21.878 10.5C21.2265 6.18301 17.819 2.77601 13.5 2.12451V10.5H21.878Z" fill="#2E7D32"/>
<path d="M10.5001 10.5V2.12451C6.18107 2.77601 2.77357 6.18301 2.12207 10.5H10.5001Z" fill="#2E7D32"/>
<path d="M2.12207 13.5C2.77357 17.817 6.18107 21.224 10.5001 21.8755V13.5H2.12207Z" fill="#2E7D32"/>
<path d="M13.5 13.5V21.8755C17.819 21.224 21.2265 17.817 21.878 13.5H13.5Z" fill="#2E7D32"/>
<path d="M21.8754 13.5C21.9249 13.1715 21.9579 12.838 21.9749 12.5H2.0249C2.0419 12.838 2.0744 13.1715 2.1244 13.5H21.8754Z" fill="white"/>
<path d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z" fill="#E53935"/>
<path d="M11.689 12.8496C11.689 12.8496 11.752 13.5856 11.9045 13.6756C12.057 13.7656 12.3715 13.5231 12.3715 13.5231L12.3805 13.0026L11.689 12.8496Z" fill="#2E7D32"/>
<path d="M11.9849 13.7196C11.9509 13.7196 11.9189 13.7126 11.8914 13.6966C11.7324 13.6031 11.6704 12.9281 11.6634 12.8516L11.6604 12.8181L12.4049 12.9826L12.4044 13.0031L12.3954 13.5356L12.3859 13.5431C12.3749 13.5516 12.1539 13.7196 11.9849 13.7196ZM11.7169 12.8816C11.7384 13.1031 11.8069 13.5896 11.9169 13.6541C12.0264 13.7171 12.2554 13.5786 12.3459 13.5106L12.3544 13.0226L11.7169 12.8816Z" fill="#424242"/>
<path d="M11.9091 15.9014L11.7696 15.5054L11.3511 15.4779L11.6846 15.2234L11.5811 14.8164L11.9266 15.0549L12.2816 14.8309L12.1611 15.2334L12.4841 15.5014L12.0646 15.5114L11.9091 15.9014Z" fill="#2E7D32"/>
<path d="M11.9075 15.9726L11.7515 15.5291L11.2825 15.4981L11.656 15.2136L11.5405 14.7581L11.927 15.0251L12.324 14.7741L12.1895 15.2241L12.551 15.5246L12.0815 15.5356L11.9075 15.9726ZM11.419 15.4576L11.788 15.4816L11.9105 15.8301L12.0475 15.4871L12.417 15.4776L12.133 15.2416L12.1375 15.2256L12.2385 14.8871L11.926 15.0846L11.622 14.8751L11.713 15.2331L11.7 15.2436L11.419 15.4576Z" fill="#424242"/>
<path d="M9.61095 15.102L9.73095 14.7L9.40845 14.4315L9.82795 14.4215L9.98345 14.032L10.1224 14.428L10.5414 14.455L10.2079 14.71L10.3109 15.1165L9.96595 14.878L9.61095 15.102Z" fill="#2E7D32"/>
<path d="M10.3521 15.1754L9.96505 14.9079L9.56805 15.1589L9.70255 14.7094L9.34155 14.4084L9.81105 14.3979L9.98505 13.9609L10.1411 14.4044L10.6101 14.4354L10.2366 14.7204L10.3521 15.1754ZM9.96655 14.8479L10.2706 15.0579L10.1796 14.6999L10.4731 14.4754L10.1046 14.4514L9.98205 14.1029L9.84505 14.4464L9.47555 14.4554L9.75955 14.6919L9.75505 14.7079L9.65405 15.0459L9.96655 14.8479Z" fill="#424242"/>
<path d="M8.22192 13.1051L8.55542 12.8501L8.45192 12.4436L8.79742 12.6821L9.15242 12.4581L9.03192 12.8601L9.35492 13.1286L8.93542 13.1386L8.77992 13.5281L8.64042 13.1326L8.22192 13.1051Z" fill="#2E7D32"/>
<path d="M8.77856 13.5995L8.62257 13.156L8.15356 13.125L8.52707 12.84L8.41156 12.3845L8.79807 12.6515L9.19506 12.401L9.06056 12.8505L9.42206 13.1515L8.95257 13.162L8.77856 13.5995ZM8.29006 13.0845L8.65856 13.1085L8.78106 13.457L8.91807 13.1135L9.28757 13.1045L9.00356 12.868L9.00807 12.852L9.10907 12.514L8.79657 12.711L8.49257 12.5015L8.58356 12.8595L8.29006 13.0845Z" fill="#424242"/>
<path d="M8.27197 10.6729L8.69147 10.6629L8.84697 10.2729L8.98647 10.6689L9.40497 10.6959L9.07147 10.9509L9.17497 11.3574L8.82947 11.1189L8.47497 11.3429L8.59497 10.9409L8.27197 10.6729Z" fill="#2E7D32"/>
<path d="M9.21558 11.4164L8.82908 11.1489L8.43208 11.3999L8.56658 10.9499L8.20508 10.6494L8.67458 10.6384L8.84858 10.2019L9.00458 10.6454L9.47358 10.6764L9.10008 10.9614L9.21558 11.4164ZM8.83058 11.0894L9.13458 11.2994L9.04358 10.9414L9.33708 10.7169L8.96808 10.6929L8.84558 10.3444L8.70858 10.6874L8.33908 10.6969L8.62308 10.9329L8.61858 10.9489L8.51758 11.2874L8.83058 11.0894Z" fill="#424242"/>
<path d="M9.74245 8.73462L10.088 8.97312L10.443 8.74912L10.3225 9.15112L10.6455 9.41962L10.226 9.42962L10.0705 9.81912L9.93095 9.42362L9.51245 9.39612L9.84595 9.14112L9.74245 8.73462Z" fill="#2E7D32"/>
<path d="M10.0691 9.89054L9.91309 9.44704L9.44409 9.41604L9.81759 9.13104L9.70159 8.67554L10.0886 8.94254L10.4856 8.69204L10.3511 9.14154L10.7126 9.44254L10.2431 9.45304L10.0691 9.89054ZM9.58059 9.37554L9.94909 9.39954L10.0716 9.74804L10.2086 9.40454L10.5781 9.39554L10.2941 9.15904L10.2986 9.14304L10.3996 8.80504L10.0871 9.00204L9.78309 8.79254L9.87409 9.15054L9.58059 9.37554Z" fill="#424242"/>
<path d="M12.0708 8.03052L12.2103 8.42652L12.6288 8.45402L12.2953 8.70852L12.3988 9.11502L12.0533 8.87702L11.6988 9.10102L11.8188 8.69852L11.4958 8.43052L11.9158 8.42052L12.0708 8.03052Z" fill="#2E7D32"/>
<path d="M12.44 9.17397L12.053 8.90697L11.656 9.15747L11.7905 8.70797L11.429 8.40697L11.8985 8.39647L12.0725 7.95947L12.2285 8.40297L12.6975 8.43397L12.324 8.71897L12.44 9.17397ZM12.0545 8.84697L12.3585 9.05647L12.2675 8.69847L12.561 8.47397L12.1925 8.44997L12.07 8.10147L11.933 8.44497L11.5635 8.45397L11.8475 8.69047L11.843 8.70647L11.742 9.04447L12.0545 8.84697Z" fill="#424242"/>
<path d="M14.3692 8.83017L14.2492 9.23217L14.5722 9.50067L14.1527 9.51067L13.9972 9.90067L13.8577 9.50467L13.4392 9.47717L13.7727 9.22267L13.6692 8.81567L14.0147 9.05417L14.3692 8.83017Z" fill="#2E7D32"/>
<path d="M13.9956 9.97159L13.8396 9.52809L13.3706 9.49709L13.7441 9.21209L13.6281 8.75659L14.0151 9.02409L14.4121 8.77309L14.2776 9.22259L14.6386 9.52359L14.1691 9.53409L13.9956 9.97159ZM13.5071 9.45659L13.8756 9.48059L13.9981 9.82909L14.1351 9.48559L14.5046 9.47659L14.2206 9.24009L14.2251 9.22409L14.3261 8.88609L14.0136 9.08359L13.7096 8.87359L13.8006 9.23159L13.5071 9.45659Z" fill="#424242"/>
<path d="M15.7585 10.8271L15.425 11.0821L15.528 11.4886L15.183 11.2501L14.828 11.4741L14.948 11.0721L14.6255 10.8041L15.045 10.7936L15.2005 10.4041L15.3395 10.8001L15.7585 10.8271Z" fill="#2E7D32"/>
<path d="M15.5686 11.5475L15.1821 11.28L14.7851 11.531L14.9196 11.0815L14.5581 10.7805L15.0276 10.77L15.2016 10.333L15.3576 10.7765L15.8266 10.8075L15.4531 11.0925L15.5686 11.5475ZM15.1836 11.22L15.4871 11.43L15.3961 11.072L15.6896 10.8475L15.3211 10.8235L15.1986 10.475L15.0616 10.8185L14.6921 10.8275L14.9761 11.064L14.9716 11.08L14.8706 11.418L15.1836 11.22Z" fill="#424242"/>
<path d="M15.7082 13.2597L15.2887 13.2697L15.1332 13.6597L14.9942 13.2637L14.5752 13.2362L14.9087 12.9817L14.8052 12.5747L15.1507 12.8132L15.5057 12.5892L15.3857 12.9917L15.7082 13.2597Z" fill="#2E7D32"/>
<path d="M15.1316 13.7311L14.9756 13.2876L14.5066 13.2566L14.8801 12.9716L14.7646 12.5161L15.1511 12.7831L15.5481 12.5326L15.4136 12.9821L15.7751 13.2831L15.3056 13.2936L15.1316 13.7311ZM14.6431 13.2161L15.0121 13.2401L15.1346 13.5886L15.2716 13.2451L15.6411 13.2361L15.3566 13.0001L15.3611 12.9841L15.4621 12.6461L15.1501 12.8431L14.8461 12.6336L14.9371 12.9916L14.6431 13.2161Z" fill="#424242"/>
<path d="M14.2375 15.1975L13.892 14.959L13.5375 15.183L13.6575 14.781L13.3345 14.5125L13.7545 14.5025L13.9095 14.1125L14.049 14.5085L14.4675 14.536L14.134 14.7905L14.2375 15.1975Z" fill="#2E7D32"/>
<path d="M14.2781 15.256L13.8916 14.989L13.4946 15.24L13.6291 14.79L13.2676 14.4895L13.7371 14.4785L13.9111 14.042L14.0671 14.4855L14.5361 14.5165L14.1626 14.801L14.2781 15.256ZM13.8931 14.929L14.1971 15.1385L14.1061 14.7805L14.1191 14.77L14.3996 14.556L14.0306 14.532L13.9081 14.1835L13.7711 14.5265L13.4016 14.536L13.6856 14.772L13.6811 14.788L13.5801 15.1265L13.8931 14.929Z" fill="#424242"/>
<path d="M11.59 10.3455C11.59 10.3455 10.9885 11.0725 11.231 12.087C11.4735 13.1015 12.398 13.4785 12.398 13.4785L12.9905 13.622L13.143 13.0565L11.994 10.4535L11.59 10.3455Z" fill="#B39DDB"/>
<path d="M13.0084 13.6519L12.3924 13.5024C12.3794 13.4974 11.4494 13.1074 11.2069 12.0929C10.9649 11.0779 11.5649 10.3364 11.5714 10.3294L11.5819 10.3174L11.5974 10.3219L12.0129 10.4329L12.0179 10.4434L13.1704 13.0549L13.0084 13.6519ZM11.5994 10.3734C11.5234 10.4734 11.0359 11.1619 11.2554 12.0809C11.4919 13.0704 12.3984 13.4509 12.4074 13.4549L12.9724 13.5914L13.1164 13.0579L11.9754 10.4739L11.5994 10.3734Z" fill="#424242"/>
<path d="M12.5234 13.5589C12.5234 13.5589 12.5774 14.1424 12.7029 14.5284C12.8284 14.9144 12.9899 14.4474 12.9899 14.4474C12.9899 14.4474 13.2144 14.2859 13.2144 14.0524C13.2144 13.8189 13.0799 12.9934 13.0799 12.9934L12.5234 13.5589Z" fill="#2E7D32"/>
<path d="M12.8175 14.7059C12.762 14.7054 12.7165 14.6499 12.6795 14.5359C12.5545 14.1514 12.4995 13.5669 12.499 13.5614L12.498 13.5499L12.506 13.5414L13.097 12.9404L13.105 12.9894C13.1105 13.0229 13.24 13.8199 13.24 14.0529C13.24 14.2764 13.0505 14.4334 13.0115 14.4629C12.992 14.5169 12.917 14.7059 12.8175 14.7059ZM12.55 13.5684C12.5585 13.6519 12.613 14.1709 12.727 14.5204C12.755 14.6064 12.788 14.6559 12.8175 14.6559C12.8685 14.6559 12.9355 14.5294 12.9665 14.4389L12.969 14.4314L12.9755 14.4264C12.9775 14.4249 13.1895 14.2699 13.1895 14.0524C13.1895 13.8559 13.0915 13.2264 13.063 13.0454L12.55 13.5684Z" fill="#424242"/>
<path d="M13.134 13.8279L11.294 13.8729C11.294 13.8729 11.0695 13.3794 10.8095 13.8279C10.648 13.8459 10.675 13.8729 10.675 13.8729L10.8545 14.1779C10.8545 14.1779 12.883 14.1779 13.1255 14.1599C13.368 14.1419 13.3855 13.8279 13.134 13.8279Z" fill="#A1887F"/>
<path d="M10.854 14.203H10.8395L10.6535 13.887C10.6485 13.88 10.645 13.8695 10.6495 13.8575C10.6565 13.838 10.679 13.8185 10.794 13.8045C10.8695 13.6785 10.951 13.6145 11.037 13.6145C11.04 13.6145 11.043 13.6145 11.0455 13.6145C11.184 13.6215 11.2825 13.794 11.3095 13.8475L13.134 13.803C13.2715 13.803 13.3365 13.889 13.3395 13.974C13.343 14.068 13.2695 14.174 13.127 14.185C12.886 14.203 10.937 14.203 10.854 14.203ZM10.704 13.8735L10.8685 14.1535C11.0505 14.1535 12.895 14.1525 13.123 14.1355C13.236 14.127 13.2925 14.049 13.2895 13.9765C13.2875 13.92 13.2455 13.854 13.134 13.8535L11.2775 13.899L11.2705 13.884C11.2695 13.882 11.1725 13.672 11.042 13.6655C11.04 13.665 11.038 13.665 11.036 13.665C10.967 13.665 10.8975 13.724 10.83 13.8415L10.8235 13.852L10.811 13.8535C10.747 13.8605 10.717 13.8685 10.704 13.8735Z" fill="#424242"/>
<path d="M12.6135 13.8009C12.6135 13.8009 12.389 13.8729 12.38 14.0434C12.371 14.2139 12.4875 14.1599 12.5865 14.1869C12.685 14.2139 12.739 14.3574 12.479 14.3574C12.2185 14.3574 12.138 14.3574 12.03 14.3484C11.922 14.3394 11.7515 14.1779 11.7515 14.0074C11.7515 13.8369 12.012 13.6844 12.012 13.6844L12.479 13.6394L12.6135 13.8009Z" fill="#FDD835"/>
<path d="M12.4791 14.383C12.2161 14.383 12.1371 14.383 12.0281 14.3735C11.9121 14.364 11.7271 14.193 11.7271 14.008C11.7271 13.825 11.9886 13.6695 11.9996 13.6635L12.0101 13.66L12.4901 13.6135L12.6566 13.8135L12.6211 13.825C12.6191 13.8255 12.4131 13.894 12.4051 14.0445C12.4001 14.1455 12.4366 14.1475 12.5096 14.1515C12.5356 14.153 12.5646 14.155 12.5931 14.1625C12.6556 14.179 12.6991 14.2325 12.6921 14.2835C12.6876 14.321 12.6531 14.383 12.4791 14.383ZM12.0201 13.709C11.9866 13.73 11.7766 13.865 11.7766 14.008C11.7766 14.165 11.9356 14.316 12.0321 14.324C12.1386 14.3335 12.2176 14.3335 12.4791 14.3335C12.5946 14.3335 12.6396 14.303 12.6431 14.278C12.6466 14.253 12.6171 14.222 12.5806 14.2115C12.5576 14.205 12.5321 14.204 12.5076 14.2025C12.4366 14.198 12.3481 14.193 12.3561 14.0425C12.3636 13.898 12.5061 13.82 12.5731 13.791L12.4691 13.6665L12.0201 13.709Z" fill="#424242"/>
<path d="M12.2365 10.2823L12.425 10.9108C12.425 10.9108 11.9315 11.1263 11.8235 10.7673C11.689 10.8393 11.4735 10.8658 11.3835 10.6688C11.4645 10.5073 11.644 10.2378 11.644 10.2378L12.2365 10.2823Z" fill="#2E7D32"/>
<path d="M12.128 10.9999C12.07 10.9999 12.0115 10.9909 11.9585 10.9669C11.8885 10.9349 11.8385 10.8789 11.809 10.8024C11.71 10.8484 11.6045 10.8579 11.521 10.8304C11.449 10.8059 11.3955 10.7554 11.361 10.6789L11.356 10.6684L11.361 10.6579C11.4415 10.4969 11.616 10.2354 11.623 10.2249L11.631 10.2129L11.6455 10.2139L12.255 10.2604L12.4545 10.9264L12.434 10.9354C12.4265 10.9374 12.281 10.9999 12.128 10.9999ZM11.8385 10.7304L11.8475 10.7599C11.8705 10.8374 11.915 10.8919 11.9795 10.9214C12.1235 10.9889 12.3295 10.9204 12.3945 10.8964L12.2175 10.3059L11.657 10.2634C11.622 10.3159 11.483 10.5289 11.4115 10.6689C11.44 10.7254 11.4825 10.7639 11.537 10.7829C11.629 10.8129 11.739 10.7839 11.8115 10.7454L11.8385 10.7304Z" fill="#424242"/>
<path d="M12.3621 12.446C12.3621 12.446 12.0391 13.0925 12.1196 13.478C12.2006 13.864 12.4426 13.6755 12.4426 13.6755C12.4426 13.6755 12.6311 13.9625 12.8196 13.6035C13.0081 13.2445 12.8196 12.679 12.8196 12.679L12.3621 12.446Z" fill="#2E7D32"/>
<path d="M12.613 13.8091C12.5295 13.8091 12.465 13.7436 12.437 13.7091C12.4025 13.7301 12.329 13.7631 12.256 13.7366C12.1785 13.7081 12.1245 13.6231 12.0955 13.4831C12.0135 13.0936 12.3265 12.4616 12.34 12.4346L12.3515 12.4121L12.374 12.4231L12.841 12.6616L12.844 12.6716C12.852 12.6951 13.0335 13.2511 12.8425 13.6156C12.777 13.7401 12.7025 13.8056 12.6215 13.8096C12.618 13.8091 12.6155 13.8091 12.613 13.8091ZM12.449 13.6391L12.464 13.6616C12.4645 13.6626 12.5295 13.7591 12.613 13.7591C12.615 13.7591 12.6165 13.7591 12.6185 13.7591C12.681 13.7556 12.741 13.6996 12.7975 13.5916C12.9655 13.2716 12.822 12.7716 12.799 12.6966L12.3735 12.4791C12.318 12.5961 12.0745 13.1396 12.144 13.4726C12.1695 13.5936 12.213 13.6671 12.2725 13.6891C12.3475 13.7161 12.4265 13.6561 12.4275 13.6556L12.449 13.6391Z" fill="#424242"/>
<path d="M12.003 10.8029C12.003 10.8029 11.8145 11.3864 12.1105 12.0864C12.4065 12.7864 12.5235 12.8764 12.73 13.0559C12.9365 13.2354 13.2775 13.6754 13.3225 13.8549C13.457 12.9754 13.062 11.2879 12.2725 10.7584C12.1105 10.6594 12.057 10.6774 12.003 10.8029Z" fill="#2E7D32"/>
<path d="M13.3286 13.9815L13.2986 13.861C13.2556 13.69 12.9186 13.2535 12.7141 13.0755L12.6976 13.0615C12.4986 12.888 12.3786 12.784 12.0881 12.097C11.7916 11.3965 11.9776 10.8015 11.9796 10.7955L11.9801 10.7935C12.0011 10.744 12.0301 10.6915 12.0806 10.6755C12.1291 10.66 12.1886 10.678 12.2856 10.737C13.0961 11.2805 13.4826 12.9785 13.3476 13.859L13.3286 13.9815ZM12.0266 10.812C12.0181 10.84 11.8506 11.408 12.1336 12.077C12.4201 12.7535 12.5366 12.855 12.7301 13.023L12.7466 13.037C12.9286 13.1955 13.2056 13.5435 13.3101 13.7555C13.4031 12.8665 13.0241 11.2925 12.2581 10.7795C12.1776 10.73 12.1266 10.714 12.0956 10.723C12.0706 10.731 12.0501 10.7575 12.0266 10.812Z" fill="#424242"/>
<path d="M12.82 10.1482L12.6155 10.1327L12.461 10.1212L12.856 10.3457L12.6945 10.4177L13.0175 10.5882L12.829 10.6867L13.0535 10.8122L12.892 10.9107L13.0985 11.0452C13.0985 11.0452 12.3175 11.3322 12.138 10.3362C11.671 10.4797 11.294 10.3272 11.357 9.89616C11.42 9.46516 12.084 9.49216 12.2815 9.73466C12.439 9.92866 12.82 10.1482 12.82 10.1482Z" fill="#B39DDB"/>
<path d="M12.8525 11.1096C12.7505 11.1096 12.629 11.0901 12.5125 11.0231C12.314 10.9091 12.181 10.6891 12.1185 10.3681C11.841 10.4471 11.601 10.4236 11.458 10.3011C11.3505 10.2086 11.307 10.0676 11.3325 9.89306C11.3565 9.72706 11.468 9.60906 11.6465 9.55956C11.882 9.49306 12.176 9.56606 12.301 9.71906C12.454 9.90706 12.829 10.1241 12.8325 10.1261L12.9275 10.1806L12.5705 10.1536L12.9115 10.3476L12.7515 10.4186L13.0715 10.5876L12.882 10.6866L13.103 10.8106L12.939 10.9111L13.154 11.0511L13.107 11.0681C13.0995 11.0721 12.994 11.1096 12.8525 11.1096ZM12.157 10.3041L12.162 10.3321C12.2195 10.6521 12.3455 10.8696 12.5365 10.9791C12.73 11.0911 12.943 11.0596 13.0385 11.0366L12.8445 10.9106L13.0035 10.8131L12.776 10.6861L12.9635 10.5881L12.637 10.4151L12.8005 10.3421L12.3515 10.0876L12.718 10.1151C12.5945 10.0376 12.3745 9.88906 12.262 9.75056C12.15 9.61406 11.874 9.54856 11.6595 9.60806C11.5015 9.65156 11.4025 9.75556 11.3815 9.90006C11.359 10.0571 11.3965 10.1826 11.49 10.2636C11.622 10.3771 11.862 10.3961 12.1305 10.3131L12.157 10.3041Z" fill="#424242"/>
<path d="M11.8324 10.0404C11.9167 10.0404 11.9849 10.0021 11.9849 9.95488C11.9849 9.90766 11.9167 9.86938 11.8324 9.86938C11.7482 9.86938 11.6799 9.90766 11.6799 9.95488C11.6799 10.0021 11.7482 10.0404 11.8324 10.0404Z" fill="#2E7D32"/>
<path d="M11.8325 10.065C11.733 10.065 11.655 10.0165 11.655 9.95498C11.655 9.89298 11.733 9.84448 11.8325 9.84448C11.932 9.84448 12.01 9.89298 12.01 9.95498C12.01 10.0165 11.932 10.065 11.8325 10.065ZM11.8325 9.89448C11.7595 9.89448 11.705 9.92648 11.705 9.95498C11.705 9.98348 11.7595 10.015 11.8325 10.015C11.9055 10.015 11.96 9.98348 11.96 9.95498C11.96 9.92648 11.9055 9.89448 11.8325 9.89448Z" fill="#424242"/>
<path d="M11.4464 9.92358C11.4464 9.92358 11.0154 10.0311 11.1324 10.4261C11.2489 10.3186 11.2489 10.3186 11.2489 10.3186C11.2489 10.3186 11.4554 10.4171 11.5184 10.2466C11.5809 10.0761 11.4464 9.92358 11.4464 9.92358Z" fill="#FDD835"/>
<path d="M11.1196 10.472L11.1086 10.4335C11.0736 10.315 11.0821 10.2095 11.1336 10.119C11.2276 9.95401 11.4321 9.90101 11.4406 9.89901L11.4556 9.89551L11.4656 9.90651C11.4716 9.91351 11.6091 10.0725 11.5421 10.255C11.5236 10.3055 11.4916 10.34 11.4481 10.358C11.3751 10.3875 11.2896 10.3615 11.2546 10.3475L11.1196 10.472ZM11.4376 9.95201C11.3906 9.96701 11.2466 10.022 11.1771 10.1445C11.1381 10.2125 11.1281 10.291 11.1466 10.3795L11.2446 10.2885L11.2601 10.296C11.2611 10.296 11.3571 10.34 11.4291 10.312C11.4601 10.2995 11.4816 10.275 11.4951 10.238C11.5421 10.11 11.4656 9.99001 11.4376 9.95201Z" fill="#424242"/>
  </svg>
</template>

<script>
export default {
  name: 'DM',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  