
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2C7.71047 2 4.06247 4.706 2.64197 8.5H21.3575C19.9375 4.706 16.2895 2 12 2Z" fill="#F44336"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M21.358 15.5H2.64197C4.06247 19.294 7.71047 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#0D47A1"/>
<path d="M12 16.4241C10.4395 16.4241 9.17004 15.1546 9.17004 13.5941V10.1741H14.83V13.5941C14.83 15.1546 13.5605 16.4241 12 16.4241Z" fill="white"/>
<path d="M14.705 10.2991V13.5941C14.705 15.0861 13.492 16.2991 12 16.2991C10.508 16.2991 9.29504 15.0861 9.29504 13.5941V10.2991H14.705ZM14.955 10.0491H9.04504V13.5941C9.04504 15.2271 10.3675 16.5491 12 16.5491C13.6325 16.5491 14.955 15.2266 14.955 13.5941V10.0491Z" fill="#F44336"/>
<path d="M13.6855 10.217H14.8045V11.4435H13.6855V10.217Z" fill="#F44336"/>
<path d="M11.4456 10.217H12.5626V11.4435H11.4456V10.217Z" fill="#F44336"/>
<path d="M9.203 10.217H10.322V11.4435H9.203V10.217Z" fill="#F44336"/>
<path d="M13.6855 12.6794H14.8045V13.9079H13.6855V12.6794Z" fill="#F44336"/>
<path d="M11.4456 12.6794H12.5626V13.9079H11.4456V12.6794Z" fill="#F44336"/>
<path d="M9.203 12.6794H10.322V13.9079H9.203V12.6794Z" fill="#F44336"/>
<path d="M13.6855 15.8126C14.0525 15.5351 14.246 15.2501 14.3205 15.1426H13.6855V15.8126Z" fill="#F44336"/>
<path d="M10.3205 15.8126C9.9535 15.5351 9.762 15.2501 9.6875 15.1426H10.3205V15.8126Z" fill="#F44336"/>
<path d="M11.4456 16.3226C11.4456 16.3226 11.6446 16.3771 12.0061 16.3771C12.3676 16.3771 12.5626 16.3771 12.5626 16.3771V15.1426H11.4456V16.3226Z" fill="#F44336"/>
<path d="M12.5645 13.908H13.6835V15.1365H12.5645V13.908Z" fill="#F44336"/>
<path d="M10.3225 13.908H11.4415V15.1365H10.3225V13.908Z" fill="#F44336"/>
<path d="M12.5645 11.4436H13.6835V12.6721H12.5645V11.4436Z" fill="#F44336"/>
<path d="M10.3225 11.4436H11.4415V12.6721H10.3225V11.4436Z" fill="#F44336"/>
<path d="M9.3635 10.043L8.75 8.6565L9.203 7.9165L10.0155 8.219L10.3885 9.686C10.3885 9.686 9.8105 9.836 9.3635 10.043Z" fill="#4FC3F7"/>
<path d="M11.455 9.51141L11.334 7.99991L10.582 7.56641L10.0135 8.21891L10.3885 9.68391C10.3905 9.68341 10.9825 9.53891 11.455 9.51141Z" fill="#3F51B5"/>
<path d="M12.547 9.51141L12.668 7.99991L13.42 7.56641L13.9865 8.21891L13.6115 9.68391C13.6115 9.68391 13.0195 9.53891 12.547 9.51141Z" fill="#3F51B5"/>
<path d="M12.547 9.51143L12.668 7.99993L12.002 7.45093L11.334 7.99993L11.455 9.51143C11.455 9.51143 12.121 9.45893 12.547 9.51143Z" fill="#4FC3F7"/>
<path d="M14.6365 10.043L15.25 8.6565L14.797 7.9165L13.9845 8.219L13.6115 9.686C13.6115 9.686 14.1895 9.836 14.6365 10.043Z" fill="#4FC3F7"/>
<path d="M14.6365 10.043L15.25 8.6565L14.797 7.9165L13.9845 8.219L13.6115 9.686C13.6115 9.686 14.1895 9.836 14.6365 10.043Z" fill="#4FC3F7"/>
<path d="M10.1095 8.58805C10.8985 8.41205 11.1445 8.38905 11.3635 8.37305L11.334 8.00005L11.332 7.99805C11.1895 8.00605 10.701 8.04505 10.0155 8.21855L10.1095 8.58805Z" fill="#F44336"/>
<path d="M11.426 9.13491L11.3945 8.75391C10.9375 8.77741 10.342 8.91591 10.203 8.95141L10.2985 9.32841C10.7715 9.19141 11.2305 9.14841 11.426 9.13491Z" fill="#F44336"/>
<path d="M13.705 9.32244C14.205 9.45144 14.5975 9.61344 14.789 9.69944L15.0255 9.16644C14.4825 8.91244 13.965 8.77994 13.8495 8.75244L13.705 9.32244Z" fill="white"/>
<path d="M13.7305 9.21911C13.879 9.25211 14.283 9.35761 14.83 9.60561L14.9865 9.25411C14.8535 9.19561 14.295 8.95911 13.8245 8.84961L13.7305 9.21911Z" fill="#F44336"/>
  </svg>
</template>

<script>
export default {
  name: 'HR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  