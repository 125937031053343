
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M5.3646 19.4716L21.3361 8.45509C20.6876 6.74759 19.5901 5.26509 18.1806 4.15259L2.4541 15.0001C3.0036 16.7491 4.0266 18.2836 5.3646 19.4716Z" fill="#263238"/>
<path d="M21.3284 8.45996L5.36841 19.4685C5.71591 19.778 6.07741 20.072 6.46691 20.3305L21.7424 9.79396C21.6389 9.33446 21.4929 8.89296 21.3284 8.45996Z" fill="#FFD600"/>
<path d="M18.1685 4.16061C17.7955 3.86661 17.409 3.58911 16.996 3.34961L2.13452 13.6006C2.21152 14.0761 2.33252 14.5356 2.47352 14.9866L18.1685 4.16061Z" fill="#FFD600"/>
<path d="M6.96306 12.3379L8.33756 13.3589L9.75706 12.4019L9.21106 14.0244L10.5596 15.0789L8.84806 15.0609L8.26156 16.6694L7.74956 15.0359L6.03906 14.9754L7.43406 13.9839L6.96306 12.3379Z" fill="white"/>
<path d="M14.3781 7.20996L15.7526 8.23046L17.1721 7.27396L16.6261 8.89646L17.9746 9.95046L16.2626 9.93296L15.6766 11.5415L15.1646 9.90796L13.4541 9.84746L14.8491 8.85546L14.3781 7.20996Z" fill="white"/>
<path d="M1.99438 12C1.99438 12.546 2.04988 13.0785 2.13438 13.6005L16.9959 3.3495C15.5234 2.4965 13.8189 2 11.9944 2C6.47188 2 1.99438 6.477 1.99438 12Z" fill="#2E7D32"/>
<path d="M11.9945 21.9999C17.5175 21.9999 21.9945 17.5229 21.9945 11.9999C21.9945 11.2409 21.9025 10.5049 21.7425 9.79395L6.46704 20.3309C8.05104 21.3839 9.95004 21.9999 11.9945 21.9999Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'KN',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  