
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M19.59 18.5H4.41003C6.24403 20.6395 8.96103 22 12 22C15.039 22 17.756 20.6395 19.59 18.5Z" fill="#D32F2F"/>
<path d="M12 2C8.96103 2 6.24403 3.3605 4.41003 5.5H19.59C17.756 3.3605 15.039 2 12 2Z" fill="#D32F2F"/>
<path d="M22 12C22 9.516 21.089 7.2485 19.59 5.5H4.41C2.911 7.2485 2 9.516 2 12C2 14.484 2.911 16.7515 4.41 18.5H19.59C21.089 16.7515 22 14.484 22 12Z" fill="#1B5E20"/>
<path d="M12 7.54712L11.5275 9.00012H10L11.2365 9.89862L10.764 11.3516L12 10.4531L13.2365 11.3516L12.764 9.89862L14 9.00012H12.4725L12 7.54712Z" fill="#FFC400"/>
<path d="M16.8515 9.5C16.84 12.078 14.588 14 12.0075 14C12.0055 14 12.0015 14 11.9995 14C11.9955 14 11.9935 14 11.9915 14C9.4115 14 7.1595 12.078 7.1475 9.5C7.047 9.8925 7 10.2325 7 10.6545C7 13.33 9.3225 15.5 12 15.5C14.6775 15.5 17 13.33 17 10.6545C17 10.2325 16.953 9.8925 16.8515 9.5Z" fill="#FFC400"/>
  </svg>
</template>

<script>
export default {
  name: 'MR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  