
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#388E3C"/>
<path d="M13.25 15C12.0075 15 11 13.9925 11 12.75C11 11.5075 12.0075 10.5 13.25 10.5C13.52 10.5 13.7755 10.5555 14.0155 10.6425C13.5375 10.242 12.9225 10 12.25 10C10.731 10 9.5 11.231 9.5 12.75C9.5 14.269 10.731 15.5 12.25 15.5C12.9225 15.5 13.5375 15.258 14.0155 14.8575C13.7755 14.9445 13.52 15 13.25 15Z" fill="#FDD835"/>
<path d="M19.646 9.86646L19.846 10.3275L20.331 10.1965L20.0955 10.64L20.5 10.9375L20.0065 11.0295L20.026 11.5315L19.646 11.203L19.266 11.5315L19.286 11.0295L18.792 10.9375L19.197 10.64L18.9615 10.1965L19.446 10.3275L19.646 9.86646Z" fill="#FDD835"/>
<path d="M16.9565 15.5444L17.1565 16.0054L17.6415 15.8744L17.406 16.3179L17.8105 16.6154L17.317 16.7079L17.3365 17.2094L16.9565 16.8814L16.5765 17.2094L16.5965 16.7079L16.1025 16.6154L16.5075 16.3179L16.272 15.8744L16.7565 16.0054L16.9565 15.5444Z" fill="#FDD835"/>
<path d="M14.834 11.1899L15.034 11.6509L15.519 11.5199L15.2835 11.9634L15.688 12.2609L15.1945 12.3529L15.214 12.8549L14.834 12.5264L14.454 12.8549L14.474 12.3529L13.98 12.2609L14.385 11.9634L14.1495 11.5199L14.634 11.6509L14.834 11.1899Z" fill="#FDD835"/>
<path d="M16.5234 7.63452L16.7234 8.09502L17.2084 7.96402L16.9724 8.40802L17.3774 8.70502L16.8839 8.79752L16.9034 9.29952L16.5234 8.97102L16.1434 9.29952L16.1634 8.79752L15.6694 8.70502L16.0744 8.40802L15.8389 7.96402L16.3234 8.09502L16.5234 7.63452Z" fill="#FDD835"/>
<path d="M18.1799 12.3474L18.3209 12.7224L18.7209 12.7399L18.4074 12.9899L18.5144 13.3759L18.1799 13.1549L17.8459 13.3759L17.9524 12.9899L17.6394 12.7399L18.0394 12.7224L18.1799 12.3474Z" fill="#FDD835"/>
<path d="M7.25 10.5C8.76878 10.5 10 9.26878 10 7.75C10 6.23122 8.76878 5 7.25 5C5.73122 5 4.5 6.23122 4.5 7.75C4.5 9.26878 5.73122 10.5 7.25 10.5Z" fill="#FDD835"/>
<path d="M7.3869 7.43359C7.3869 7.43359 7.6144 8.43909 6.8244 9.76759C6.8004 9.87509 6.7644 9.94709 6.7644 9.94709H7.5544V9.76759L7.3509 9.75559C7.3509 9.75559 7.6859 9.04959 7.5784 7.39809C7.4824 7.31409 7.3869 7.43359 7.3869 7.43359Z" fill="#8D6E63"/>
<path d="M7.42301 7.05052L7.38051 6.97552L7.05151 6.39202L7.18301 6.45202L7.35051 5.73402L7.42251 5.90152L7.63801 5.72202C7.63801 5.72202 7.91351 5.66202 8.02101 5.71002C8.12851 5.75802 7.63801 6.12902 7.76951 6.10502C7.90101 6.08102 8.03301 5.99752 8.12851 6.03302C8.22401 6.06902 8.11651 6.38002 7.92501 6.39202C7.73351 6.40402 7.41051 6.59552 7.64951 6.54752C7.88901 6.49952 8.05651 6.61952 7.90101 6.70302C7.74551 6.78652 7.69751 7.19352 7.82901 7.00202C7.96051 6.81052 8.08051 6.57102 8.30751 6.61902C8.53501 6.66702 8.22351 6.73852 8.35551 6.82252C8.48751 6.90652 8.15201 7.12152 8.43951 7.08602C8.72651 7.05002 9.02601 7.22952 9.02601 7.54102C9.02601 7.85252 8.95401 7.64852 8.72701 7.64852C8.49951 7.64852 8.32001 7.60052 8.18851 7.60052C8.05701 7.60052 8.36801 7.81602 8.49951 7.87602C8.63101 7.93602 8.36801 8.05552 8.43951 8.22302C8.51151 8.39052 8.37951 8.53402 8.21201 8.30702C8.04451 8.07952 7.62551 7.86402 7.47001 7.52902C7.51801 7.90002 7.42201 8.10352 7.39801 8.24702C7.37401 8.39052 7.13451 8.65402 7.06301 8.28302C6.99101 7.91202 7.11101 7.87602 7.15851 7.68452C7.20651 7.49302 7.07451 7.49302 6.99101 7.63652C6.90701 7.78002 6.83551 8.21102 6.84751 8.31852C6.85951 8.42602 6.57201 8.95302 6.52451 8.62952C6.47651 8.30652 6.62001 7.72002 6.80001 7.45652C6.97951 7.19302 6.44101 7.50452 6.08201 7.55202C5.72301 7.60002 5.55551 7.46802 5.32801 7.75552C5.10051 8.04302 4.93301 7.70752 5.26801 7.40852C5.60301 7.10952 5.80651 7.16902 6.05801 7.16902C6.30951 7.16902 6.65651 7.18102 6.44101 7.03752C6.22551 6.89402 5.85451 6.76202 5.68701 6.87002C5.51951 6.97802 5.44751 6.75052 5.57951 6.64252C5.71151 6.53452 6.33351 6.54702 6.44101 6.67852C6.54851 6.81002 6.72801 6.72652 6.52501 6.61852C6.32151 6.51102 6.14201 6.40302 5.93851 6.31952C5.73501 6.23552 5.99851 6.11602 6.24951 6.15202C6.50051 6.18802 6.99151 6.73852 7.12301 6.88202C7.25551 7.02652 7.42301 7.05052 7.42301 7.05052Z" fill="#2E7D32"/>
  </svg>
</template>

<script>
export default {
  name: 'CC',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  