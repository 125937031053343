
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M16 2.8375C14.7745 2.302 13.423 2 12 2C10.577 2 9.2255 2.302 8 2.8375V21.162C9.2255 21.698 10.577 22 12 22C13.423 22 14.7745 21.698 16 21.1625V2.8375Z" fill="#FFCA28"/>
<path d="M22 11.9999C22 7.9004 19.5305 4.3814 16 2.8374V21.1619C19.5305 19.6184 22 16.0994 22 11.9999Z" fill="#D50000"/>
<path d="M2 11.9999C2 16.0994 4.4695 19.6184 8 21.1624V2.8374C4.4695 4.3814 2 7.9004 2 11.9999Z" fill="#1A237E"/>
  </svg>
</template>

<script>
export default {
  name: 'RO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  