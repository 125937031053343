
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.7989 14H12.4999H2.20093C2.50143 15.4805 3.13743 16.8355 4.01393 18H19.9869C20.8624 16.8355 21.4984 15.4805 21.7989 14Z" fill="#FFEB3B"/>
<path d="M12.5 10H21.799C21.4985 8.5195 20.8625 7.1645 19.986 6H12.5V10Z" fill="#FFEB3B"/>
<path d="M19.9865 5.9999C18.256 3.7004 15.562 2.1769 12.5 2.0249V5.9999H19.9865Z" fill="#4CAF50"/>
<path d="M19.9864 18H4.01343C5.83793 20.425 8.73143 22 11.9999 22C15.2684 22 18.1614 20.425 19.9864 18Z" fill="#4CAF50"/>
<path d="M12.5 14H21.799C21.9305 13.3535 22 12.685 22 12C22 11.315 21.9305 10.6465 21.799 10H12.5V14Z" fill="#4CAF50"/>
<path d="M12.5 10V6V2.025C12.3335 2.017 12.169 2 12 2C6.477 2 2 6.477 2 12C2 12.685 2.0695 13.3535 2.201 14H12.5V10Z" fill="#FF3D00"/>
<path d="M7.49996 5.51953L8.24396 8.00003H10.654L8.70496 9.22853L9.44896 11.5195L7.49996 10.1035L5.55096 11.5195L6.29496 9.22853L4.34546 8.00003H6.75546L7.49996 5.51953Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TG',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  