
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M20.651 17H5.00196L3.83496 17.761C5.64546 20.3225 8.62446 22 12 22C15.699 22 18.9215 19.986 20.651 17Z" fill="#388E3C"/>
<path d="M12.6665 12.0025L7.30248 15.5H21.3575C21.766 14.409 22 13.2335 22 12C22 10.7665 21.766 9.591 21.358 8.5H7.29749L12.6665 12.0025Z" fill="#D50000"/>
<path d="M20.6515 7C18.9215 4.014 15.699 2 12 2C8.62348 2 5.64448 3.678 3.83398 6.2405L4.99798 7H20.6515Z" fill="#424242"/>
<path d="M7.29755 8.5H21.358C21.1625 7.978 20.9275 7.4765 20.6515 7H4.99805L7.29755 8.5Z" fill="white"/>
<path d="M5.00195 17H20.6515C20.9275 16.5235 21.1625 16.022 21.358 15.5H7.30295L5.00195 17Z" fill="white"/>
<path d="M7.3025 15.5L12.6665 12.0025L7.2975 8.49998L4.998 6.99998L3.834 6.24048C2.6825 7.86948 2 9.85348 2 12C2 14.147 2.683 16.1315 3.835 17.761L5.002 17L7.3025 15.5Z" fill="#3F51B5"/>
<path d="M6.69351 12.0686L7.73451 10.6781L6.04901 11.2056L5.00401 9.80908L5.00801 11.5316L3.32251 12.0611L5.01001 12.5981L5.01201 14.3206L6.05101 12.9281L7.73851 13.4651L6.69351 12.0686Z" fill="#FFEB3B"/>
  </svg>
</template>

<script>
export default {
  name: 'SS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  