
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M2.8375 8C2.302 9.2255 2 10.577 2 12C2 13.423 2.302 14.7745 2.8375 16H21.162C21.698 14.7745 22 13.423 22 12C22 10.577 21.698 9.2255 21.1625 8H2.8375Z" fill="#FBC02D"/>
<path d="M12 2C7.90052 2 4.38152 4.4695 2.83752 8H21.162C19.6185 4.4695 16.0995 2 12 2Z" fill="#D50000"/>
<path d="M12 22C16.0995 22 19.6185 19.5305 21.1625 16H2.83752C4.38152 19.5305 7.90052 22 12 22Z" fill="#388E3C"/>
<path d="M13.6 12.96L16.16 11.04H12.96L12 8L11.04 11.04H7.83997L10.4 12.96L9.43997 16L12 14.16L14.56 16L13.6 12.96Z" fill="#212121"/>
  </svg>
</template>

<script>
export default {
  name: 'GH',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  