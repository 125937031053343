
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M19.9865 6H4.0135C2.755 7.6725 2 9.7455 2 12C2 14.2545 2.755 16.3275 4.0135 18H19.9865C21.245 16.3275 22 14.2545 22 12C22 9.7455 21.245 7.6725 19.9865 6Z" fill="#D50000"/>
<path d="M14.6155 11.375H9.61646V13.373H14.6155V11.375Z" fill="white"/>
<path d="M14.6405 13.3981H9.59204V11.3501H14.6405V13.3981ZM9.64154 13.3481H14.5905V11.4001H9.64154V13.3481Z" fill="#424242"/>
<path d="M8.01646 11.314V11.888L8.16196 12.003V12.9675L7.97046 13.2965H8.96596V11.3295L8.01646 11.314Z" fill="white"/>
<path d="M8.9905 13.3221H7.927L8.137 12.9611V12.0151L7.9915 11.9006V11.2886L8.9905 11.3046V13.3221ZM8.014 13.2721H8.941V11.3536L8.0415 11.3396V11.8761L8.187 11.9906V12.9746L8.184 12.9806L8.014 13.2721Z" fill="#424242"/>
<path d="M10.007 11.314V11.888L9.86148 12.003V12.9675L10.053 13.2965H9.05798V11.3295L10.007 11.314Z" fill="white"/>
<path d="M10.0965 13.3221H9.03296V11.3051L9.05746 11.3046L10.032 11.2891V11.9011L9.88646 12.0156V12.9616L10.0965 13.3221ZM9.08296 13.2721H10.01L9.83696 12.9746V11.9906L9.98246 11.8761V11.3396L9.08296 11.3536V13.2721Z" fill="#424242"/>
<path d="M9.47105 12.9065V12.118L9.67755 11.8655V10.786L9.57805 10.924L9.45555 10.84L9.56255 10.641L9.40155 10.082L9.28005 10.25C9.28005 10.25 9.30305 10.2195 9.29555 9.98954C9.28805 9.76004 9.01255 9.67554 9.01255 9.67554C9.01255 9.67554 8.73705 9.75954 8.72955 9.98954C8.72205 10.219 8.74505 10.25 8.74505 10.25L8.62255 10.0815L8.46155 10.6405L8.56855 10.8395L8.44605 10.9235L8.34655 10.7855V11.865L8.55305 12.1175V12.906L8.33105 13.2965H9.01255H9.69405L9.47105 12.9065Z" fill="white"/>
<path d="M9.73598 13.3219H8.28748L8.52748 12.8994V12.1264L8.32098 11.8739V10.7084L8.45148 10.8889L8.53498 10.8314L8.43348 10.6429L8.43647 10.6329L8.61147 10.0249L8.70347 10.1519C8.70147 10.1124 8.70097 10.0594 8.70347 9.98843C8.71197 9.74343 8.99248 9.65493 9.00448 9.65143L9.01198 9.64893L9.01947 9.65143C9.03147 9.65493 9.31198 9.74343 9.32048 9.98843C9.32298 10.0599 9.32248 10.1124 9.32048 10.1519L9.41248 10.0249L9.58998 10.6424L9.48847 10.8309L9.57248 10.8884L9.70298 10.7079V11.8734L9.49598 12.1259V12.8989L9.73598 13.3219ZM8.37348 13.2719H9.64997L9.44598 12.9129V12.1089L9.65298 11.8564V10.8629L9.58397 10.9574L9.42298 10.8469L9.53598 10.6369L9.39248 10.1374L9.29998 10.2644C9.29998 10.2644 9.29998 10.2644 9.29998 10.2649L9.25947 10.2349C9.25897 10.2349 9.27648 10.1934 9.26998 9.99043C9.26348 9.80143 9.05398 9.71693 9.01148 9.70193C8.96948 9.71693 8.75948 9.80143 8.75298 9.99043C8.74648 10.1954 8.76398 10.2354 8.76498 10.2369L8.72348 10.2649C8.72348 10.2644 8.72348 10.2644 8.72348 10.2644L8.63097 10.1374L8.48748 10.6369L8.60048 10.8469L8.43898 10.9574L8.37047 10.8629V11.8564L8.57697 12.1089V12.9129L8.57398 12.9184L8.37348 13.2719Z" fill="#424242"/>
<path d="M9.24156 11.98L9.46356 11.7045L9.11156 10.0585L9.01206 9.93604L8.91256 10.0585L8.56006 11.7045L8.78206 11.98V12.9445L8.60606 13.3045H9.01206H9.41756L9.24156 12.9445V11.98Z" fill="white"/>
<path d="M9.45746 13.329H8.56596L8.75696 12.939V11.989L8.53296 11.711L8.53546 11.6995L8.89246 10.0435L9.01146 9.89697L9.13546 10.0535L9.48996 11.711L9.48246 11.7205L9.26546 11.989V12.939L9.45746 13.329ZM8.64596 13.2795H9.37696L9.21596 12.945V11.9715L9.22146 11.9645L9.43646 11.6985L9.08796 10.07L9.01196 9.97597L8.93546 10.07L8.58696 11.6985L8.80696 11.9715L8.80446 12.9565L8.64596 13.2795Z" fill="#424242"/>
<path d="M14.003 11.314V11.888L14.1485 12.003V12.9675L13.957 13.2965H14.9525V11.3295L14.003 11.314Z" fill="white"/>
<path d="M14.9774 13.3221H13.9139L14.1239 12.9611V12.0151L13.9784 11.9006V11.2886L14.9774 11.3046V13.3221ZM14.0004 13.2721H14.9274V11.3536L14.0279 11.3396V11.8761L14.1734 11.9906V12.9746L14.1704 12.9806L14.0004 13.2721Z" fill="#424242"/>
<path d="M15.9936 11.314V11.888L15.8481 12.003V12.9675L16.0396 13.2965H15.0446V11.3295L15.9936 11.314Z" fill="white"/>
<path d="M16.083 13.3221H15.0195V11.3051L15.044 11.3046L16.019 11.2891V11.9011L15.8735 12.0156V12.9616L16.083 13.3221ZM15.069 13.2721H15.996L15.823 12.9746V11.9906L15.9685 11.8761V11.3396L15.069 11.3536V13.2721Z" fill="#424242"/>
<path d="M15.4575 12.9065V12.118L15.664 11.8655V10.786L15.5645 10.924L15.442 10.84L15.549 10.641L15.388 10.082L15.2665 10.25C15.2665 10.25 15.2895 10.2195 15.282 9.98954C15.2745 9.76004 14.999 9.67554 14.999 9.67554C14.999 9.67554 14.7235 9.75954 14.716 9.98954C14.7085 10.219 14.7315 10.25 14.7315 10.25L14.609 10.0815L14.448 10.6405L14.555 10.8395L14.4325 10.9235L14.333 10.7855V11.865L14.5395 12.1175V12.906L14.3175 13.2965H14.999H15.6805L15.4575 12.9065Z" fill="white"/>
<path d="M15.7225 13.3219H14.274L14.514 12.8994V12.1264L14.307 11.8739V10.7084L14.4375 10.8889L14.5215 10.8314L14.42 10.6429L14.423 10.6329L14.598 10.0249L14.69 10.1519C14.688 10.1124 14.6875 10.0594 14.69 9.98843C14.698 9.74343 14.979 9.65493 14.991 9.65143L14.9985 9.64893L15.006 9.65143C15.018 9.65493 15.2985 9.74343 15.307 9.98843C15.3095 10.0594 15.309 10.1124 15.307 10.1519L15.399 10.0249L15.577 10.6424L15.4755 10.8309L15.559 10.8884L15.6895 10.7079V11.8734L15.483 12.1259V12.8989L15.7225 13.3219ZM14.36 13.2719H15.6365L15.4325 12.9129V12.1089L15.639 11.8564V10.8629L15.5705 10.9574L15.409 10.8469L15.522 10.6369L15.378 10.1374L15.2855 10.2644C15.2855 10.2644 15.2855 10.2644 15.2855 10.2649L15.245 10.2349C15.2445 10.2349 15.262 10.1934 15.2555 9.99043C15.249 9.80093 15.0395 9.71693 14.997 9.70193C14.955 9.71693 14.745 9.80143 14.7385 9.99043C14.732 10.1954 14.7495 10.2354 14.7505 10.2369L14.709 10.2649C14.709 10.2644 14.709 10.2644 14.709 10.2644L14.6165 10.1374L14.473 10.6369L14.586 10.8469L14.425 10.9574L14.356 10.8629V11.8564L14.563 12.1089V12.9129L14.56 12.9184L14.36 13.2719Z" fill="#424242"/>
<path d="M15.228 11.98L15.45 11.7045L15.098 10.0585L14.9985 9.93604L14.899 10.0585L14.5465 11.7045L14.7685 11.98V12.9445L14.5925 13.3045H14.9985H15.404L15.228 12.9445V11.98Z" fill="white"/>
<path d="M15.444 13.329H14.5525L14.744 12.939V11.989L14.52 11.711L14.5225 11.6995L14.8795 10.0435L14.9985 9.89697L15.1225 10.0535L15.477 11.711L15.4695 11.7205L15.253 11.989V12.939L15.444 13.329ZM14.6325 13.2795H15.364L15.203 12.945V11.9715L15.2085 11.9645L15.423 11.6985L15.0745 10.07L14.998 9.97597L14.922 10.07L14.5735 11.6985L14.7935 11.9715L14.791 12.9565L14.6325 13.2795Z" fill="#424242"/>
<path d="M10.725 10.7261V11.4701L10.9135 11.6186V12.8681L10.6655 13.2946H11.9545V10.7461L10.725 10.7261Z" fill="white"/>
<path d="M11.9794 13.3194H10.6219L10.8884 12.8614V11.6309L10.6999 11.4819V10.7009L10.7254 10.7014L11.9794 10.7219V13.3194ZM10.7089 13.2694H11.9294V10.7704L10.7494 10.7514V11.4574L10.9379 11.6064V12.8749L10.9349 12.8809L10.7089 13.2694Z" fill="#424242"/>
<path d="M13.302 10.7261V11.4701L13.1135 11.6186V12.8681L13.3615 13.2946H12.0725V10.7461L13.302 10.7261Z" fill="white"/>
<path d="M13.405 13.3194H12.0475V10.7219L12.072 10.7214L13.327 10.7009V11.4819L13.3175 11.4894L13.1385 11.6309V12.8614L13.405 13.3194ZM12.097 13.2694H13.3175L13.0885 12.8749V11.6064L13.098 11.5989L13.277 11.4574V10.7514L12.097 10.7704V13.2694Z" fill="#424242"/>
<path d="M12.6075 12.7885V11.767L12.875 11.44V10.042L12.746 10.2205L12.5875 10.1115L12.7265 9.8535L12.5185 9.1295L12.36 9.3475C12.36 9.3475 12.3895 9.308 12.38 9.0105C12.3705 8.719 12.028 8.6085 12.0145 8.6045L12.014 8.604L12.0135 8.6045C11.9995 8.609 11.6575 8.719 11.648 9.0105C11.638 9.308 11.668 9.3475 11.668 9.3475L11.5095 9.1295L11.3 9.8535L11.439 10.1115L11.2805 10.2205L11.1515 10.042V11.44L11.419 11.767V12.7885L11.1315 13.294H12.013H12.0145H12.896L12.6075 12.7885Z" fill="white"/>
<path d="M12.938 13.3196H11.0885L11.394 12.7821V11.7766L11.1265 11.4491V9.96511L11.286 10.1861L11.406 10.1036L11.273 9.85661L11.276 9.84661L11.4985 9.07311L11.6235 9.24561C11.62 9.19361 11.6185 9.11811 11.622 9.00961C11.631 8.73661 11.9085 8.61561 11.9875 8.58661V8.57911L12.0375 8.57861L12.038 8.58611C12.1155 8.61461 12.3955 8.73511 12.4045 9.00961C12.408 9.11811 12.4065 9.19311 12.403 9.24561L12.528 9.07311L12.7535 9.85611L12.7485 9.86511L12.6205 10.1036L12.7405 10.1861L12.9 9.96511V11.4491L12.632 11.7766V12.7821L12.938 13.3196ZM11.1745 13.2696H12.852L12.5825 12.7956V11.7591L12.8505 11.4316V10.1196L12.7525 10.2551L12.555 10.1196L12.7 9.85111L12.509 9.18611L12.3805 9.36311L12.34 9.33361C12.34 9.33311 12.364 9.28111 12.355 9.01211C12.3465 8.75911 12.0635 8.64811 12.0135 8.63111C11.964 8.64811 11.6805 8.75911 11.672 9.01211C11.663 9.28261 11.687 9.33311 11.688 9.33511L11.6465 9.36361L11.518 9.18611L11.327 9.85111L11.472 10.1196L11.2745 10.2551L11.1765 10.1196V11.4316L11.444 11.7591V12.7956L11.441 12.8011L11.1745 13.2696Z" fill="#424242"/>
<path d="M12.3105 11.5889L12.598 11.2319L12.1415 9.09992L12.014 8.94242V8.94092L12.0135 8.94192L12.013 8.94092V8.94242L11.885 9.09992L11.429 11.2319L11.7165 11.5889V12.8384L11.4885 13.3044H12.013H12.014H12.5385L12.3105 12.8384V11.5889Z" fill="white"/>
<path d="M12.5785 13.329H11.4485L11.6915 12.8325V11.5975L11.402 11.238L11.4045 11.2265L11.866 9.08395L11.988 8.93396V8.86746L12.0135 8.90046L12.0385 8.86646L12.039 8.93396L12.166 9.09396L12.6245 11.238L12.617 11.2475L12.335 11.5975V12.8325L12.5785 13.329ZM11.5285 13.2795H12.498L12.285 12.8385V11.58L12.2905 11.573L12.571 11.2255L12.1185 9.11096L12.0135 8.98145L11.9085 9.11096L11.456 11.2255L11.7415 11.58L11.739 12.8495L11.5285 13.2795Z" fill="#424242"/>
<path d="M16.3075 13.2839H7.6925V13.7634H16.3075V13.2839Z" fill="white"/>
<path d="M16.3325 13.7885H7.66748V13.259H16.332L16.3325 13.7885ZM7.71748 13.739H16.2825V13.3095H7.71748V13.739Z" fill="#424242"/>
<path d="M16.606 13.728H7.39404V14.2075H16.606V13.728Z" fill="white"/>
<path d="M16.631 14.2324H7.36902V13.7029H16.6305L16.631 14.2324ZM7.41902 14.1824H16.581V13.7529H7.41902V14.1824Z" fill="#424242"/>
<path d="M16.8361 14.1719H7.16455V14.6514H16.8361V14.1719Z" fill="white"/>
<path d="M16.8605 14.677H7.13953V14.147H16.8605V14.677ZM7.18903 14.627H16.8105V14.197H7.18903V14.627Z" fill="#424242"/>
<path d="M17.0375 14.616H6.96301V15.0955H17.0375V14.616Z" fill="white"/>
<path d="M17.0625 15.1206H6.9375V14.5911H17.062L17.0625 15.1206ZM6.9875 15.0711H17.0125V14.6416H6.9875V15.0711Z" fill="#424242"/>
<path d="M14.8375 13.2815H14.6615V15.096H14.8375V13.2815Z" fill="white"/>
<path d="M14.8625 15.1206H14.6365V13.2561H14.8625V15.1206ZM14.6865 15.0711H14.8125V13.3066H14.6865V15.0711Z" fill="#424242"/>
<path d="M15.32 13.2815H15.144V15.096H15.32V13.2815Z" fill="white"/>
<path d="M15.3445 15.1206H15.1185V13.2561H15.3445V15.1206ZM15.1685 15.0711H15.2945V13.3066H15.1685V15.0711Z" fill="#424242"/>
<path d="M8.85105 13.2815H8.67505V15.096H8.85105V13.2815Z" fill="white"/>
<path d="M8.87603 15.1206H8.65002V13.2561H8.87603V15.1206ZM8.70002 15.0711H8.82603V13.3066H8.70002V15.0711Z" fill="#424242"/>
<path d="M9.33347 13.2815H9.15747V15.096H9.33347V13.2815Z" fill="white"/>
<path d="M9.35845 15.1206H9.13245V13.2561H9.35845V15.1206ZM9.18195 15.0711H9.30845V13.3066H9.18195V15.0711Z" fill="#424242"/>
<path d="M11.8595 13.2815H11.6835V15.096H11.8595V13.2815Z" fill="white"/>
<path d="M11.8844 15.1206H11.6584V13.2561H11.8844V15.1206ZM11.7084 15.0711H11.8344V13.3066H11.7084V15.0711Z" fill="#424242"/>
<path d="M12.342 13.2815H12.166V15.096H12.342V13.2815Z" fill="white"/>
<path d="M12.367 15.1206H12.141V13.2561H12.367V15.1206ZM12.1905 15.0711H12.317V13.3066H12.1905V15.0711Z" fill="#424242"/>
<path d="M13.5265 12.2419H13.343V13.0514H13.5265V12.2419Z" fill="white"/>
<path d="M13.5515 13.0765H13.318V12.217H13.5515V13.0765ZM13.3675 13.027H13.5015V12.267H13.3675V13.027Z" fill="#424242"/>
<path d="M13.911 12.2419H13.7275V13.0514H13.911V12.2419Z" fill="white"/>
<path d="M13.9365 13.0765H13.703V12.217H13.9365V13.0765ZM13.7525 13.027H13.8865V12.267H13.7525V13.027Z" fill="#424242"/>
<path d="M10.2765 12.2419H10.093V13.0514H10.2765V12.2419Z" fill="white"/>
<path d="M10.302 13.0765H10.0685V12.217H10.302V13.0765ZM10.118 13.027H10.252V12.267H10.118V13.027Z" fill="#424242"/>
<path d="M10.6615 12.2419H10.478V13.0514H10.6615V12.2419Z" fill="white"/>
<path d="M10.6865 13.0765H10.453V12.217H10.6865V13.0765ZM10.5025 13.027H10.6365V12.267H10.5025V13.027Z" fill="#424242"/>
<path d="M15.289 11.4705H14.7035V11.5205H15.289V11.4705Z" fill="#424242"/>
<path d="M15.186 11.178H14.807V11.228H15.186V11.178Z" fill="#424242"/>
<path d="M15.1455 10.9199H14.847V10.9699H15.1455V10.9199Z" fill="#424242"/>
<path d="M15.1225 10.656H14.87V10.706H15.1225V10.656Z" fill="#424242"/>
<path d="M15.094 10.4084H14.899V10.4584H15.094V10.4084Z" fill="#424242"/>
<path d="M9.31804 11.4705H8.73254V11.5205H9.31804V11.4705Z" fill="#424242"/>
<path d="M9.21457 11.178H8.83557V11.228H9.21457V11.178Z" fill="#424242"/>
<path d="M9.1746 10.9199H8.8761V10.9699H9.1746V10.9199Z" fill="#424242"/>
<path d="M9.15155 10.656H8.89905V10.706H9.15155V10.656Z" fill="#424242"/>
<path d="M9.12249 10.4084H8.92749V10.4584H9.12249V10.4084Z" fill="#424242"/>
<path d="M12.3035 11.1265H11.718V11.1765H12.3035V11.1265Z" fill="#424242"/>
<path d="M12.2005 10.696H11.8215V10.746H12.2005V10.696Z" fill="#424242"/>
<path d="M12.1601 10.3159H11.8616V10.3659H12.1601V10.3159Z" fill="#424242"/>
<path d="M12.137 9.92749H11.8845V9.97749H12.137V9.92749Z" fill="#424242"/>
<path d="M12.1086 9.56494H11.9136V9.61494H12.1086V9.56494Z" fill="#424242"/>
<path d="M12 2C8.50997 2 5.44147 3.7905 3.65247 6.5H20.3475C18.5585 3.7905 15.49 2 12 2Z" fill="#283593"/>
<path d="M12 22C15.49 22 18.5585 20.2095 20.3475 17.5H3.65247C5.44147 20.2095 8.50997 22 12 22Z" fill="#283593"/>
  </svg>
</template>

<script>
export default {
  name: 'KH',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  