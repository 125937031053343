
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.64805 2 3.95555 4.7855 2.58105 8.6665H21.4186C20.0446 4.7855 16.3521 2 12.0001 2Z" fill="#FF6D00"/>
<path d="M21.419 15.3335C21.7885 14.2895 22 13.1705 22 12C22 10.8295 21.7885 9.7105 21.419 8.6665H2.581C2.2115 9.7105 2 10.8295 2 12C2 13.1705 2.2115 14.2895 2.581 15.3335H21.419Z" fill="white"/>
<path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" fill="#FF6D00"/>
<path d="M11.9996 22H12.0006C16.3526 22 20.0446 19.2145 21.4186 15.3335H2.58105C3.95555 19.2145 7.64755 21.9995 11.9996 22Z" fill="#00C853"/>
  </svg>
</template>

<script>
export default {
  name: 'NE',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  