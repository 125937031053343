import AD from './AD.vue'
import AE from './AE.vue'
import AF from './AF.vue'
import AG from './AG.vue'
import AI from './AI.vue'
import AL from './AL.vue'
import AM from './AM.vue'
import AO from './AO.vue'
import AQ from './AQ.vue'
import AR from './AR.vue'
import AS from './AS.vue'
import AT from './AT.vue'
import AU from './AU.vue'
import AW from './AW.vue'
import AX from './AX.vue'
import AZ from './AZ.vue'
import BA from './BA.vue'
import BB from './BB.vue'
import BD from './BD.vue'
import BE from './BE.vue'
import BF from './BF.vue'
import BG from './BG.vue'
import BH from './BH.vue'
import BI from './BI.vue'
import BJ from './BJ.vue'
import BM from './BM.vue'
import BN from './BN.vue'
import BO from './BO.vue'
import BR from './BR.vue'
import BS from './BS.vue'
import BT from './BT.vue'
import BW from './BW.vue'
import BY from './BY.vue'
import BZ from './BZ.vue'
import CA from './CA.vue'
import CC from './CC.vue'
import CD from './CD.vue'
import CF from './CF.vue'
import CG from './CG.vue'
import CH from './CH.vue'
import CI from './CI.vue'
import CK from './CK.vue'
import CL from './CL.vue'
import CM from './CM.vue'
import CN from './CN.vue'
import CO from './CO.vue'
import CR from './CR.vue'
import CU from './CU.vue'
import CV from './CV.vue'
import CX from './CX.vue'
import CY from './CY.vue'
import CZ from './CZ.vue'
import DE from './DE.vue'
import DJ from './DJ.vue'
import DK from './DK.vue'
import DM from './DM.vue'
import DO from './DO.vue'
import DZ from './DZ.vue'
import EC from './EC.vue'
import EE from './EE.vue'
import EG from './EG.vue'
import EH from './EH.vue'
import ER from './ER.vue'
import ES from './ES.vue'
import ET from './ET.vue'
import EU from './EU.vue'
import Fallback from './Fallback.vue'
import FI from './FI.vue'
import FJ from './FJ.vue'
import FK from './FK.vue'
import FM from './FM.vue'
import FO from './FO.vue'
import FR from './FR.vue'
import GA from './GA.vue'
import GB from './GB.vue'
import GD from './GD.vue'
import GE from './GE.vue'
import GG from './GG.vue'
import GH from './GH.vue'
import GI from './GI.vue'
import GL from './GL.vue'
import GM from './GM.vue'
import GN from './GN.vue'
import GQ from './GQ.vue'
import GR from './GR.vue'
import GS from './GS.vue'
import GT from './GT.vue'
import GU from './GU.vue'
import GW from './GW.vue'
import GY from './GY.vue'
import HK from './HK.vue'
import HN from './HN.vue'
import HR from './HR.vue'
import HT from './HT.vue'
import HU from './HU.vue'
import ID from './ID.vue'
import IE from './IE.vue'
import IL from './IL.vue'
import IM from './IM.vue'
import IN from './IN.vue'
import IO from './IO.vue'
import IQ from './IQ.vue'
import IR from './IR.vue'
import IS from './IS.vue'
import IT from './IT.vue'
import JE from './JE.vue'
import JM from './JM.vue'
import JO from './JO.vue'
import JP from './JP.vue'
import KE from './KE.vue'
import KG from './KG.vue'
import KH from './KH.vue'
import KI from './KI.vue'
import KM from './KM.vue'
import KN from './KN.vue'
import KP from './KP.vue'
import KR from './KR.vue'
import KW from './KW.vue'
import KY from './KY.vue'
import KZ from './KZ.vue'
import LA from './LA.vue'
import LB from './LB.vue'
import LC from './LC.vue'
import LI from './LI.vue'
import LK from './LK.vue'
import LR from './LR.vue'
import LS from './LS.vue'
import LT from './LT.vue'
import LU from './LU.vue'
import LV from './LV.vue'
import LY from './LY.vue'
import MA from './MA.vue'
import MC from './MC.vue'
import MD from './MD.vue'
import ME from './ME.vue'
import MG from './MG.vue'
import MH from './MH.vue'
import MK from './MK.vue'
import ML from './ML.vue'
import MM from './MM.vue'
import MN from './MN.vue'
import MO from './MO.vue'
import MP from './MP.vue'
import MR from './MR.vue'
import MS from './MS.vue'
import MT from './MT.vue'
import MU from './MU.vue'
import MV from './MV.vue'
import MW from './MW.vue'
import MX from './MX.vue'
import MY from './MY.vue'
import MZ from './MZ.vue'
import NA from './NA.vue'
import NE from './NE.vue'
import NF from './NF.vue'
import NG from './NG.vue'
import NI from './NI.vue'
import NL from './NL.vue'
import NO from './NO.vue'
import NP from './NP.vue'
import NR from './NR.vue'
import NU from './NU.vue'
import NZ from './NZ.vue'
import OM from './OM.vue'
import PA from './PA.vue'
import PE from './PE.vue'
import PF from './PF.vue'
import PG from './PG.vue'
import PH from './PH.vue'
import PK from './PK.vue'
import PL from './PL.vue'
import PN from './PN.vue'
import PR from './PR.vue'
import PS from './PS.vue'
import PT from './PT.vue'
import PY from './PY.vue'
import QA from './QA.vue'
import RO from './RO.vue'
import RS from './RS.vue'
import RU from './RU.vue'
import RW from './RW.vue'
import SA from './SA.vue'
import SB from './SB.vue'
import SC from './SC.vue'
import SD from './SD.vue'
import SE from './SE.vue'
import SG from './SG.vue'
import SI from './SI.vue'
import SK from './SK.vue'
import SL from './SL.vue'
import SM from './SM.vue'
import SN from './SN.vue'
import SO from './SO.vue'
import SR from './SR.vue'
import SS from './SS.vue'
import ST from './ST.vue'
import SV from './SV.vue'
import SY from './SY.vue'
import TC from './TC.vue'
import TD from './TD.vue'
import TF from './TF.vue'
import TG from './TG.vue'
import TH from './TH.vue'
import TJ from './TJ.vue'
import TK from './TK.vue'
import TLS from './TLS.vue'
import TM from './TM.vue'
import TN from './TN.vue'
import TO from './TO.vue'
import TR from './TR.vue'
import TT from './TT.vue'
import TW from './TW.vue'
import TZ from './TZ.vue'
import UA from './UA.vue'
import UG from './UG.vue'
import US from './US.vue'
import UY from './UY.vue'
import UZ from './UZ.vue'
import VA from './VA.vue'
import VC from './VC.vue'
import VE from './VE.vue'
import VG from './VG.vue'
import VN from './VN.vue'
import VQ from './VQ.vue'
import VU from './VU.vue'
import WS from './WS.vue'
import XOF from './XOF.vue'
import YE from './YE.vue'
import ZA from './ZA.vue'
import ZM from './ZM.vue'
import ZW from './ZW.vue'

export default {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BM,
  BN,
  BO,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  EU,
  Fallback,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PN,
  PR,
  PS,
  PT,
  PY,
  QA,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SI,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SY,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TLS,
  TM,
  TN,
  TO,
  TR,
  TT,
  TW,
  TZ,
  UA,
  UG,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VN,
  VQ,
  VU,
  WS,
  XOF,
  YE,
  ZA,
  ZM,
  ZW,
} as Record<string, typeof Fallback>;
