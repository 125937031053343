
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M12 9.5C10.619 9.5 9.5 10.619 9.5 12C9.5 13.381 10.619 14.5 12 14.5C13.381 14.5 14.5 13.381 14.5 12C14.5 10.619 13.381 9.5 12 9.5ZM12 14.188C10.791 14.188 9.812 13.209 9.812 12C9.812 10.791 10.791 9.812 12 9.812C13.209 9.812 14.188 10.791 14.188 12C14.188 13.209 13.209 14.188 12 14.188Z" fill="#000088"/>
<path d="M12.437 12C12.437 12.2425 12.242 12.437 12 12.437C11.758 12.437 11.563 12.242 11.563 12C11.563 11.758 11.758 11.563 12 11.563C12.242 11.563 12.437 11.7575 12.437 12Z" fill="#000088"/>
<path d="M14.1855 12.1774C14.1255 12.1699 14.0705 12.2099 14.063 12.2699C14.0555 12.3299 14.0955 12.3849 14.1555 12.3924C14.2155 12.4024 14.2705 12.3599 14.278 12.2999C14.2875 12.2399 14.2455 12.1849 14.1855 12.1774Z" fill="#000088"/>
<path d="M12 14.188L12.075 12.8745L12 12.25L11.925 12.8745L12 14.188Z" fill="#000088"/>
<path d="M14.0655 12.737C14.008 12.7145 13.9455 12.7395 13.9205 12.797C13.898 12.852 13.9255 12.917 13.9805 12.9395C14.0355 12.962 14.1005 12.937 14.123 12.8795C14.148 12.824 14.1205 12.759 14.0655 12.737Z" fill="#000088"/>
<path d="M11.433 14.1129L11.845 12.8639L11.935 12.2419L11.7 12.8264L11.433 14.1129Z" fill="#000088"/>
<path d="M13.803 13.2465C13.7555 13.209 13.688 13.2165 13.6505 13.2665C13.613 13.314 13.623 13.3815 13.6705 13.419C13.718 13.4565 13.788 13.4465 13.823 13.399C13.8605 13.351 13.8505 13.281 13.803 13.2465Z" fill="#000088"/>
<path d="M10.906 13.8955L11.628 12.794L11.8755 12.217L11.4985 12.7215L10.906 13.8955Z" fill="#000088"/>
<path d="M13.421 13.6685C13.3835 13.621 13.3135 13.6135 13.266 13.6485C13.2185 13.686 13.2085 13.756 13.246 13.8035C13.2835 13.851 13.351 13.861 13.3985 13.8235C13.4485 13.7855 13.456 13.7185 13.421 13.6685Z" fill="#000088"/>
<path d="M10.4515 13.5485L11.433 12.672L11.8225 12.1775L11.328 12.567L10.4515 13.5485Z" fill="#000088"/>
<path d="M12.939 13.9781C12.9165 13.9231 12.8515 13.8981 12.7965 13.9206C12.7415 13.9431 12.714 14.0081 12.7365 14.0631C12.7615 14.1181 12.824 14.1456 12.879 14.1231C12.9365 14.0981 12.9615 14.0356 12.939 13.9781Z" fill="#000088"/>
<path d="M10.1045 13.094L11.2785 12.502L11.783 12.125L11.206 12.3725L10.1045 13.094Z" fill="#000088"/>
<path d="M12.3945 14.1554C12.387 14.0954 12.332 14.0529 12.272 14.0604C12.212 14.0704 12.1695 14.1254 12.177 14.1854C12.187 14.2454 12.242 14.2854 12.302 14.2779C12.362 14.2699 12.402 14.2154 12.3945 14.1554Z" fill="#000088"/>
<path d="M9.88696 12.5669L11.173 12.2999L11.7575 12.0649L11.1355 12.1549L9.88696 12.5669Z" fill="#000088"/>
<path d="M11.728 14.063C11.668 14.053 11.613 14.0955 11.6055 14.1555C11.598 14.2155 11.6405 14.2705 11.7005 14.278C11.7605 14.288 11.8155 14.2455 11.823 14.1855C11.83 14.1255 11.7875 14.0705 11.728 14.063Z" fill="#000088"/>
<path d="M9.81201 12L11.1255 12.075L11.75 12L11.1255 11.925L9.81201 12Z" fill="#000088"/>
<path d="M11.206 13.9205C11.151 13.898 11.086 13.9255 11.0635 13.9805C11.0385 14.0355 11.066 14.1005 11.121 14.123C11.1785 14.1455 11.241 14.1205 11.266 14.0655C11.288 14.008 11.2605 13.9455 11.206 13.9205Z" fill="#000088"/>
<path d="M9.88696 11.4331L11.136 11.8451L11.758 11.9351L11.1735 11.7001L9.88696 11.4331Z" fill="#000088"/>
<path d="M10.7365 13.6485C10.689 13.6135 10.619 13.621 10.584 13.6685C10.5465 13.7185 10.554 13.786 10.604 13.8235C10.6515 13.8585 10.719 13.851 10.7565 13.8035C10.7935 13.753 10.7835 13.686 10.7365 13.6485Z" fill="#000088"/>
<path d="M10.1045 10.906L11.206 11.628L11.783 11.8755L11.2785 11.4985L10.1045 10.906Z" fill="#000088"/>
<path d="M10.3515 13.266C10.314 13.2185 10.2465 13.2085 10.199 13.246C10.1515 13.281 10.1415 13.351 10.179 13.3985C10.214 13.446 10.284 13.456 10.3315 13.4185C10.379 13.381 10.389 13.3135 10.3515 13.266Z" fill="#000088"/>
<path d="M10.4515 10.4514L11.328 11.4329L11.8225 11.8224L11.433 11.3279L10.4515 10.4514Z" fill="#000088"/>
<path d="M10.0795 12.7941C10.057 12.7391 9.99201 12.7116 9.93701 12.7366C9.88201 12.7591 9.85451 12.8241 9.87701 12.8791C9.90201 12.9341 9.96451 12.9616 10.022 12.9391C10.077 12.9141 10.1045 12.8516 10.0795 12.7941Z" fill="#000088"/>
<path d="M10.906 10.1045L11.498 11.2785L11.875 11.783L11.6275 11.206L10.906 10.1045Z" fill="#000088"/>
<path d="M9.93955 12.2694C9.92955 12.2094 9.87455 12.1694 9.81705 12.1769C9.75705 12.1844 9.71455 12.2394 9.72205 12.2994C9.72955 12.3594 9.78455 12.4019 9.84455 12.3919C9.90455 12.3844 9.94705 12.3294 9.93955 12.2694Z" fill="#000088"/>
<path d="M11.433 9.88696L11.7 11.173L11.935 11.7575L11.845 11.1355L11.433 9.88696Z" fill="#000088"/>
<path d="M9.84455 11.6056C9.78455 11.5981 9.72955 11.6406 9.72205 11.7006C9.71455 11.7606 9.75705 11.8156 9.81705 11.8231C9.87705 11.8306 9.93205 11.7881 9.93955 11.7281C9.94705 11.6681 9.90455 11.6131 9.84455 11.6056Z" fill="#000088"/>
<path d="M12 9.81201L11.925 11.1255L12 11.75L12.075 11.1255L12 9.81201Z" fill="#000088"/>
<path d="M10.0195 11.0611C9.96448 11.0386 9.89948 11.0661 9.87698 11.1211C9.85448 11.1761 9.87948 11.2411 9.93698 11.2636C9.99198 11.2861 10.057 11.2611 10.0795 11.2036C10.102 11.1486 10.0745 11.0836 10.0195 11.0611Z" fill="#000088"/>
<path d="M12.5669 9.88696L12.1549 11.136L12.0649 11.758L12.2999 11.1735L12.5669 9.88696Z" fill="#000088"/>
<path d="M10.3315 10.5815C10.2815 10.544 10.214 10.5515 10.1765 10.6015C10.1415 10.649 10.149 10.7165 10.1965 10.754C10.2465 10.7915 10.314 10.7815 10.3515 10.734C10.3865 10.6865 10.379 10.6165 10.3315 10.5815Z" fill="#000088"/>
<path d="M13.094 10.1045L12.372 11.206L12.1245 11.783L12.5015 11.2785L13.094 10.1045Z" fill="#000088"/>
<path d="M10.7559 10.1969C10.7184 10.1494 10.6509 10.1394 10.6034 10.1769C10.5534 10.2144 10.5459 10.2819 10.5809 10.3319C10.6184 10.3794 10.6884 10.3869 10.7359 10.3519C10.7834 10.3139 10.7934 10.2444 10.7559 10.1969Z" fill="#000088"/>
<path d="M13.5485 10.4514L12.567 11.3279L12.1775 11.8224L12.672 11.4329L13.5485 10.4514Z" fill="#000088"/>
<path d="M11.263 9.93692C11.2405 9.88192 11.1755 9.85442 11.1205 9.87942C11.0655 9.90192 11.038 9.96442 11.0605 10.0219C11.083 10.0769 11.148 10.1044 11.203 10.0794C11.2605 10.0569 11.2855 9.99442 11.263 9.93692Z" fill="#000088"/>
<path d="M13.8955 10.906L12.7215 11.498L12.217 11.875L12.794 11.6275L13.8955 10.906Z" fill="#000088"/>
<path d="M11.8225 9.81459C11.815 9.75459 11.76 9.71459 11.7 9.72209C11.64 9.72959 11.5975 9.78459 11.605 9.84459C11.6125 9.90459 11.6675 9.94709 11.7275 9.93709C11.7875 9.92959 11.83 9.87459 11.8225 9.81459Z" fill="#000088"/>
<path d="M14.1131 11.4331L12.8271 11.7001L12.2426 11.9351L12.8646 11.8451L14.1131 11.4331Z" fill="#000088"/>
<path d="M12.2995 9.72254C12.2395 9.71504 12.1845 9.75754 12.177 9.81754C12.1695 9.87754 12.212 9.93254 12.272 9.94004C12.332 9.94754 12.387 9.90504 12.3945 9.84504C12.402 9.78454 12.3595 9.73004 12.2995 9.72254Z" fill="#000088"/>
<path d="M14.188 12L12.8745 11.925L12.25 12L12.8745 12.075L14.188 12Z" fill="#000088"/>
<path d="M12.879 9.87697C12.824 9.85197 12.759 9.87947 12.7365 9.93447C12.714 9.99197 12.739 10.0545 12.794 10.077C12.8515 10.102 12.914 10.0745 12.939 10.0195C12.9615 9.96197 12.934 9.89947 12.879 9.87697Z" fill="#000088"/>
<path d="M14.1129 12.5669L12.8639 12.1549L12.2419 12.0649L12.8264 12.2999L14.1129 12.5669Z" fill="#000088"/>
<path d="M13.3985 10.1771C13.351 10.1421 13.2835 10.1496 13.246 10.1971C13.2085 10.2471 13.2185 10.3146 13.266 10.3521C13.3135 10.3871 13.3835 10.3796 13.421 10.3321C13.456 10.2816 13.4485 10.2146 13.3985 10.1771Z" fill="#000088"/>
<path d="M13.8955 13.094L12.794 12.372L12.217 12.1245L12.7215 12.5015L13.8955 13.094Z" fill="#000088"/>
<path d="M13.823 10.6015C13.7855 10.554 13.7155 10.544 13.668 10.5815C13.6205 10.619 13.6105 10.6865 13.648 10.734C13.6855 10.7815 13.753 10.7915 13.803 10.754C13.8505 10.719 13.858 10.649 13.823 10.6015Z" fill="#000088"/>
<path d="M13.5485 13.5485L12.672 12.567L12.1775 12.1775L12.567 12.672L13.5485 13.5485Z" fill="#000088"/>
<path d="M14.1231 11.1211C14.0981 11.0661 14.0356 11.0386 13.9806 11.0611C13.9231 11.0861 13.8981 11.1486 13.9206 11.2036C13.9431 11.2611 14.0081 11.2861 14.0631 11.2636C14.1181 11.2411 14.1456 11.1761 14.1231 11.1211Z" fill="#000088"/>
<path d="M13.094 13.8955L12.502 12.7215L12.125 12.217L12.3725 12.794L13.094 13.8955Z" fill="#000088"/>
<path d="M14.2775 11.7006C14.27 11.6406 14.215 11.5981 14.155 11.6056C14.095 11.6156 14.0525 11.6681 14.0625 11.7281C14.07 11.7881 14.125 11.8306 14.185 11.8231C14.2455 11.8151 14.2875 11.7601 14.2775 11.7006Z" fill="#000088"/>
<path d="M12.5669 14.1129L12.2999 12.8269L12.0649 12.2424L12.1549 12.8644L12.5669 14.1129Z" fill="#000088"/>
<path d="M21.358 15.5H2.64197C4.06247 19.294 7.71047 22 12 22C16.2895 22 19.9375 19.294 21.358 15.5Z" fill="#388E3C"/>
<path d="M12 2C7.71047 2 4.06247 4.706 2.64197 8.5H21.3575C19.9375 4.706 16.2895 2 12 2Z" fill="#FF9800"/>
  </svg>
</template>

<script>
export default {
  name: 'IN',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  