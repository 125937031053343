
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M11.9945 22C17.5174 22 21.9945 17.5228 21.9945 12C21.9945 6.47715 17.5174 2 11.9945 2C6.47166 2 1.99451 6.47715 1.99451 12C1.99451 17.5228 6.47166 22 11.9945 22Z" fill="#D50000"/>
<path d="M11.9945 18.5054C15.5874 18.5054 18.5 15.5928 18.5 11.9999C18.5 8.407 15.5874 5.49438 11.9945 5.49438C8.40163 5.49438 5.48901 8.407 5.48901 11.9999C5.48901 15.5928 8.40163 18.5054 11.9945 18.5054Z" fill="#388E3C"/>
<path d="M10.733 12.0495C10.733 10.3225 12.0235 8.894 13.7115 8.6265C13.4155 8.5465 13.106 8.5 12.785 8.5C10.825 8.5 9.23547 10.089 9.23547 12.0495C9.23547 14.01 10.8245 15.599 12.785 15.599C13.106 15.599 13.4155 15.5525 13.7115 15.4725C12.023 15.2045 10.733 13.7765 10.733 12.0495Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'MV',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  