
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M2 12C2 16.4775 4.943 20.2665 9 21.541V2.45898C4.943 3.73348 2 7.52248 2 12Z" fill="#C62828"/>
<path d="M15 2.45898V21.5405C19.057 20.2665 22 16.4775 22 12C22 7.52248 19.057 3.73348 15 2.45898Z" fill="#C62828"/>
<path d="M12 2C10.9545 2 9.947 2.1615 9 2.459V21.5405C9.947 21.8385 10.9545 22 12 22C13.0455 22 14.053 21.8385 15 21.541V2.459C14.053 2.1615 13.0455 2 12 2Z" fill="#01579B"/>
<path d="M6 9.49991C5.33 9.49991 4.754 9.05091 4.5 8.47241C4.5 8.48241 4.5 8.48991 4.5 8.49991C4.5 9.32791 5.172 9.99991 6 9.99991C6.828 9.99991 7.5 9.32791 7.5 8.49991C7.5 8.48991 7.5 8.48241 7.5 8.47241C7.246 9.05091 6.67 9.49991 6 9.49991Z" fill="#FFD600"/>
<path d="M7 8C7 8.5525 6.5525 9 6 9C5.4475 9 5 8.5525 5 8C5 7.4475 5.4475 7 6 7C6.5525 7 7 7.4475 7 8Z" fill="#FFD600"/>
<path d="M5.5 6.0315C5.5 6.289 5.7245 6.5 6 6.5C6.2755 6.5 6.5 6.289 6.5 6.031C6.5 5.8435 6.375 5.82 6.375 5.7495C6.375 5.679 6.449 5.5855 6.301 5.468C6.375 5.585 6.25 5.6085 6.25 5.726C6.25 5.8435 6.301 5.843 6.301 5.937C6.301 5.988 6.256 6.031 6.1995 6.031C6.145 6.031 6.1 5.988 6.1 5.937C6.1 5.843 6.1995 5.7495 6.1995 5.609C6.1995 5.4685 6.176 5.4215 6.1 5.3045C6.0255 5.1875 5.9005 5.094 6 5C5.8495 5.047 5.926 5.2345 5.926 5.3515C5.926 5.4685 5.8245 5.539 5.8245 5.6795C5.8245 5.82 5.9005 5.8435 5.9005 5.9375C5.9005 5.99 5.8555 6.0315 5.801 6.0315C5.7445 6.0315 5.6995 5.9905 5.6995 5.9375C5.6995 5.8435 5.7505 5.8435 5.7505 5.7265C5.7505 5.6095 5.6255 5.586 5.6995 5.4685C5.551 5.5855 5.6255 5.6795 5.6255 5.75C5.6255 5.8205 5.5 5.844 5.5 6.0315Z" fill="#FFD600"/>
<path d="M3.5 10.5H4.5V16.5H3.5V10.5Z" fill="#FFD600"/>
<path d="M7.5 10.5H8.5V16.5H7.5V10.5Z" fill="#FFD600"/>
<path d="M5 15.0115H7V15.5115H5V15.0115Z" fill="#FFD600"/>
<path d="M5 11.5H7V12H5V11.5Z" fill="#FFD600"/>
<path d="M5 16.0115H7L6 16.5115L5 16.0115Z" fill="#FFD600"/>
<path d="M5 10.5H7L6 11L5 10.5Z" fill="#FFD600"/>
<path d="M6 12.5115C5.449 12.5115 5 12.9605 5 13.5115C5 13.8905 5.213 14.2225 5.5255 14.3905C5.453 14.293 5.4105 14.1715 5.4105 14.041C5.4105 13.717 5.674 13.451 6.0005 13.451C6.2605 13.451 6.471 13.24 6.471 12.982C6.4705 12.7225 6.26 12.5115 6 12.5115ZM6 13.1815C5.8885 13.1815 5.801 13.0915 5.801 12.9825C5.801 12.871 5.889 12.7815 6 12.7815C6.111 12.7815 6.199 12.8715 6.199 12.9825C6.199 13.092 6.1115 13.1815 6 13.1815Z" fill="#FFD600"/>
<path d="M6.47455 12.6311C6.54705 12.7306 6.58955 12.8501 6.58955 12.9826C6.58955 13.3066 6.32605 13.5726 5.99955 13.5726C5.73955 13.5726 5.52905 13.7836 5.52905 14.0416C5.52905 14.3016 5.74005 14.5121 5.99955 14.5121C6.55055 14.5121 6.99955 14.0631 6.99955 13.5121C7.00005 13.1311 6.78705 12.8011 6.47455 12.6311ZM6.00005 14.2521C5.88855 14.2521 5.80105 14.1621 5.80105 14.0531C5.80105 13.9416 5.88905 13.8521 6.00005 13.8521C6.11105 13.8521 6.19905 13.9421 6.19905 14.0531C6.19905 14.1621 6.11155 14.2521 6.00005 14.2521Z" fill="#FFD600"/>
  </svg>
</template>

<script>
export default {
  name: 'MN',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  