
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M3.6525 6.5C2.6105 8.0785 2 9.967 2 12C2 14.033 2.6105 15.9215 3.6525 17.5H20.3475C21.3895 15.9215 22 14.033 22 12C22 9.967 21.3895 8.0785 20.3475 6.5H3.6525Z" fill="white"/>
<path d="M12.7546 7.44554C12.7546 7.44554 12.1301 7.39354 11.7761 7.38304C11.4221 7.37254 11.0681 7.72654 11.0681 8.17404C11.0681 8.62154 11.0891 10.964 11.0891 10.964L12.7546 11.422V9.03804C12.7546 9.03804 11.9116 8.89254 11.9321 8.17404C11.9531 7.45604 12.7546 7.44554 12.7546 7.44554Z" fill="#D50000"/>
<path d="M12.7795 11.4549L12.748 11.4459L11.064 10.9829V10.9639C11.064 10.9404 11.043 8.61691 11.043 8.17391C11.043 7.94191 11.137 7.71741 11.301 7.55891C11.433 7.43041 11.5995 7.35791 11.76 7.35791C11.7655 7.35791 11.7705 7.35791 11.776 7.35841C12.1265 7.36891 12.7495 7.42041 12.756 7.42091L12.754 7.47091C12.7225 7.47141 11.976 7.48941 11.9565 8.17491C11.9365 8.86391 12.75 9.01291 12.758 9.01441L12.779 9.01791L12.7795 11.4549ZM11.114 10.9454L12.7295 11.3899V9.05941C12.598 9.02991 11.888 8.84091 11.907 8.17391C11.921 7.68591 12.287 7.51441 12.533 7.45391C12.3185 7.43791 11.993 7.41491 11.7745 7.40841C11.7695 7.40841 11.7645 7.40841 11.76 7.40841C11.6125 7.40841 11.4585 7.47591 11.3355 7.59491C11.181 7.74491 11.0925 7.95591 11.0925 8.17441C11.0935 8.59541 11.112 10.7214 11.114 10.9454Z" fill="#880E4F"/>
<path d="M13.9724 9.49658H14.5034L14.4099 9.68408C14.4099 9.68408 14.6909 9.84008 14.3994 10.0066C14.6074 10.2146 14.5449 10.2981 14.3889 10.4231C14.5659 10.4961 14.5554 10.5271 14.5554 10.5271V13.0566C14.5554 13.0566 14.4409 14.2436 13.4934 14.2436C12.5459 14.2436 12.4109 13.1296 12.4109 13.1296C12.4109 13.1296 12.6919 13.4941 13.2539 13.4941C13.8159 13.4941 14.0034 13.1716 14.0034 12.9736C14.0034 12.7756 14.0034 10.5166 14.0034 10.5166L14.1489 10.4126C14.1489 10.4126 13.8989 10.2046 14.1279 10.0276C13.9509 9.87158 13.9614 9.79858 14.0969 9.68408C14.0559 9.62158 13.9724 9.49658 13.9724 9.49658Z" fill="#D50000"/>
<path d="M13.494 14.2684C12.5355 14.2684 12.388 13.1434 12.3865 13.1324L12.376 13.0424L12.4315 13.1144C12.434 13.1179 12.7125 13.4694 13.255 13.4694C13.7865 13.4694 13.9795 13.1729 13.9795 12.9739V10.5044L13.99 10.4964L14.111 10.4104C14.074 10.3724 14.0065 10.2899 14.008 10.1924C14.009 10.1314 14.037 10.0759 14.0915 10.0269C14.0075 9.94894 13.971 9.88994 13.9715 9.83344C13.972 9.77344 14.015 9.72393 14.0655 9.67943L13.927 9.47144H14.545L14.443 9.67444C14.4805 9.70044 14.557 9.76193 14.5575 9.84443C14.5575 9.90393 14.5185 9.95993 14.44 10.0109C14.5245 10.1009 14.561 10.1704 14.5575 10.2354C14.5535 10.3059 14.501 10.3619 14.4375 10.4154C14.5395 10.4609 14.5855 10.4969 14.581 10.5289V13.0564C14.5795 13.0714 14.453 14.2684 13.494 14.2684ZM12.451 13.2094C12.505 13.4644 12.7305 14.2189 13.494 14.2189C14.4075 14.2189 14.5295 13.0659 14.531 13.0544V10.5294C14.526 10.5229 14.5 10.4954 14.38 10.4459L14.3405 10.4299L14.3735 10.4034C14.456 10.3374 14.504 10.2889 14.507 10.2329C14.51 10.1794 14.4705 10.1129 14.382 10.0239L14.359 10.0009L14.3875 9.98493C14.467 9.93893 14.5075 9.89194 14.507 9.84494C14.5065 9.76794 14.399 9.70694 14.398 9.70644L14.377 9.69493L14.463 9.52194H14.019L14.1305 9.68944L14.1135 9.70343C14.049 9.75793 14.021 9.79744 14.0205 9.83444C14.02 9.87894 14.0585 9.93243 14.145 10.0094L14.1675 10.0294L14.1435 10.0474C14.087 10.0914 14.0575 10.1409 14.0565 10.1939C14.0545 10.3004 14.1635 10.3929 14.165 10.3939L14.1895 10.4144L14.028 10.5294V12.9739C14.028 13.1929 13.822 13.5194 13.2535 13.5194C12.828 13.5189 12.562 13.3164 12.451 13.2094Z" fill="#880E4F"/>
<path d="M9.94396 9.49658H9.41296L9.50646 9.68408C9.50646 9.68408 9.22546 9.84008 9.51696 10.0066C9.30896 10.2146 9.37146 10.2981 9.52746 10.4231C9.35046 10.4961 9.36096 10.5271 9.36096 10.5271V13.0566C9.36096 13.0566 9.47546 14.2436 10.423 14.2436C11.3705 14.2436 11.5055 13.1296 11.5055 13.1296C11.5055 13.1296 11.2245 13.4941 10.6625 13.4941C10.1005 13.4941 9.91296 13.1716 9.91296 12.9736C9.91296 12.7756 9.91296 10.5166 9.91296 10.5166L9.76746 10.4126C9.76746 10.4126 10.0175 10.2046 9.78846 10.0276C9.96546 9.87158 9.95496 9.79858 9.81946 9.68408C9.86096 9.62158 9.94396 9.49658 9.94396 9.49658Z" fill="#D50000"/>
<path d="M10.423 14.2684C9.46398 14.2684 9.33748 13.0709 9.33648 13.0589V10.5289C9.33198 10.4974 9.37798 10.4609 9.47998 10.4154C9.41648 10.3619 9.36398 10.3054 9.35998 10.2354C9.35648 10.1704 9.39248 10.1004 9.47748 10.0109C9.39898 9.95993 9.35948 9.90394 9.35998 9.84394C9.36048 9.76194 9.43648 9.70044 9.47448 9.67444L9.37248 9.47144H9.99048L9.85198 9.67943C9.90248 9.72393 9.94498 9.77344 9.94598 9.83344C9.94648 9.88944 9.91048 9.94793 9.82598 10.0264C9.88048 10.0759 9.90848 10.1314 9.90948 10.1924C9.91098 10.2894 9.84348 10.3719 9.80598 10.4104L9.93748 10.5044V12.9739C9.93748 13.1729 10.1305 13.4694 10.662 13.4694C11.2055 13.4694 11.483 13.1179 11.4855 13.1144L11.541 13.0419L11.53 13.1324C11.529 13.1434 11.3815 14.2684 10.423 14.2684ZM9.38598 10.5294V13.0569C9.38698 13.0664 9.50948 14.2189 10.423 14.2189C11.1865 14.2189 11.412 13.4644 11.466 13.2094C11.355 13.3169 11.089 13.5189 10.6625 13.5189C10.094 13.5189 9.88798 13.1929 9.88798 12.9734V10.5289L9.72648 10.4139L9.75098 10.3934C9.75198 10.3924 9.86148 10.2994 9.85948 10.1934C9.85848 10.1404 9.82898 10.0909 9.77248 10.0474L9.74848 10.0289L9.77098 10.0089C9.85748 9.93243 9.89598 9.87843 9.89548 9.83393C9.89498 9.79693 9.86748 9.75794 9.80248 9.70294L9.78548 9.68894L9.89698 9.52143H9.45298L9.53898 9.69444L9.51798 9.70594C9.51698 9.70644 9.40948 9.76743 9.40898 9.84443C9.40898 9.89193 9.44898 9.93844 9.52898 9.98444L9.55748 10.0004L9.53448 10.0234C9.44598 10.1124 9.40648 10.1789 9.40948 10.2324C9.41248 10.2879 9.46098 10.3369 9.54298 10.4029L9.57598 10.4294L9.53698 10.4454C9.41698 10.4954 9.39098 10.5229 9.38598 10.5294Z" fill="#880E4F"/>
<path d="M12.4109 12.8379H12.9524C12.9524 12.8379 13.2749 12.9939 13.4624 13.2544L12.8484 13.2754C12.8484 13.2754 12.5359 13.1294 12.4109 12.8379Z" fill="#880E4F"/>
<path d="M11.446 12.8379H10.905C10.905 12.8379 10.5825 12.9939 10.395 13.2544L11.009 13.2754C11.009 13.2754 11.3215 13.1294 11.446 12.8379Z" fill="#880E4F"/>
<path d="M13.8061 12.7421H10.0686C10.0411 12.7421 10.0186 12.7201 10.0186 12.6921C10.0186 12.6641 10.0411 12.6421 10.0686 12.6421H13.8061C13.8336 12.6421 13.8561 12.6641 13.8561 12.6921C13.8561 12.7201 13.8336 12.7421 13.8061 12.7421Z" fill="#880E4F"/>
<path d="M10.6521 12.409C10.6371 12.409 10.6221 12.4025 10.6126 12.3895L10.1961 11.848C10.1791 11.8265 10.1831 11.795 10.2051 11.778C10.2266 11.7615 10.2576 11.7645 10.2751 11.7875L10.6916 12.329C10.7086 12.3505 10.7046 12.382 10.6826 12.399C10.6736 12.406 10.6626 12.409 10.6521 12.409Z" fill="#880E4F"/>
<path d="M10.2045 12.4295C10.193 12.4295 10.1815 12.4255 10.172 12.418C10.151 12.4 10.149 12.368 10.167 12.347L10.677 11.7535C10.695 11.733 10.7265 11.7305 10.7475 11.748C10.7685 11.766 10.7705 11.798 10.7525 11.819L10.2425 12.4125C10.2325 12.424 10.2185 12.4295 10.2045 12.4295Z" fill="#880E4F"/>
<path d="M10.4646 12.1281C10.4371 12.1281 10.4146 12.1061 10.4146 12.0781V11.7351C10.4146 11.7071 10.4371 11.6851 10.4646 11.6851C10.4921 11.6851 10.5146 11.7071 10.5146 11.7351V12.0786C10.5146 12.1061 10.4921 12.1281 10.4646 12.1281Z" fill="#880E4F"/>
<path d="M11.3514 12.409C11.3364 12.409 11.3214 12.4025 11.3119 12.3895L10.8954 11.848C10.8784 11.8265 10.8829 11.795 10.9044 11.778C10.9259 11.7615 10.9574 11.7645 10.9744 11.7875L11.3909 12.329C11.4079 12.3505 11.4034 12.382 11.3819 12.399C11.3729 12.406 11.3619 12.409 11.3514 12.409Z" fill="#880E4F"/>
<path d="M10.904 12.4295C10.8925 12.4295 10.881 12.4255 10.8715 12.418C10.8505 12.4 10.8485 12.368 10.8665 12.347L11.3765 11.7535C11.394 11.733 11.4255 11.7305 11.447 11.748C11.468 11.766 11.47 11.798 11.452 11.819L10.942 12.4125C10.932 12.424 10.918 12.4295 10.904 12.4295Z" fill="#880E4F"/>
<path d="M11.164 12.1281C11.1365 12.1281 11.114 12.1061 11.114 12.0781V11.7351C11.114 11.7071 11.1365 11.6851 11.164 11.6851C11.1915 11.6851 11.214 11.7071 11.214 11.7351V12.0786C11.214 12.1061 11.1915 12.1281 11.164 12.1281Z" fill="#880E4F"/>
<path d="M12.0743 12.409C12.0593 12.409 12.0443 12.4025 12.0348 12.3895L11.6183 11.848C11.6013 11.8265 11.6058 11.795 11.6273 11.778C11.6483 11.7615 11.6803 11.7645 11.6973 11.7875L12.1138 12.329C12.1308 12.3505 12.1263 12.382 12.1048 12.399C12.0958 12.406 12.0848 12.409 12.0743 12.409Z" fill="#880E4F"/>
<path d="M11.6269 12.4295C11.6154 12.4295 11.6039 12.4255 11.5944 12.418C11.5734 12.4 11.5714 12.368 11.5894 12.347L12.0994 11.7535C12.1169 11.733 12.1489 11.7305 12.1699 11.748C12.1909 11.766 12.1929 11.798 12.1749 11.819L11.6649 12.4125C11.6549 12.424 11.6409 12.4295 11.6269 12.4295Z" fill="#880E4F"/>
<path d="M11.8869 12.1281C11.8594 12.1281 11.8369 12.1061 11.8369 12.0781V11.7351C11.8369 11.7071 11.8594 11.6851 11.8869 11.6851C11.9144 11.6851 11.9369 11.7071 11.9369 11.7351V12.0786C11.9374 12.1061 11.9149 12.1281 11.8869 12.1281Z" fill="#880E4F"/>
<path d="M12.827 12.409C12.812 12.409 12.797 12.4025 12.7875 12.3895L12.371 11.848C12.354 11.8265 12.3585 11.795 12.38 11.778C12.401 11.7615 12.433 11.7645 12.45 11.7875L12.8665 12.329C12.8835 12.3505 12.879 12.382 12.8575 12.399C12.8485 12.406 12.8375 12.409 12.827 12.409Z" fill="#880E4F"/>
<path d="M12.3796 12.4295C12.3681 12.4295 12.3566 12.4255 12.3471 12.418C12.3261 12.4 12.3241 12.368 12.3421 12.347L12.8521 11.7535C12.8696 11.733 12.9016 11.7305 12.9226 11.748C12.9436 11.766 12.9456 11.798 12.9276 11.819L12.4176 12.4125C12.4076 12.424 12.3936 12.4295 12.3796 12.4295Z" fill="#880E4F"/>
<path d="M12.6396 12.1281C12.6121 12.1281 12.5896 12.1061 12.5896 12.0781V11.7351C12.5896 11.7071 12.6121 11.6851 12.6396 11.6851C12.6671 11.6851 12.6896 11.7071 12.6896 11.7351V12.0786C12.6896 12.1061 12.6671 12.1281 12.6396 12.1281Z" fill="#880E4F"/>
<path d="M13.5733 12.409C13.5583 12.409 13.5433 12.4025 13.5338 12.3895L13.1173 11.848C13.1003 11.8265 13.1048 11.795 13.1263 11.778C13.1478 11.7615 13.1793 11.7645 13.1963 11.7875L13.6128 12.329C13.6298 12.3505 13.6253 12.382 13.6038 12.399C13.5948 12.406 13.5838 12.409 13.5733 12.409Z" fill="#880E4F"/>
<path d="M13.1259 12.4295C13.1144 12.4295 13.1029 12.4255 13.0934 12.418C13.0724 12.4 13.0704 12.368 13.0884 12.347L13.5984 11.7535C13.6164 11.733 13.6474 11.7305 13.6689 11.748C13.6899 11.766 13.6919 11.798 13.6739 11.819L13.1639 12.4125C13.1539 12.424 13.1399 12.4295 13.1259 12.4295Z" fill="#880E4F"/>
<path d="M13.3859 12.1281C13.3584 12.1281 13.3359 12.1061 13.3359 12.0781V11.7351C13.3359 11.7071 13.3584 11.6851 13.3859 11.6851C13.4134 11.6851 13.4359 11.7071 13.4359 11.7351V12.0786C13.4364 12.1061 13.4139 12.1281 13.3859 12.1281Z" fill="#880E4F"/>
<path d="M12.9313 15.3884C12.6088 15.3989 12.3068 15.0969 11.9528 15.0969C11.5988 15.0969 11.2448 15.3884 10.9638 15.3884C10.6828 15.3884 10.2768 15.0759 9.98529 15.0659C9.78129 15.0584 9.25679 15.2834 8.95679 15.4209C9.06829 15.5234 9.18479 15.6204 9.30629 15.7114H14.6933C14.7618 15.6599 14.8313 15.6109 14.8968 15.5559C14.6643 15.3689 14.2343 15.0769 13.7748 15.0559C13.5143 15.0659 13.2543 15.3779 12.9313 15.3884Z" fill="#0D47A1"/>
<path d="M13.8789 14.7429C13.4834 14.7429 13.3999 14.3784 12.9419 14.3784C12.4839 14.3784 12.3279 14.7219 11.9529 14.7429C11.5779 14.7639 11.1619 14.3264 10.8809 14.3579C10.5999 14.3889 10.3604 14.7329 10.0064 14.7534C9.6524 14.7744 9.3924 14.2954 9.0799 14.3264C8.9169 14.3429 8.5974 14.5309 8.3269 14.7079C8.4039 14.8154 8.4844 14.9199 8.5699 15.0199L15.4619 14.9839C15.5159 14.9194 15.5659 14.8519 15.6164 14.7844C15.4089 14.5954 15.0744 14.3404 14.7844 14.3469C14.3264 14.3579 14.2744 14.7429 13.8789 14.7429Z" fill="#0D47A1"/>
<path d="M14.9614 13.0043C14.7114 12.9523 14.6804 13.0668 14.6804 13.0668C14.6804 13.0668 14.6909 13.4833 14.5869 13.5768H16.2649C16.3254 13.4083 16.3769 13.2363 16.4179 13.0593C16.2779 13.1888 16.0294 13.3753 15.7734 13.3478C15.3884 13.3063 15.2114 13.0563 14.9614 13.0043Z" fill="#0D47A1"/>
<path d="M15.6489 13.7229C15.3679 13.7229 15.0244 14.0039 14.8159 14.0249C14.6079 14.0459 14.4829 13.8064 14.4829 13.8064L14.0354 14.2434L15.9579 14.2619C15.9979 14.1899 16.0339 14.1159 16.0704 14.0414C15.9614 13.8919 15.8059 13.7229 15.6489 13.7229Z" fill="#0D47A1"/>
<path d="M9.39195 13.8374C9.39195 13.8374 9.26695 14.0769 9.05895 14.0559C8.85095 14.0349 8.47595 13.7644 8.19495 13.7644C8.08945 13.7644 7.97695 13.8374 7.87695 13.9294C7.93195 14.0499 7.99045 14.1684 8.05495 14.2829L9.83995 14.2749L9.39195 13.8374Z" fill="#0D47A1"/>
<path d="M11.9009 13.2649C11.5679 13.2649 11.4949 13.5979 11.4949 13.5979H12.3484C12.3484 13.5044 12.2339 13.2649 11.9009 13.2649Z" fill="#0D47A1"/>
<path d="M9.3194 13.5979C9.2154 13.5144 9.1944 13.0774 9.1944 13.0774C9.1944 13.0774 9.2154 12.9839 8.9654 12.9839C8.7154 12.9839 8.2889 13.3589 8.0599 13.3484C7.9309 13.3424 7.7409 13.2249 7.5979 13.1229C7.6379 13.2849 7.6859 13.4434 7.7424 13.5984H9.3194V13.5979Z" fill="#0D47A1"/>
<path d="M11.8803 16.2839C11.5578 16.2734 11.4223 15.8989 10.9538 15.8884C10.6343 15.8814 10.3043 16.0759 10.1238 16.2014C10.6963 16.4659 11.3303 16.6174 11.9998 16.6174C12.6443 16.6174 13.2568 16.4789 13.8123 16.2329C13.5498 16.0769 13.1728 15.8869 12.9003 15.8994C12.4423 15.9194 12.2028 16.2944 11.8803 16.2839Z" fill="#0D47A1"/>
<path d="M11.0474 14.2643H12.7859C12.7859 14.2643 12.4529 13.7123 11.9429 13.7023C11.4324 13.6913 11.0474 14.2643 11.0474 14.2643Z" fill="#0D47A1"/>
<path d="M9.08005 11.714L7.47255 11.6655C7.46505 11.775 7.45605 11.8835 7.45605 11.995C7.45605 12.24 7.48005 12.4785 7.51655 12.7135H9.08005V11.714Z" fill="#FF8F00"/>
<path d="M10.7666 10.9851L10.0586 10.4231L10.0376 10.9646L10.7666 11.3706V10.9851Z" fill="#FF8F00"/>
<path d="M10.7041 11.4746L10.0481 11.1831V11.4431L10.7041 11.4746Z" fill="#FF8F00"/>
<path d="M9.48595 9.34052H9.88145C9.88145 9.34052 10.204 9.43402 9.97495 9.66302C10.173 9.80902 9.98545 10.0585 9.98545 10.0585L10.787 10.777L10.766 10.0585L9.55395 8.10352C9.26245 8.29352 8.99295 8.51452 8.75195 8.76502L9.30845 9.41302L9.48595 9.34052Z" fill="#FF8F00"/>
<path d="M14.7017 12.713H16.4842C16.5202 12.4785 16.5447 12.2395 16.5447 11.9945C16.5447 11.867 16.5357 11.7415 16.5257 11.6165L14.7017 11.7655V12.713Z" fill="#FF8F00"/>
<path d="M13.7646 10.5791C13.7646 10.4751 13.8376 10.3191 13.8376 10.3191L13.0256 11.0061V11.3496L13.7541 11.0061C13.7541 11.0061 13.7646 10.6831 13.7646 10.5791Z" fill="#FF8F00"/>
<path d="M10.9016 7.70613L10.8191 7.53613C10.4796 7.62913 10.1541 7.75663 9.85059 7.92263C10.0951 8.40663 10.5091 9.22263 10.7556 9.69463C10.7246 8.36213 10.9016 7.70613 10.9016 7.70613Z" fill="#FF8F00"/>
<path d="M14.7017 10.7769V11.5159C14.7017 11.5159 16.0472 11.3514 16.4877 11.3014C16.4297 10.9099 16.3282 10.5329 16.1797 10.1799L14.7017 10.7769Z" fill="#FF8F00"/>
<path d="M9.0696 10.5686L9.2466 10.3916C9.2466 10.3916 9.0281 10.1626 9.2361 10.0271C9.0381 9.83959 9.2466 9.71459 9.2466 9.71459L8.5086 9.03809C8.2731 9.32559 8.0751 9.64509 7.9126 9.98559L9.0696 10.5686Z" fill="#FF8F00"/>
<path d="M13.7541 11.2036L13.0461 11.5161L13.7541 11.4746V11.2036Z" fill="#FF8F00"/>
<path d="M9.1111 11.4746V10.7981L7.7761 10.2986C7.6471 10.6301 7.5566 10.9801 7.5061 11.3441L9.1111 11.4746Z" fill="#FF8F00"/>
<path d="M13.0671 8.78854L13.0361 9.71504L14.0561 7.87654C13.7366 7.71104 13.3956 7.58354 13.0376 7.49854L12.6401 8.77804L13.0671 8.78854Z" fill="#FF8F00"/>
<path d="M13.0256 10.7976L13.8896 10.0066C13.8896 10.0066 13.6711 9.79862 13.9106 9.65262C13.7336 9.48612 13.8376 9.38212 13.9941 9.37162C14.1501 9.36112 14.5561 9.38212 14.5561 9.38212L15.2126 8.72562C14.9516 8.46012 14.6561 8.23162 14.3381 8.03662L13.0256 10.0791V10.7976Z" fill="#FF8F00"/>
<path d="M14.5872 10.038C14.8267 10.1315 14.5977 10.371 14.5977 10.371C14.5977 10.371 14.7227 10.4335 14.7332 10.5375L16.0322 9.86846C15.8732 9.55696 15.6807 9.26646 15.4587 9.00146L14.6287 9.61146C14.6287 9.61146 14.7847 9.91296 14.5872 10.038Z" fill="#FF8F00"/>
<path d="M12.0001 2C8.51009 2 5.44159 3.7905 3.65259 6.5H20.3476C18.5586 3.7905 15.4901 2 12.0001 2Z" fill="#D50000"/>
<path d="M20.3476 17.5H3.65259C5.44159 20.2095 8.51009 22 12.0001 22C15.4901 22 18.5586 20.2095 20.3476 17.5Z" fill="#D50000"/>
  </svg>
</template>

<script>
export default {
  name: 'PF',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  