
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M13 6.5H20.3475C19.71 5.5345 18.906 4.6925 17.982 4H13V6.5Z" fill="#0038A8"/>
<path d="M13 11H21.9495C21.863 10.127 21.6545 9.292 21.358 8.5H13V11Z" fill="#0038A8"/>
<path d="M21.9495 13H13H12.5H8.50004H6.50004H2.05054C2.13704 13.873 2.34554 14.708 2.64204 15.5H21.3575C21.6545 14.708 21.863 13.873 21.9495 13Z" fill="#0038A8"/>
<path d="M20.3476 17.5H3.65259C4.29009 18.4655 5.09409 19.3075 6.01809 20H17.9816C18.9061 19.3075 19.7101 18.4655 20.3476 17.5Z" fill="#0038A8"/>
<path d="M17.982 4.00004C16.5635 2.93754 14.86 2.23504 13 2.05054V4.00004H17.982Z" fill="white"/>
<path d="M13 8.5H21.358C21.0935 7.7925 20.7575 7.121 20.3475 6.5H13V8.5Z" fill="white"/>
<path d="M13 13H21.9495C21.982 12.671 22 12.3375 22 12C22 11.6625 21.982 11.329 21.9495 11H13V13Z" fill="white"/>
<path d="M21.3581 15.5H2.64209C2.90659 16.2075 3.24259 16.879 3.65259 17.5H20.3476C20.7576 16.879 21.0931 16.2075 21.3581 15.5Z" fill="white"/>
<path d="M17.9821 20H6.01807C7.68757 21.2505 9.75357 22 12.0001 22C14.2466 22 16.3126 21.2505 17.9821 20Z" fill="white"/>
<path d="M12 2C10.7665 2 9.591 2.234 8.5 2.642C7.7925 2.9065 7.121 3.2425 6.5 3.6525C5.369 4.3995 4.3995 5.369 3.6525 6.5C3.2425 7.121 2.907 7.7925 2.642 8.5C2.234 9.591 2 10.7665 2 12C2 12.3375 2.018 12.671 2.0505 13H13V4V2.0505C12.671 2.018 12.3375 2 12 2Z" fill="white"/>
<path d="M7.84289 9.23389L8.17889 9.73839C6.79739 10.7374 7.64039 11.3294 6.66089 11.6484C7.26989 11.0539 6.56589 11.0054 7.07989 9.76489" fill="#FCD116"/>
<path d="M6.62695 11.6704L6.65595 11.6434C6.95545 11.3484 6.93395 11.1904 6.89695 10.9489C6.86045 10.7099 6.81395 10.3834 7.06995 9.76241L7.07495 9.75291L7.09195 9.76041L7.08695 9.76791C6.83095 10.3869 6.87995 10.7109 6.91395 10.9474C6.95045 11.1864 6.97495 11.3469 6.69445 11.6294C7.11845 11.4809 7.17195 11.2759 7.24745 10.9984C7.33295 10.6719 7.43995 10.2624 8.16595 9.73641L7.82995 9.23191L7.84445 9.22241L8.19045 9.74141L8.18295 9.74641C7.45695 10.2729 7.34945 10.6769 7.26445 11.0034C7.18645 11.2959 7.13045 11.5054 6.66245 11.6589L6.62695 11.6704Z" fill="black"/>
<path d="M6.66595 11.6585L6.65845 11.6415C6.99945 11.4785 7.03845 11.2225 7.09445 10.8375C7.14795 10.4795 7.22095 9.98955 7.59645 9.37305L7.61095 9.38255C7.23795 9.99405 7.16995 10.4645 7.11395 10.8395C7.05595 11.232 7.01695 11.4905 6.66595 11.6585Z" fill="black"/>
<path d="M8.07411 8.23999H8.74411L8.07411 11.9315L7.40161 8.23999H8.07411V11.9315Z" fill="#FCD116"/>
<path d="M8.07409 11.9824L7.39209 8.23291H8.75659L8.75409 8.24241L8.07409 11.9824ZM8.08409 8.24941V11.8284L8.73459 8.24941H8.08409ZM7.41409 8.24941L8.06459 11.8284V8.24941H7.41409Z" fill="black"/>
<path d="M7.20952 8.78099L7.09252 9.37299C5.40652 9.10249 5.58452 10.1185 4.66602 9.65299C5.51902 9.66249 5.05352 9.12899 6.29352 8.61499" fill="#FCD116"/>
<path d="M5.09445 9.78703C4.97745 9.78703 4.84095 9.75053 4.66095 9.66053L4.62695 9.64353H4.66595C5.08745 9.64853 5.18245 9.51903 5.32645 9.32453C5.47045 9.12953 5.66745 8.86403 6.28895 8.60803L6.29845 8.60303L6.30595 8.62003L6.29645 8.62503C5.67995 8.88103 5.48245 9.14403 5.34145 9.33653C5.19745 9.52903 5.10245 9.66053 4.70545 9.66303C5.10995 9.85803 5.29245 9.75053 5.54095 9.60703C5.83345 9.43653 6.19895 9.22453 7.08345 9.36353L7.20045 8.77153L7.21995 8.77403L7.09795 9.38553L7.09045 9.38303C6.20345 9.24153 5.84045 9.45353 5.55045 9.62203C5.39395 9.71153 5.26495 9.78703 5.09445 9.78703Z" fill="black"/>
<path d="M4.91159 9.70889C4.83609 9.70889 4.75309 9.69439 4.66309 9.66239L4.66809 9.64289C5.02609 9.76939 5.23359 9.61589 5.54759 9.38439C5.83759 9.16739 6.23459 8.87289 6.93659 8.70239L6.93909 8.71939C6.24459 8.88989 5.86459 9.17239 5.55759 9.39939C5.31859 9.57489 5.14059 9.70889 4.91159 9.70889Z" fill="black"/>
<path d="M8.0741 8.24008L8.5491 8.71508L5.4646 10.8496L7.5991 7.76758L8.0741 8.24008L5.4646 10.8496Z" fill="#FCD116"/>
<path d="M5.42798 10.8859L7.59898 7.75244L8.56148 8.71494L8.55398 8.72244L5.42798 10.8859ZM8.07398 8.25444L5.54248 10.7834L8.53448 8.71244L8.07398 8.25444ZM7.60148 7.77944L5.53048 10.7714L8.06198 8.23994L7.60148 7.77944Z" fill="black"/>
<path d="M7.08003 8.0111L6.57803 8.3471C5.57653 6.9631 4.98453 7.8086 4.66553 6.8291C5.26003 7.4381 5.31103 6.7316 6.54903 7.2456" fill="#FCD116"/>
<path d="M6.57604 8.35948L6.57104 8.35198C6.04454 7.62598 5.63804 7.51848 5.31404 7.43098C5.02154 7.35548 4.80954 7.29948 4.65854 6.83148L4.64404 6.79248L4.67304 6.82148C4.96554 7.12348 5.12604 7.09948 5.36504 7.06248C5.60404 7.02848 5.93254 6.97948 6.55404 7.23548L6.56154 7.24048L6.55654 7.25748L6.54704 7.25248C5.93054 6.99648 5.60404 7.04548 5.36754 7.08198C5.12854 7.11598 4.97054 7.14048 4.68754 6.86248C4.83354 7.28398 5.03854 7.33998 5.31604 7.41298C5.64504 7.50048 6.05204 7.60798 6.58054 8.33398L7.08254 7.99548L7.09204 8.00998L6.57604 8.35948Z" fill="black"/>
<path d="M6.93395 7.77697C6.31995 7.40647 5.85195 7.33597 5.47445 7.27997C5.08445 7.22147 4.82395 7.18247 4.65845 6.83147L4.67295 6.82397C4.83595 7.16747 5.09195 7.20397 5.47695 7.26247C5.83495 7.31347 6.32745 7.38697 6.94395 7.76197L6.93395 7.77697Z" fill="black"/>
<path d="M8.07406 8.24007V8.91257L4.38306 8.24007L8.07456 7.57007L8.07406 8.24007H4.38556Z" fill="#FCD116"/>
<path d="M8.08397 8.92207L8.07197 8.91957L4.33447 8.23957L8.08397 7.55957V8.92207ZM4.48547 8.24957L8.06447 8.90007V8.24957H4.48547ZM4.48547 8.23257H8.06447V7.58207L4.48547 8.23257Z" fill="black"/>
<path d="M7.53348 7.37504L6.94148 7.25804C7.21198 5.57204 6.19598 5.75004 6.66148 4.83154C6.65198 5.68454 7.18548 5.22154 7.69948 6.46154" fill="#FCD116"/>
<path d="M7.54056 7.38498L6.93156 7.26548V7.25598C7.07556 6.36898 6.86356 6.00848 6.69256 5.71598C6.54156 5.45548 6.43206 5.26748 6.65356 4.82648L6.67056 4.79248V4.83148C6.66556 5.25298 6.79506 5.34798 6.98956 5.49198C7.18456 5.63798 7.45006 5.83298 7.70856 6.45698L7.71106 6.46448L7.69406 6.47198L7.69156 6.46448C7.43556 5.84548 7.17006 5.65048 6.97756 5.50698C6.78506 5.36548 6.65606 5.26798 6.65106 4.87098C6.45856 5.27548 6.56356 5.45798 6.70956 5.70648C6.87756 6.00148 7.08956 6.36448 6.95056 7.25098L7.54506 7.36798L7.54056 7.38498Z" fill="black"/>
<path d="M7.59397 7.1071C7.42347 6.4101 7.14097 6.0301 6.91397 5.7231C6.67747 5.4066 6.52147 5.1966 6.65347 4.8291L6.67047 4.8341C6.54397 5.1921 6.69747 5.3996 6.92897 5.7136C7.14597 6.0036 7.44047 6.4006 7.61097 7.1026L7.59397 7.1071Z" fill="black"/>
<path d="M8.0741 8.24012L7.5991 8.71512L5.4646 5.63312L8.5466 7.76762L8.0741 8.24012L5.4646 5.63062Z" fill="#FCD116"/>
<path d="M7.59898 8.72944L7.59148 8.71994L5.42798 5.59644L5.46948 5.62344L8.56398 7.76494L7.59898 8.72944ZM5.53048 5.71094L7.60148 8.70044L8.06198 8.23994L5.53048 5.71094ZM5.54248 5.69644L8.07398 8.22794L8.53448 7.76744L5.54248 5.69644Z" fill="black"/>
<path d="M8.30304 7.24604L7.96704 6.74404C9.35104 5.74254 8.50554 5.15054 9.48504 4.83154C8.87604 5.42604 9.58254 5.47704 9.06854 6.71504" fill="#FCD116"/>
<path d="M8.30108 7.25801L7.95508 6.74151L7.96258 6.73651C8.69108 6.21001 8.79808 5.80601 8.88358 5.47951C8.96158 5.18701 9.01508 4.97501 9.48308 4.82401L9.52208 4.81201L9.49308 4.83901C9.19358 5.13151 9.21508 5.29201 9.25208 5.53101C9.28908 5.77001 9.33758 6.09851 9.07908 6.72001L9.07408 6.72951L9.05708 6.72201L9.06208 6.71251C9.31808 6.09601 9.26908 5.77201 9.23508 5.53301C9.19858 5.29651 9.17408 5.13601 9.45458 4.85301C9.03058 5.00151 8.97708 5.20651 8.90158 5.48401C8.81608 5.81051 8.70908 6.22001 7.98308 6.74601L8.31908 7.24801L8.30108 7.25801Z" fill="black"/>
<path d="M8.5521 7.10946L8.5376 7.09996C8.9081 6.48596 8.9786 6.01796 9.0346 5.64296C9.0931 5.25046 9.1321 4.99246 9.4831 4.82446L9.4906 4.83896C9.1471 5.00196 9.1106 5.25796 9.0521 5.64546C9.0001 6.00346 8.9271 6.49296 8.5521 7.10946Z" fill="black"/>
<path d="M8.07405 8.24003H7.40405L8.07405 4.55103L8.74655 8.24003H8.07405V4.55103Z" fill="#FCD116"/>
<path d="M8.75659 8.2495H7.39209L7.39459 8.24L8.07409 4.5L8.75659 8.2495ZM8.08409 8.2325H8.73459L8.08409 4.6535V8.2325ZM7.41409 8.2325H8.06459V4.6535L7.41409 8.2325Z" fill="black"/>
<path d="M8.93896 7.70142L9.05596 7.10692C10.742 7.37742 10.564 6.36142 11.4825 6.82892C10.6295 6.81692 11.095 7.35292 9.85496 7.86692" fill="#FCD116"/>
<path d="M9.85044 7.87708L9.84294 7.86008L9.85244 7.85758C10.4689 7.60158 10.6639 7.33858 10.8074 7.14608C10.9514 6.95108 11.0464 6.82208 11.4434 6.81958C11.0389 6.62458 10.8564 6.72958 10.6079 6.87558C10.3154 7.04608 9.94994 7.25808 9.06344 7.11658L8.94644 7.71108L8.92944 7.70608L9.05144 7.09708H9.05894C9.94594 7.24108 10.3089 7.02908 10.5989 6.85808C10.8594 6.70708 11.0494 6.59758 11.4884 6.81908L11.5224 6.83858L11.4834 6.83608C11.4759 6.83608 11.4689 6.83608 11.4589 6.83608C11.0594 6.83608 10.9644 6.96508 10.8229 7.15508C10.6789 7.35008 10.4819 7.61558 9.86044 7.87408L9.85044 7.87708Z" fill="black"/>
<path d="M9.21203 7.7795L9.20703 7.7625C9.90403 7.592 10.284 7.3095 10.591 7.0825C10.9075 6.846 11.1175 6.69 11.485 6.8195L11.48 6.8365C11.122 6.71 10.9145 6.8635 10.6005 7.097C10.311 7.3115 9.91403 7.6065 9.21203 7.7795Z" fill="black"/>
<path d="M8.07412 8.24012L7.59912 7.76512L10.6811 5.63062L8.54662 8.71512L8.07412 8.24012L10.6836 5.63062Z" fill="#FCD116"/>
<path d="M8.54947 8.72945L7.58447 7.76445L7.59397 7.75945L10.72 5.59595L8.54947 8.72945ZM8.08647 8.23995L8.54697 8.70045L10.618 5.71095L8.08647 8.23995ZM7.61347 7.76745L8.07397 8.22795L10.6055 5.69645L7.61347 7.76745Z" fill="black"/>
<path d="M9.0686 8.4715L9.5706 8.1355C10.5721 9.517 11.1641 8.674 11.4831 9.6535C10.8886 9.0445 10.8376 9.7485 9.5996 9.2345" fill="#FCD116"/>
<path d="M11.5051 9.68754L11.4761 9.66053C11.1836 9.35853 11.0256 9.38254 10.7841 9.41704C10.5451 9.45354 10.2186 9.50254 9.59512 9.24404L9.58762 9.24154L9.59512 9.22453L9.60262 9.22704C10.2216 9.48304 10.5456 9.43403 10.7821 9.40003C11.0211 9.36353 11.1791 9.33904 11.4621 9.61954C11.3161 9.19554 11.1111 9.14204 10.8336 9.06904C10.5046 8.98154 10.0976 8.87404 9.57162 8.14804L9.06712 8.48404L9.05762 8.46953L9.57412 8.12354L9.57912 8.13103C10.1056 8.85703 10.5121 8.96454 10.8361 9.05204C11.1311 9.12754 11.3406 9.18354 11.4941 9.65154L11.5051 9.68754Z" fill="black"/>
<path d="M11.4756 9.65788C11.3126 9.31438 11.0566 9.27538 10.6691 9.21938C10.3111 9.16588 9.82109 9.09288 9.20459 8.71988L9.21409 8.70288C9.82809 9.07588 10.2961 9.14638 10.6711 9.19988C11.0636 9.25838 11.3216 9.29738 11.4896 9.64838L11.4756 9.65788Z" fill="black"/>
<path d="M8.07397 8.24007V7.57007L11.763 8.24007L8.07397 8.91257V8.24007H11.763Z" fill="#FCD116"/>
<path d="M8.06445 8.92206V7.56006H8.07395L11.814 8.24006L8.06445 8.92206ZM8.08395 8.24956V8.90006L11.663 8.24956H8.08395ZM8.08395 8.23256H11.663L8.08395 7.58206V8.23256Z" fill="black"/>
<path d="M8.61496 9.10498L9.20696 9.22448C8.93646 10.908 9.95246 10.73 9.48496 11.649C9.49696 10.7985 8.96096 11.2615 8.44946 10.0215" fill="#FCD116"/>
<path d="M9.47501 11.69L9.47751 11.6485C9.48251 11.2295 9.35301 11.132 9.15851 10.988C8.96351 10.844 8.69801 10.647 8.43951 10.0255L8.43701 10.016L8.45401 10.0085L8.45651 10.018C8.71251 10.6345 8.97551 10.832 9.16801 10.973C9.36301 11.117 9.49201 11.212 9.49451 11.609C9.68951 11.2045 9.58451 11.022 9.43851 10.7735C9.26801 10.481 9.05601 10.1155 9.19751 9.23098L8.60301 9.11398L8.60801 9.09448L9.21701 9.21648V9.22398C9.07551 10.111 9.28501 10.474 9.45601 10.764C9.60701 11.027 9.71651 11.2145 9.49501 11.6535L9.47501 11.69Z" fill="black"/>
<path d="M9.49441 11.6535L9.47741 11.646C9.60391 11.288 9.45041 11.0805 9.21691 10.7665C9.00241 10.4765 8.70791 10.0795 8.53491 9.3775L8.55441 9.375C8.72491 10.0695 9.00491 10.4495 9.23441 10.7565C9.46791 11.076 9.62391 11.2855 9.49441 11.6535Z" fill="black"/>
<path d="M8.07412 8.23989L8.54912 7.76489L10.6836 10.8494L7.59912 8.71489L8.07412 8.23989L10.6836 10.8494Z" fill="#FCD116"/>
<path d="M10.72 10.8859L10.6785 10.8569L7.58398 8.71543L8.54898 7.75293L8.55648 7.76043L10.72 10.8859ZM7.61348 8.71243L10.603 10.7834L8.07398 8.25443L7.61348 8.71243ZM8.08648 8.23993L10.618 10.7714L8.54698 7.77943L8.08648 8.23993Z" fill="black"/>
<path d="M9.30451 8.24002C9.30451 8.92002 8.75401 9.47052 8.07401 9.47052C7.39401 9.47052 6.84351 8.92002 6.84351 8.24002C6.84351 7.56002 7.39401 7.00952 8.07401 7.00952C8.75401 7.00952 9.30451 7.56002 9.30451 8.24002Z" fill="#FCD116"/>
<path d="M8.07398 9.47994C7.39198 9.47994 6.83398 8.92444 6.83398 8.23994C6.83398 7.55794 7.39198 7.00244 8.07398 7.00244C8.75598 7.00244 9.31398 7.55794 9.31398 8.23994C9.31448 8.92444 8.75648 9.47994 8.07398 9.47994ZM8.07398 7.01944C7.40148 7.01944 6.85348 7.56744 6.85348 8.23994C6.85348 8.91244 7.40148 9.46044 8.07398 9.46044C8.74648 9.46044 9.29448 8.91244 9.29448 8.23994C9.29498 7.56744 8.74648 7.01944 8.07398 7.01944Z" fill="black"/>
<path d="M8.98054 7.74793C8.90254 7.83793 8.85604 7.68193 8.57604 7.68193C8.29804 7.68193 8.25204 7.84993 8.20804 7.81593C8.16154 7.78193 8.44204 7.58193 8.53204 7.56993C8.62254 7.55793 8.87854 7.64793 8.98054 7.74793ZM8.65404 7.84993C8.73204 7.91593 8.66604 8.06193 8.58804 8.06193C8.51004 8.06193 8.36404 7.92793 8.43204 7.83793" fill="black"/>
<path d="M8.28589 7.94994C8.29789 7.81594 8.41039 7.79395 8.58789 7.79395C8.76839 7.79395 8.84639 7.92794 8.91189 7.95944C8.83389 7.95944 8.76789 7.84995 8.58789 7.84995C8.40989 7.84995 8.40989 7.84995 8.28589 7.95944M8.32039 7.98395C8.36439 7.91595 8.42039 8.04995 8.54439 8.04995C8.66639 8.04995 8.73439 8.01595 8.81239 7.96245C8.89039 7.90645 8.70039 8.09645 8.57839 8.09645C8.45439 8.09595 8.28589 8.02795 8.32039 7.98395Z" fill="black"/>
<path d="M8.70057 8.04989C8.84657 7.97189 8.75657 7.85989 8.70057 7.83789C8.72257 7.85989 8.81257 7.97189 8.70057 8.04989ZM8.07407 8.72239C8.14207 8.72239 8.16157 8.69789 8.25207 8.69789C8.34257 8.69789 8.55407 8.82239 8.67857 8.77589C8.42007 8.87839 8.52007 8.80989 8.07457 8.80989H8.01857M8.72257 8.87839C8.67857 8.80039 8.68857 8.82239 8.60057 8.69789C8.68807 8.76639 8.71257 8.80039 8.72257 8.87839ZM8.07407 8.99039C8.35407 8.99039 8.30807 8.93439 8.67807 8.77839C8.41007 8.80989 8.35407 8.90039 8.07407 8.90039H8.01807M8.07407 8.57589C8.15207 8.57589 8.17407 8.51989 8.26407 8.51989C8.35407 8.51989 8.47607 8.55389 8.53207 8.59789C8.54407 8.60739 8.49807 8.50789 8.41007 8.49789C8.32007 8.48589 8.35407 8.33939 8.25157 8.31739C8.25157 8.36389 8.28557 8.35139 8.29807 8.41739C8.29807 8.47339 8.19807 8.47339 8.17357 8.41739C8.19807 8.50739 8.12957 8.50739 8.07357 8.50739" fill="black"/>
<path d="M7.16797 7.74793C7.24597 7.83793 7.29247 7.68193 7.56997 7.68193C7.84997 7.68193 7.89397 7.84993 7.94047 7.81593C7.98447 7.78193 7.70647 7.58193 7.61647 7.56993C7.52597 7.55793 7.26747 7.64793 7.16797 7.74793ZM7.49197 7.84993C7.41397 7.91593 7.48247 8.06193 7.55997 8.06193C7.63747 8.06193 7.78397 7.92793 7.71597 7.83793" fill="black"/>
<path d="M7.86208 7.94994C7.85008 7.81594 7.73758 7.79395 7.56008 7.79395C7.37958 7.79395 7.30158 7.92794 7.23608 7.95944C7.31408 7.95944 7.38008 7.84995 7.56008 7.84995C7.73808 7.84995 7.73808 7.84995 7.86208 7.95944M7.82808 7.98395C7.78408 7.91595 7.72808 8.04995 7.60408 8.04995C7.48208 8.04995 7.41408 8.01595 7.33608 7.96245C7.25808 7.90645 7.44808 8.09645 7.57008 8.09645C7.69408 8.09395 7.86208 8.02795 7.82808 7.98395Z" fill="black"/>
<path d="M7.4481 8.04989C7.3021 7.97189 7.3921 7.85989 7.4481 7.83789C7.4261 7.85989 7.3361 7.97189 7.4481 8.04989ZM8.0741 8.72239C8.0061 8.72239 7.9841 8.69789 7.8936 8.69789C7.8061 8.69789 7.5941 8.82239 7.4696 8.77589C7.7281 8.87839 7.6256 8.80989 8.0736 8.80989H8.1296M7.4261 8.87839C7.4701 8.80039 7.4576 8.82239 7.5481 8.69789C7.4581 8.76639 7.4361 8.80039 7.4261 8.87839ZM8.0741 8.99039C7.7941 8.99039 7.8401 8.93439 7.4701 8.77839C7.7381 8.80989 7.7941 8.90039 8.0741 8.90039H8.1301M8.0741 8.57589C7.9961 8.57589 7.9741 8.51989 7.8841 8.51989C7.7941 8.51989 7.6721 8.55389 7.6161 8.59789C7.6041 8.60739 7.6501 8.50789 7.7381 8.49789C7.8281 8.48589 7.7941 8.33939 7.8941 8.31739C7.8941 8.36389 7.8626 8.35139 7.8501 8.41739C7.8501 8.47339 7.9501 8.47339 7.9721 8.41739C7.9501 8.50739 8.0186 8.50739 8.0746 8.50739" fill="black"/>
<path d="M8.07402 9.09058C8.01802 9.09058 7.87202 9.12458 8.07402 9.12458C8.27652 9.12458 8.13052 9.09058 8.07402 9.09058Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'UY',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  