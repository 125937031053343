
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.3581 15.5H6.60007L3.51807 17.278C5.28357 20.1095 8.41707 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#2E7D32"/>
<path d="M12.6666 12L6.6001 15.5H21.3581C21.7661 14.409 22.0001 13.2335 22.0001 12C22.0001 10.7665 21.7661 9.591 21.3581 8.5H6.6001L12.6666 12Z" fill="white"/>
<path d="M21.3581 8.5C19.9376 4.706 16.2896 2 12.0001 2C8.41707 2 5.28357 3.8905 3.51807 6.722L6.60007 8.5H21.3581Z" fill="#263238"/>
<path d="M12.6665 11.9999L6.6 8.49992L3.518 6.72192C2.5615 8.25492 2 10.0599 2 11.9999C2 13.9399 2.5615 15.7449 3.518 17.2779L6.6 15.4999L12.6665 11.9999Z" fill="#D50000"/>
<path d="M5.5 10.5L5.834 11.367L6.703 11.0935L6.25 11.9025L7 12.43L6.1015 12.5705L6.168 13.5L5.5 12.867L4.832 13.5L4.8985 12.5705L4 12.4295L4.75 11.902L4.297 11.0935L5.166 11.367L5.5 10.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'JO',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  