
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12H2C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12Z" fill="#7CB342"/>
<path d="M12 2C6.477 2 2 6.477 2 12H22C22 6.477 17.523 2 12 2Z" fill="#FF3D00"/>
<path d="M12 9.62207L12.59 11.4971H14.5L12.955 12.5616L13.545 14.3781L12 13.2551L10.455 14.3781L11.045 12.5616L9.5 11.4971H11.41L12 9.62207Z" fill="#FFEB3B"/>
  </svg>
</template>

<script>
export default {
  name: 'BF',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  