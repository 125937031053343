
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22.0001 12C22.0001 6.477 17.5231 2 12.0001 2C9.09109 2 6.48009 3.25 4.65259 5.233L16.8336 12L4.65259 18.767C6.48009 20.75 9.09109 22 12.0001 22C17.5231 22 22.0001 17.523 22.0001 12Z" fill="#E53935"/>
<path d="M4.65246 5.23291C4.54246 5.35241 4.42896 5.46891 4.32446 5.59341L12.8665 11.9999L4.32496 18.4064C4.42896 18.5314 4.54246 18.6474 4.65296 18.7669L16.8335 11.9999L4.65246 5.23291Z" fill="#FFD600"/>
<path d="M4.325 5.59351C2.8745 7.32901 2 9.56151 2 12C2 14.4385 2.8745 16.671 4.325 18.4065L12.8665 12L4.325 5.59351ZM5.3205 14.59L5.0355 12.7035L3.1545 12.391L4.8595 11.5375L4.5765 9.65251L5.9145 11.012L7.6195 10.1585L6.7425 11.85L8.0805 13.2095L6.1995 12.897L5.3205 14.59Z" fill="#263238"/>
<path d="M6.74204 11.8496L7.61904 10.1581L5.91404 11.0116L4.57604 9.65259L4.85954 11.5371L3.15454 12.3906L5.03504 12.7031L5.32054 14.5901L6.19904 12.8966L8.08004 13.2091L6.74204 11.8496Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TLS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  