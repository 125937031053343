
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="white"/>
<path d="M5.95496 10.5479L8.13246 7.19238L8.42746 7.38388L6.24996 10.7374L5.95496 10.5479Z" fill="#263238"/>
<path d="M4.39648 14.4641L4.73248 14.2471L6.90648 17.6046L6.57248 17.8216L4.39648 14.4641Z" fill="#263238"/>
<path d="M16.332 6.87008L16.666 6.65308L18.879 10.0436L18.543 10.2626L16.332 6.87008Z" fill="#263238"/>
<path d="M5.172 10.0635L7.3495 6.70801L7.6425 6.89751L5.465 10.253L5.172 10.0635Z" fill="#263238"/>
<path d="M16.7225 9.20004L17.0155 9.00854L18.0235 10.561L17.7285 10.7525L16.7225 9.20004Z" fill="#263238"/>
<path d="M18.2264 15.8215L19.2344 14.269L19.5274 14.4605L18.5194 16.0115L18.2264 15.8215Z" fill="#263238"/>
<path d="M6.31848 15.7549L6.61148 15.5654L7.61948 17.1164L7.32648 17.3079L6.31848 15.7549Z" fill="#263238"/>
<path d="M18.2795 8.2081L18.5725 8.0166L19.586 9.5636L19.293 9.7551L18.2795 8.2081Z" fill="#263238"/>
<path d="M16.6855 14.8096L17.6935 13.2571L17.9865 13.4486L16.9785 14.9996L16.6855 14.8096Z" fill="#263238"/>
<path d="M17.4685 15.2939L18.48 13.7429L18.814 13.9619L17.804 15.5129L17.4685 15.2939Z" fill="#263238"/>
<path d="M15.574 7.38388L15.867 7.19238L16.8805 8.73938L16.5875 8.93088L15.574 7.38388Z" fill="#263238"/>
<path d="M17.0525 17.6105L18.0505 16.052L18.3455 16.2415L17.3455 17.798L17.0525 17.6105Z" fill="#263238"/>
<path d="M5.17603 13.9326L5.51203 13.7156L6.51203 15.2721L6.17603 15.4891L5.17603 13.9326Z" fill="#263238"/>
<path d="M17.1331 6.38539L17.4261 6.19189L18.4416 7.73889L18.1486 7.93039L17.1331 6.38539Z" fill="#263238"/>
<path d="M15.504 16.5849L16.514 15.0359L16.85 15.2549L15.8385 16.8039L15.504 16.5849Z" fill="#263238"/>
<path d="M16.2675 17.1199L17.2695 15.5654L17.6055 15.7824L16.6035 17.3369L16.2675 17.1199Z" fill="#263238"/>
<path d="M4.37903 9.56946L6.59403 6.17896L6.93003 6.39795L4.71503 9.78846L4.37903 9.56946Z" fill="#263238"/>
<path d="M5.92749 13.4249L6.22249 13.2334L8.39649 16.5909L8.10349 16.7824L5.92749 13.4249Z" fill="#263238"/>
<path d="M10.25 13.741C11.212 13.741 12 12.953 12 11.991C12 11.029 12.788 10.241 13.75 10.241C14.712 10.241 15.5 11.029 15.5 11.991C15.5 10.0665 13.9245 8.49097 12 8.49097C10.0755 8.49097 8.5 10.0665 8.5 11.991C8.5 12.9535 9.288 13.741 10.25 13.741Z" fill="#F44336"/>
<path d="M13.75 10.241C12.788 10.241 12 11.029 12 11.991C12 12.953 11.212 13.741 10.25 13.741C9.288 13.741 8.5 12.953 8.5 11.991C8.5 13.9155 10.0755 15.491 12 15.491C13.9245 15.491 15.5 13.9155 15.5 11.991C15.5 11.029 14.712 10.241 13.75 10.241Z" fill="#3949AB"/>
  </svg>
</template>

<script>
export default {
  name: 'KR',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  