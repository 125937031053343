
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12.0001 2C7.64805 2 3.95555 4.7855 2.58105 8.6665H21.4186C20.0446 4.7855 16.3521 2 12.0001 2Z" fill="#43A047"/>
<path d="M21.419 15.3335C21.7885 14.2895 22 13.1705 22 12C22 10.8295 21.7885 9.7105 21.419 8.6665H2.581C2.2115 9.7105 2 10.8295 2 12C2 13.1705 2.2115 14.2895 2.581 15.3335H21.419Z" fill="#FFCA28"/>
<path d="M11.9996 22H12.0006C16.3526 22 20.0446 19.2145 21.4186 15.3335H2.58105C3.95555 19.2145 7.64755 21.9995 11.9996 22Z" fill="#43A047"/>
<path d="M2 11.9999C2 14.7934 3.148 17.3169 4.995 19.1309L10.7 11.9999L4.995 4.8689C3.148 6.6829 2 9.2064 2 11.9999Z" fill="#E53935"/>
<path d="M18.7585 9.7395L19.3125 11.3745L21.0385 11.396L19.6545 12.4285L20.1675 14.077L18.7585 13.0795L17.349 14.077L17.862 12.4285L16.478 11.396L18.2045 11.3745L18.7585 9.7395Z" fill="#263238"/>
<path d="M13.2805 9.7395L13.834 11.3745L15.5605 11.396L14.1765 12.4285L14.6895 14.077L13.2805 13.0795L11.871 14.077L12.384 12.4285L11 11.396L12.7265 11.3745L13.2805 9.7395Z" fill="#263238"/>
  </svg>
</template>

<script>
export default {
  name: 'ST',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  