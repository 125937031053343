
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#64B5F6"/>
<path d="M12.0001 3.896L6.10962 18.46H17.8906L12.0001 3.896Z" fill="white"/>
<path d="M16.864 18.0001L12 5.87158L7.13599 18.0001H16.864Z" fill="#263238"/>
<path d="M11.9999 11.186L6.05591 18.5H17.9439L11.9999 11.186Z" fill="#FFCA28"/>
  </svg>
</template>

<script>
export default {
  name: 'LC',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  