
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M9.50004 2.32788C7.47254 2.85088 5.69404 3.98588 4.37854 5.53438L9.50004 8.66538V2.32788Z" fill="#3F51B5"/>
<path d="M4.30249 18.3821C5.62449 19.9746 7.43499 21.1396 9.49999 21.6726V15.2051L4.30249 18.3821Z" fill="#3F51B5"/>
<path d="M2.328 14.5C2.3345 14.526 2.341 14.5515 2.348 14.5775L2.474 14.5H2.328Z" fill="white"/>
<path d="M19.788 5.73438C18.458 4.08388 16.614 2.87288 14.5 2.32788V8.96638L19.788 5.73438Z" fill="#3F51B5"/>
<path d="M14.5 21.6721C16.6515 21.1171 18.5205 19.8706 19.8555 18.1751L14.5 14.9021V21.6721Z" fill="#3F51B5"/>
<path d="M2.3765 9.31055C2.359 9.37305 2.344 9.43705 2.328 9.50005H2.6865L2.3765 9.31055Z" fill="white"/>
<path d="M21.8755 10.5C21.828 10.185 21.7665 9.87554 21.6905 9.57104L20.171 10.5H21.8755Z" fill="white"/>
<path d="M2.68651 9.5H2.32801C2.24351 9.8275 2.17601 10.1605 2.12451 10.5H4.32251L2.68651 9.5Z" fill="white"/>
<path d="M21.72 14.3145C21.7835 14.047 21.834 13.775 21.8755 13.5H20.387L21.72 14.3145Z" fill="white"/>
<path d="M4.11001 13.5H2.12451C2.17551 13.8395 2.24351 14.1725 2.32801 14.5H2.47451L4.11001 13.5Z" fill="white"/>
<path d="M10.5 9.27651V2.12451C10.1605 2.17551 9.8275 2.24351 9.5 2.32801V8.66551L10.5 9.27651Z" fill="white"/>
<path d="M13.5 14.291V21.8755C13.8395 21.8245 14.1725 21.7565 14.5 21.672V14.902L13.5 14.291Z" fill="white"/>
<path d="M9.5 15.205V21.672C9.8275 21.7565 10.1605 21.824 10.5 21.8755V14.594L9.5 15.205Z" fill="white"/>
<path d="M13.5925 9.34701L13.7345 9.43401L14.5 8.96601V2.32801C14.1725 2.24351 13.8395 2.17601 13.5 2.12451V9.49851L13.5925 9.34701Z" fill="white"/>
<path d="M4.32248 10.4999H9.01748L10.5 9.59392V9.27692L9.49998 8.66542L4.37848 5.53442C3.95148 6.03742 3.57198 6.58142 3.24948 7.16292L7.02498 9.49992H4.29698L2.65048 8.47642C2.54748 8.74942 2.45548 9.02692 2.37598 9.31042L2.68648 9.49992L4.32248 10.4999Z" fill="white"/>
<path d="M13.7345 9.43412L15.4785 10.5001H20.171L21.6905 9.57112C21.528 8.92112 21.303 8.29762 21.02 7.70512L18.139 9.50012H15.411L20.317 6.45012C20.1515 6.20312 19.9735 5.96562 19.7875 5.73462L14.5 8.96612L13.7345 9.43412Z" fill="white"/>
<path d="M7.025 9.50009L3.25 7.16309C3.0175 7.58259 2.822 8.02309 2.651 8.47659L4.297 9.50009H7.025Z" fill="#E53935"/>
<path d="M18.139 9.49994L21.0205 7.70494C20.813 7.26994 20.5835 6.84794 20.3175 6.45044L15.411 9.49994H18.139Z" fill="#E53935"/>
<path d="M13.5 13.5V14.291L14.5 14.902L19.8555 18.1755C20.2415 17.685 20.5835 17.159 20.8735 16.6005L17.4855 14.5H20.2135L21.443 15.267C21.5505 14.956 21.643 14.639 21.7195 14.315L20.387 13.5H13.5Z" fill="white"/>
<path d="M15.4785 10.4999L13.7345 9.43392L13.5925 9.34692L13.5 9.49842V10.4999H15.4785Z" fill="white"/>
<path d="M10.5 14.594V13.5H4.11003L2.47403 14.5L2.34753 14.5775C2.52353 15.238 2.76353 15.871 3.06403 16.4705L6.16453 14.5H8.89253L3.80153 17.7165C3.96053 17.9445 4.12553 18.1685 4.30253 18.382L9.50003 15.205L10.5 14.594Z" fill="white"/>
<path d="M9.01746 10.5H10.5V9.59399L9.01746 10.5Z" fill="white"/>
<path d="M17.4855 14.5L20.873 16.6C21.095 16.173 21.2835 15.727 21.443 15.2665L20.2135 14.5H17.4855Z" fill="#E53935"/>
<path d="M6.16447 14.5L3.06396 16.4705C3.28146 16.9045 3.52547 17.3215 3.80147 17.7165L8.89246 14.5H6.16447Z" fill="#E53935"/>
<path d="M15.4785 10.5H13.5V13.5H20.387H21.8755C21.9495 13.0095 22 12.511 22 12C22 11.489 21.9495 10.9905 21.8755 10.5H20.171H15.4785Z" fill="#E53935"/>
<path d="M10.5 13.5V10.5H9.0175H4.3225H2.1245C2.0505 10.9905 2 11.489 2 12C2 12.511 2.0505 13.0095 2.1245 13.5H4.11H10.5Z" fill="#E53935"/>
<path d="M13.5 21.8755V14.291V13.5V10.5V9.4985V2.1245C13.0095 2.0505 12.511 2 12 2C11.489 2 10.9905 2.0505 10.5 2.1245V9.2765V9.5935V10.5V13.5V14.594V21.8755C10.9905 21.9495 11.489 22 12 22C12.511 22 13.0095 21.9495 13.5 21.8755Z" fill="#E53935"/>
  </svg>
</template>

<script>
export default {
  name: 'GB',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  