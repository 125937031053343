
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.3579 8.5H2.64189C2.51989 8.826 2.41539 9.159 2.32739 9.5H21.6719C21.5844 9.159 21.4794 8.826 21.3579 8.5Z" fill="white"/>
<path d="M21.6719 14.5H2.32788C2.41588 14.841 2.52038 15.174 2.64238 15.5H21.3579C21.4794 15.174 21.5844 14.841 21.6719 14.5Z" fill="white"/>
<path d="M12.0001 2C7.71059 2 4.06259 4.706 2.64209 8.5H21.3576C19.9376 4.706 16.2896 2 12.0001 2Z" fill="#263238"/>
<path d="M22 12C22 11.135 21.8785 10.3005 21.672 9.5H2.328C2.1215 10.3005 2 11.135 2 12C2 12.865 2.1215 13.6995 2.328 14.5H21.6725C21.8785 13.6995 22 12.865 22 12Z" fill="#D50000"/>
<path d="M21.3581 15.5H2.64209C4.06259 19.294 7.71059 22 12.0001 22C16.2896 22 19.9376 19.294 21.3581 15.5Z" fill="#33691E"/>
<path d="M13.8435 7.92751C13.693 8.18751 13.5485 8.44901 13.537 8.71651L9.28101 16.3785L9.71851 16.6205L13.9685 8.97001C14.199 8.82751 14.3535 8.56951 14.5035 8.30801C14.6305 8.08751 14.8885 7.64401 15.109 6.49951C14.2285 7.26351 13.9705 7.70701 13.8435 7.92751Z" fill="white"/>
<path d="M13.8435 7.92751C13.693 8.18751 13.5485 8.44901 13.537 8.71651L9.28101 16.3785L9.71851 16.6205L13.9685 8.97001C14.199 8.82751 14.3535 8.56951 14.5035 8.30801C14.6305 8.08751 14.8885 7.64401 15.109 6.49951C14.2285 7.26351 13.9705 7.70701 13.8435 7.92751Z" fill="white"/>
<path d="M10.1559 7.92751C10.3064 8.18751 10.4509 8.44901 10.4624 8.71651L14.7184 16.3785L14.2809 16.6205L10.0309 8.97001C9.80038 8.82751 9.64588 8.56951 9.49588 8.30801C9.36888 8.08751 9.11088 7.64401 8.89038 6.49951C9.77138 7.26351 10.0294 7.70701 10.1559 7.92751Z" fill="white"/>
<path d="M13.9785 9.75C13.666 9 12.625 7.5 12 7.5C11.375 7.5 10.334 9 10.0215 9.75C9.709 10.5 9.5 11.25 9.5 12C9.5 12.75 9.709 13.5 10.0215 14.25C10.334 15 11.375 16.5 12 16.5C12.625 16.5 13.666 15 13.9785 14.25C14.291 13.5 14.5 12.75 14.5 12C14.5 11.25 14.291 10.5 13.9785 9.75Z" fill="#D50000"/>
<path d="M9.5 12C9.5 12.7385 9.703 13.4785 10.008 14.217C10.301 13.477 10.4965 12.7385 10.4965 12C10.4965 11.2615 10.301 10.5215 10.008 9.78296C9.703 10.5215 9.5 11.2615 9.5 12Z" fill="black"/>
<path d="M14.4999 12C14.4999 12.7385 14.2969 13.4785 13.9919 14.217C13.6989 13.477 13.5034 12.7385 13.5034 12C13.5034 11.2615 13.6989 10.5215 13.9919 9.78296C14.2969 10.5215 14.4999 11.2615 14.4999 12Z" fill="black"/>
<path d="M12.5 12C12.5 11.7245 12.2755 11.5 12 11.5C11.7245 11.5 11.5 11.7245 11.5 12C11.5 12.2755 11.7245 12.5 12 12.5C12.2755 12.5 12.5 12.2755 12.5 12Z" fill="white"/>
<path d="M12 7.5C12 7.5 11.5 8.2615 11.5 9.5C11.5 10.7385 12 11.5 12 11.5C12 11.5 12.5 10.7385 12.5 9.5C12.5 8.2615 12 7.5 12 7.5Z" fill="white"/>
<path d="M12 12.5C12 12.5 11.5 13.2615 11.5 14.5C11.5 15.7385 12 16.5 12 16.5C12 16.5 12.5 15.7385 12.5 14.5C12.5 13.2615 12 12.5 12 12.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'KE',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  