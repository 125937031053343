
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.892 2.18 9.826 2.513 8.8295C3.1555 6.906 7.2445 5.5 7.2445 5.5C7.2445 5.5 7.2645 3.1535 7.998 2.833C9.2235 2.297 10.577 2 12 2C17.523 2 22 6.477 22 12Z" fill="#283593"/>
<path d="M8.00001 4.297V2.8375C7.28401 3.1505 6.61651 3.5495 6.00001 4.0135V4.155L5.89701 4.091C4.34451 5.2905 3.15551 6.9325 2.52051 8.8325L6.00001 6.66V9H8.00001V6.5175L11.9745 9H12.5V7.6955L10.5855 6.5H12.5V4.5H10.2895L12.5 3.119V2.025C12.3335 2.017 12.169 2 12 2C11.8825 2 11.768 2.0135 11.6515 2.0175L8.00001 4.297Z" fill="white"/>
<path d="M6.50004 6.00011V9.00011H7.50004V6.00011H12.5V5.00011H7.50004V3.07861C7.15554 3.25261 6.82004 3.44111 6.50004 3.65261V5.00011H4.86454C4.55804 5.31261 4.27754 5.64961 4.01404 6.00011H6.50004Z" fill="#E53935"/>
<path d="M5.82054 6.5H4.94904L2.96304 7.7395C2.84854 7.9815 2.74454 8.228 2.64954 8.48L5.82054 6.5Z" fill="#E53935"/>
<path d="M12.1065 2.00562L8.11145 4.50012H8.98445L12.5 2.30462V2.02512C12.369 2.01862 12.239 2.00712 12.1065 2.00562Z" fill="#E53935"/>
<path d="M12.5 7.967L10.1505 6.5H9.27954L12.5 8.51V7.967Z" fill="#E53935"/>
<path d="M13.67 13.1486L14.754 12.1916L13.3145 12.0526L12.7385 10.7266L12.162 12.0526L10.7225 12.1916L11.8045 13.1486L11.492 14.5606L12.7385 13.8261L13.9845 14.5606L13.67 13.1486Z" fill="white"/>
<path d="M16.3145 16.0526L15.7385 14.7246L15.162 16.0526L13.7225 16.1896L14.8045 17.1486L14.492 18.5586L15.7385 17.8261L16.9845 18.5586L16.67 17.1486L17.754 16.1896L16.3145 16.0526Z" fill="white"/>
<path d="M14.492 11.0606L15.7385 10.3261L16.9845 11.0606L16.67 9.64856L17.754 8.69156L16.3145 8.55256L15.7365 7.22656L15.162 8.55256L13.7225 8.69156L14.8045 9.64856L14.492 11.0606Z" fill="white"/>
<path d="M20.254 11.6916L18.8145 11.5526L18.2385 10.2266L17.662 11.5526L16.2225 11.6916L17.3065 12.6486L16.992 14.0606L18.2385 13.3261L19.4845 14.0606L19.17 12.6506L20.254 11.6916Z" fill="white"/>
<path d="M12.7385 11.9805L12.9725 12.5235L13.5605 12.578L13.119 12.9705L13.246 13.547L12.7385 13.246L12.2305 13.547L12.3575 12.9705L11.916 12.578L12.504 12.5235L12.7385 11.9805Z" fill="#E53935"/>
<path d="M15.7385 15.9805L15.9725 16.5235L16.5605 16.578L16.119 16.9705L16.246 17.547L15.7385 17.246L15.2305 17.547L15.3575 16.9705L14.916 16.578L15.504 16.5235L15.7385 15.9805Z" fill="#E53935"/>
<path d="M15.7385 8.48047L15.9725 9.02347L16.5605 9.07797L16.119 9.47047L16.246 10.047L15.7385 9.74597L15.2305 10.047L15.3575 9.47047L14.916 9.07797L15.504 9.02347L15.7385 8.48047Z" fill="#E53935"/>
<path d="M18.2385 11.4805L18.4725 12.0235L19.0605 12.078L18.619 12.4705L18.746 13.047L18.2385 12.746L17.7305 13.047L17.8575 12.4705L17.416 12.078L18.004 12.0235L18.2385 11.4805Z" fill="#E53935"/>
  </svg>
</template>

<script>
export default {
  name: 'NZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  