
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.892 2.18 9.826 2.513 8.8295C3.1555 6.906 7.2445 5.5 7.2445 5.5C7.2445 5.5 7.2645 3.1535 7.998 2.833C9.2235 2.297 10.577 2 12 2C17.523 2 22 6.477 22 12Z" fill="#283593"/>
<path d="M8.00001 4.297V2.8375C7.28401 3.1505 6.61651 3.5495 6.00001 4.0135V4.155L5.89701 4.091C4.34451 5.2905 3.15551 6.9325 2.52051 8.8325L6.00001 6.66V9H8.00001V6.5175L11.9745 9H12.5V7.6955L10.5855 6.5H12.5V4.5H10.2895L12.5 3.119V2.025C12.3335 2.017 12.169 2 12 2C11.8825 2 11.768 2.0135 11.6515 2.0175L8.00001 4.297Z" fill="white"/>
<path d="M6.49992 6.00011V9.00011H7.49992V6.00011H12.4999V5.00011H7.49992V3.07861C7.15542 3.25261 6.81992 3.44111 6.49992 3.65261V5.00011H4.86442C4.55792 5.31261 4.27742 5.64961 4.01392 6.00011H6.49992Z" fill="#E53935"/>
<path d="M5.82041 6.5H4.94891L2.96291 7.7395C2.84841 7.9815 2.74441 8.228 2.64941 8.48L5.82041 6.5Z" fill="#E53935"/>
<path d="M12.1066 2.00562L8.11157 4.50012H8.98457L12.5001 2.30462V2.02512C12.3691 2.01862 12.2391 2.00712 12.1066 2.00562Z" fill="#E53935"/>
<path d="M12.5 7.967L10.1505 6.5H9.27954L12.5 8.51V7.967Z" fill="#E53935"/>
<path d="M15.797 10.5H15.7925H12.606V14.798C12.606 16.546 13.871 18.095 15.7925 18.4985V18.5C15.7935 18.5 15.794 18.4995 15.795 18.4995C15.796 18.4995 15.7965 18.5 15.7975 18.5V18.4985C17.719 18.095 18.9845 16.5455 18.9845 14.798V10.5H15.797Z" fill="#00ACC1"/>
<path d="M12.739 15.7141C13.097 17.0631 14.2175 18.1676 15.7925 18.4986V18.5001C15.7935 18.5001 15.794 18.4996 15.795 18.4996C15.796 18.4996 15.7965 18.5001 15.7975 18.5001V18.4986C17.3725 18.1681 18.4935 17.0631 18.851 15.7141H12.739Z" fill="#BF360C"/>
<path d="M15.7974 18.55C15.7954 18.55 15.7944 18.55 15.7924 18.55C15.7844 18.55 15.7774 18.5485 15.7709 18.545C13.8769 18.1425 12.5554 16.603 12.5554 14.798V10.5C12.5554 10.472 12.5774 10.45 12.6054 10.45H18.9839C19.0119 10.45 19.0339 10.472 19.0339 10.5V14.798C19.0339 16.6035 17.7119 18.143 15.8179 18.5455C15.8114 18.5485 15.8044 18.55 15.7974 18.55ZM12.6554 10.55V14.798C12.6554 16.557 13.9459 18.057 15.7949 18.448C17.6434 18.057 18.9339 16.557 18.9339 14.798V10.55H12.6554Z" fill="white"/>
<path d="M15.3266 14.7571H15.2766V16.8121H15.3266V14.7571Z" fill="#424242"/>
<path d="M15.1306 14.7571H15.0806V16.8121H15.1306V14.7571Z" fill="#424242"/>
<path d="M14.9355 14.7571H14.8855V16.4631H14.9355V14.7571Z" fill="#424242"/>
<path d="M14.7395 15.229H14.6895V16.293H14.7395V15.229Z" fill="#424242"/>
<path d="M16.6289 17.1949L16.7054 17.4334L16.5354 17.9354L16.8589 17.8334L16.9949 17.5014L16.8164 17.0249L16.6289 17.1949Z" fill="#FFEBEE"/>
<path d="M16.4961 17.9739L16.6791 17.4329L16.6001 17.1869L16.6121 17.1764L16.8266 16.9814L17.0221 17.5019L17.0181 17.5109L16.8776 17.8544L16.8666 17.8579L16.4961 17.9739ZM16.6576 17.2024L16.7316 17.4329L16.7291 17.4409L16.5746 17.8969L16.8396 17.8129L16.9676 17.5009L16.8056 17.0684L16.6576 17.2024Z" fill="#424242"/>
<path d="M15.8034 17.4504L15.5139 17.6714H15.7779L16.0844 17.5269L15.8034 17.4504Z" fill="#FFEBEE"/>
<path d="M15.7829 17.6971H15.4399L15.7979 17.4231L15.8094 17.4266L16.1554 17.5206L15.7829 17.6971ZM15.5879 17.6471H15.7719L16.0124 17.5331L15.8089 17.4776L15.5879 17.6471Z" fill="#424242"/>
<path d="M16.893 12.9054V13.3139C16.893 13.3139 16.893 14.0629 16.6715 14.0544C16.85 14.3524 17.0375 14.8799 17.063 15.0759C17.0885 15.2719 16.8415 16.8459 17.199 17.4589C16.9945 17.4079 16.901 17.1184 16.569 17.3824C16.237 17.6464 15.922 17.6889 15.6755 17.4759C15.599 17.2204 15.9225 16.7694 15.948 16.3864C15.9735 16.0034 15.778 14.6759 15.778 14.6759L15.7865 12.7609C15.7865 12.7609 16.0505 12.5139 16.2375 12.5139C16.4245 12.5139 16.5865 12.5309 16.5865 12.5309C16.5865 12.5309 16.9355 12.3864 16.893 12.9054Z" fill="#388E3C"/>
<path d="M16.0265 17.6356C15.8925 17.6356 15.7685 17.5886 15.66 17.4951L15.654 17.4901L15.652 17.4831C15.6075 17.3356 15.6865 17.1331 15.7705 16.9186C15.84 16.7401 15.912 16.5551 15.9235 16.3851C15.9485 16.0086 15.755 14.6926 15.753 14.6791L15.762 12.7506L15.77 12.7431C15.781 12.7326 16.043 12.4891 16.2385 12.4891C16.4045 12.4891 16.5525 12.5026 16.5835 12.5056C16.612 12.4956 16.7255 12.4611 16.815 12.5266C16.8995 12.5876 16.934 12.7156 16.9185 12.9066V13.3136C16.9185 13.3371 16.917 13.8946 16.7645 14.0411C16.7485 14.0566 16.731 14.0676 16.7125 14.0731C16.8835 14.3716 17.062 14.8696 17.0885 15.0716C17.095 15.1216 17.085 15.2491 17.072 15.4256C17.031 15.9716 16.9545 16.9871 17.2215 17.4456L17.252 17.4971L17.1945 17.4831C17.1275 17.4661 17.073 17.4266 17.02 17.3886C16.9035 17.3031 16.801 17.2306 16.586 17.4016C16.3895 17.5571 16.1995 17.6356 16.0265 17.6356ZM15.698 17.4621C15.9925 17.7101 16.3255 17.5446 16.554 17.3631C16.8 17.1681 16.932 17.2631 17.0485 17.3486C17.081 17.3721 17.1125 17.3951 17.146 17.4126C16.9085 16.9186 16.9815 15.9521 17.0215 15.4231C17.034 15.2556 17.044 15.1236 17.038 15.0791C17.014 14.8951 16.831 14.3686 16.6505 14.0676L16.6265 14.0281L16.673 14.0301C16.674 14.0301 16.6755 14.0301 16.6755 14.0301C16.695 14.0301 16.7125 14.0221 16.7295 14.0056C16.866 13.8736 16.868 13.3196 16.868 13.3141V12.9041C16.8815 12.7336 16.8535 12.6176 16.785 12.5676C16.7075 12.5116 16.5975 12.5546 16.5955 12.5546L16.59 12.5571L16.5835 12.5566C16.5815 12.5566 16.421 12.5396 16.2375 12.5396C16.0795 12.5396 15.8555 12.7331 15.811 12.7731L15.802 14.6766C15.81 14.7276 15.998 16.0076 15.9725 16.3891C15.961 16.5676 15.887 16.7556 15.8165 16.9381C15.738 17.1396 15.663 17.3316 15.698 17.4621Z" fill="#424242"/>
<path d="M15.9054 11.0334H15.4714V17.0589H15.9054V11.0334Z" fill="#263238"/>
<path d="M17.1395 11.5525H14.2715V11.995H17.1395V11.5525Z" fill="#263238"/>
<path d="M16.1951 12.344L15.9906 12.3015L16.0331 12.0885L15.9651 12.0375L16.0926 11.944V11.8505L16.1946 11.74L16.5266 11.8505L16.6031 12.0375L16.4756 12.31L16.5861 12.5315L16.4161 12.7015L16.2031 12.829C16.2031 12.829 16.1181 12.6505 16.1861 12.531C16.1951 12.4205 16.1951 12.344 16.1951 12.344Z" fill="#FFEBEE"/>
<path d="M16.1931 12.8644L16.1816 12.8394C16.1776 12.8314 16.0941 12.6524 16.1626 12.5234C16.1681 12.4504 16.1696 12.3924 16.1701 12.3639L15.9621 12.3204L16.0061 12.0989L15.9241 12.0369L16.0686 11.9309V11.8404L16.0751 11.8329L16.1886 11.7104L16.2036 11.7154L16.5466 11.8299L16.6321 12.0374L16.5051 12.3089L16.6181 12.5354L16.4356 12.7184L16.1931 12.8644ZM16.0201 12.2814L16.2196 12.3229V12.3434C16.2196 12.3464 16.2196 12.4224 16.2111 12.5329L16.2076 12.5434C16.1616 12.6249 16.1961 12.7414 16.2156 12.7929L16.4036 12.6799L16.5561 12.5259L16.4481 12.3099L16.5761 12.0364L16.5081 11.8699L16.2021 11.7679L16.1176 11.8599V11.9559L16.0071 12.0374L16.0606 12.0779L16.0571 12.0929L16.0201 12.2814Z" fill="#424242"/>
<path d="M16.7226 12.2931C16.7226 12.2931 16.9441 12.2761 16.8501 12.0716C16.8586 11.8671 16.6716 11.9356 16.6716 11.9356C16.6716 11.9356 16.6801 11.6971 16.4076 11.6971C16.2716 11.5866 16.1351 11.6971 16.1351 11.6971C16.1351 11.6971 16.0926 11.7906 16.3566 11.8671C16.3906 12.0286 16.4416 12.0371 16.4416 12.0371C16.4416 12.0371 16.3566 12.2416 16.5691 12.2501C16.6971 12.3781 16.7226 12.2931 16.7226 12.2931Z" fill="#BF360C"/>
<path d="M16.683 12.3454C16.649 12.3454 16.608 12.3219 16.5585 12.2744C16.4935 12.2699 16.448 12.2469 16.4225 12.2064C16.388 12.1529 16.4015 12.0829 16.412 12.0489C16.3895 12.0304 16.359 11.9874 16.336 11.8864C16.216 11.8504 16.142 11.8044 16.117 11.7504C16.101 11.7154 16.1115 11.6899 16.1125 11.6869L16.12 11.6774C16.171 11.6359 16.297 11.5809 16.417 11.6719C16.5065 11.6734 16.5765 11.7004 16.626 11.7509C16.6755 11.8024 16.691 11.8654 16.6955 11.9034C16.7295 11.8964 16.782 11.8939 16.822 11.9239C16.8595 11.9514 16.878 11.9994 16.8765 12.0664C16.9055 12.1324 16.907 12.1884 16.8805 12.2329C16.8455 12.2924 16.771 12.3104 16.7395 12.3154C16.733 12.3259 16.721 12.3379 16.7015 12.3434C16.6955 12.3449 16.6895 12.3454 16.683 12.3454ZM16.1575 11.7119C16.1575 11.7159 16.1585 11.7229 16.163 11.7324C16.175 11.7559 16.2175 11.8014 16.3635 11.8434L16.378 11.8474L16.381 11.8624C16.4095 11.9974 16.448 12.0139 16.448 12.0139L16.479 12.0189L16.4645 12.0479C16.4645 12.0479 16.4315 12.1299 16.464 12.1814C16.482 12.2089 16.5175 12.2239 16.57 12.2264L16.58 12.2269L16.587 12.2339C16.651 12.2974 16.681 12.2989 16.6885 12.2959C16.696 12.2944 16.699 12.2864 16.699 12.2864L16.704 12.2704L16.721 12.2689C16.722 12.2689 16.8065 12.2609 16.837 12.2084C16.855 12.1779 16.852 12.1354 16.8275 12.0829L16.825 12.0774L16.8255 12.0714C16.8275 12.0189 16.816 11.9829 16.7915 11.9654C16.7505 11.9344 16.6805 11.9594 16.6805 11.9594L16.646 11.9719L16.647 11.9349C16.647 11.9349 16.649 11.8474 16.589 11.7859C16.5485 11.7439 16.4875 11.7224 16.408 11.7224H16.399L16.392 11.7169C16.287 11.6324 16.185 11.6929 16.1575 11.7119Z" fill="#424242"/>
<path d="M15.4884 12.7695H15.2929C15.2929 12.7695 15.4204 12.361 15.3609 12.0035C15.5224 11.893 15.6504 11.8165 15.6504 11.8165L15.6249 11.6465L15.4974 11.655L15.3699 11.7315L15.3359 11.6805L15.2084 11.9105C15.2084 11.9105 15.0039 12.421 15.0299 12.872L15.0044 13.059L15.4894 13.11V12.7695H15.4884Z" fill="#FFEBEE"/>
<path d="M15.5136 13.1374L14.9751 13.0809L15.0046 12.8679C14.9786 12.4209 15.1826 11.9054 15.1846 11.9004L15.3336 11.6319L15.3771 11.6974L15.4951 11.6294L15.6466 11.6189L15.6776 11.8284L15.6631 11.8374C15.6621 11.8379 15.5416 11.9104 15.3881 12.0144C15.4336 12.3154 15.3546 12.6409 15.3261 12.7439H15.5136V13.1374ZM15.0316 13.0369L15.4636 13.0819V12.7944H15.2586L15.2691 12.7624C15.2706 12.7584 15.3941 12.3549 15.3361 12.0074L15.3336 11.9919L15.3466 11.9829C15.4766 11.8944 15.5856 11.8269 15.6231 11.8039L15.6036 11.6724L15.5051 11.6794L15.3626 11.7654L15.3376 11.7284L15.2301 11.9229C15.2291 11.9249 15.0291 12.4304 15.0541 12.8709L15.0316 13.0369Z" fill="#424242"/>
<path d="M15.0971 14.4885L14.9016 14.7525C14.9016 14.7525 14.9271 15.0845 14.6801 15.1865C14.4331 15.2885 14.0331 15.3995 14.0501 15.714C14.0671 16.029 14.3916 15.9965 14.4671 15.9355C14.5351 15.88 14.5801 15.7835 14.4926 15.714C14.4201 15.6565 14.3226 15.68 14.3056 15.731C14.2971 15.731 14.2781 15.5855 14.3501 15.529C14.4396 15.459 14.6201 15.416 14.7906 15.3565C14.9606 15.297 15.2076 15.016 15.2416 14.812C15.6756 15.2205 16.0076 16.4035 15.5991 17.025C15.1991 16.6165 14.4906 15.912 14.4906 15.912L14.3691 15.9695L15.7266 17.544C15.7266 17.544 15.9396 16.8715 15.9991 16.497C16.0591 16.1225 15.9481 14.8545 15.0971 14.4885Z" fill="#FFA000"/>
<path d="M15.7375 17.594L15.708 17.56L14.361 15.9975C14.3065 16.0055 14.2415 15.9995 14.184 15.974C14.116 15.9435 14.034 15.875 14.0255 15.7155C14.0095 15.421 14.3365 15.2935 14.575 15.201C14.6085 15.1885 14.641 15.1755 14.6705 15.1635C14.898 15.0695 14.8765 14.7575 14.8765 14.7545L14.8755 14.745L15.089 14.458L15.107 14.466C15.9905 14.8455 16.078 16.1625 16.0245 16.501C15.965 16.873 15.753 17.545 15.751 17.5515L15.7375 17.594ZM14.417 15.9865L15.717 17.494C15.7645 17.3395 15.925 16.8075 15.975 16.4935C16.027 16.1655 15.945 14.8985 15.106 14.52L14.9275 14.7605C14.93 14.818 14.93 15.111 14.6905 15.2095C14.66 15.222 14.6275 15.235 14.5935 15.248C14.3565 15.34 14.062 15.4545 14.076 15.713C14.082 15.8205 14.1255 15.893 14.205 15.9285C14.256 15.9515 14.3135 15.9555 14.3585 15.948L14.36 15.9475L14.4795 15.8905C14.5035 15.863 14.5175 15.8325 14.517 15.805C14.517 15.7785 14.5045 15.7545 14.4785 15.734C14.44 15.7035 14.395 15.7025 14.368 15.71C14.3485 15.716 14.3345 15.727 14.3305 15.7395L14.3245 15.7565H14.289L14.28 15.7355C14.2695 15.706 14.258 15.571 14.3355 15.51C14.405 15.4555 14.5235 15.4175 14.649 15.3775C14.6935 15.3635 14.739 15.349 14.7835 15.3335C14.941 15.278 15.185 15.0085 15.2175 14.808L15.2255 14.7615L15.2595 14.7935C15.6975 15.2055 16.0405 16.4 15.621 17.038L15.604 17.064L15.582 17.042C15.2225 16.6745 14.606 16.0605 14.492 15.9475C14.4895 15.95 14.4865 15.9525 14.4835 15.955C14.468 15.9675 14.445 15.9785 14.417 15.9865ZM14.526 15.9115C14.635 16.02 15.2315 16.614 15.595 16.9845C15.9665 16.375 15.661 15.282 15.256 14.861C15.196 15.0725 14.961 15.3235 14.7995 15.38C14.754 15.396 14.7085 15.411 14.664 15.425C14.543 15.463 14.4285 15.4995 14.3665 15.5485C14.331 15.5765 14.322 15.6345 14.322 15.6755C14.3315 15.6695 14.3415 15.665 14.353 15.662C14.402 15.647 14.4635 15.659 14.509 15.6945C14.5465 15.7245 14.5665 15.7625 14.5665 15.8045C14.5665 15.8405 14.552 15.878 14.526 15.9115Z" fill="#424242"/>
<path d="M16.7908 12.9055C16.7908 12.9055 16.8248 13.161 16.7568 13.3395C16.6888 13.518 16.4928 13.935 16.4928 13.935C16.4928 13.935 15.7353 14.2245 15.4543 14.25C15.3353 14.4715 15.3013 14.4715 15.3013 14.4715L15.2418 14.667L15.0373 14.769L14.9863 14.488L15.0798 14.2925L15.3863 14.114C15.3863 14.114 15.8458 13.7225 16.2373 13.697C16.3308 13.4245 16.4158 13.212 16.4158 13.212C16.4158 13.212 16.6118 12.778 16.7908 12.9055Z" fill="#FFEBEE"/>
<path d="M15.0189 14.8066L14.9604 14.4851L15.0609 14.2751L15.0674 14.2711L15.3739 14.0926C15.3889 14.0791 15.8279 13.7081 16.2189 13.6736C16.3099 13.4111 16.3924 13.2046 16.3929 13.2026C16.3994 13.1896 16.5294 12.9036 16.6834 12.8621C16.7274 12.8516 16.7679 12.8586 16.8054 12.8851L16.8145 12.8916L16.8159 12.9021C16.8174 12.9126 16.8494 13.1656 16.7804 13.3486C16.7124 13.5261 16.518 13.9421 16.516 13.9461L16.5119 13.9551L16.502 13.9591C16.4715 13.9706 15.7604 14.2406 15.4704 14.2741C15.3879 14.4261 15.3449 14.4741 15.3219 14.4896L15.2629 14.6856L15.0189 14.8066ZM15.0119 14.4921L15.0559 14.7321L15.2204 14.6496L15.2824 14.4466H15.2954C15.3044 14.4396 15.3434 14.4031 15.4319 14.2386L15.4384 14.2271L15.4514 14.2256C15.7089 14.2016 16.386 13.9491 16.474 13.9156C16.5035 13.8536 16.6714 13.4921 16.7329 13.3311C16.7899 13.1811 16.7724 12.9706 16.7669 12.9206C16.7444 12.9076 16.7204 12.9041 16.6959 12.9106C16.5864 12.9401 16.4734 13.1456 16.4384 13.2221C16.4379 13.2231 16.3524 13.4366 16.2604 13.7056L16.2549 13.7211L16.2385 13.7221C15.86 13.7466 15.4064 14.1291 15.4019 14.1331L15.0984 14.3101L15.0119 14.4921Z" fill="#424242"/>
<path d="M16.7455 16.6921L16.4385 15.6886C16.4385 15.1096 16.3875 14.4846 16.387 14.4781L16.437 14.4741C16.4375 14.4806 16.4885 15.1046 16.4885 15.6846L16.7935 16.6771L16.7455 16.6921Z" fill="#424242"/>
<path d="M16.7961 15.8431C16.7706 15.1506 16.6321 14.4621 16.6306 14.4556L16.6796 14.4456C16.6811 14.4526 16.8206 15.1446 16.8461 15.8411L16.7961 15.8431Z" fill="#424242"/>
<path d="M16.335 17.1971L16.285 17.1931C16.335 16.6066 16.022 15.8466 16.019 15.8391L16.065 15.8196C16.0685 15.8271 16.3855 16.5981 16.335 17.1971Z" fill="#424242"/>
  </svg>
</template>

<script>
export default {
  name: 'MS',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  