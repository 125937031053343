
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.892 2.18 9.826 2.513 8.8295C3.1555 6.906 7.2445 5.5 7.2445 5.5C7.2445 5.5 7.2645 3.1535 7.998 2.833C9.2235 2.297 10.577 2 12 2C17.523 2 22 6.477 22 12Z" fill="#283593"/>
<path d="M8.00001 4.297V2.8375C7.28401 3.1505 6.61651 3.5495 6.00001 4.0135V4.155L5.89701 4.091C4.34451 5.2905 3.15551 6.9325 2.52051 8.8325L6.00001 6.66V9H8.00001V6.5175L11.9745 9H12.5V7.6955L10.5855 6.5H12.5V4.5H10.2895L12.5 3.119V2.025C12.3335 2.017 12.169 2 12 2C11.8825 2 11.768 2.0135 11.6515 2.0175L8.00001 4.297Z" fill="white"/>
<path d="M6.50004 6.00011V9.00011H7.50004V6.00011H12.5V5.00011H7.50004V3.07861C7.15554 3.25261 6.82004 3.44111 6.50004 3.65261V5.00011H4.86454C4.55804 5.31261 4.27754 5.64961 4.01404 6.00011H6.50004Z" fill="#E53935"/>
<path d="M5.82054 6.5H4.94904L2.96304 7.7395C2.84854 7.9815 2.74454 8.228 2.64954 8.48L5.82054 6.5Z" fill="#E53935"/>
<path d="M12.1065 2.00562L8.11145 4.50012H8.98445L12.5 2.30462V2.02512C12.369 2.01862 12.239 2.00712 12.1065 2.00562Z" fill="#E53935"/>
<path d="M12.5 7.967L10.1505 6.5H9.27954L12.5 8.51V7.967Z" fill="#E53935"/>
<path d="M15.4796 19.4999L15.1641 18.9399L14.5341 19.0649L14.9691 18.5919L14.6551 18.0314L15.2396 18.2989L15.6761 17.8274L15.6021 18.4654L16.1856 18.7349L15.5556 18.8619L15.4796 19.4999Z" fill="white"/>
<path d="M13.3935 19.4746L13.333 18.8346L12.7065 18.6926L13.296 18.4376L13.2375 17.7976L13.6625 18.2796L14.253 18.0266L13.926 18.5796L14.3495 19.0626L13.7225 18.9226L13.3935 19.4746Z" fill="white"/>
<path d="M11.498 18.6035L11.703 17.9945L11.1885 17.61L11.831 17.617L12.0375 17.0085L12.23 17.6215L12.8725 17.6305L12.3485 18.0025L12.539 18.616L12.023 18.233L11.498 18.6035Z" fill="white"/>
<path d="M10.121 17.0366L10.556 16.5636L10.242 16.0031L10.8265 16.2706L11.2625 15.7986L11.189 16.4371L11.772 16.7066L11.1425 16.8336L11.0665 17.4716L10.751 16.9116L10.121 17.0366Z" fill="white"/>
<path d="M9.5 15.0444L10.09 14.7894L10.031 14.1494L10.456 14.6314L11.0465 14.3779L10.7195 14.9309L11.143 15.4144L10.516 15.2744L10.187 15.8264L10.127 15.1864L9.5 15.0444Z" fill="white"/>
<path d="M9.74304 12.9726L10.386 12.9791L10.5925 12.3706L10.7845 12.9841L11.427 12.9926L10.9035 13.3646L11.0935 13.9786L10.578 13.5956L10.053 13.9661L10.258 13.3571L9.74304 12.9726Z" fill="white"/>
<path d="M10.808 11.1786L11.3925 11.4461L11.8285 10.9741L11.755 11.6126L12.338 11.8816L11.7085 12.0086L11.6325 12.6471L11.317 12.0871L10.687 12.2121L11.122 11.7391L10.808 11.1786Z" fill="white"/>
<path d="M12.5105 9.97241L12.9355 10.4544L13.526 10.2009L13.1985 10.7539L13.622 11.2374L12.995 11.0969L12.666 11.6494L12.606 11.0094L11.979 10.8674L12.569 10.6124L12.5105 9.97241Z" fill="white"/>
<path d="M14.556 9.56299L14.7485 10.1765L15.391 10.185L14.867 10.5575L15.0575 11.171L14.5415 10.788L14.0165 11.1585L14.2215 10.5495L13.707 10.165L14.3495 10.1715L14.556 9.56299Z" fill="white"/>
<path d="M16.5915 10.0215L16.5175 10.66L17.101 10.929L16.471 11.056L16.3955 11.6945L16.08 11.1345L15.4495 11.2595L15.8845 10.7865L15.571 10.226L16.155 10.4935L16.5915 10.0215Z" fill="white"/>
<path d="M18.2645 11.2681L17.9375 11.8211L18.361 12.3046L17.7335 12.1641L17.405 12.7161L17.3445 12.0766L16.718 11.9346L17.3075 11.6791L17.249 11.0396L17.674 11.5216L18.2645 11.2681Z" fill="white"/>
<path d="M19.2854 13.0871L18.7614 13.4596L18.9519 14.0731L18.4359 13.6901L17.9114 14.0606L18.1164 13.4516L17.6014 13.0671L18.2439 13.0736L18.4509 12.4651L18.6429 13.0786L19.2854 13.0871Z" fill="white"/>
<path d="M19.479 15.1646L18.849 15.2916L18.773 15.9296L18.4575 15.3696L17.8275 15.4951L18.2625 15.0221L17.9485 14.4611L18.533 14.7286L18.9695 14.2571L18.8955 14.8951L19.479 15.1646Z" fill="white"/>
<path d="M18.811 17.141L18.1835 17.0005L17.855 17.5525L17.7945 16.913L17.168 16.771L17.7575 16.5155L17.699 15.876L18.124 16.3575L18.7145 16.1045L18.3875 16.6575L18.811 17.141Z" fill="white"/>
<path d="M17.3955 18.6739L16.8795 18.2909L16.355 18.6614L16.56 18.0524L16.045 17.6679L16.6875 17.6744L16.8945 17.0659L17.0865 17.6794L17.729 17.6879L17.2055 18.0599L17.3955 18.6739Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'CK',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  