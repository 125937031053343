
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#DD2C00"/>
<path d="M12 8C9.791 8 8 9.791 8 12C8 14.209 9.791 16 12 16C14.209 16 16 14.209 16 12C16 9.791 14.209 8 12 8ZM12 15C10.3435 15 9 13.656 9 12C9 10.344 10.344 9 12 9C12.957 9.002 13.8575 9.459 14.42 10.2345C13.9785 9.8265 13.4005 9.6015 12.801 9.5995C11.475 9.5995 10.4005 10.6735 10.4005 12C10.4005 13.3265 11.4745 14.4005 12.801 14.4005C13.4005 14.3985 13.9785 14.174 14.42 13.7675C13.8575 14.541 12.957 14.998 12 15ZM14.256 13.0585L13.012 12.654L12.2445 13.7125V12.404L11 12L12.244 11.5955V10.289L13.0115 11.3455L14.2555 10.943L13.4885 12L14.256 13.0585Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TN',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  