
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#D50000"/>
<path d="M9.72503 15.5L10.5935 12.8265L8.32153 11.174H11.132L12 8.5L12.8715 11.1735H15.679L13.407 12.826L14.275 15.5L12.003 13.8475L9.72503 15.5ZM12.4855 13.4955L13.189 14.009L12.9215 13.178L12.4855 13.4955ZM11.0785 13.178L10.811 14.009L11.5145 13.4955L11.0785 13.178ZM11.2625 12.6085L12 13.144L12.7345 12.6085L12.4545 11.7465H11.5455L11.2625 12.6085ZM10.0765 11.7465L10.78 12.257L10.945 11.7465H10.0765ZM13.055 11.7465L13.22 12.257L13.9265 11.7465H13.055ZM11.729 11.1735H12.2705L12 10.3425L11.729 11.1735Z" fill="#1B5E20"/>
  </svg>
</template>

<script>
export default {
  name: 'MA',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  