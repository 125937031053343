
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M12 2.50001C13.606 2.50001 15.1185 2.37851 16.4635 3.05151C19.743 4.69251 21.9945 8.08351 21.9945 12C21.9945 17.523 17.5175 22 11.9945 22C6.47151 22 1.99451 17.523 1.99451 12C3.00001 6.50001 6.47701 2.50001 12 2.50001Z" fill="#FDD835"/>
<path d="M1.99901 11.871C1.99851 11.914 1.99451 11.9565 1.99451 12H7.14551V8.79346L1.99901 11.871Z" fill="#283593"/>
<path d="M7.14545 4.92294V3.25244C6.68795 3.50644 6.25545 3.79944 5.84595 4.11944L7.14545 4.92294Z" fill="#283593"/>
<path d="M10.0544 5.141L14.6614 2.3635C13.8129 2.1275 12.9189 2 11.9944 2C11.3304 2 10.6819 2.0655 10.0544 2.189V5.141Z" fill="#283593"/>
<path d="M3.56954 8.42847H2.65704C2.57204 8.65047 2.49454 8.87547 2.42554 9.10447L3.56954 8.42847Z" fill="#283593"/>
<path d="M13.522 5.57143H16.6005V3.71143L13.522 5.57143Z" fill="#283593"/>
<path d="M16.6005 9.99247V8.42847H13.995L16.6005 9.99247Z" fill="#283593"/>
<path d="M10.0544 12H15.7604L10.0544 8.57495V12Z" fill="#283593"/>
<path d="M16.6005 3.12891C15.9935 2.81141 15.35 2.55391 14.6775 2.36591L10.055 5.14041V2.19141C9.01849 2.39541 8.04049 2.75941 7.14599 3.25691V4.92291L5.83249 4.13491C4.41949 5.24391 3.31649 6.72291 2.66299 8.42841H3.56949L2.42049 9.11791C2.15699 9.99441 2.01199 10.9214 2.00049 11.8814L7.14549 8.79291V11.9999H10.0545V8.57491L15.7605 11.9999H16.6V9.99241L13.9945 8.42841H16.6V5.57141H13.522L16.6 3.72341L16.6005 3.12891Z" fill="white"/>
<path d="M4.48047 5.41593L4.73897 5.57093H6.13397L4.97947 4.87793C4.80497 5.04993 4.64197 5.23193 4.48047 5.41593Z" fill="#E53935"/>
<path d="M15.986 2.83654C15.7095 2.71504 15.427 2.60454 15.138 2.50854L10.0355 5.57154H11.43L15.986 2.83654Z" fill="#E53935"/>
<path d="M11.903 8.42847L16.6005 11.248V10.411L13.2975 8.42847H11.903Z" fill="#E53935"/>
<path d="M9.32747 2.37109C8.82547 2.51009 8.33997 2.68509 7.87297 2.89659V6.28609H3.79447C3.48047 6.73609 3.20747 7.21509 2.96997 7.71459H7.87297V12.0001H9.32747V7.71409H16.6005V6.28559H9.32747V2.37109Z" fill="#E53935"/>
<path d="M2.10996 10.56C2.06746 10.853 2.03896 11.1495 2.02246 11.45L7.05596 8.42847H5.66146L2.10996 10.56Z" fill="#E53935"/>
<path d="M4.42396 6.26709L4.60646 6.80559L5.17446 6.81259L4.71896 7.15259L4.88796 7.69509L4.42396 7.36659L3.95996 7.69509L4.12896 7.15259L3.67346 6.81259L4.24146 6.80559L4.42396 6.26709Z" fill="#FDD835"/>
<path d="M8.60499 3.35254L8.78699 3.89054L9.35549 3.89754L8.89999 4.23754L9.06899 4.78004L8.60499 4.45154L8.14099 4.78004L8.30999 4.23754L7.85449 3.89754L8.42249 3.89054L8.60499 3.35254Z" fill="#FDD835"/>
<path d="M8.60499 9.21411L8.78699 9.75261L9.35549 9.75961L8.89999 10.0991L9.06899 10.6421L8.60499 10.3136L8.14099 10.6421L8.30999 10.0991L7.85449 9.75961L8.42249 9.75261L8.60499 9.21411Z" fill="#FDD835"/>
<path d="M13.137 6.26709L13.319 6.80559L13.8875 6.81259L13.432 7.15259L13.601 7.69509L13.137 7.36659L12.673 7.69509L12.842 7.15259L12.3865 6.81259L12.9545 6.80559L13.137 6.26709Z" fill="#FDD835"/>
<path d="M8.59998 8.3335C9.33645 8.3335 9.93348 7.73648 9.93348 7C9.93348 6.26353 9.33645 5.6665 8.59998 5.6665C7.86351 5.6665 7.26648 6.26353 7.26648 7C7.26648 7.73648 7.86351 8.3335 8.59998 8.3335Z" fill="#283593"/>
<path d="M8.58303 5.66406L8.88453 6.55356L9.82353 6.56556L9.07053 7.12706L9.35003 8.02356L8.58303 7.48106L7.81653 8.02356L8.09553 7.12706L7.34253 6.56556L8.28153 6.55356L8.58303 5.66406Z" fill="#FDD835"/>
  </svg>
</template>

<script>
export default {
  name: 'NU',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  