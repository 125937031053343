
<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      class="flag-border"
      cx="12"
      cy="12"
      r="10.25"
      fill="none"
      stroke-width="1.5"
      :stroke="includeBorder ? '#F7F8F9' : 'transparent'"
    />
    <path d="M21.1624 16H2.8374C4.3814 19.5305 7.9004 22 11.9999 22C16.0994 22 19.6184 19.5305 21.1624 16Z" fill="#00C853"/>
<path d="M11.9999 2C7.9004 2 4.3814 4.4695 2.8374 8H21.1619C19.6184 4.4695 16.0994 2 11.9999 2Z" fill="#00ACC1"/>
<path d="M21.3581 15.5H2.64209C2.70509 15.668 2.76609 15.8365 2.83759 16H21.1621C21.2341 15.8365 21.2951 15.668 21.3581 15.5Z" fill="#D50000"/>
<path d="M21.1626 8H2.83759C2.76609 8.1635 2.70509 8.332 2.64209 8.5H21.3576C21.2951 8.332 21.2341 8.1635 21.1626 8Z" fill="#D50000"/>
<path d="M22 12C22 10.7665 21.766 9.591 21.358 8.5H2.642C2.234 9.591 2 10.7665 2 12C2 13.2335 2.234 14.409 2.642 15.5H21.3575C21.766 14.409 22 13.2335 22 12Z" fill="white"/>
<path d="M9.5 5.75C9.5 6.717 8.717 7.5 7.75 7.5C6.783 7.5 6 6.717 6 5.75C6 4.783 6.783 4 7.75 4C8.717 4 9.5 4.783 9.5 5.75Z" fill="white"/>
<path d="M10 5.75C10 6.717 9.217 7.5 8.25 7.5C7.283 7.5 6.5 6.717 6.5 5.75C6.5 4.783 7.283 4 8.25 4C9.217 4 10 4.783 10 5.75Z" fill="#00ACC1"/>
<path d="M13.3455 4.3085L13.5 4.1915H13.3085L13.25 4L13.1915 4.1915H13L13.1545 4.3085L13.0955 4.5L13.25 4.383L13.4045 4.5L13.3455 4.3085Z" fill="white"/>
<path d="M14.8455 4.3085L15 4.1915H14.8085L14.75 4L14.6915 4.1915H14.5L14.6545 4.3085L14.5955 4.5L14.75 4.383L14.9045 4.5L14.8455 4.3085Z" fill="white"/>
<path d="M11.8455 4.3085L12 4.1915H11.8085L11.75 4L11.6915 4.1915H11.5L11.6545 4.3085L11.5955 4.5L11.75 4.383L11.9045 4.5L11.8455 4.3085Z" fill="white"/>
<path d="M13.3455 5.8085L13.5 5.6915H13.3085L13.25 5.5L13.1915 5.6915H13L13.1545 5.8085L13.0955 6L13.25 5.883L13.4045 6L13.3455 5.8085Z" fill="white"/>
<path d="M14.8455 5.8085L15 5.6915H14.8085L14.75 5.5L14.6915 5.6915H14.5L14.6545 5.8085L14.5955 6L14.75 5.883L14.9045 6L14.8455 5.8085Z" fill="white"/>
<path d="M11.8455 5.8085L12 5.6915H11.8085L11.75 5.5L11.6915 5.6915H11.5L11.6545 5.8085L11.5955 6L11.75 5.883L11.9045 6L11.8455 5.8085Z" fill="white"/>
<path d="M13.3455 7.3085L13.5 7.1915H13.3085L13.25 7L13.1915 7.1915H13L13.1545 7.3085L13.0955 7.5L13.25 7.383L13.4045 7.5L13.3455 7.3085Z" fill="white"/>
<path d="M14.8455 7.3085L15 7.1915H14.8085L14.75 7L14.6915 7.1915H14.5L14.6545 7.3085L14.5955 7.5L14.75 7.383L14.9045 7.5L14.8455 7.3085Z" fill="white"/>
<path d="M11.8455 7.3085L12 7.1915H11.8085L11.75 7L11.6915 7.1915H11.5L11.6545 7.3085L11.5955 7.5L11.75 7.383L11.9045 7.5L11.8455 7.3085Z" fill="white"/>
<path d="M10.3455 5.8085L10.5 5.6915H10.3085L10.25 5.5L10.1915 5.6915H10L10.1545 5.8085L10.0955 6L10.25 5.883L10.4045 6L10.3455 5.8085Z" fill="white"/>
<path d="M10.3455 7.3085L10.5 7.1915H10.3085L10.25 7L10.1915 7.1915H10L10.1545 7.3085L10.0955 7.5L10.25 7.383L10.4045 7.5L10.3455 7.3085Z" fill="white"/>
<path d="M8.8455 7.3085L9 7.1915H8.8085L8.75 7L8.6915 7.1915H8.5L8.6545 7.3085L8.5955 7.5L8.75 7.383L8.9045 7.5L8.8455 7.3085Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'UZ',
  props: {
    includeBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
}
</script>
  